<template>
  <ion-card class="plan-spend-overview-tile">
    <h2 class="title">Your {{ supportCategory }} spend overview</h2>
    <ion-accordion-group :multiple="true" :value="accordionKeys">
      <ion-card class="reserved-budget-card" v-if="planSupportItems.plan_support_preferences?.length > 0">
        <div class="reserved-budget-title title">
          <ion-icon :src="$brand_svg_src + `/padlock.svg`" />
          <span>Reserved budget</span>
        </div>
        <ion-accordion
          v-for="(item, idx) in planSupportItems.plan_support_preferences"
          :item="{ ...item, id: item.sfid, active: true }"
          :key="`plan-support-preference-${idx}`"
          class="inner-card"
          :value="item.sfid"
        >
          <ion-item slot="header">
            <ion-label class="accordion-title">
              {{ item.name }}
            </ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-card
              v-for="(content, idx) in item.plan_support_items"
              :key="`${content.name}-${idx}`"
              class="card-content"
            >
              <div class="content-row">
                <span class="content-label">{{ content.name }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">{{ content.code }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">Start date: </span>
                <span class="content-value">{{ $filters.friendlyDate(content.start_date) }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">End date: </span>
                <span class="content-value">{{ $filters.friendlyDate(content.end_date) }}</span>
              </div>
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col class="ion-no-padding">
                    <div class="content-label content-row">Opening balance:</div>
                    <div class="content-value">{{ $filters.currency(content.budget_allocated) }}</div>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <div class="balance-prog-bar">
                <balance-progress-bar
                  :color-class="supportTypeColorClass"
                  :spent="content.budget_spent"
                  :allocated="content.budget_allocated"
                  :remaining="content.budget_remaining"
                  height="8"
                ></balance-progress-bar>
              </div>
              <ion-row>
                <ion-col class="ion-no-padding">
                  <div>
                    <span class="content-value"
                      >{{ $filters.currency(content.budget_spent) }}
                      <span>({{ ((content.budget_spent * 100) / content.budget_allocated).toFixed(0) }}%)</span></span
                    >
                    <span class="content-label"> spent</span>
                  </div>
                </ion-col>
                <ion-col class="ion-no-padding">
                  <div class="ion-text-end">
                    <span class="content-value">
                      {{ $filters.currency(content.budget_remaining) }}
                      ({{ ((content.budget_remaining * 100) / content.budget_allocated).toFixed(0) }}%)
                    </span>
                    <span class="content-label ion-text-end"> available</span>
                  </div>
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
        </ion-accordion>
      </ion-card>
      <template v-if="planSupportItems.reimbursements?.length > 0">
        <div class="reimbursements-title title">
          Reimbursements
        </div>
        <ion-accordion
          v-for="(item, idx) in planSupportItems.reimbursements"
          :item="{ ...item, id: item.sfid, active: true }"
          :key="`reimbursements-${idx}`"
          class="inner-card"
          :value="item.sfid"
        >
          <ion-item slot="header">
            <ion-label class="accordion-title">
              {{ item.name }}
            </ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-card
              v-for="(content, idx) in item.plan_support_items"
              :key="`${content.name}-${idx}`"
              class="card-content"
            >
              <div class="content-row">
                <span class="content-label">{{ content.name }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">{{ content.code }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">spent: </span>
                <span class="content-value"
                  >{{ $filters.currency(content.budget_spent) }} ({{
                    ((content.budget_spent * 100) / item.budget_spent).toFixed(0)
                  }}%)</span
                >
              </div>
            </ion-card>
          </div>
        </ion-accordion>
      </template>
      <template v-if="planSupportItems.providers?.length > 0">
        <div class="providers-title title">
          Providers
        </div>
        <ion-accordion
          v-for="(item, idx) in planSupportItems.providers"
          :item="{ ...item, id: item.sfid, active: true }"
          multiple
          :key="`providers-${idx}`"
          class="inner-card"
          :value="item.sfid"
        >
          <ion-item slot="header">
            <ion-label class="accordion-title">
              {{ item.name }}
            </ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <ion-card
              v-for="(content, idx) in item.plan_support_items"
              :key="`${content.name}-${idx}`"
              class="card-content"
            >
              <div class="content-row">
                <span class="content-label">{{ content.name }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">{{ content.code }}</span>
              </div>
              <div class="content-row">
                <span class="content-label">spent: </span>
                <span class="content-value"
                  >{{ $filters.currency(content.budget_spent) }} ({{
                    ((content.budget_spent * 100) / item.budget_spent).toFixed(1)
                  }}%)</span
                >
              </div>
            </ion-card>
          </div>
        </ion-accordion>
      </template>
    </ion-accordion-group>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonIcon,
  IonRow,
  IonCard,
  IonCol,
  IonGrid,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel
} from "@ionic/vue";
import { mapGetters } from "vuex";
import { paceSupportCategories, supportTypes } from "@/helpers/support-category";
import { converter } from "@/helpers/filters";
import BalanceProgressBar from "@/components/plans/pace/BalanceProgressBar.vue";

export default defineComponent({
  name: "PacePlanSpendOverview",
  props: {},
  data() {
    return {};
  },
  components: {
    IonIcon,
    IonRow,
    IonCard,
    IonCol,
    BalanceProgressBar,
    IonGrid,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel
  },
  computed: {
    ...mapGetters({
      planSupportItems: "pace/planSupports/getPlanSupportItems"
    }),
    supportTypeColorClass() {
      return converter("route_name", this.$route.params.supportArea, "cssClass", supportTypes);
    },
    accordionContent() {
      const arr = [...this.planSupports];
      if (arr.length > 0) {
        arr[0].active = true;
      }
      return arr;
    },
    accordionKeys() {
      const preferences = this.planSupportItems.plan_support_preferences?.map(item => item.sfid) ?? [];
      const reimbursements = this.planSupportItems.reimbursements?.map(item => item.sfid) ?? [];
      const providers = this.planSupportItems.providers?.map(item => item.sfid) ?? [];
      return [preferences, reimbursements, providers].flat();
    },
    supportCategory() {
      return converter("route_name", this.$route.params.supportCategory, "name", paceSupportCategories);
    }
  }
});
</script>
<style lang="scss" scoped>
.plan-spend-overview-tile {
  padding: 20px;
  margin: 24px 0 0 0;
  background-color: $brand--pace-plan-tile-bg;
  @media only screen and (min-width: 992px) {
    margin-left: 10px;
  }
  .title {
    color: $brand--font-color-primary;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
  }
  .inner-card {
    background-color: $brand--pace-plan-spend-overview-inner-cards-bg;
    margin-top: 8px;
    border-radius: 4px;
    ion-item {
      --background: $brand--pace-plan-accordion-bg;
      --border-width: 0px;
      --ripple-color: transparent;
      &:focus {
        border: 3px solid $brand--focus-outline;
        outline: none;
      }
    }
    .accordion-title {
      color: $brand--font-color-primary;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
    }
    .accordion-item-details {
      margin-top: 8px;
    }
    .card-content {
      background-color: $brand--pace-plan-spend-overview-inner-card-details-bg;
      margin: 0;
      padding: 8px 10px;
      .content-row {
        margin-bottom: 10px;
      }
      .content-label {
        color: $brand--font-color-primary;
        font-size: 14px;
        line-height: 19px;
      }
      .content-value {
        color: $brand--font-color-primary;
        font-size: 14px;
        line-height: 19px;
        font-weight: bold;
      }
      .balance-prog-bar {
        margin: 8px 0;
      }
    }
    .card-content:not(:first-child) {
      margin-top: 8px;
    }
  }
  .reserved-budget-card {
    background-color: $brand--pace-plan-spend-overview-cards-bg;
    margin: 32px 0 0 0;
    padding: 10px;
    .reserved-budget-title {
      color: $brand--font-color-primary;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 16px;
        height: 22px;
      }
      span {
        color: $brand--font-color-primary;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
      }
    }
  }
  .reimbursements-title {
    margin-top: 32px;
  }
  .providers-title {
    margin-top: 32px;
  }
}
</style>
