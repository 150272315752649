<template>
  <div class="side-nav">
    <participant-info />
    <!-- Nav Items -->
    <nav>
      <ul id="sidemenu">
        <li v-for="tab in tabs" :key="tab" @keyup.space="$router.push(`/${tab}`)">
          <router-link :to="{ path: `/${tab}` }" :class="{ active: activeTab(tab).includes('active') }">
            <ion-icon :src="$brand_svg_src + `/${activeTab(tab)}.svg`" aria-hidden="true"></ion-icon>
            <ion-label>{{ tab }}</ion-label>
          </router-link>
        </li>
      </ul>
    </nav>
    <total-available-budget v-if="!$store.getters.currentPlan.pace_plan" />
    <button
      v-if="$store.getters.hasFullParticipantAccess"
      aria-label="Submit Claim"
      class="side-submit-button"
      @click="submitClaim()"
      id="submit-claim"
    >
      <ion-icon :src="$brand_svg_src + '/money-circle.svg'" />
      <span>Submit Claim</span>
    </button>
    <div v-if="brand !== 'mpm'">
      <ion-img v-desktop-breakpoint-only :src="$brand_svg_src + '/powered-by.svg'" class="powered-by" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonImg, IonButton } from "@ionic/vue";
import { openModal } from "@/helpers/system";
import { isDesktop } from "@/directives/platform";
import { createNamespacedHelpers } from "vuex";
import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import ParticipantInfo from "@/components/menu/ParticipantInfo.vue";
import SubmitClaim from "@/views/SubmitClaim.vue";

const { mapActions } = createNamespacedHelpers("layout");
const brand = process.env.VUE_APP_BRAND;

export default defineComponent({
  name: "Side Nav",
  components: {
    ParticipantInfo,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    TotalAvailableBudget,
    SubmitClaim,
    IonImg,
    IonButton
  },
  props: {
    participant: {
      type: Object
    }
  },
  methods: {
    ...mapActions(["updateCurrentTab"]),
    submitClaim() {
      if (isDesktop) {
        this.$router.push({ path: "/submit-claim" });
      } else {
        openModal(SubmitClaim);
      }
    },
    activeTab(tab) {
      if (tab === this.routeToTabMapping[this.$route?.name]) {
        this.updateCurrentTab(this.routeToTabMapping[this.$route?.name]);
        return this.routeToTabMapping[this.$route?.name] + "-active";
      } else {
        return tab;
      }
    }
  },
  data() {
    return {
      brand: brand,
      selectedTab: "",
      tabs: ["plan", "claims", "providers", "reports", "settings"],
      routeToTabMapping: {
        PlanTab: "plan",
        PacePlanView2Tab: "plan",
        PacePlanView3Tab: "plan",
        ClaimsTab: "claims",
        SubmitClaim: "claims",
        ProvidersTab: "providers",
        ProviderClaims: "providers",
        ReportsTab: "reports",
        GenerateReports: "reports",
        SettingsTab: "settings"
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.powered-by {
  padding-top: 48px;
  width: 233px;
}
.side-nav {
  display: none;
  background: $brand--global-nav-bg;
  position: fixed;
  width: 12rem;
  left: 0;
  height: 100vh;
  z-index: 101;
  overflow-y: auto;
  padding-bottom: 3rem;
  box-shadow: $brand--sidemenu-box-shadow;
  @include tablet-only {
    display: flex;
    flex-direction: column;
  }

  .side-submit-button {
    display: flex;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 0;
    background-color: $brand--button-main;
    margin: 1rem auto;
    width: 162px;
    font-weight: 600;
    color: $brand--font-color-secondary;
    line-height: 1.29;
    align-items: center;
    min-height: 3rem;
    border-radius: 4px;
    text-transform: capitalize;
    &:focus {
      outline: 3px solid $brand--button-sidemenu-submit-focus;
      outline-offset: 2px;
      box-shadow: none;
    }
    &:focus-visible {
      outline: 3px solid $brand--button-sidemenu-submit-focus;
      outline-offset: 2px;
      box-shadow: none;
    }
    &:hover {
      color: $brand--font-color-secondary;
      background-color: $brand--button-main-hover;
    }
  }
  #sidemenu {
    font-weight: 600;
    background: $brand--sidemenu-bg;
    list-style-position: inside;
    padding-left: 0;
    margin: 0;
    a {
      align-items: center;
      display: flex;
      padding: 1rem;
      height: 4.375rem;
      text-decoration: none;
      text-transform: capitalize;
      color: white;
      &.active {
        border-left: 6px solid $brand--sidemenu-active;
        color: $brand--sidemenu-active;
        background-color: rgba(255, 255, 255, 0.1);
      }
      &:focus-visible {
        border: 3px solid $brand--focus-outline;
        outline: none;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      &:active {
        background: rgba(255, 255, 255, 0.1);
      }
      ion-icon {
        margin-right: 35px;
      }
      ion-label {
        font-size: 16px;
        margin: 0;
        padding: 0;
      }
    }

    li {
      list-style: none;
    }
  }
}
</style>
