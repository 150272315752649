<template>
  <div>
    <ion-grid :class="['plan-support-category-' + $route.params.supportArea]">
      <ion-row class="page-header ion-align-items-center">
        <div
          tabindex="0"
          class="icon arrow-left focus-item"
          :aria-label="ariaLabel"
          role="button"
          @click="goBackPath ? $router.push({ path: goBackPath }) : $router.go(-1)"
          @keyup.space="goBackPath ? $router.push({ path: goBackPath }) : $router.go(-1)"
          @keyup.enter="goBackPath ? $router.push({ path: goBackPath }) : $router.go(-1)"
        >
          <ion-icon src="svg/back-arrow.svg" />
        </div>
        <h1 class="title">
          <slot name="title" />
          <mpm-tooltip class="tooltip" v-if="tooltipContent">
            <template v-slot:icon>
              <ion-icon class="help-icon" :src="`${$brand_svg_src}/info-banner.svg`" />
            </template>
            <template v-slot:content>
              <p class="tooltip-content">
                {{ tooltipContent }}
              </p>
            </template>
          </mpm-tooltip>
        </h1>
      </ion-row>
    </ion-grid>

    <div v-if="showBody" class="body-content" :class="['plan-support-category-' + $route.params.supportArea]">
      <slot name="body-content" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonRow, IonCol, IonGrid } from "@ionic/vue";
import mpmTooltip from "@/components/MpmTooltip.vue";

export default defineComponent({
  name: "PacePageHeader",
  props: {
    ariaLabel: {
      type: String,
      default: "Back Arrow. Back to Plan screen."
    },
    goBackPath: {
      type: String,
      default: null
    },
    tooltipContent: {
      type: String,
      default: null
    },
    showBody: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonIcon,
    IonRow,
    IonCol,
    IonGrid,
    mpmTooltip
  }
});
</script>
<style lang="scss" scoped>
ion-grid {
  padding: 24px 10px;
}
.page-header {
  height: auto;
  width: 100%;
  h1 {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    display: inline-block;
  }

  .title {
    color: white;
    display: flex;
    align-items: center;
  }

  .tooltip {
    margin-left: 16px;
  }

  .icon {
    margin: 0 16px 0 10px;
    ion-icon {
      height: 21px;
      width: 21px;
      display: inline-block;
    }
    svg ion-icon {
      width: 22px;
      height: 22px;
    }
  }
  .help-icon {
    border: 0;
    border-radius: 11px;
    height: 24px;
    width: 24px;
  }
  .tooltip-content {
    color: $brand--font-color-primary;
    font-family: $brand--theme--body-font;
    font-size: 1rem;
  }
}

.body-content {
  padding: 0 10px 14px 10px;
}

.plan-support-category-core-support {
  background-color: $brand--pace-plan-banner-core-support;
}

.plan-support-category-capacity-building-support {
  background-color: $brand--pace-plan-banner-capacity-building-support;
}

.plan-support-category-capital-support {
  background-color: $brand--pace-plan-banner-capital-support;
}
</style>
