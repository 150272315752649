<template>
  <button class="button-link">
    <span>
      <ion-icon aria-hidden="true" class="button-icon" :src="icon" />
      {{ label }}
    </span>
    <ion-icon aria-hidden="true" class="button-icon right" src="svg/arrow-right-circle.svg" />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "ButtonLink",
  components: { IonIcon },
  props: {
    label: {
      type: String
    },
    icon: {
      type: String
    }
  }
});
</script>

<style lang="scss" scoped>
.button-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $brand--button-link-bg;
  color: white !important;
  padding: 5px;
  border-radius: 7px;
  margin: 10px 0;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 20%);
  transition: all 0.2s ease 0s;
  &:focus-visible {
    outline: 3px solid $brand--focus-outline-button;
  }

  .button-icon {
    padding: 5px;
    margin: 0 5px;
  }

  span {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1rem;
  }
}
</style>
