<template>
  <div class="reimbursement-pagination">
    <span class="page-meta" v-if="!$root.isMobileView">
      {{ pagination.first_record_number }}
      -
      {{ pagination.last_record_number }}
      of
      {{ pagination.total }}
      items
    </span>
    <button
      :aria-label="`Go to page ${pagination.current_page - 1} of records`"
      class="filter-button arrow-button"
      @click="onPageChange(pagination.current_page - 1)"
      :disabled="pagination.is_first_page"
    >
      <ion-icon :src="$brand_svg_src + `/arrow-left-white.svg`" />
    </button>
    <button
      :aria-label="`Go to page ${pagination.current_page + 1} of records`"
      class="filter-button arrow-button"
      @click="onPageChange(pagination.current_page + 1)"
      :disabled="pagination.is_last_page"
    >
      <ion-icon :src="$brand_svg_src + `/arrow-right.svg`" />
    </button>
  </div>
</template>

<script lang="ts">
export default {
  name: "RecordsPagination",
  props: {
    pagination: {
      type: Object
    }
  },
  methods: {
    onPageChange() {}
  }
};
</script>

<style lang="scss">
.record-controls {
  display: flex;
  align-items: center;
  .page-meta {
    margin-right: 1rem;
  }
  .page-meta {
    font-size: 12px;
    padding-left: 15px;
  }
}
</style>
