<template>
  <div class="allocated-budget">
    <h1 v-if="planSupportItems.supportItems.length">Detailed breakdown</h1>
    <provider-progress-summary
      :provider-name="item.providerName"
      v-for="(item, index) in planSupportItems.supportItems"
      :key="index"
      :colour="$strings.providerColors[index]"
    >
      <plan-support-item
        :budget-allocated="Number(supportItem.budget_allocated)"
        :budget-spent="Number(supportItem.budget_spent)"
        :title="supportItem.name"
        :is-locked="supportItem.locked"
        v-for="supportItem in item.supportItems"
        :key="supportItem.id"
        :code="supportItem.code || supportItem.support_code"
        :dates="formatDates(supportItem)"
      />
    </provider-progress-summary>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PlanSupportItem from "@/components/plans/support-items/PlanSupportItem.vue";
import ProviderProgressSummary from "@/components/plans/support-items/ProviderProgressSummary.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "AllocatedBudget",
  props: {
    total: {
      type: Number
    }
  },
  components: {
    ProviderProgressSummary,
    PlanSupportItem
  },
  computed: {
    planSupportItems() {
      return this.$store.getters.supportItems;
    }
  },
  methods: {
    formatDates(supportItem) {
      if (supportItem.locked && supportItem.start_date) {
        return (
          dayjs(supportItem.start_date, "YYYY-MM-DD").format("DD/MM/YYYY") +
          " - " +
          dayjs(supportItem.end_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        );
      }
      return null;
    }
  }
});
</script>

<style lang="scss">
.allocated-budget {
  max-width: 454px;
  margin: 0 auto 1rem;
  h1 {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 42rem) {
    h1 {
      margin-bottom: 14px;
    }
  }
  @media only screen and (max-width: 42rem) {
    h1 {
      line-height: 22px;
    }
  }
}
</style>
