<template>
  <div class="cv-text-input bx--form-item">
    <label
      for=""
      class="bx--label"
      :class="{
        'bx--label--disabled': $attrs.disabled
      }"
    >
      {{ label }}
    </label>
    <div class="bx--text-input__field-wrapper">
      <input
        class="bx--text-input"
        :class="[
          {
            'bx--text-input--invalid': invalid
          }
        ]"
        id=""
        v-bind="$attrs"
        v-model="dataModel"
        ref="input"
      />
    </div>
    <div class="bx--form-requirement" aria-live="assertive" aria-relevant="additions removals">
      <slot v-if="invalid" name="invalid-message">{{ invalidMessage }}</slot>
    </div>
    <div
      v-if="!invalid"
      class="bx--form__helper-text"
      :class="[
        {
          'bx--form__helper-text--disabled': $attrs.disabled
        }
      ]"
    >
      <slot name="helper-text">{{ helperText }}</slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: { required: false },
    invalid: { type: Boolean },
    label: { type: String },
    helperText: { type: String },
    invalidMessage: { type: String },
    caseTransform: {
      type: String,
      default: "none"
    }
  },
  computed: {
    dataModel: {
      get() {
        return this.value;
      },
      set(value: string) {
        switch (this.caseTransform) {
          case "lower":
            this.$emit("input", value.toLowerCase());
            break;
          case "upper":
            this.$emit("input", value.toUpperCase());
            break;
          default:
            this.$emit("input", value);
            break;
        }
      }
    }
  }
});
</script>
