<template>
  <section id="notifications-setting" aria-label="notifications setting">
    <ion-page>
      <modal-header :modal-name="modalName"></modal-header>
      <ion-content>
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content
            pulling-icon="svg/loading.svg"
            refreshing-spinner="circles"
            refreshing-text="Refreshing..."
          >
          </ion-refresher-content>
        </ion-refresher>
        <mpm-loading :is-loading="contentsLoading"></mpm-loading>
        <div class="notification modal-content">
          <form>
            <ion-grid>
              <ion-row>
                <ion-col>
                  <h1 class="main-title">Notifications</h1>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h3>SMS Notifications</h3>
                </ion-col>
                <ion-col size="auto">
                  <ion-toggle
                    v-model="smsModel"
                    :switch-label="smsModel ? 'On' : 'Off'"
                    @keydown.enter="triggerChange"
                  />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <p>
                    When {{ $strings.brand_name }} receives a claim. This notification will be received by the primary
                    contact for this NDIS participant.
                  </p>
                </ion-col>
              </ion-row>
              <hr role="presentation" />
              <ion-row>
                <ion-col>
                  <h3>Invoice Approval</h3>
                  <p>
                    <strong>Please note:</strong> If this notification is set to ‘Off’ you will have up to 5 days to
                    review and approve your claims via the {{ $strings.brand_name_short }} phone app, client portal or
                    you can phone {{ $strings.brand_name_short }} directly to place new claims on hold before they are
                    progressed for payment.
                  </p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <h3>Auto-Approve new claims</h3>
                </ion-col>
                <ion-col size="auto">
                  <ion-toggle
                    v-model="autoApproveModel"
                    aria-label-id="auto-approve-label"
                    :switch-label="autoApproveModel ? 'On' : 'Off'"
                    @keydown.enter="triggerChange"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </div>
      </ion-content>
    </ion-page>
  </section>
</template>

<script lang="ts">
import { TrackingEvent } from "@/types/tracking-events";
import { defineComponent } from "vue";
import { IonRow, IonCol, IonGrid, IonContent, IonPage, IonRefresher, IonRefresherContent, IonToggle } from "@ionic/vue";
import ModalHeader from "@/components/ModalHeader.vue";
import SettingsToggleRow from "@/components/SettingsToggleRow.vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "notification",
  data() {
    return {
      modalName: "Notifications Settings",
      showToast: false
    };
  },
  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonContent,
    IonPage,
    IonToggle,
    ModalHeader,
    IonRefresher,
    IonRefresherContent,
    SettingsToggleRow
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    smsNotification() {
      return this.$store.state.participant.participant.smsNotification;
    },
    autoApprove() {
      return this.$store.state.participant.participant.autoApprove;
    },
    smsModel: {
      get() {
        return this.smsNotification;
      },
      set(value: boolean) {
        this.updateSmsNotification({ value }).then(
          () => {
            this.$notification.createToast("Your preference was successfully updated.", "success");
          },
          () => {
            this.$notification.createToast("Your preference could not be updated, please try again later.", "error");
          }
        );
        this.$amplitude.track(TrackingEvent.SmsNotificationToggle, {});
      }
    },
    autoApproveModel: {
      get() {
        return this.autoApprove;
      },
      set(value: boolean) {
        this.updateAutoApprove({ value }).then(
          () => {
            this.$notification.createToast("Your preference was successfully updated.", "success");
          },
          () => {
            this.$notification.createToast("Your preference could not be updated, please try again later.", "error");
          }
        );
        this.$amplitude.track(TrackingEvent.AutoApproveInvoiceToggle, {});
      }
    }
  },
  methods: {
    ...mapActions("participant", {
      updateAutoApprove: "updateAutoApprove",
      updateSmsNotification: "updateSmsNotification"
    }),
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async loadPage() {
      this.updateContentsLoading(true);
      try {
        await this.$store.dispatch("user/refreshUserDetails");
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    triggerChange(e) {
      e.target.click();
    }
  }
});
</script>

<style lang="scss" scoped>
.notification {
  hr {
    margin: 2rem 0;
  }
  h1 {
    margin: 0 0 1rem 0;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
ion-toggle {
  padding: 3px;
}
ion-toggle:focus,
ion-toggle:focus-visible {
  outline: 3px solid $brand--focus-outline;
  padding: 3px;
}
</style>
