<template>
  <div>
    <ion-alert
      class="version-alert"
      :class="$brand === 'ndsp' ? 'version-alert-bg-light button-text-bold' : 'version-alert-bg-dark'"
      :is-open="$store.getters['systemTemp/getShowVersionUpdate']"
      :header="headerContent"
      sub-header=""
      :message="messageContent"
      :buttons="alertButtons"
      @didDismiss="showVersionUpdate = false"
      :backdrop-dismiss="false"
    >
    </ion-alert>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonAlert, IonButton } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { isDesktop, isAndroid, isIOS, isMobileWeb } from "@/directives/platform";

export default defineComponent({
  name: "VersionDialog",
  components: { IonIcon, IonAlert, IonButton },
  data() {
    return {
      webMustUpdateAlertButtons: [
        {
          text: "Refresh now",
          role: "confirm",
          cssClass: "alert-button-confirm web-alert-button",
          handler: () => {
            window.location.reload();
            console.log("Alert confirmed with reload.");
          }
        }
      ],
      webOptionalAlertButtons: [
        {
          text: "Remind me later",
          role: "cancel",
          cssClass: "alert-button-cancel web-alert-button",
          handler: () => {
            this.updateDismissDate(new Date().getTime());
            console.log("Alert cancelled");
          }
        },
        {
          text: "Refresh now",
          role: "confirm",
          cssClass: "alert-button-confirm web-alert-button",
          handler: () => {
            window.location.reload();
            console.log("Alert confirmed with reload.");
          }
        }
      ],
      appMustUpdateAlertButtons: [
        {
          text: "OK",
          role: "confirm",
          cssClass: "alert-button-confirm",
          handler: () => {
            let storeUrl = "";
            if (isIOS) {
              storeUrl = "https://apps.apple.com/au/app/my-plan-manager/id1204246634";
            }
            if (isAndroid) {
              storeUrl = "https://play.google.com/store/apps/details?id=au.com.myplanmanager&pcampaignid=web_share";
            }
            window.open(storeUrl, "_blank")?.focus();
            console.log("Alert confirmed");
            return false;
          }
        }
      ],
      appOptionalAlertButtons: [
        {
          text: "Remind me later",
          role: "cancel",
          cssClass: "alert-button-cancel",
          handler: () => {
            this.updateDismissDate(new Date().getTime());
            console.log("Alert cancelled");
          }
        },
        {
          text: "Update now",
          role: "confirm",
          cssClass: "alert-button-confirm",
          handler: () => {
            let storeUrl = "";
            if (isIOS) {
              storeUrl = "https://apps.apple.com/au/app/my-plan-manager/id1204246634";
            }
            if (isAndroid) {
              storeUrl = "https://play.google.com/store/apps/details?id=au.com.myplanmanager&pcampaignid=web_share";
            }
            window.open(storeUrl, "_blank")?.focus();
            console.log("Alert confirmed");
          }
        }
      ]
    };
  },
  props: {
    label: {
      type: String
    },
    icon: {
      type: String
    }
  },
  methods: {
    ...mapActions({
      updateDismissDate: "systemPersist/updateVersionUpdateAlertDismissDate"
    })
  },
  computed: {
    ...mapGetters({
      mustUpdate: "systemTemp/getMustUpdate"
    }),
    showVersionUpdate: {
      get() {
        return this.$store.getters["systemTemp/getShowVersionUpdate"];
      },
      set(value: boolean) {
        this.$store.dispatch("systemTemp/updateShowVersionUpdate", value);
      }
    },
    messageContent() {
      if (this.isWebApp) {
        return this.mustUpdate
          ? `There is a new update for the ${this.$strings.brand_name_portal} client portal. Please refresh now. `
          : `There is a new update for the ${this.$strings.brand_name_portal} client portal. Please refresh.`;
      } else {
        return this.mustUpdate
          ? `There is a new update for the ${this.$strings.brand_name_portal} app. You need to update now.`
          : `There is a new update for the ${this.$strings.brand_name_portal} app.`;
      }
    },
    headerContent() {
      return this.mustUpdate ? "New Update" : "Optional Update";
    },
    alertButtons() {
      if (this.isWebApp) {
        return this.mustUpdate ? this.webMustUpdateAlertButtons : this.webOptionalAlertButtons;
      } else {
        return this.mustUpdate ? this.appMustUpdateAlertButtons : this.appOptionalAlertButtons;
      }
    },
    isWebApp() {
      return isDesktop || isMobileWeb;
    }
  }
});
</script>
<style lang="scss">
.version-alert {
  --min-width: 330px;
  --backdrop-opacity: 0.5;
  ion-backdrop {
    background: #000;
  }
  .alert-button {
    text-transform: none;
    font-size: 1rem;
  }
  .alert-message {
    color: $brand--alert-text-color;
  }
  .alert-button.ion-focused.sc-ion-alert-md {
    background: rgba(100, 100, 100, 0.5);
  }
}
.version-alert-bg-light {
  --background: white;
}
.version-alert-bg-dark {
  --background: #202020;
}
.button-text-bold {
  .alert-button {
    font-weight: bold;
  }
}
.web-alert-button:focus-visible {
  outline: 3px solid $brand--focus-outline;
  outline-offset: 2px;
}
</style>
