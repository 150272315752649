<template>
  <a class="skip-link" href="" @keydown.prevent.enter="goToTarget" @keyup.space="goToTarget">
    <slot>{{ label }}</slot>
  </a>
</template>

<script>
export default {
  name: "MpmSkipLink",
  props: {
    label: String,
    target: String,
    type: {
      type: String,
      default: null
    }
  },
  methods: {
    goToTarget() {
      let targetElement = null;
      if (this.type === "id") {
        targetElement = document.getElementById(this.target);
      } else if (this.type === "class") {
        targetElement = document.getElementsByClassName(this.target)[0];
      } else {
        targetElement = document.getElementsByTagName(this.target)[0];
      }
      targetElement?.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.skip-link {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  padding: 1.25rem 1.5rem;
  z-index: 1000000;
  transform: translateY(-100px);
  transition: all 100ms ease-in-out;

  background-color: $brand--skip-link-bg;
  border: 2px solid $brand--skip-link-border;
  outline: none;
  border-radius: 4px;
  font-weight: 800;
  text-decoration: none;
  color: $brand--font-color-primary;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07) !important;

  &:focus {
    border: 3px solid $brand--focus-outline;
    outline: none;
  }
}

.skip-link:focus {
  transform: translateY(0);
}
</style>
