<template>
  <DonutChart>
    <div class="cdc-container" :style="placementStyles.container">
      <div class="chart-container" :style="chartStyle">
        <vue3-chart-js
          id="doughnut"
          type="doughnut"
          :data="chartData"
          :options="chartOptions"
          ref="chartRef"
          :key="updateKey"
          :aria-label="donutLabel"
        ></vue3-chart-js>
      </div>

      <slot name="legend">
        <div class="cdc-legend" v-if="hasLegend" :style="placementStyles.legend">
          <span class="cdc-legend-item-label" v-if="secondaryTitle">
            {{ secondaryTitle }}: <strong>{{ secondaryTitleValue }}</strong>
          </span>
          <span
            class="cdc-legend-item"
            v-for="(item, idx) in legendData"
            :key="idx"
            :title="
              item.label
                .replace('<strong>', '')
                .replace('</strong>', '')
                .replace('-', '')
            "
          >
            <span class="cdc-legend-item-color" :style="item.styles"></span>
            <span v-html="item.label" />
          </span>
        </div>
      </slot>
    </div>
  </DonutChart>
</template>

<style lang="scss" scoped>
.cdc-legend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdc-sections {
  overflow: hidden;
}
.cdc-overlay {
  &.shadow {
    box-shadow: inset 0.25rem 0.25rem 0.5rem #999999;
  }
}
strong {
  font-weight: 800;
}

.sections-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  &.shadow {
    box-shadow: 0.5rem 0.5rem 1rem #999999;
  }
}
.paddings {
  position: absolute;
  width: 100%;
  height: 100%;
  .padding {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    width: 0.25rem;
    transform-origin: bottom center;
  }
}
</style>

<script lang="ts">
import Donut from "vue-css-donut-chart/src/components/Donut.vue";
import MpmPaddedDonutSections from "@/components/MpmPaddedDonutSections.vue";

// import { DoughnutChart } from "vue-chart-3";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

import { defineComponent } from "vue";

export default defineComponent({
  name: "MpmPaddedDonut",
  extends: Donut,
  components: {
    MpmPaddedDonutSections,
    Vue3ChartJs
    // DoughnutChart
  },
  props: {
    sections: {
      type: Array
    },
    total: {
      type: Number,
      default: 0
    },
    paddingSize: {
      type: String,
      default: "0"
    },
    paddingBackground: {
      type: String,
      default: "#ffffff"
    },
    highlightScale: {
      type: Number,
      default: 0.9
    },
    noShadow: {
      type: Boolean,
      default: false
    },
    secondaryTitle: {
      type: String
    },
    secondaryTitleValue: {
      type: String
    },
    cutout: {
      type: String,
      default: `50%`
    },
    sortLegend: {
      type: Boolean,
      default: true
    },
    spacing: {
      type: Number,
      default: 0
    },
    donutLabel: {
      type: String,
      default: "donut chart"
    }
  },
  data() {
    return {
      updateKey: 0
    };
  },
  watch: {
    //Force refresh using a key update on the component. Bit hacky but the library solution did not work
    sections: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.updateKey += 1;
    }
  },
  methods: {
    updateChart() {
      this.$refs.chartRef.value.update(250);
    }
  },
  computed: {
    legendData() {
      const array = this.legend;
      if (this.sortLegend) {
        array.unshift(array.pop());
      }
      return array;
    },
    chartStyle() {
      return {
        position: "relative",
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    },
    chartData() {
      console.log("Running chart data computed function");
      const labels = this.sections.map(section => section.label);
      const datasets = [
        {
          borderColor: this.paddingBackground,
          backgroundColor: [],
          data: [],
          spacing: this.spacing
        }
      ];
      this.sections.forEach(section => {
        datasets[0].backgroundColor.push(section.color);
        datasets[0].data.push(section.value);
      });
      return {
        labels,
        datasets
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        animation: {
          animateRotate: false
        },
        cutout: this.cutout
      };
    }
  }
});
</script>
