<template>
  <ion-page>
    <ion-content>
      <section id="maintenance" aria-label="maintenance page">
        <div class="maintenance-page">
          <div class="maintenance-contents">
            <div class="logo">
              <ion-icon v-if="$brand == 'mpm'" class="mpm-logo" src="svg/mpm-logo.svg" />
              <ion-icon v-else :src="$brand_svg_src + '/title.svg'" class="ndsp-logo" />
            </div>
            <p class="main-text">Client Portal{{ $brand == "mpm" ? " and Mobile App" : "" }} currently unavailable</p>
            <p class="sub-text">
              We are investing in our client and provider experience by making important upgrades to our systems and
              technology. We're introducing an upgrade right now, which means our client portal
              {{ $brand == "mpm" ? "and mobile app are" : "is" }}
              temporarily unavailable.
            </p>
            <p class="sub-text">
              Don't worry - we're working as quickly as we can, so try logging in again in a few hours.
            </p>
            <p class="sub-text">
              If you have an urgent query, you can email us at
              <a :href="`mailto:${$strings.emails.enquiries}`">{{ $strings.emails.enquiries }}</a> or call us on
              <a :href="`tel:${$strings.brand_phone_number}`" :aria-label="$strings.brand_phone_number_aria">
                <span aria-hidden="true" class="tel">{{ $strings.brand_phone_number }}</span>
              </a>
              from {{ $strings.brand_hours }} (ACST) Monday to Friday. And remember, you can also email invoices
              directly to
              <a :href="`mailto:${$strings.emails.accounts}`">{{ $strings.emails.accounts }}</a>
            </p>
            <p class="sub-text">
              Thanks for bearing with us!
            </p>
          </div>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonIcon, IonPage } from "@ionic/vue";
import { mapGetters } from "vuex";
import { getClientPortalStatus } from "@/api/maintenance";

export default defineComponent({
  name: "Maintenance",
  components: {
    IonIcon,
    IonPage,
    IonContent
  },
  data() {
    return {
      refreshIntervalId: null
    };
  },
  mounted() {
    getClientPortalStatus();
    // check if maintenance is finished by calling api every 10min
    this.refreshIntervalId = setInterval(getClientPortalStatus, 60000);
  },
  unmounted() {
    clearInterval(this.refreshIntervalId);
  },
  computed: {
    ...mapGetters("layout", {
      isUnderMaintenance: "getMaintenanceStatus"
    })
  }
});
</script>

<style lang="scss" scoped>
.maintenance-page {
  width: 100%;
  height: 100%;
}
.maintenance-contents {
  margin: 20vh auto;
  max-width: 800px;
  padding: 20px;
}
.logo {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.mpm-logo {
  height: 72px;
  width: 293px;
}
.ndsp-logo {
  height: 166px;
  width: 659px;
}
.main-text {
  color: $brand--primary-color;
  font-weight: bolder;
  font-size: 1.3rem;
  margin-bottom: 30px;
  text-align: center;
}
.sub-text {
  color: $brand--font-color-primary;
  font-size: 1rem;
  margin-bottom: 15px;
}
.tel {
  color: $brand--font-color-primary;
  white-space: nowrap;
}
a {
  color: $brand--maintenance-page-link;
  font-weight: bold;
  text-decoration: none;
}
</style>
