<template>
  <div>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-grid class="click-to-call">
      <ion-row>
        <ion-col>
          <h1 class="title">{{ modalName }}</h1>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p class="caption">
            If you have questions or are experiencing issues call {{ $strings.brand_name_short }}/request a call back
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            v-mobile-only
            class="option call-mpm-option"
            expand="full"
            fill="outline"
            :href="`tel:${$strings.brand_phone_number}`"
            @click="clickToCall"
            :aria-label="`Click to call ${$strings.brand_name_short}`"
          >
            <div class="button-contents">
              <ion-label>Call {{ $strings.brand_name_short }} </ion-label>
              <ion-icon :src="`${$brand_svg_src}/phone-pickup.svg`"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
            class="option"
            v-if="!hasRequestedCallBack"
            expand="full"
            fill="outline"
            @click.prevent="requestCallBack"
            :aria-label="`Request a call back from ${$strings.brand_name_short}`"
          >
            <div class="button-contents">
              <ion-label>Request a call back</ion-label>
              <ion-icon :src="$brand_svg_src + '/phone-hangup.svg'"></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="message" v-if="!hasRequestedCallBack">
            Alternatively, you can call
            <strong>
              <span :aria-label="$strings.brand_phone_number_aria">
                <span aria-hidden="true">{{ $strings.brand_phone_number }}</span>
              </span>
            </strong>
            to talk to our customer support team.
          </div>
          <div class="message" v-if="hasRequestedCallBack">
            You have requested a <strong>call back</strong>. One of our Client Relationship team members will be in
            <strong>contact</strong> with you.
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonButton, IonLabel, IonRow, IonCol, IonGrid } from "@ionic/vue";
import { requestCallback } from "@/api/request-call";
import { createNamespacedHelpers } from "vuex";
import { TrackingEvent } from "@/types/tracking-events";
import ModalHeader from "@/components/ModalHeader.vue";

const { mapState } = createNamespacedHelpers("participant");
const brand = process.env.VUE_APP_BRAND;

export default defineComponent({
  name: "click-to-call",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonButton,
    IonIcon,
    IonLabel,
    ModalHeader
  },
  data() {
    return {
      modalName: "Contact Us",
      hasRequestedCallBack: false,
      brand
    };
  },
  computed: {
    ...mapState(["participant"])
  },
  methods: {
    clickToCall() {
      this.$amplitude.track(TrackingEvent.ClickToCall, {});
      window.open(`tel:${this.$strings.brand_phone_number}`);
    },
    async requestCallBack() {
      try {
        const ok = await requestCallback(this.participant);
        this.hasRequestedCallBack = true;
        console.log("call back requested:", ok);
        this.$amplitude.track(TrackingEvent.RequestCallBack, {});
      } catch (error) {
        this.$notification.createToast("Sorry, something went wrong", "error");
      }
    }
  }
});
</script>

<style lang="scss">
.click-to-call {
  background: $brand--modals-background;
  color: $brand--modals-text-color;
  min-height: 100vh;
  .header {
    margin-left: 0;
  }
  ion-button {
    height: 75px;
    margin: 0 -10px;
    --color: inherit;
    --border-color: #{$brand--modals-border-color};
  }

  .options {
    width: 100%;
    ion-button::part(native):focus-visible {
      border: 3px solid $brand--focus-outline;
      --background-focused: none;
    }
    .option {
      a,
      button {
        font-size: 18px;
        color: $brand--theme--text-01;
        padding: 25px 1rem;
        max-width: unset;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &:hover {
          text-decoration: unset;
        }
        .icon {
          padding-right: 20px;
        }
      }
    }
    .message {
      font-size: 16px;
      line-height: 19.07px;
      color: $brand--modals-text-color;
      padding: 25px 1rem;
    }
  }
  .title {
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
  }
  .caption {
    text-align: left;
    margin-bottom: 10px;
  }
  .call-action {
    text-align: right;
    margin-right: 24px;
  }
  .button-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
@media only screen and (min-width: $ion--breakpoint--md) {
  ion-modal {
    --max-width: 960px;
  }
}
</style>
