<template>
  <div class="donut">
    <h2>Funds spent by provider</h2>
    <div tabindex="1" aria-label="Funds spent by provider">
      <ul v-if="dataSections">
        <li>
          <span role="contentinfo" class="offscreen" v-for="item in dataSections">
            <span v-html="item.label" />- <span>{{ item.budget_spent }}% spent</span>
            <span v-html="item.label"></span>
          </span>
        </li>
      </ul>
    </div>

    <MpmPaddedDonutChart
      :background="$strings.donutBaseColors.background"
      :foreground="$strings.donutBaseColors.foreground"
      padding-size="0.1rem"
      :padding-background="$strings.donutBaseColors.pacePaddingBackground"
      :sections="dataSections"
      :thickness="40"
      :size="180"
      :total="100"
      :highlight-scale="1"
      has-legend
      no-shadow
      :secondary-title="`Opening balance`"
      :secondary-title-value="openingBalance"
      :cutout="cutout"
      :sort-legend="true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpmPaddedDonutChart from "@/components/MpmPaddedDonut.vue";
import { currency } from "@/helpers/formatter";

export default defineComponent({
  name: "ProviderDonut",
  components: {
    MpmPaddedDonutChart
  },
  props: {
    total: {
      type: Number
    },
    cutout: {
      type: String,
      default: `50%`
    },
    limit: {
      type: Number,
      default: 5
    },
    planSupport: {
      type: Object
    },
    data: {
      type: Array
    }
  },
  computed: {
    dataSections() {
      let sections = this.data;
      sections = this.mapMyself(sections);
      sections.sort((a, b) => b.budget_spent - a.budget_spent);
      const topLimit = this.mapSections(sections.slice(0, this.limit)) ?? [];
      const others = this.mapSections(sections.slice(this.limit));
      const otherProviders = [];
      if (others.length) {
        const othersTotalSpending = others.map(i => i.value).reduce((item, val) => item + val);
        otherProviders.push({
          value: others.map(i => i.budget_spent).reduce((item, val) => item + val),
          color: others[0]?.color ?? this.$strings.providerColors[this.limit + 1],
          label:
            "Others - " +
            currency(othersTotalSpending) +
            this.calculatePercentage(othersTotalSpending, this.planSupport.budget_allocated),
          arialabel: "Others",
          percentage: 0
        });
      }
      const totalSpent = sections.length ? sections.map(i => i.budget_spent).reduce((item, val) => item + val) : 0;
      const remaining = this.planSupport.budget_allocated - totalSpent;
      const availableBalance = [];
      availableBalance.push({
        value: remaining <= 0 ? 0 : this.planSupport.budget_remaining,
        color: this.$strings.donutBaseColors.availableBudgetColor,
        label:
          "Available balance - " +
          currency(remaining) +
          this.calculatePercentage(remaining, this.planSupport.budget_allocated),
        arialabel: "Available balance",
        percentage: 0
      });
      return [].concat(topLimit, otherProviders, availableBalance);
    },
    openingBalance() {
      return currency(this.planSupport.budget_allocated ?? 0);
    }
  },
  methods: {
    calculatePercentage(spent, remaining) {
      const percentage = (spent / remaining) * 100;
      if (isNaN(percentage) || spent < 0) {
        return "";
      }
      return " (" + percentage.toFixed(1) + "%)";
    },
    mapSections(sections) {
      return sections.map((section, i: number) => {
        const label = section.name + " - " + currency(section.budget_spent);
        const labelPercentage = this.calculatePercentage(section.budget_spent, this.planSupport.budget_allocated);
        return {
          value: section.budget_spent,
          color:
            section.name === "Myself" ? this.$strings.donutBaseColors.myselfColor : this.$strings.providerColors[i],
          label: label + " " + labelPercentage,
          arialabel: label,
          percentage: 0
        };
      });
    },
    mapMyself(sections) {
      const myselfList = [];
      const dataSections = sections.map((section, i: number) => {
        if (section.name === "Myself") {
          myselfList.push(section);
        }
        return {
          budget_spent: section.budget_spent,
          name: section.name
        };
      });
      const providers = dataSections.filter(prop => prop.name !== "Myself");
      if (myselfList.length) {
        providers.push({
          name: "Myself",
          budget_spent: myselfList.map(i => i.budget_spent).reduce((item, val) => item + val)
        });
      }
      return providers;
    }
  }
});
</script>

<style lang="scss" scoped>
.donut {
  padding: 24px;
  background: $brand--pace-donut-bg;
  color: $brand--pace-donut-color;

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 16px;
  }
}
</style>
