<template>
  <div class="reimbursements">
    <claims-grid
      ref="claimsGrid"
      :sf-claims="reimbursementClaims"
      @row-expand-collapse-change="onRowExpandCollapseChange"
      @on-toggle-row="onToggleRow"
    >
      <template v-if="!hasFiltersApplied(filters)" v-slot:noDataMessage>
        <div class="claims-list-empty" v-if="hasFullParticipantAccess">
          <h5>You haven't submitted any reimbursements.</h5>
          <p class="empty-message">
            All submitted and processed reimbursements will appear here. Use the ‘Submit claim’ button or email the
            invoice and receipt to
            <a class="emailLink" :href="`mailto:${$strings.emails.accounts}`">{{ $strings.emails.accounts }}</a> to
            submit your reimbursement to be processed.
          </p>
        </div>
        <div class="claims-list-empty" v-if="hasReadOnlyParticipantAccess">
          <h5>There are no Reimbursement claims to show here yet.</h5>
          <p class="empty-message">
            All submitted and processed reimbursements will appear here.
          </p>
        </div>
      </template>
    </claims-grid>
    <claims-pagination v-if="!hasNoClaims" :pagination="pagination" @change-page="changePage"></claims-pagination>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ClaimsFilters from "@/components/salesforce/ClaimsFilters.vue";
import ClaimsFilterList from "@/components/salesforce/ClaimsFilterList.vue";
import ClaimsGrid from "@/components/salesforce/ClaimsGrid.vue";
import MobileAddClaimButton from "@/components/MobileAddClaimButton.vue";
import MpmHelpLink from "@/components/MpmHelpLink.vue";
import ClaimsPagination from "@/components/salesforce/ClaimsPagination.vue";
import { DateGroupedClaims } from "@/types/claims";
import SiteMap from "@/components/menu/SiteMap.vue";
import { IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";

interface Content extends HTMLElement {
  scrollToTop(n: number): void;
}

interface Data {
  activeFilter: string;
  isLoading: boolean;
  currentPage: number;
  collapseAllLinkDisabled: boolean;
  planRetrieved: number;
  activePlan: object;
}
interface Methods {
  loadClaims: () => void;
  setFilter: (filter: string) => void;
  approveClaim: ({ claimId, approve }: { claimId: number; approve: boolean }) => Promise<any>;
  approve: (claimId: number) => void;
  decline: (claimId: number) => void;
  changePage: (pageNum: number) => void;
  loadClaimsAtPage: (page: number) => void;
  onCollapseAllClick: () => void;
  onRowExpandCollapseChange: (rows: string[]) => void;
}
interface Computed {
  claims: DateGroupedClaims;
  groupedClaims: DateGroupedClaims;
  claimsForApprovalCount: number;
  reminderText: string;
  filterClaims: (supportTypeCode: string) => DateGroupedClaims;
  tableData: object;
  hasNoClaims: boolean;
  participant: any;
}

export default defineComponent({
  name: "Reimbursements",
  components: {
    ClaimsFilters,
    ClaimsFilterList,
    ClaimsGrid,
    ClaimsPagination,
    MobileAddClaimButton,
    MpmHelpLink,
    SiteMap,
    IonPage,
    IonContent,
    IonIcon,
    IonRefresher,
    IonRefresherContent
  },
  props: {
    filters: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  data(): Data {
    return {
      activeFilter: "",
      isLoading: true,
      currentPage: 1,
      collapseAllLinkDisabled: true,
      planRetrieved: null,
      activePlan: this.$store.state.currentPlan
    };
  },
  methods: {
    ...mapActions("sf/reimbursements", ["loadClaims", "approveClaim"]),
    scrollToTop() {
      const form = document.getElementById("claims-container") as Content;
      form.scrollToTop(0);
    },
    setFilter(filter: string) {
      this.activeFilter = filter;
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadClaimsAtPage(this.currentPage);
    },
    async approve(claimId: number): Promise<any> {
      await this.approveClaim({
        claimId,
        approve: true
      });
      this.loadClaimsAtPage(this.currentPage);
    },
    async decline(claimId: number): Promise<any> {
      await this.approveClaim({
        claimId,
        approve: false
      });
      this.loadClaimsAtPage(this.currentPage);
    },
    loadClaimsAtPage: debounce(async function(page: number) {
      //Record the User + Plan selected so it can be invalidated later.
      this.planRetrieved = this.$store.state.currentPlan.id;
      this.scrollToTop();
      this.isLoading = true;
      try {
        await this.loadClaims({ page });
      } catch (error) {
        console.error(error);
      } finally {
        this.$refs.claimsGrid?.resetExpandedRows();
        this.isLoading = false;
      }
      this.currentPage = page;
      // Note removed logic check > && !this.participant.autoApprove
      // This meant if a claim was added in the backend by MPM it could be missed as required approval
      if (this.$store.getters.hasFullParticipantAccess && this.claimsForApprovalCount > 0) {
        await this.$notification.createToast(this.reminderText, "awaiting");
      }
    }, 150),
    changePage(pageNum: number) {
      this.loadClaimsAtPage(pageNum);
    },
    onCollapseAllClick() {
      if (!this.collapseAllLinkDisabled) this.$refs.claimsGrid.collapseAll();
    },
    onRowExpandCollapseChange(rows) {
      this.collapseAllLinkDisabled = rows.length <= 0;
    },
    onPageChange(pageNumber: number) {
      this.isLoading = true;
      try {
        this.loadProviderClaims({
          providerId: this.providerId,
          pageNumber: pageNumber
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.currentPage = pageNumber;
      }
    },
    onFilterChange(filters: unknown) {
      console.log("updating filters from claims.vue");
      console.log(filters);
      this.$store.dispatch("sf/reimbursements/applyFiltersAndSorts", {
        filters,
        sorts: this.sorts
      });
    },
    onSortChange(sorts: unknown) {
      this.$store.dispatch("sf/reimbursements/applyFiltersAndSorts", {
        filters: this.filters,
        sorts
      });
    },
    resetExpandedRows() {
      this.$refs.claimsGrid?.resetExpandedRows();
    },
    onToggleRow() {
      this.$emit("on-toggle-row");
    },
    hasFiltersApplied(filters) {
      if (!filters) return false;
      return filters?.dates || filters?.supportTypes?.length != 0 || filters?.paidToTypes?.length != 0;
    }
  },
  computed: {
    ...mapGetters("sf/reimbursements", {
      groupedClaims: "groupedClaims",
      filterClaims: "filteredClaims",
      claimsForApprovalCount: "claimsForApprovalCount",
      pagination: "pagination",
      filters: "getFilters",
      sorts: "getSorts"
    }),
    ...mapGetters({
      hasFullParticipantAccess: "hasFullParticipantAccess",
      hasReadOnlyParticipantAccess: "hasReadOnlyParticipantAccess"
    }),
    reminderText(): string {
      switch (this.claimsForApprovalCount) {
        case 0:
          return "";
        case 1:
          return "There is 1 claim that requires approval!";
        default:
          return `There are ${this.claimsForApprovalCount} claims that require approval!`;
      }
    },
    reimbursementClaims() {
      if (this.activeFilter) {
        return this.filterClaims(this.activeFilter);
      }
      return this.groupedClaims;
    },
    tableData() {
      return {
        columns: ["provider", "amount"],
        data: this.reimbursementClaims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    hasNoClaims() {
      return !Object.keys(this.reimbursementClaims).length;
    },
    participant() {
      return this.$store.getters["participant"];
    }
  },
  unmounted() {
    // Clear the filter and sorting
    this.$store.dispatch("sf/reimbursements/clearFiltersAndSorts");
  }
});
</script>

<style lang="scss">
.reimbursements {
  .claims-grid {
    margin-top: -2px;
    padding-top: 0;
    .collapse-all {
      display: none;
    }
  }
}
.collapse-all {
  text-align: right;
}
.collapse-all-button {
  cursor: pointer;
  background: transparent;
  padding: 1rem 0 1rem 0;
  line-height: 1rem;
  min-height: 0;
  outline: none;
  border: none;
  color: #8fb8ff;
  text-decoration: underline;
  &:hover {
    color: white;
    background: transparent;
  }
  &[aria-disabled],
  &[disabled] {
    color: $brand--claims-label-color;
    background: transparent;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background: transparent;
      color: $brand--claims-label-color;
    }
  }
}
.claims {
  //margin-top: calc(96px + var(--ion-safe-area-top, 0));
  padding: 15px;
  padding-top: 8px;
  margin-bottom: 120px;
  .currency,
  .claims-filters {
    @include grid-media($grid--breakpoint--md) {
      // margin-top: 22px;
      .head {
        margin-bottom: 18px;
      }
    }
  }

  .expanded-pane {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .claims-list-empty {
    padding: 0.5rem;
    h5 {
      line-height: normal;
    }
    p.empty-message {
      font-style: normal;
      line-height: 24px;
      margin-top: 24px;
    }
  }
  .emailLink {
    color: #73a4ff;
    font-weight: bold;
    text-decoration: none;
    &:focus-visible {
      outline: 3px solid $brand--focus-outline !important;
    }
  }
  @each $id, $colour in $plan_support_colours {
    .cv-data-table-row--expandable.plan-support-category-#{$id} {
      border-left: 6px solid $colour;
    }
  }
  .mpm-data-table tbody.cv-data-table-row {
    border-bottom: none !important;
  }

  .mpm-data-table tbody {
    border-bottom: $brand--claims-table-tbody-border-bottom;
  }
  .mpm-data-table td {
    border-bottom: 0 !important;
  }
  .toolbar-segment {
    margin-bottom: 0;
  }
  ion-segment {
    justify-content: flex-start;
    --background: none;
    ion-segment-button {
      --indicator-color: none;
      --color-checked: #fff;
      border-radius: 6px 6px 0px 0;
      border-left: 1px solid rgba(86, 148, 251, 0.89);
      border-top: 1px solid rgba(86, 148, 251, 0.89);
      border-right: 1px solid rgba(86, 148, 251, 0.89);
      ion-label {
        font-size: 0.875rem;
        font-weight: 800;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.125rem;
        letter-spacing: 0.16px;
      }
    }
    ion-segment-button:nth-child(1) {
      max-width: 10rem;
      margin-right: 3px;
    }
    ion-segment-button:nth-child(2) {
      max-width: 14rem;
      margin-right: 3px;
    }
    ion-segment-button:nth-child(3) {
      max-width: 15rem;
    }
    .segment-button-checked {
      background: rgba(86, 148, 251, 0.89);
    }
  }
}
</style>
