<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <component :is="'PacePlanView3'" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import PacePlanView3 from "@/components/pages/PacePlanView3.vue";
import { IonPage } from "@ionic/vue";
import { map, debounce } from "lodash";
import { supportTypes, paceSupportCategories } from "@/helpers/support-category";
import { converter } from "@/helpers/filters";

export default defineComponent({
  name: "PacePlanView3Tab",
  components: {
    IonPage,
    PacePlanView3
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading"
    })
  },
  async ionViewWillEnter() {
    this.updateContentsLoading(true);
    await this.loadPage();
  },
  beforeRouteEnter(to, from, next) {
    if (map(paceSupportCategories, "route_name")?.includes(to.params?.supportCategory as string)) {
      next();
    }
  },
  methods: {
    ...mapActions({
      updateContentsLoading: "layout/updateContentsLoading",
      loadSelectedPlanSupport: "pace/planSupports/fetchSelectedPlanSupport",
      loadSelectedPlanSupportItems: "pace/planSupports/fetchPlanSupportItems",
      loadReservedBudget: "pace/planSupports/fetchReservedBudget"
    }),
    loadPage: debounce(async function() {
      this.updateContentsLoading(true);
      try {
        const supportTypeCode = converter(
          "route_name",
          this.$route.params.supportArea,
          "support_type_code",
          supportTypes
        );
        await this.loadSelectedPlanSupport({ supportTypeCode, supportId: this.$route.params.planSupportId });
        await this.loadSelectedPlanSupportItems({ supportTypeCode, supportId: this.$route.params.planSupportId });
        await this.loadReservedBudget({ supportTypeCode, supportId: this.$route.params.planSupportId });
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100)
  }
});
</script>
