<template>
  <div>
    <ion-header id="app-header" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" aria-hidden="true">
          <ion-icon
            v-mobile-breakpoint-only
            class="brand-icon"
            :src="$brand_svg_src + '/brand-icon.svg'"
            aria-hidden="true"
          />
          <ion-icon
            v-desktop-breakpoint-only
            :src="$brand_svg_src + '/logo.svg'"
            class="brand-logo"
            aria-hidden="true"
          />
        </ion-buttons>
        <ion-buttons slot="primary" class="header-buttons-right">
          <div v-desktop-breakpoint-only aria-hidden="true" class="header-greeter">
            Hi {{ user.profile ? user.profile.first_name : "" }}!
          </div>
          <ion-button v-if="$brand === 'mpm'" @click="openContactWidgetModal()" aria-label="Contact us">
            <ion-icon :src="$brand_svg_src + '/phone-pickup.svg'"></ion-icon>
          </ion-button>
          <ion-button @click="openParticipantListModal()" aria-label="Participant list">
            <ion-icon :src="$brand_svg_src + '/user-list.svg'"></ion-icon>
          </ion-button>
          <ion-button v-desktop-breakpoint-only @click="openLogoutModal" class="btn-logout" aria-label="Logout">
            <span>Logout</span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <!-- Side nav content contains links, only renders Tablet and up, participant info + submit claim -->
    <side-nav v-desktop-breakpoint-only />

    <ion-menu
      side="end"
      menu-id="participants"
      content-id="layout-base-wrapper"
      @ionDidClose="showParticipantList = false"
      @ionDidOpen="showParticipantList = true"
    >
      <participant-list @dismiss="closeParticipantList" :active="showParticipantList"></participant-list>
    </ion-menu>

    <!-- Show participant info toolbar if they're in mobile view -->
    <ion-toolbar v-if="participant && $root.isMobileView">
      <participant-info />
    </ion-toolbar>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ParticipantInfo from "@/components/menu/ParticipantInfo.vue";
import ParticipantList from "@/components/ParticipantList.vue";
import ClickToCall from "@/components/ClickToCall.vue";
import LogoutModal from "@/components/LogoutModal.vue";
import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import SideNav from "@/components/menu/SideNav.vue";
import { openModal } from "@/helpers/system";
import { debounce } from "lodash";
import {
  IonLabel,
  IonIcon,
  IonPage,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonMenu,
  menuController,
  IonRouterOutlet
} from "@ionic/vue";
import { modalController } from "@ionic/vue";
import { isMobileView } from "@/helpers/screen";
import { mapActions } from "vuex";

export default defineComponent({
  name: "layout-base",
  components: {
    ClickToCall,
    ParticipantInfo,
    ParticipantList,
    LogoutModal,
    TotalAvailableBudget,
    IonLabel,
    IonIcon,
    IonPage,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonMenu,
    SideNav,
    menuController,
    IonRouterOutlet
  },
  methods: {
    ...mapActions("layout", {
      updateParticipantListLoading: "updateParticipantListLoading"
    }),
    openParticipantListModal() {
      this.updateParticipantListLoading(true);
      this.$store.dispatch("participant/getParticipants").then(() => {
        this.updateParticipantListLoading(false);
      });
      if (isMobileView(window.innerWidth)) {
        return openModal(ParticipantList);
      }
      menuController.enable(true, "participants");
      menuController.open("participants");
    },
    closeParticipantList() {
      menuController.close("participants");
    },
    async openContactWidgetModal() {
      return openModal(ClickToCall);
    },
    openLogoutModal: debounce(async function() {
      const modal = await modalController.create({
        component: LogoutModal,
        cssClass: "app-modal logout-modal"
      });
      return modal.present();
    }, 50)
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    showVersionUpdate: {
      get() {
        return this.$store.getters["systemTemp/getShowVersionUpdate"];
      },
      set(value: boolean) {
        this.$store.dispatch("systemTemp/updateShowVersionUpdate", value);
      }
    }
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => (isOpenRef.value = state);
  },
  data() {
    return {
      participant: this.$store.getters.participant,
      showParticipantList: false
    };
  }
});
</script>
<style lang="scss">
#app-header {
  padding: 0 11px;
  box-shadow: $brand--header-box-shadow;
  ion-button {
    border-radius: 2px;
  }

  ion-button::part(native):focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
    --background-focused: none;
  }

  .btn-logout {
    margin: 0 0.5rem;
    text-transform: capitalize;
    width: 70px;
    height: 44px;
    span {
      letter-spacing: 0.02em;
      font-size: 1.1em;
      text-decoration: none;
      padding: 14px 0;
      font-weight: 600;
      margin: 0 14px;
      color: $brand--button-logout-color;
    }
  }
}

ion-toolbar > ion-buttons > ion-icon {
  padding: 8px 5px;
  font-size: 2.3rem;
}

.header-buttons-right ion-button {
  border: 0.125rem solid transparent;
  margin-left: 6px;
  width: 3rem;
  height: 3rem;
}

ion-toolbar {
  display: flex;
  align-items: center;
}

.brand-logo {
  width: 160px;
}

.brand-icon {
  .icon-inner {
    width: 60%;
  }
}

main.main {
  padding: 30px $layout-side-padding-mobile;
  padding-bottom: 5rem;
  @include grid-media($grid--breakpoint--md) {
    padding: 60px $layout-side-padding-desktop;
  }
}
#header-logo {
  width: 38px;
  @include grid-media($grid--breakpoint--md) {
    width: 140px;
  }
}
.mpm-side-nav-link {
  svg {
    height: 45px;
    width: 45px;
  }
  svg.active {
    display: none;
  }
  .router-link-exact-active {
    svg.active {
      display: unset;
    }
    svg.inactive {
      display: none;
    }
  }
}
#mobile-footer {
  z-index: 999;
  svg.active {
    display: none;
  }
  .router-link-exact-active {
    svg.active {
      display: unset;
    }
    svg.inactive {
      display: none;
    }
  }
}
.main-panel .menu-participant-info {
  display: flex;
  @include grid-media($grid--breakpoint--md) {
    display: none;
  }
}
#side-nav .bx--side-nav__items {
  padding: 0 0 0 0;
}
.main-panel .menu-participant-info {
  display: flex;
  @include grid-media($grid--breakpoint--md) {
    display: none;
  }
}
#side-nav .bx--side-nav__items {
  padding: 0 0 0 0;
}
#side-nav .bx--side-nav__items .bx--side-nav__link:not(.router-link-exact-active) svg.mpm-nav-link-icon path {
  transition: all 0.2s ease;
}
.bx--side-nav__link-text {
  display: flex;
  align-items: center;
  svg {
    padding-top: 5px;
  }
}
.footer-link-wrapper {
  svg {
    margin-bottom: 10px;
  }
}
.bx--header__global {
  margin-top: 12px;
}
.bx--header__global .bx--header__action > svg {
  fill: none;
}
.header-link-user {
  svg {
    margin: 8px 10px;
    path:nth-child(1),
    path:nth-child(2) {
      fill: none;
    }
  }
}
.user-link-active.header-link-user {
  svg {
    path:nth-child(1),
    path:nth-child(2) {
      fill: $brand--theme--brand-01;
      stroke: $brand--theme--brand-01;
    }
    path:nth-child(3) {
      stroke: $brand--theme--brand-01;
    }
    path:nth-child(4) {
      stroke: #fff;
    }
  }
}
#mobile-footer .footer-link {
  flex-basis: 20%;
}
.header-greeter {
  padding: 16px;
  color: $brand--theme--header-font-color;
  font-size: 16px;
  display: none;
  @include grid-media($grid--breakpoint--md) {
    display: inline-block;
  }
}
.bx--header__action {
  margin: 0 6px;
}
.header-link {
  span {
    color: #8fb8ff;
    display: none;
  }
  svg {
    display: inline-block;
    margin: 6px 10px 0;
  }
  @include grid-media($grid--breakpoint--md) {
    svg {
      display: none;
    }
    span {
      display: block;
    }
    text-decoration: none;
    padding: 14px 0;
    font-weight: 600;
    margin: 0 14px;
  }
}
.submit-claim-link .bx--side-nav__link-text,
.submit-claim-link a {
  width: 100%;
}
#side-nav .submit-claim-link {
  margin-top: 1.618rem;
}
#side-nav .submit-claim-link .mock-button {
  background-color: $brand--button-main;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  span {
    color: #000;
  }
  svg {
    padding-top: 0;
  }
}
.logout-modal {
  .modal-wrapper {
    max-width: 640px !important;
    width: 100%;
    max-height: 28rem;
  }
  @media only screen and (max-width: $brand-support-drawer-breakpoint) {
    .modal-wrapper {
      max-height: 100%;
    }
  }
}
ion-menu {
  --width: 30rem;
}
ion-modal.logout-modal {
  --height: 28rem;
}
@media only screen and (max-width: $ion--breakpoint--md) {
  ion-modal.logout-modal {
    --height: 100%;
  }
}
</style>
