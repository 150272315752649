<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <component :is="$store.getters.currentPlan.pace_plan ? 'PacePlan' : 'SFPlan'" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import SFPlan from "@/components/pages/Plan.vue";
import PacePlan from "@/components/pages/PacePlan.vue";

import { IonPage } from "@ionic/vue";
import { debounce } from "lodash";
import { TrackingEvent } from "@/types/tracking-events";

export default defineComponent({
  name: "PlanTab",
  components: {
    IonPage,
    SFPlan,
    PacePlan
  },
  beforeRouteEnter(to, from, next) {
    next((self: any) => {
      // callback runs when navigation finish
      // prevents someone landing at plan page with plan open
      // not ideal, except when user is coming from AddSupportItem page
      if (from.name !== "AddSupportItem") {
        self.$store.dispatch("supportItems/collapseSupportArea", false);
        self.$store.dispatch("supportItems/expandSupportArea", false);
      }
    });
  },
  async ionViewWillEnter() {
    this.updateContentsLoading(true);
    await this.loadPage();
    this.$amplitude.track(TrackingEvent.Navigation, {
      page: this.$store.getters.currentPlan.pace_plan ? "PacePlanTab" : "PlanTab"
    });
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading",
      planSupportTypes: "pace/supportPlanTypes/getSupportTypes"
    })
  },
  methods: {
    ...mapActions({
      updateContentsLoading: "layout/updateContentsLoading",
      updateFullScreenLoading: "layout/updateFullScreenLoading",
      loadPlanSupportTypes: "pace/supportPlanTypes/fetchSupportTypes"
    }),
    loadPage: debounce(async function() {
      this.updateContentsLoading(true);
      try {
        await this.loadPlanSupportTypes();
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100)
  }
});
</script>
