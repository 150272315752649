<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <ion-content class="page-content plan-section settings-tab" tabindex="0" aria-label="Settings page content">
      <section id="settings" aria-label="settings page">
        <div style="overflow:auto" tabindex="-1">
          <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
            <ion-refresher-content
              pulling-icon="svg/loading.svg"
              refreshing-spinner="circles"
              refreshing-text="Refreshing..."
            >
            </ion-refresher-content>
          </ion-refresher>
          <div class="participant-settings content-spacer header-spacer-mobile">
            <div style="padding: 5px 17px">
              <h1 class="main-title">Settings</h1>
              <p class="main-desc">
                User and Participant account information, Settings and Notifications
              </p>

              <p class="sub-heading">User settings</p>
              <button-link
                label="Contact Details"
                icon="svg/contact.svg"
                @click="openModal(pages.ContactInformationForm)"
              />

              <button-link
                v-desktop-breakpoint-only
                v-if="showUserOptions()"
                label="User Options"
                icon="svg/user--avatar.svg"
                @click="openModal(pages.UserOptionsForm)"
              />

              <p class="sub-heading gap-top">Participant settings</p>

              <button-link
                label="Personal Information"
                icon="svg/personal-information.svg"
                @click="openModal(pages.PersonalInformation)"
              />

              <button-link
                label="Participant Contacts"
                icon="svg/participant-contact.svg"
                @click="openModal(pages.ParticipantContacts)"
              />

              <button-link
                v-if="$store.getters.hasFullParticipantAccess"
                label="Notifications"
                icon="svg/notifications.svg"
                @click="openModal(pages.NotificationForm)"
              />
              <ion-button class="btn-logout" v-mobile-breakpoint-only @click="logout" style="float:right;">
                <ion-icon aria-hidden="true" :src="`${$brand_svg_src}/logout-settings.svg`" /> Logout
              </ion-button>
            </div>
          </div>
          <site-map v-desktop-only></site-map>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent, IonButton } from "@ionic/vue";
import { TrackingEvent } from "@/types/tracking-events";
import ButtonLink from "@/components/ButtonLink.vue";
import { openModal } from "@/helpers/system";
import ContactInformationForm from "@/components/usersettings/ContactInformationForm.vue";
import UserOptionsForm from "@/components/usersettings/UserOptionsForm.vue";
import PersonalInformation from "@/views/ParticipantSettings/PersonalInformation.vue";
import ParticipantContacts from "@/views/ParticipantSettings/ParticipantContacts.vue";
import NotificationForm from "@/views/ParticipantSettings/Notification.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "SettingsDashboard",
  data() {
    return {
      pages: {
        ContactInformationForm,
        UserOptionsForm,
        PersonalInformation,
        ParticipantContacts,
        NotificationForm
      }
    };
  },
  components: {
    ButtonLink,
    SiteMap,
    IonButton,
    IonPage,
    IonContent,
    IonIcon,
    IonRefresher,
    IonRefresherContent
  },
  async ionViewWillEnter() {
    this.updateContentsLoading(true);
    await this.loadPage();
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    })
  },
  methods: {
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    openModal(component) {
      openModal(component);
    },
    tabSelect(index: number) {
      const tab = this.tabs[index];
      if (this.$route.name !== tab.name) {
        this.$router.push(tab);
      }
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    loadPage: debounce(async function() {
      this.updateContentsLoading(true);
      try {
        await this.$store.dispatch("user/refreshUserDetails");
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100),
    logout() {
      this.$nextTick(() => {
        this.$store.dispatch("user/logout");
        this.$router.push({ path: "/login" }).then(() => {
          this.$amplitude.track(TrackingEvent.Logout, {});
          this.$notification.createToast(
            "You have successfully logged out. To log back in on this device please request a new SMS code or email link.",
            "success"
          );
        });
      });
      this.$emit("close");
    },
    showUserOptions() {
      return this.$brand === "mpm";
    }
  }
});
</script>

<style lang="scss" scoped>
.settings-tab {
  .main-desc {
    margin-bottom: 22px;
  }
  p {
    margin-bottom: 0;
  }
  .participant-settings {
    padding-bottom: 50px;
    .gap-top {
      margin-top: 22px;
    }
    .mpm-tabs {
      max-width: 700px;
    }
    .bx--btn {
      margin-top: 14px;
    }
    @media only screen and (min-width: $brand-support-drawer-breakpoint) {
      .button-link {
        max-width: 500px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 0.5;
        letter-spacing: 0;
      }
    }
  }
  .sub-heading {
    font-weight: 700;
  }
  .btn-logout {
    width: 142px;
    height: 48px;
    font-size: 0.875rem;
    &::part(native) {
      border-radius: 4px;
    }
  }
}
</style>
