<template>
  <div class="plan-header">
    <div class="main-shortdesc">
      <div class="parts">
        <h1 class="title">Plan</h1>
        <div class="main-plandates" aria-hidden="true">
          <span class="plan-date">{{ friendlyDate(activePlan.start_date) }}</span>
          -
          <span class="plan-date">{{ friendlyDate(activePlan.end_date) }}</span>
        </div>
      </div>
      <div class="parts">
        <mpm-help-link :href="$root.helpLinks.plan">
          Watch Plan video
        </mpm-help-link>
        <a class="icon" @click.prevent="closeSupportArea" aria-label="collapse support area">
          <svg-collapse />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpmHelpLink from "@/components/MpmHelpLink.vue";
import { find } from "lodash";
import friendlyDate from "@/filters/friendlyDate";
import { Plan } from "@/types";
import { TrackingEvent } from "@/types/tracking-events";
import { getSfParticipantPlanSupportCategories } from "@/api/salesforce/plans";

export default defineComponent({
  name: "PlanHeader",
  components: { MpmHelpLink },
  props: {
    plans: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      isLoading: true,
      activePlan: this.$store.state.currentPlan,
      planSupportCategories: [] as any[],
      isExpanded: false
    };
  },

  methods: {
    friendlyDate,
    handlePlanSelected(planId: number) {
      const plan = find(this.plans, (plan: Plan) => plan.id === planId);
      this.$store.dispatch("currentPlan/setCurrentPlan", plan);
      this.fetchPlanSupports();
      this.$store.dispatch("supportItems/expandSupportArea", false);
      this.$store.dispatch("supportItems/collapseSupportArea", false);
      this.$amplitude.track(TrackingEvent.SelectPlan, {
        pacePlan: plan.pace_plan
      });
    },
    async fetchPlanSupports() {
      this.$parent.isLoading = true;
      this.$parent.planSupportCategories = [];
      this.$parent.planSupportCategories = (await getSfParticipantPlanSupportCategories()) as Array<any>;
      this.$parent.isLoading = false;
    },
    closeSupportArea() {
      this.$store.dispatch("supportItems/expandSupportArea", false);
      this.$store.dispatch("supportItems/collapseSupportArea", true);
    }
  }
});
</script>

<style lang="scss">
.plan-header {
  max-width: 1018px;
  margin-top: 1rem;
  .icon {
    margin-left: 8px;

    width: 32px;
    height: 32px;
    display: flex;
    padding: 2px;
  }

  .icon svg {
    width: 21px;
    height: 21px;
    margin: 5px 2px;
  }

  .main-plandates {
    // padding-top: 36px;
    margin: 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
  .main-shortdesc {
    .parts {
      display: flex;
      align-items: baseline;
    }
  }

  .title {
    font-style: normal;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    padding-top: 18px;
  }
}
</style>
