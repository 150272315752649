<template>
  <ion-page>
    <modal-header v-mobile-only modal-name="Reserve Budget" @close="dismissModal()"></modal-header>
    <ion-content class="page-content reserve-budget-tab" tabindex="0" aria-label="Reserve budget page content">
      <section id="reserve-budget" aria-label="reserve budget page">
        <div style="overflow:auto" tabindex="-1">
          <div class="reserve-budget">
            <reserve-budget-form
              ref="form"
              :plan-support-category-id="planSupportCategorySfId"
              :plan-support-category-name="planSupportCategoryName"
              :activePlan="activePlan"
              @success="success"
              @cancel="dismissModal"
            />
          </div>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { createNamespacedHelpers, mapGetters } from "vuex";
import { closeModal } from "@/helpers/system";
import { IonButton, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/vue";
import { isDesktop } from "@/directives/platform";
import ReserveBudgetForm from "@/components/plans/support-items/ReserveBudgetForm.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import { TrackingEvent } from "@/types/tracking-events";

const { mapMutations } = createNamespacedHelpers("supportItem");

export default defineComponent({
  name: "reserve-budget",
  props: {
    categoryId: Number,
    planSupportCategoryId: String
  },
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    ReserveBudgetForm,
    ModalHeader
  },
  ...mapGetters({
    selectedPlanSupportType: "pace/supportPlanTypes/getSelectedSupportType"
  }),
  ionViewWillEnter() {
    this.$refs.form.clearForm();
  },
  computed: {
    activePlan() {
      return this.$store.state.currentPlan || { id: null, start_date: null, end_date: null };
    },
    planSupportCategory() {
      return this.$store.getters.supportPlanCategory;
    },
    planSupportCategoryName() {
      if (this.$store.state.currentPlan && this.$store.state.currentPlan.pace_plan) {
        return this.$route.params.categoryName;
      }
      return this.$store.getters.supportPlanCategory?.support_category?.support_category_name;
    },
    planSupportCategorySfId() {
      if (this.$store.state.currentPlan && this.$store.state.currentPlan.pace_plan) {
        return this.$route.params.planSupportId;
      }
      return this.planSupportCategory.sfid;
    }
  },
  methods: {
    ...mapMutations(["clearSupportItem"]),
    dismissModal() {
      this.clearSupportItem();
      if (isDesktop) {
        this.$router.go(-1);
      } else {
        closeModal();
      }
    },
    success() {
      this.$amplitude.track(TrackingEvent.BudgetReserved, {
        pacePlan: this.activePlan.pace_plan
      });
      this.reloadSupportItems();
      this.dismissModal();
    },
    reloadSupportItems() {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        this.$store.dispatch("supportItems/getSupportItems").catch(error => {
          console.log("error loading plan support items", error);
          vm.$notification.createToast("Unable to load support item spending", "error");
        });
      }, 5000);
    }
  }
});
</script>
<style lang="scss" scoped>
.page-header {
  width: 100%;
  height: 92px;
  margin: 0px -64px 22px -65px;
  width: unset;
  padding: 0 55.5px;
  flex-wrap: wrap;
  display: flex;
  .arrow-left {
    text-align: center;
    display: inline-block;
    margin-top: 22.5px;
    padding-left: 4px;
    color: #fff;
    margin-left: -5px;
  }
  h4 {
    flex: 1 1 91%;
    margin-top: 22.5px;
    padding-left: 18px;
  }
  p.sub-header {
    padding-left: 10px;
    padding-bottom: 5px;
    margin-top: -13px;
  }
  .icon-expand {
    display: none;
  }
  @media only screen and (min-width: $brand-support-drawer-breakpoint) {
    .arrow-left {
      text-align: center;
      display: inline-block;
      margin-top: 22.5px;
      padding-left: 4px;
      color: #fff;
      margin-left: -30px;
    }
    h4 {
      flex: 1 1 91%;
      margin-top: 22.5px;
      padding-left: 18px;
    }
    p.sub-header {
      padding-left: 10px;
      padding-bottom: 5px;
      margin-top: -13px;
    }
    .icon-expand {
      display: none;
    }
  }
}

@media only screen and (max-width: $brand-support-drawer-breakpoint) {
  .page-header {
    width: unset;
    flex-wrap: wrap;
    display: flex;
    min-height: 75px;
    height: 1%;
    .arrow-left {
      text-align: center;
      display: inline-block;
      margin-top: 12px;
      color: #fff;
    }
    h4 {
      flex: 1 1 80%;
      margin-top: 12px;
      padding-left: 16px;
    }
    p.sub-header {
      padding-left: 35px;
      padding-bottom: 5px;
    }
    .icon-expand {
      display: none;
    }
  }
}
.add-support-item {
  background: #162032;
  color: $brand--theme--text-01;
  padding-right: 22px;
  padding-left: 22px;
  padding-top: 0;
  padding-bottom: 50px;

  .help-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cv-link {
      padding-top: 0;
    }
  }
  p.sub-title {
    padding: 11px 0 1rem 0;
    font-size: 14px;
  }
}
</style>
