<template>
  <div
    class="progressbar"
    role="progressbar"
    :aria-valuenow="percentageLeft"
    aria-valuemin="0"
    aria-valuemax="100"
    :data-width="proportionalWidth"
    :style="{ width: `${proportionalWidth}%`, maxWidth: '100%' }"
    :aria-valuetext="percentageLeft + '%'"
  >
    <div
      class="bar"
      :style="{
        width: percentageLeft + '%',
        minWidth: minimumWidth
      }"
    ></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    percentageLeft: {
      default: 0
    },
    proportionalWidth: {
      default: 5
    }
  },
  computed: {
    minimumWidth() {
      return this.percentageLeft > 0 ? "2px" : "0px";
    }
  }
});
</script>
