<template>
  <div class="claims-filters">
    <div class="head">
      <div class="title">
        <slot name="heading"></slot>

        <slot name="description"></slot>
      </div>
      <div class="view-controls">
        <div class="view-help" v-if="!$root.isMobileView && $slots.help">
          <slot name="help"></slot>
        </div>
        <div class="record-controls">
          <div class="page-summary" v-if="!$root.isMobileView && selectedTab === 'all-claims'">
            <span class="page-meta">
              {{ pagination.first_record_number }}
              -
              {{ pagination.last_record_number }}
              of
              {{ pagination.total }}
              items
            </span>
            <button
              :aria-label="`Go to page ${pagination.current_page - 1} of records`"
              class="filter-button arrow-button"
              @click="onPageChange(pagination.current_page - 1)"
              :disabled="pagination.is_first_page"
            >
              <ion-icon
                v-if="$brand === 'ndsp'"
                :src="$brand_svg_src + `/arrow-left-${pagination.is_first_page ? 'black' : 'white'}.svg`"
              />
              <ion-icon v-else-if="$brand === 'mpm'" :src="$brand_svg_src + `/arrow-left-white.svg`" />
            </button>
            <button
              :aria-label="`Go to page ${pagination.current_page + 1} of records`"
              class="filter-button arrow-button"
              @click="onPageChange(pagination.current_page + 1)"
              :disabled="pagination.is_last_page"
            >
              <ion-icon
                v-if="$brand === 'ndsp'"
                :src="$brand_svg_src + `/arrow-right-${pagination.is_last_page ? 'black' : 'white'}.svg`"
              />
              <ion-icon v-else-if="$brand === 'mpm'" :src="$brand_svg_src + `/arrow-right-white.svg`" />
            </button>
          </div>
          <div
            class="page-summary"
            v-if="!$root.isMobileView && selectedTab === 'reimbursements' && paginationReimbursement"
          >
            <span class="page-meta">
              {{ paginationReimbursement.first_record_number }}
              -
              {{ paginationReimbursement.last_record_number }}
              of
              {{ paginationReimbursement.total }}
              items
            </span>
            <button
              :aria-label="`Go to page ${paginationReimbursement.current_page - 1} of records`"
              class="filter-button arrow-button"
              @click="onPageChange(paginationReimbursement.current_page - 1)"
              :disabled="paginationReimbursement.is_first_page"
            >
              <ion-icon :src="$brand_svg_src + `/arrow-left-white.svg`" />
            </button>
            <button
              :aria-label="`Go to page ${paginationReimbursement.current_page + 1} of records`"
              class="filter-button arrow-button"
              @click="onPageChange(paginationReimbursement.current_page + 1)"
              :disabled="paginationReimbursement.is_last_page"
            >
              <ion-icon :src="$brand_svg_src + `/arrow-right-white.svg`" />
            </button>
          </div>
          <!-- Filter Button -->
          <div v-desktop-breakpoint-only class="button" v-if="!filterDisabled">
            <button
              :class="`filter-button toggle-filter-button ${isFilterDisplayActive ? 'active' : ''}`"
              @click.prevent.stop="onFilterDisplayToggle"
            >
              <ion-icon src="svg/filter.svg" class="button-icon" />
              <span class="label dropdown-button-label">Filter</span>
              <ion-icon :src="$brand_svg_src + `/arrow-right-white.svg`" class="dropdown-arrow-icon" />
            </button>
            <ClaimsFilterDisplay
              :active="isFilterDisplayActive"
              :dates="filters.dates"
              :supportTypes="filters.supportTypes"
              :paidToTypes="filters.paidToTypes"
              :showPaidToTypeFilter="showPaidToTypeFilter()"
              @dismiss="isFilterDisplayActive = false"
              @update="onFilterDisplayUpdate"
            />
          </div>

          <div v-mobile-breakpoint-only class="button" v-if="!filterDisabled">
            <button
              :class="`filter-button toggle-filter-button ${isFilterDisplayActive ? 'active' : ''}`"
              @click="setFilterOpen(true)"
            >
              <ion-icon src="svg/filter.svg" class="button-icon" />
            </button>
            <ion-modal class="app-modal" :is-open="isFilterOpenRef" @didDismiss="setFilterOpen(false)">
              <ClaimsFilterDisplay
                :active="true"
                :dates="filters.dates"
                :supportTypes="filters.supportTypes"
                :paidToTypes="filters.paidToTypes"
                :showPaidToTypeFilter="showPaidToTypeFilter()"
                @dismiss="isFilterDisplayActive = false"
                @update="onFilterDisplayUpdate"
              />
            </ion-modal>
          </div>

          <!-- Sort Button -->
          <div v-desktop-breakpoint-only class="button" v-if="!sortDisabled">
            <button
              :class="`sort-button toggle-filter-button ${isSortDisplayActive ? 'active' : ''}`"
              @click.prevent.stop="onSortDisplayToggle"
            >
              <ion-icon src="svg/sort.svg" class="button-icon" />
              <span class="label dropdown-button-label">Sort</span>
              <ion-icon :src="$brand_svg_src + `/arrow-right-white.svg`" class="dropdown-arrow-icon" />
            </button>
            <ClaimsSortDisplay
              :active="isSortDisplayActive"
              :sortByProviderName="sorts.sortByProviderName"
              @dismiss="isSortDisplayActive = false"
              @update="onSortDisplayUpdate"
            />
          </div>

          <div v-mobile-breakpoint-only class="button" v-if="!sortDisabled">
            <button
              :class="`filter-button toggle-sort-button ${isSortDisplayActive ? 'active' : ''}`"
              @click="setSortOpen(true)"
            >
              <ion-icon src="svg/sort.svg" class="button-icon" />
            </button>
            <ion-modal class="app-modal" :is-open="isSortOpenRef" @didDismiss="setSortOpen(false)">
              <ClaimsSortDisplay
                :active="true"
                :sortByProviderName="sorts.sortByProviderName"
                @dismiss="isSortDisplayActive = false"
                @update="onSortDisplayUpdate"
              />
            </ion-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="other-actions">
      <slot v-if="!filterDisabled" name="other-actions"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { UIPagination } from "@/types/requests";
import { IonModal, IonIcon } from "@ionic/vue";
import ClaimsFilterDisplay from "./ClaimsFilterDisplay.vue";
import ClaimsSortDisplay from "./ClaimsSortDisplay.vue";

interface Data {
  filterLabel: string;
  filtersDisplayed: boolean;
  filters: object;
}
interface Methods {
  toggleFiltersDisplayed: () => void;
  changeFilter: (filter: string) => void;
  changePage: (page: number) => void;
  filterButtonClass: (filter: string) => string;
}
interface Computed {
  filterButtonIcon: string;
}
interface Props {
  activeFilter: string;
  pagination: UIPagination;
  paginationReimbursement: UIPagination;
  selectedTab: string;
}

const REIMBURSEMENT_LABEL = "reimbursements";

export default defineComponent({
  name: "claims-filters",
  components: { ClaimsFilterDisplay, ClaimsSortDisplay, IonModal, IonIcon },
  props: {
    setup() {},
    pagination: {
      type: Object
    },
    paginationReimbursement: {
      type: Object
    },
    filters: {
      type: Object,
      default() {
        return {
          dates: null,
          supportTypes: [],
          paidToTypes: []
        };
      }
    },
    filterDisabled: {
      type: Boolean,
      default: false
    },
    sortDisabled: {
      type: Boolean,
      default: false
    },
    sorts: {
      type: Object,
      default() {
        return {
          sortByProviderName: "A-Z"
        };
      }
    },
    selectedTab: {
      type: String
    }
  },
  data() {
    return {
      isFilterOpenRef: false,
      isSortOpenRef: false,
      isFilterDisplayActive: false,
      isSortDisplayActive: false
    };
  },
  computed: {
    filterButtonIcon(): string {
      return this.filtersDisplayed ? "Close20" : "CaretDown20";
    }
  },
  methods: {
    setFilterOpen(state: boolean) {
      this.isFilterOpenRef = state;
    },
    setSortOpen(state: boolean) {
      this.isSortOpenRef = state;
    },
    onPageChange(page: number) {
      this.$emit("page-change", page);
    },
    onReimbursementPageChange(page: number) {
      this.$emit("reimbursement-page-change", page);
    },
    onFilterDisplayToggle() {
      this.isFilterDisplayActive = !this.isFilterDisplayActive;
      this.isSortDisplayActive = false;
    },
    onSortDisplayToggle() {
      this.isSortDisplayActive = !this.isSortDisplayActive;
      this.isFilterDisplayActive = false;
    },

    onFilterDisplayUpdate(params) {
      console.log("In claims filter.vue");
      this.$emit("filter-change", params);
    },
    onSortDisplayUpdate(params) {
      this.$emit("sort-change", params);
    },
    showPaidToTypeFilter() {
      return this.selectedTab !== REIMBURSEMENT_LABEL;
    }
  }
});
</script>

<style lang="scss" scoped>
.claims-filters {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .record-controls {
      display: flex;
      align-items: center;
      .page-summary {
        display: flex;
        align-items: center;
        padding-right: 1.5rem;
        .page-meta {
          margin-right: 1rem;
        }
        .page-meta {
          font-size: 12px;
          padding-left: 15px;
        }
      }
    }
  }
  .view-controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > div {
      flex: 30px 1;
    }
  }
  :deep .other-actions {
    min-height: 3rem;
    display: flex;
    justify-content: center;
    & > * {
      flex: 1 1 auto;
    }
  }
  .filter-control {
    position: relative;
    padding-right: 26px !important;
    svg {
      position: absolute;
      right: 8px;
      top: 18px;
    }
  }
  .toggle-filter-button:hover {
    background-color: $brand--filter-toggle-button-hover;
    border-color: $brand--filter-toggle-button-hover;
  }
  .filter-button {
    box-shadow: $brand--button-filter-box-shadow;
    background: $brand--button-filter;
    border: none;
    border-radius: 0.25rem;
    color: $brand--theme--text-01;
    display: flex;
    padding: 8px;
    height: 40px;
    align-items: center;

    &.arrow-button {
      display: flex;
      justify-content: center;
      width: 2.75rem;
      ion-icon {
        display: inline-block;
        height: 1.25rem;
      }
      &:hover:not(:disabled) {
        background-color: $brand--filter-toggle-button-hover;
        border-color: $brand--filter-toggle-button-hover;
      }
    }
    &:focus-visible,
    &:active {
      outline: 3px solid $brand--focus-outline !important;
      outline-offset: 2px;
    }
    &.active {
      .dropdown-arrow-icon {
        height: 1rem;
        transform: rotate(90deg);
      }
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
      background: $brand--pagination-disabled-color;
    }
    .button-icon {
      padding-top: 4px;
    }

    .dropdown-arrow-icon {
      height: 1rem;
      transform: rotate(0deg);
      transition: transform 0.25s;
    }

    .dropdown-button-label {
      margin: 0 0.5rem;
      font-size: 1rem;
    }
  }
  .sort-button {
    box-shadow: $brand--button-filter-box-shadow;
    background: $brand--button-filter;
    border: none;
    border-radius: 0.25rem;
    color: $brand--theme--text-01;
    display: flex;
    padding: 8px;
    height: 40px;
    align-items: center;

    &.arrow-button {
      display: flex;
      justify-content: center;
      width: 2.75rem;
      ion-icon {
        display: inline-block;
        height: 1.25rem;
      }
      &:hover:not(:disabled) {
        background-color: $brand--filter-toggle-button-hover;
        border-color: $brand--filter-toggle-button-hover;
      }
    }
    &:focus-visible,
    &:active {
      outline: 3px solid $brand--focus-outline;
      outline-offset: 2px;
    }
    &.active {
      .dropdown-arrow-icon {
        height: 1rem;
        transform: rotate(90deg);
      }
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    .button-icon {
      padding-top: 4px;
    }

    .dropdown-arrow-icon {
      height: 1rem;
      transform: rotate(0deg);
      transition: transform 0.25s;
    }

    .dropdown-button-label {
      margin: 0 0.5rem;
      font-size: 1rem;
    }
  }
  .filter-row {
    min-height: 55px;
    display: flex;
    justify-content: flex-end;
    &.with-filters {
      justify-content: space-between;
    }
    @media screen and (max-width: $width--breakpoint--md) {
      display: block;
    }
  }
  .filters {
    display: flex;
    flex-wrap: wrap;
    button {
      background-color: $brand--theme--ui-background-hover;
      margin: 5px;
      &:first-child {
        margin-left: 0;
      }

      &.active-filter {
        background-color: $brand--theme--ui-03;
        &.all {
          background-color: $brand--support-type-all;
        }
        &.core {
          background-color: $brand--support-type-core;
        }
        &.capacity {
          background-color: $brand--support-type-capacity;
        }
        &.capital {
          background-color: $brand--support-type-capital;
        }
      }
    }
  }
  @media screen and (max-width: $width--breakpoint--md) {
    .head {
      h1 {
        font-size: 20px;
      }
    }
  }
}
.record-controls {
  display: flex;
  align-items: center;
  .page-summary {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    .page-meta {
      margin-right: 1rem;
    }
    button:not(:last-child) {
      &.duet-date__toggle {
        margin: 0;
      }
    }
    .button {
      position: relative;
      @media screen and (max-width: $width--breakpoint--md) {
        position: static;
      }
      &:last-child {
        button {
          margin: 0;
        }
      }
    }
    .page-meta {
      font-size: 12px;
      padding-left: 15px;
    }
  }
}
</style>
