<template>
  <div>
    <ion-header id="app-header">
      <ion-toolbar>
        <ion-buttons slot="secondary">
          <ion-icon class="mpm-icon" src="assets/icons/mpm-icon.svg"></ion-icon>
        </ion-buttons>
        <ion-title> </ion-title>
        <ion-buttons slot="primary">
          <ion-button v-if="$brand === 'mpm'" @click="openContactWidgetModal()" aria-label="Contact us">
            <ion-icon slot="start" src="svg/phone-pickup.svg"></ion-icon>
          </ion-button>
          <ion-button @click="openParticipantListModal()" aria-label="Participant list">
            <ion-icon slot="start" src="svg/user-list.svg"></ion-icon>
          </ion-button>
          <ion-button>
            <ion-icon slot="start" src="svg/help.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <component :is="showClickToCall ? 'svg-phone-active' : 'svg-phone'" style="stroke: #fff; fill: transparent" />
    </ion-header>
    <div class="main-panel">
      <main class="main">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ParticipantInfo from "@/components/menu/ParticipantInfo.vue";
import ParticipantList from "@/components/ParticipantList.vue";
import ClickToCall from "@/components/ClickToCall.vue";
import UserListIcon from "@/assets/icons/user-list.svg";
import UserCircleIcon from "@/assets/icons/user-circle.svg";
import UserCircleIconActive from "@/assets/icons/user-circle-active.svg";
import PhonePickupIcon from "@/assets/icons/phone-pickup.svg";
import LogoutIcon from "@/assets/icons/logout.svg";
import MoneyIcon from "@/assets/icons/money-circle.svg";
import { openModal } from "@/helpers/system";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "layout-base",
  components: {
    IonIcon,
    ClickToCall,
    ParticipantInfo,
    UserListIcon,
    UserCircleIcon,
    UserCircleIconActive,
    PhonePickupIcon,
    ParticipantList,
    LogoutIcon,
    MoneyIcon
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    async toggleParticpantList() {
      const isOpen = this.showParticipantList;
      this.showParticipantList = !isOpen;
      if (!isOpen) {
        await this.$store.dispatch("participant/getParticipants");
      }
    },
    async openParticipantListModal() {
      return openModal(ParticipantList);
    },
    async openContactWidgetModal() {
      return openModal(ClickToCall);
    }
  },
  data() {
    return {
      showClickToCall: false,
      participant: this.$store.getters.participant
    };
  }
});
</script>
<style lang="scss">
.main-panel {
  margin-top: 40px;
}
#header-logo {
  width: 38px;
  @include grid-media($grid--breakpoint--md) {
    width: 140px;
  }
}

.main-panel .menu-participant-info {
  display: flex;
  @include grid-media($grid--breakpoint--md) {
    display: none;
  }
}

.bx--header__global {
  margin-top: 12px;
}
.bx--header__global .bx--header__action > svg {
  fill: none;
}
.header-link-user {
  svg {
    margin: 8px 10px;
    path:nth-child(1),
    path:nth-child(2) {
      fill: none;
    }
  }
}
.user-link-active.header-link-user {
  svg {
    path:nth-child(1),
    path:nth-child(2) {
      fill: $brand--theme--brand-01;
      stroke: $brand--theme--brand-01;
    }
    path:nth-child(3) {
      stroke: $brand--theme--brand-01;
    }
    path:nth-child(4) {
      stroke: #fff;
    }
  }
}
#mobile-footer .footer-link {
  flex-basis: 20%;
}
.header-greeter {
  padding: 16px;
  color: $brand--theme--header-font-color;
  font-size: 16px;
  display: none;
  @include grid-media($grid--breakpoint--md) {
    display: inline-block;
  }
}
.bx--header__action {
  margin: 0 6px;
  &:disabled {
    opacity: 0.5;
    &:hover {
      background-color: unset;
      cursor: not-allowed;
    }
  }
}
.header-link {
  span {
    color: #8fb8ff;
    display: none;
  }
  svg {
    display: inline-block;
    margin: 6px 10px 0;
  }
  @include grid-media($grid--breakpoint--md) {
    svg {
      display: none;
    }
    span {
      display: block;
    }
    text-decoration: none;
    padding: 14px 0;
    font-weight: 600;
    margin: 0 14px;
  }
}
</style>
