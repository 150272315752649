<template>
  <ion-page>
    <div id="layout-base-wrapper">
      <layout-base></layout-base>
      <ion-tabs @ionTabsWillChange="beforeTabChange" @ionTabsDidChange="afterTabChange">
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar ref="tabBar" slot="bottom" id="mobile-footer">
          <ion-tab-button tab="PlanTab" href="/plan">
            <ion-icon aria-hidden="true" :src="$brand_svg_src + `/${icons['PlanTab']}.svg`"></ion-icon>
            <ion-label aria-label="Plan" role="navigation">Plan</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="ClaimsTab" href="/claims">
            <ion-icon aria-hidden="true" :src="$brand_svg_src + `/${icons['ClaimsTab']}.svg`"></ion-icon>
            <ion-label aria-label="Claims" role="navigation">Claims</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="ProvidersTab" href="/providers">
            <ion-icon aria-hidden="true" :src="$brand_svg_src + `/${icons['ProvidersTab']}.svg`"></ion-icon>
            <ion-label aria-label="Providers" role="navigation">Providers</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="ReportsTab" href="/reports">
            <ion-icon aria-hidden="true" :src="$brand_svg_src + `/${icons['ReportsTab']}.svg`"></ion-icon>
            <ion-label aria-label="Reports" role="navigation">Reports</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="SettingsTab" href="/settings">
            <ion-icon aria-hidden="true" :src="$brand_svg_src + `/${icons['SettingsTab']}.svg`"></ion-icon>
            <ion-label aria-label="Settings" role="navigation">Settings</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

import LayoutBase from "@/layouts/LayoutBase.vue";

export default defineComponent({
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    LayoutBase
  },
  data() {
    return {
      icons: {
        PlanTab: "plan",
        ClaimsTab: "claims",
        ProvidersTab: "providers",
        ReportsTab: "reports",
        SettingsTab: "settings"
      }
    };
  },
  methods: {
    beforeTabChange: function() {
      const selectedTab = this.$refs.tabBar.$el.selectedTab;
      if (typeof selectedTab !== "undefined") {
        if (this.icons[selectedTab]) this.icons[selectedTab] = this.icons[selectedTab].replace("-active", "");
      }
    },
    afterTabChange: function() {
      const selectedTab = this.$refs.tabBar.$el.selectedTab;
      if (selectedTab === "ProvidersTab") {
        this.$router.push("/providers");
      }
      this.icons[selectedTab] = this.icons[selectedTab].concat("-active");
    }
  },
  props: {}
});
</script>

<style lang="scss">
#layout-base-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#mobile-footer {
  ion-icon {
    margin-bottom: 3px;
  }
}

.tabs-inner {
  @include tablet-only {
    margin-left: 12rem;
  }
}

ion-tab-button {
  --background-focused: none;

  &:focus {
    outline: 2px solid white;
  }

  &::after {
    background-color: transparent;
    display: block;
    content: "";
    margin: -3px auto;
    width: 90%;
    height: 3px;
  }

  &.tab-selected {
    color: $brand--footer-tab-active;
  }

  &.tab-selected::after {
    background-color: $brand--footer-tab-active;
  }
}

ion-tabs {
  position: initial !important;
}

ion-tab-button ion-label {
  font-size: 0.68rem;
  padding-bottom: 2px;
}
</style>
