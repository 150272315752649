<template>
  <div class="balance-card">
    <div v-if="reservedBudget" class="reserved-budget-title">
      <ion-icon :src="$brand_svg_src + `/padlock.svg`" />
      <span>Reserved budget</span>
    </div>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col class="ion-no-padding">
          <div class="card-label">Opening balance:</div>
          <div class="card-amount">{{ $filters.currency(balanceData.allocated) }}</div>
        </ion-col>
        <ion-col class="ion-no-padding">
          <div class="card-label ion-text-end">Available balance</div>
          <div class="card-amount ion-text-end">
            {{ $filters.currency(balanceData.remaining) }}
            <span v-if="balanceData.remaining >= 0">({{ remainingPercentage }}%)</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="balance-prog-bar">
      <balance-progress-bar
        :color-class="supportTypeColorClass"
        :spent="balanceData.spent"
        :allocated="balanceData.allocated"
        :remaining="balanceData.remaining"
        height="10"
      ></balance-progress-bar>
    </div>
    <div class="spent-details" :class="reservedBudget ? '' : 'ion-margin-top'">
      <div>
        <span class="card-amount"
          >{{ $filters.currency(balanceData.spent) }} <span>({{ spentPercentage }}%)</span></span
        >
        <span class="spent-text">spent</span>
      </div>
      <div class="flex-spacer" />
      <div v-if="!reservedBudget">
        <mpm-tooltip>
          <template v-slot:icon>
            <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
          </template>
          <template v-slot:content>
            <p>The spent and available amount includes your reserved budget if you have one set up.</p>
          </template>
        </mpm-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonRow, IonCol, IonGrid, IonCard } from "@ionic/vue";
import BalanceProgressBar from "@/components/plans/pace/BalanceProgressBar.vue";
import { converter } from "@/helpers/filters";
import { supportTypes } from "@/helpers/support-category";
import mpmTooltip from "@/components/MpmTooltip.vue";

export default defineComponent({
  name: "PacePlanTotalBudget",
  props: {
    balanceData: {
      type: Object,
      default: () => {
        return { spent: 0, allocated: 0, remaining: 0 };
      }
    },
    reservedBudget: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BalanceProgressBar,
    mpmTooltip,
    IonIcon,
    IonRow,
    IonCol,
    IonGrid,
    IonCard
  },
  computed: {
    supportTypeColorClass() {
      return converter("route_name", this.$route.params.supportArea, "cssClass", supportTypes);
    },
    remainingPercentage() {
      if (this.balanceData.remaining === 0) {
        return 0;
      }
      return ((this.balanceData.remaining / this.balanceData.allocated) * 100).toFixed(1);
    },
    spentPercentage() {
      if (this.remainingPercentage === 0) {
        return 0;
      }
      return (100 - this.remainingPercentage).toFixed(1);
    }
  }
});
</script>
<style lang="scss" scoped>
.balance-card {
  background-color: $brand--pace-plan-tile-bg;
  padding: 10px 16px;
  margin: 8px 10px;
  border-radius: 3px;
  @media only screen and (min-width: 992px) {
    height: 165px;
  }
  .reserved-budget-title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    ion-icon {
      margin-right: 16px;
    }
    span {
      color: $brand--font-color-primary;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
  }
  .card-label {
    color: $brand--font-color-primary;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .card-amount {
    font-size: 18px;
    font-weight: bold;
    color: $brand--font-color-primary;
  }
  .spent-text {
    color: $brand--font-color-primary;
    font-size: 16px;
    line-height: 22px;
    margin-left: 8px;
  }
  .balance-prog-bar {
    margin: 10px 0 8px;
  }
  .spent-details {
    display: flex;
    .flex-spacer {
      flex-grow: 1;
    }
    .help-icon {
      border: 0;
      border-radius: 11px;
      height: 24px;
      width: 24px;
    }
  }
}
</style>
