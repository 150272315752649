<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <SFProviderClaims ref="providerClaims" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonPage } from "@ionic/vue";
import { mapGetters } from "vuex";
import SFProviderClaims from "@/components/pages/ProviderClaims.vue";

export default defineComponent({
  name: "provider-claims",
  components: {
    IonPage,
    SFProviderClaims
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading"
    })
  },
  ionViewWillEnter() {
    this.$refs.providerClaims.loadPage();
  },
  ionViewWillLeave() {
    try {
      this.$refs.providerClaims.resetExpandedRows();
    } catch (e) {
      console.log(e);
    }
  }
});
</script>
