<template>
  <ion-page>
    <ion-content>
      <div class="logout-modal-wrap">
        <div class="modal-actions">
          <button type="button" aria-label="Close modal" class="logout-modal-close" @click="onCancelButtonClick">
            <ion-icon class="close-icon" :src="`${$brand_svg_src}/close.svg`"></ion-icon>
          </button>
        </div>
        <h2>Are you sure you want to logout?</h2>
        <p>
          You are currently logged in on this device and don't need to log in each time you access the portal.
        </p>
        <br />
        <p>
          If you logout a <strong>new SMS code</strong> or <strong>email code</strong> will need to be requested to log
          back in on this device.
        </p>
        <div class="actions">
          <ion-button class="confirm focusable-button" kind="primary" @click="onConfirmButtonClick"
            >Yes, logout</ion-button
          >
          <ion-button class="cancel focusable-button" kind="tertiary" @click="onCancelButtonClick">Cancel</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss" scoped>
.focusable-button::part(native):focus-visible {
  outline: 3px solid $brand--focus-outline-button;
  outline-offset: 2px;
}
ion-button {
  margin-right: 0;
  display: block;
  width: 100%;
  max-width: none;
  margin-bottom: 1rem;
  text-align: center;
  --padding: 0 2rem;
  text-transform: inherit;
  font-weight: 400;
  border-radius: 3px;
  font-size: 14px;
  min-height: 48px;
}
ion-button.confirm {
  background: $brand--primary-color;
  margin-right: 1rem;
  color: $brand--modals-submit-button-text-color !important;
}
ion-button.cancel {
  --border-style: solid;
  --border-color: $brand--modals-cancel-button-border-color;
  --background: $brand--modals-cancel-button-background-color;
}
ion-button.cancel::part(native) {
  border: 1px solid $brand--modals-cancel-button-border-color;
  --background-focused: none;
  color: $brand--modals-cancel-button-text-color;
}
.logout-modal-wrap {
  position: inherit;
  background: $brand--theme--ui-background;
  padding: 1rem 1rem 3rem;
  max-width: 40rem;
  .modal-actions {
    height: 3rem;
    width: 3rem;
    button:hover {
      background-color: $brand--modals-close-hover;
    }
    .logout-modal-close {
      position: absolute;
      top: 0;
      right: 0;
      height: 3rem;
      width: 3rem;
      padding: 0.75rem;
      border: 2px solid transparent;
      overflow: hidden;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
      &:focus-visible {
        border: 3px solid $brand--focus-outline;
        outline: none;
      }
      .close-icon {
        fill: $brand--modals-close-color;
        height: 1.25rem;
        width: 1.25rem;
        .icon-inner svg {
          fill: $brand--modals-close-color !important;
        }
      }
    }
  }
  h2 {
    padding-left: 1rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $brand--logout-modal-text-color;
    padding: 1rem;
    margin: 0;
  }
  &::v-deep {
    /* .bx--modal-close {
        display: none;
      } */
    .bx--modal-container {
      position: inherit;
      top: 6.25rem;
      background: $brand--theme--ui-background;
      padding: 1rem;
      max-width: 40rem;
      padding-bottom: 3rem;
    }
    .bx--modal-header {
      padding-top: 2rem;
      margin-bottom: 1rem;
      h2 {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
    .bx--modal-content {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #cccccc;
      padding: 1rem;
      margin: 0;
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: #cccccc;
      }

      .bx--btn--tertiary {
        border-color: $brand--button-main;
        color: $brand--button-main;
        &:hover {
          border-color: $brand--button-secondary-hover;
          color: $brand--button-secondary-hover;
          background: transparent;
        }
      }
    }
  }
  .actions {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    button {
      display: inline-block;
      margin-right: 1rem;
      flex: 1 0 auto;
      text-align: center;
      box-shadow: $brand--modals-button-box-shadow;
    }
    button.confirm {
      background-color: $brand--modals-submit-button-bg-color;
      color: $brand--modals-submit-button-text-color;
    }
    button.cancel {
      border-color: $brand--modals-cancel-button-border-color;
      color: $brand--modals-cancel-button-text-color;
      background: none;
    }
  }
  @media screen and (max-width: $width--breakpoint--md) {
    &::v-deep {
      .bx--modal-container {
        top: 3.625rem;
        bottom: 4.375rem;
        height: auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
      }
      .bx--modal-header {
        padding-top: 1rem;
      }
    }
    .actions {
      margin-top: 3rem;
      display: block;
      text-align: center;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { TrackingEvent } from "@/types/tracking-events";
import { closeModal } from "@/helpers/system";
import { IonIcon, IonContent, IonPage } from "@ionic/vue";
import { debounce } from "lodash";

export default defineComponent({
  name: "LogoutModal",
  components: {
    IonIcon,
    IonContent,
    IonPage
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onConfirmButtonClick: debounce(function() {
      this.logout();
    }, 50),
    onCancelButtonClick() {
      closeModal();
      this.$emit("close");
    },
    logout() {
      closeModal();
      this.$emit("close");
      this.$nextTick(() => {
        this.$store.dispatch("user/logout");
        this.$router.push({ path: "/login" }).then(async () => {
          this.$amplitude.track(TrackingEvent.Logout, {});
          await this.$notification.createToast(
            "You have successfully logged out. To log back in on this device please request a new SMS or email code.",
            "success"
          );
        });
      });
    }
  },
  data() {
    return {};
  }
});
</script>
