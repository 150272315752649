<template>
  <ion-content
    id="provider-claims-container"
    class="page-content provider-claims-tab"
    tabindex="0"
    aria-label="Provider claims page content"
  >
    <section id="provider-claims" aria-label="provider claims page">
      <div style="overflow:auto" tabindex="-1">
        <mpm-loading :is-loading="contentsLoading"></mpm-loading>
        <div class="provider-claims content-spacer">
          <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
            <ion-refresher-content
              pulling-icon="svg/loading.svg"
              refreshing-spinner="circles"
              refreshing-text="Refreshing..."
            >
            </ion-refresher-content>
          </ion-refresher>

          <provider-header></provider-header>

          <provider-claims-filters :pagination="pagination" @change-filter="setFilter" @page-change="onPageChange">
            <template v-slot:description>
              <p class="main-desc">
                A list of all claims for {{ providerLabel() }} for Plan
                <span>{{ planLabel() }}</span>
              </p>

              <p v-if="currentPlan.pace_plan" class="pace-message">
                This shows your provider claims details from your new look NDIS plan.<img
                  class="myndislogo"
                  :src="`${$brand_svg_src}/myndislogo.png`"
                  alt="my NDIS logo"
                />
              </p>
            </template>
          </provider-claims-filters>

          <div class="tab-container">
            <SFProviderClaimsGrid
              v-show="!isEmpty"
              :claims="claims"
              @approve="approve"
              @decline="decline"
              ref="sfClaimsGrid"
              @row-expand-collapse-change="onRowExpandCollapseChange"
              @on-toggle-row="onToggleRow"
            >
              <template v-if="!hasFiltersApplied(filters)" v-slot:noDataMessage>
                <div class="claims-list-empty" v-if="hasFullParticipantAccess">
                  <h5>You haven't submitted any claims.</h5>
                  <p class="empty-message">
                    All submitted and processed claims will appear here. Use the ‘Submit claim’ button or email the
                    invoice to
                    <a class="emailLink" :href="`mailto:${$strings.emails.accounts}`">{{ $strings.emails.accounts }}</a>
                    to submit your claim to be processed.
                  </p>
                </div>
                <div class="claims-list-empty" v-if="hasReadOnlyParticipantAccess">
                  <h5>There are no claims to show here yet.</h5>
                  <p class="empty-message">
                    All submitted and processed claims will appear here.
                  </p>
                </div>
              </template>
            </SFProviderClaimsGrid>
            <div class="empty-state" v-if="isEmpty">
              <p>
                There has not yet been any claims for this provider in this plan.
              </p>
              <button
                v-if="$store.getters.hasFullParticipantAccess"
                aria-label="Submit New Claim"
                @click="$router.push({ name: 'SubmitClaim' })"
              >
                Submit Claim
              </button>
            </div>
            <!-- If that have lodged atleast one claim, show pagination -->
            <claims-pagination :pagination="pagination" @change-page="onPageChange"></claims-pagination>
          </div>
        </div>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProviderClaimsFilters from "@/components/ProviderClaimsFilters.vue";
import SFProviderClaimsGrid from "@/components/salesforce/SFProviderClaimsGrid.vue";
import ProviderHeader from "@/components/ProviderHeader.vue";
import { SfDateGroupedClaims } from "@/types/salesforce/claims";
import { Provider } from "@/types/providers";
import { IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent } from "@ionic/vue";
import ClaimsPagination from "@/components/ClaimsPagination.vue";
import { Plan } from "@/types/plans";
import { DateGroupedClaims } from "@/types/claims";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";

interface Content extends HTMLElement {
  scrollToTop(n: number): void;
}

interface Data {
  isEmpty: boolean;
  activeFilter: string;
  providerId: any;
  provider: Provider;
  currentPage: number;
  activePlan: Plan;
}

interface Methods {
  loadSfProviderClaims: (providerId: string, planSfId: string, pageNumber: number) => any;
  setFilter: (filter: string) => void;
  onPageChange: (pageNumber: number) => void;
  onToggleRow: (rows: string[]) => void;
  onRowExpandCollapseChange: (rows: string[]) => void;
}

interface Computed {
  claims: DateGroupedClaims;
  sfGroupedClaims: SfDateGroupedClaims;
  filterClaims: (supportTypeCode: string) => SfDateGroupedClaims;
}

export default defineComponent({
  name: "provider-claims",
  components: {
    SFProviderClaimsGrid,
    ProviderHeader,
    IonPage,
    IonContent,
    IonIcon,
    ProviderClaimsFilters,
    ClaimsPagination,
    IonRefresher,
    IonRefresherContent
  },
  data(): Data {
    return {
      isEmpty: true,
      activeFilter: "",
      providerId: null,
      provider: null,
      activePlan: this.$store.getters.currentPlan,
      currentPage: 1
    };
  },
  methods: {
    ...mapActions("providers", {
      loadSfProviderClaims: "loadSfProviderClaims",
      approveClaim: "approveClaim",
      sfApproveClaim: "sfApproveClaim"
    }),
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    ...mapActions("systemPersist", {
      removeOutDatedClaimStatus: "removeOutDatedClaimStatus"
    }),
    scrollToTop() {
      const form = document.getElementById("provider-claims-container") as Content;
      form.scrollToTop(0);
    },
    setFilter(filter: string) {
      this.activeFilter = filter;
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async approve(claimId: string): Promise<any> {
      try {
        this.updateContentsLoading(true);
        this.removeOutDatedClaimStatus();
        await this.sfApproveClaim({
          claimId,
          approve: true
        });
        await this.reloadProviderClaims();
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    async decline(claimId: string): Promise<any> {
      try {
        this.updateContentsLoading(true);
        this.removeOutDatedClaimStatus();
        await this.sfApproveClaim({
          claimId,
          approve: false
        });
        await this.reloadProviderClaims();
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    async reloadProviderClaims() {
      this.updateContentsLoading(true);
      try {
        await this.loadSfProviderClaims({
          providerId: this.$store.getters["providers/provider"].sfid,
          planSfId: this.activePlan.sfid,
          pageNumber: this.currentPage
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    async onPageChange(pageNumber: number) {
      this.updateContentsLoading(true);
      try {
        this.scrollToTop();
        await this.loadSfProviderClaims({
          providerId: this.$store.getters["providers/provider"].sfid,
          planSfId: this.activePlan.sfid,
          pageNumber: pageNumber
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
        this.$refs.sfClaimsGrid.resetExpandedRows();
        this.currentPage = pageNumber;
      }
    },
    onRowExpandCollapseChange(rows) {
      this.collapseAllLinkDisabled = rows.length <= 0;
    },
    resetExpandedRows() {
      this.$refs.sfClaimsGrid?.resetExpandedRows();
      this.isCollapseAllActive = false;
    },
    onToggleRow() {
      const allClaimsRowsExpandedRows = this.$refs.sfClaimsGrid?.expandedRows ?? [];
      const allClaimsRows = Object.values(allClaimsRowsExpandedRows).map(String);
      this.isCollapseAllActive = allClaimsRows?.includes("true");
    },
    loadPage: debounce(async function() {
      try {
        this.updateContentsLoading(true);
        this.isEmpty = false;
        await this.loadSfProviderClaims({
          providerId: this.$store.getters["providers/provider"].sfid,
          planSfId: this.activePlan.sfid,
          pageNumber: this.currentPage
        });

        if (Object.keys(this.sfGroupedClaims).length === 0) {
          this.isEmpty = true;
        }
        this.provider = this.providers.find(provider => provider.sfid == this.$route.params.providerId);
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 50),
    providerLabel() {
      let providerName = "Provider";
      for (const prop in this.claims) {
        providerName = this.claims[prop][0].provider.account_name;
        break;
      }

      return providerName;
    },
    planLabel() {
      const plan = this.$store.getters["currentPlan/getCurrentPlan"];

      return !plan
        ? ""
        : this.$filters.friendlyDate(plan.start_date) + " to " + this.$filters.friendlyDate(plan.end_date);
    },
    hasFiltersApplied(filters) {
      return filters?.dates || filters?.supportTypes?.length != 0 || filters?.paidToTypes?.length != 0;
    }
  },
  computed: {
    ...mapGetters({
      hasFullParticipantAccess: "hasFullParticipantAccess",
      hasReadOnlyParticipantAccess: "hasReadOnlyParticipantAccess"
    }),
    ...mapGetters("sf/claims", {
      filters: "getFilters"
    }),
    ...mapGetters("providers", {
      sfGroupedClaims: "sfGroupedClaims",
      providers: "providers",
      pagination: "pagination"
    }),
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    ...mapGetters("currentPlan", {
      currentPlan: "getCurrentPlan"
    }),
    claims(): SfDateGroupedClaims {
      if (this.activeFilter) {
        return this.filterClaims(this.activeFilter);
      }
      return this.sfGroupedClaims;
    }
  }
});
</script>
<style lang="scss">
.empty-state {
  line-height: 24px;

  button {
    margin: 2rem 0;
    float: right;
    padding: 0 40px;
  }
}

.pace-message {
  font-size: 14px;
  font-weight: 600;

  .myndislogo {
    vertical-align: sub;
    margin-left: 5px;
  }
}

.provider-claims {
  padding: 16px;

  h1 {
    font-size: $brand--theme-h1-mobile;
    margin-bottom: 10px;
  }

  @include grid-media($grid--breakpoint--md) {
    h1 {
      font-size: $brand--theme-h1-desktop;
    }
  }
  @each $id, $colour in $plan_support_colours {
    .cv-data-table-row--expandable.plan-support-category-#{$id} {
      border-left: 6px solid $colour;
    }
  }

  .mpm-data-table tbody {
    border-bottom: $brand--claims-table-tbody-border-bottom;
  }

  .mpm-data-table td {
    border-bottom: 0 !important;
  }

  .main-title {
    margin-top: 0;
  }
}
</style>
