<template>
  <div class="donut">
    <h2>{{ title }}</h2>
    <div tabindex="1" aria-label="Summary of spent Support items">
      <ul>
        <li>
          <span role="contentinfo" class="offscreen" v-for="item in supports">
            <span v-html="item.support_category.pace_support_category_name" />-
            <span>{{ item.budget_allocated }}% spent</span>
            <span v-html="item.support_category.pace_support_category_name"></span>
          </span>
        </li>
      </ul>
    </div>

    <MpmPaddedDonutChart
      :background="$strings.donutBaseColors.background"
      :foreground="$strings.donutBaseColors.foreground"
      padding-size="0.1rem"
      :padding-background="$strings.donutBaseColors.pacePaddingBackground"
      :sections="dataSections"
      :thickness="40"
      :size="180"
      :total="100"
      :highlight-scale="1"
      has-legend
      no-shadow
      :secondary-title="secondaryTitle"
      :secondary-title-value="mainLegend"
      cutout="50%"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpmPaddedDonutChart from "@/components/MpmPaddedDonut.vue";

interface SupportItem {
  budget_allocated: number;
  budget_spent: number;
}

const AVAILABLE_BUDGET_LABEL = "Available balance";

export default defineComponent({
  name: "SupportCategoryDonut",
  components: {
    MpmPaddedDonutChart
  },
  props: {
    total: {
      type: Number
    },
    supports: {
      type: Array
    },
    title: {
      type: String
    },
    secondaryTitle: {
      type: String
    }
  },
  computed: {
    mainLegend() {
      return this.$filters.currency(this.totalBudget());
    },
    dataSections() {
      const TOTAL_BUDGET = this.totalBudget();

      let budgetUsed = 0;
      let colorCounter = 0;
      let sections = [];
      if (this.supports.length) {
        sections = this.supports.map((support, i: number) => {
          const spentOnThisItem = Number(support.budget_spent);
          const spentOnThisItemFormatted = this.$filters.currency(spentOnThisItem);
          const percentage = Number(((spentOnThisItem / TOTAL_BUDGET) * 100).toFixed(2));
          const label = `${
            support.support_category.pace_support_category_name
          } - <strong>${spentOnThisItemFormatted}</strong> ${this.calculatePercentage(spentOnThisItem, TOTAL_BUDGET)}`;
          const arialabel = `${AVAILABLE_BUDGET_LABEL} - ${this.$filters.currency(TOTAL_BUDGET - budgetUsed)}`;

          if (colorCounter >= this.$strings.providerColors.length) {
            colorCounter = 0; //Repeat color if provider is more than default colors
          }

          const color = this.$strings.providerColors[colorCounter];
          colorCounter++;
          budgetUsed += spentOnThisItem;

          return {
            percentage,
            color,
            label,
            arialabel,
            value: spentOnThisItem
          };
        });

        sections = sections.filter(section => {
          return section.value > 0;
        });
      }
      sections.sort((a, b) => b.value - a.value);
      const remaining = TOTAL_BUDGET - budgetUsed;
      sections.push({
        percentage: 0,
        color: this.$strings.donutBaseColors.availableBudgetColor,
        arialabel: `${AVAILABLE_BUDGET_LABEL} - ${this.$filters.currency(remaining)} ${this.calculatePercentage(
          remaining,
          TOTAL_BUDGET
        )}`,
        label: `${AVAILABLE_BUDGET_LABEL} - <strong>${this.$filters.currency(
          remaining
        )}</strong>  ${this.calculatePercentage(remaining, TOTAL_BUDGET)}`,
        value: TOTAL_BUDGET - budgetUsed
      });
      return sections;
    }
  },
  methods: {
    totalBudget() {
      return this.supports.length ? this.supports.map(i => i.budget_allocated).reduce((item, val) => item + val) : 0;
    },
    totalSpent() {
      return this.supports.length ? this.supports.map(i => i.budget_spent).reduce((item, val) => item + val) : 0;
    },
    calculatePercentage(spent, remaining) {
      const percentage = (spent / remaining) * 100;
      if (isNaN(percentage)) {
        return "";
      }
      return " (" + percentage.toFixed(1) + "%)";
    }
  }
});
</script>

<style lang="scss" scoped>
.donut {
  padding: 24px;
  background: $brand--pace-donut-bg;
  color: $brand--pace-donut-color;
  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 16px;
  }
}
</style>
