<template>
  <ion-page>
    <ion-content v-desktop-breakpoint-only>
      <div class="participant-list">
        <!-- Header -->
        <div class="header">
          <div class="row">
            <!-- Title and Caption -->
            <div>
              <h1 class="title">{{ modalName }}</h1>
            </div>
            <div
              role="button"
              class="close-button"
              ref="closeButton"
              tabindex="0"
              @click="dismiss"
              @keydown.enter="dismiss"
              @keydown.space="dismiss"
              aria-label="Close Participant List"
            >
              <ion-icon :src="`${$brand_svg_src}/close.svg`" aria-label=""></ion-icon>
            </div>
          </div>
        </div>

        <!-- List / items structure -->
        <fieldset role="radiogroup">
          <legend class="caption">Select Participant</legend>
          <div
            class="radio-item"
            @click="onParticipantSelect(participant.id)"
            @keydown.enter="onParticipantSelect(participant.id)"
            @keydown.prevent.space="onParticipantSelect(participant.id)"
            v-for="participant in participants"
            tabindex="0"
            :key="participant.id"
          >
            <div class="participant-info">
              <label class="participant-name" :for="`label-` + participant.ndis">{{ participant.name }}</label>
              <p class="participant-ndis">NDIS {{ participant.ndis }}</p>
            </div>
            <div class="access">
              <div class="access-level" v-if="participant.permissionLevel.toLowerCase() === 'full access'">
                <ion-icon :src="`${$brand_svg_src}/full-access.svg`" aria-hidden="true"></ion-icon>
                <!-- <FullAccessIcon /> -->
                <span class="participant-access-label">FULL ACCESS</span>
              </div>
              <div class="access-level" v-else-if="participant.permissionLevel.toLowerCase() !== 'full access'">
                <!-- <ReadonlyAccessIcon /> -->
                <ion-icon :src="`${$brand_svg_src}/readonly-access.svg`"></ion-icon>
                <span class="participant-access-label">VIEW ACCESS</span>
              </div>
              <svg
                v-show="$store.state.participant.participant.id === participant.id"
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                aria-hidden="true"
                class="structured-list-svg"
                style="will-change: transform"
                :class="{
                  active: $store.state.participant.participant.id === participant.id
                }"
              >
                <path
                  d="M8,1C4.1,1,1,4.1,1,8c0,3.9,3.1,7,7,7s7-3.1,7-7C15,4.1,11.9,1,8,1z M7,11L4.3,8.3l0.9-0.8L7,9.3l4-3.9l0.9,0.8L7,11z"
                ></path>
                <path
                  d="M7,11L4.3,8.3l0.9-0.8L7,9.3l4-3.9l0.9,0.8L7,11z"
                  data-icon-path="inner-path"
                  opacity="0"
                ></path>
              </svg>
              <input
                tabindex="-1"
                class="hidden"
                type="radio"
                :id="`label-` + participant.ndis"
                name="participant"
                :aria-checked="$store.state.participant.participant.id === participant.id"
                :aria-label="$store.state.participant.participant.id === participant.id ? `selected` : ``"
              />
            </div>
          </div>
        </fieldset>
        <div class="participant-explainer">
          <p>
            To add participants or signup new participants please call {{ $strings.brand_name }} on
            <strong>
              <span :aria-label="$strings.brand_phone_number_aria">
                <span aria-hidden="true">{{ $strings.brand_phone_number }}</span
                >.
              </span>
            </strong>
            <br />
            <span v-if="brand === 'mpm'">
              You can also signup new participants by visiting
              <a class="mpm-link" target="_blank" href="https://www.myplanmanager.com.au/">www.myplanmanager.com.au </a>
            </span>
          </p>
        </div>
      </div>
    </ion-content>
    <modal-header v-mobile-breakpoint-only :modal-name="modalName"></modal-header>
    <ion-content v-mobile-breakpoint-only>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          pulling-icon="svg/loading.svg"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <mpm-loading :is-loading="participantListLoading && participants.length < 1"></mpm-loading>
      <div class="participant-list">
        <!-- Header -->
        <div class="header">
          <div class="row">
            <!-- Title and Caption -->
            <h1 class="title">{{ modalName }}</h1>
          </div>
        </div>

        <!-- List / items structure -->
        <fieldset role="radiogroup">
          <legend class="caption">Select Participant</legend>
          <div
            class="radio-item"
            @click="onParticipantSelect(participant.id)"
            @keydown.enter="onParticipantSelect(participant.id)"
            @keydown.prevent.space="onParticipantSelect(participant.id)"
            v-for="participant in participants"
            tabindex="0"
            :key="participant.id"
          >
            <div class="participant-info">
              <label class="participant-name" :for="`label-` + participant.ndis">{{ participant.name }}</label>
              <p class="participant-ndis">NDIS {{ participant.ndis }}</p>
            </div>
            <div class="access">
              <div class="access-level" v-if="participant.permissionLevel.toLowerCase() === 'full access'">
                <ion-icon :src="`${$brand_svg_src}/full-access.svg`" aria-hidden="true"></ion-icon>
                <!-- <FullAccessIcon /> -->
                <span class="participant-access-label">FULL ACCESS</span>
              </div>
              <div class="access-level" v-else-if="participant.permissionLevel.toLowerCase() !== 'full access'">
                <!-- <ReadonlyAccessIcon /> -->
                <ion-icon :src="`${$brand_svg_src}/readonly-access.svg`"></ion-icon>
                <span class="participant-access-label">VIEW ACCESS</span>
              </div>
              <svg
                v-show="$store.state.participant.participant.id === participant.id"
                focusable="false"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                aria-hidden="true"
                class="structured-list-svg"
                style="will-change: transform"
                :class="{
                  active: $store.state.participant.participant.id === participant.id
                }"
              >
                <path
                  d="M8,1C4.1,1,1,4.1,1,8c0,3.9,3.1,7,7,7s7-3.1,7-7C15,4.1,11.9,1,8,1z M7,11L4.3,8.3l0.9-0.8L7,9.3l4-3.9l0.9,0.8L7,11z"
                ></path>
                <path
                  d="M7,11L4.3,8.3l0.9-0.8L7,9.3l4-3.9l0.9,0.8L7,11z"
                  data-icon-path="inner-path"
                  opacity="0"
                ></path>
              </svg>
              <input
                tabindex="-1"
                class="hidden"
                type="radio"
                :id="`label-` + participant.ndis"
                name="participant"
                :aria-checked="$store.state.participant.participant.id === participant.id"
                :aria-label="$store.state.participant.participant.id === participant.id ? `selected` : ``"
              />
            </div>
          </div>
        </fieldset>
        <div class="participant-explainer">
          <p>
            To add participants or signup new participants please call {{ $strings.brand_name }} on
            <strong>
              <span :aria-label="$strings.brand_phone_number_aria">
                <span aria-hidden="true">{{ $strings.brand_phone_number }}</span
                >.
              </span>
            </strong>
            <br />
            <span v-if="brand === 'mpm'">
              You can also signup new participants by visiting
              <a class="mpm-link" target="_blank" href="https://www.myplanmanager.com.au/">www.myplanmanager.com.au </a>
            </span>
          </p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent
} from "@ionic/vue";
import { isDesktop } from "@/directives/platform";
import ModalHeader from "@/components/ModalHeader.vue";
import { sortBy } from "lodash";
import { closeModal } from "@/helpers/system";
import { mapGetters, mapActions } from "vuex";

const brand = process.env.VUE_APP_BRAND;

export default defineComponent({
  name: "ParticipantList",
  components: {
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    ModalHeader,
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent
  },
  methods: {
    ...mapActions("layout", {
      updateFullScreenLoading: "updateFullScreenLoading",
      updateParticipantListLoading: "updateParticipantListLoading"
    }),
    /*
     * Event handler when participant selected
     */
    onParticipantSelect(value: string) {
      if (this.$store.state.participant.participant.id === value) return;
      this.updateFullScreenLoading(true);
      this.$store.dispatch("participant/switchParticipant", value);
      this.$store.dispatch("supportPlanCategory/clearSelectedSupportPlanCategory");
      this.$store.dispatch("supportItems/collapseSupportArea", false);
      this.$store.dispatch("supportItems/expandSupportArea", false);
      if (!isDesktop) {
        closeModal();
      }
      this.dismiss();
      this.$router.push("/plan");
    },
    async doRefresh(refresher) {
      await this.loadPage();
      refresher.target.complete();
    },
    async loadPage() {
      this.updateParticipantListLoading(true);
      try {
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.updateParticipantListLoading(false);
      }
    },
    dismiss() {
      this.$emit("dismiss"); // Emit "dismiss" event
    }
  },
  computed: {
    ...mapGetters("layout", {
      participantListLoading: "getParticipantListLoading"
    }),
    /*
     * List of available participants
     */
    participants() {
      return sortBy(this.$store.getters.participants, participant => participant.name);
    }
  },
  watch: {
    // Watch props 'active' change
    active(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.closeButton.focus();
        }, 1);
      }
    }
  },
  props: {
    active: { type: Boolean, default: false }
  },
  data() {
    return {
      modalName: "Participant List",
      brand
    };
  }
});
</script>

<style lang="scss">
ion-toolbar {
  --background: clear !important;
}
.participant-explainer {
  max-width: 400px;
  padding: 1.2rem 1.5rem;
  a {
    font-weight: 600;
    text-decoration: none;
    color: $brand--participant-list-link-color;
    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  p {
    font-size: 14px;
    color: $brand--participant-list-paragraph-color;
  }
  strong {
    color: $brand--font-color-primary;
  }
}
.participant-list {
  background: $brand--participant-list-bg;
  color: $brand--font-color-primary;
  min-width: 30rem;
  max-width: 100vw;
  height: 100vh;
  @media screen and (max-width: $width--breakpoint--md) {
    & {
      min-width: 100vw;
    }
  }
  .header {
    padding: 0 1.5rem;
    margin-left: 0;
  }
  .close-button {
    padding: 1rem;
    cursor: pointer;
    &:focus-visible {
      border: 3px solid $brand--focus-outline;
      outline: none;
    }
  }
  .title {
    text-align: left;
    margin: 1rem 0 4px;
    font-size: 20px;
    letter-spacing: 0;
  }
  .caption {
    text-align: left;
  }
  .participant-name {
    font-size: 18px;
    font-style: normal;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }

  .participant-ndis {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-align: left;
  }
  .participant-access-icon {
    vertical-align: middle;
  }
  .participant-access-label {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.08em;
    text-align: left;
    opacity: $brand--participant-access-label-opacity;
    margin-left: 1rem;
  }
  .access-level {
    display: flex;
    align-items: center;
  }

  .structured-list {
    margin-bottom: 0;
  }
  .structured-list {
    display: table-row;
    border-bottom: 1px solid rgba(86, 148, 251, 0.66);
    transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    margin-bottom: 0;
  }
  .participant-row {
    border-bottom: 1px solid $brand--participant-list-divider-border-color;
    &:hover {
      background-color: $brand--participant-list-focus-bg !important;
      border-bottom: 1px solid $brand--participant-list-focus-bg !important;
      .structured-list-svg {
        fill: $brand--participant-list-checkmark-color-hover;
      }
    }
    &:focus-visible {
      border: 3px solid $brand--focus-outline !important;
      outline: none !important;
    }
  }
  .structured-list-svg {
    width: 2rem;
    height: 2rem;
    margin-left: 10px;
  }
  .mpm-link {
    &:focus-visible {
      border: 3px solid $brand--focus-outline;
      outline: none !important;
    }
  }
  .active {
    svg {
      fill: $brand--primary-color;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  fieldset {
    padding-right: 1rem;
    padding-left: 0;
    margin-left: 0;
    border: none;
    div.radio-item {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      border-bottom: 1px solid $brand--participant-list-divider-border-color;
      &:hover {
        background-color: $brand--participant-list-focus-bg !important;
        border-bottom: 1px solid $brand--participant-list-focus-bg !important;
        .structured-list-svg {
          fill: $brand--participant-list-checkmark-color-hover;
        }
      }
      &:focus {
        border: none !important;
        background: $brand--participant-list-focus-bg;
        color: $brand--font-color-primary;
      }
      &:focus-visible {
        border: 3px solid $brand--focus-outline !important;
        outline: none !important;
      }
      div.participant-info {
        display: flex;
        flex-direction: column;
      }
      div.access {
        display: flex;
        align-items: center;
        min-width: 167px;
        svg.active {
          fill: $brand--primary-color;
        }
      }
    }
    legend {
      font-family: $brand--theme--header-font;
      line-height: 2rem;
      text-align: left;
      margin-left: 1.5rem;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .hidden {
    height: 1px;
    width: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }
}
</style>
