<template>
  <ion-content
    tabindex="0"
    class="page-content claims-container claims-tab"
    aria-label="Claims page content"
    id="claims-container"
  >
    <section id="claims" aria-label="claims page">
      <div style="overflow:auto" tabindex="-1">
        <mpm-loading :is-loading="contentsLoading"></mpm-loading>
        <div class="content-spacer">
          <total-available-budget v-if="$root.isMobileView && !$store.getters.currentPlan.pace_plan" />
          <div class="claims header-spacer-mobile">
            <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
              <ion-refresher-content
                pulling-icon="svg/loading.svg"
                refreshing-spinner="circles"
                refreshing-text="Refreshing..."
              >
              </ion-refresher-content>
            </ion-refresher>
            <claims-filters
              :pagination="pagination"
              :filters="filters"
              :filterDisabled="selectedTab === 'uploaded-claims'"
              :sortDisabled="selectedTab === 'uploaded-claims'"
              :sorts="sorts"
              @change-filter="setFilter"
              @page-change="changePage"
              @filter-change="onFilterChange"
              @sort-change="onSortChange"
              @reimbursement-page-change="reimbursementChangePage"
              :selectedTab="selectedTab"
              :paginationReimbursement="$refs.reimbursementsGrid?.pagination"
            >
              <template v-slot:heading>
                <h1 class="main-title">Claims</h1>
              </template>
              <template v-slot:description>
                <div>
                  A list of all claims for Plan
                  <span>
                    {{ $filters.friendlyDate(activePlan.start_date) }} to
                    {{ $filters.friendlyDate(activePlan.end_date) }}
                  </span>
                </div>
                <div v-if="currentPlan.pace_plan" class="pace-message">
                  This shows your claims details from your new look NDIS plan.<img
                    class="myndislogo"
                    :src="`${$brand_svg_src}/myndislogo.png`"
                    alt="my NDIS logo"
                  />
                </div>
              </template>

              <template v-slot:other-actions>
                <claims-filter-list :filters="filters" @filter-change="onFilterChange" />
                <button
                  class="collapse-all-btn"
                  type="button"
                  aria-label="Collapse All"
                  @click.prevent="resetExpandedRows"
                  :disabled="!isCollapseAllActive"
                  tabindex="0"
                >
                  <span class="collapse-all-text">Collapse All</span>
                </button>
              </template>
            </claims-filters>

            <ion-toolbar>
              <ion-segment @ionChange="handleSelected($event)" :value="selectedTab">
                <ion-segment-button value="all-claims" @keyup.enter="handleWcagNavigation('all-claims')">
                  <ion-label>ALL CLAIMS</ion-label>
                </ion-segment-button>
                <ion-segment-button value="reimbursements" @keyup.enter="handleWcagNavigation('reimbursements')">
                  <ion-label>REIMBURSEMENTS</ion-label>
                </ion-segment-button>
                <ion-segment-button value="uploaded-claims" @keyup.enter="handleWcagNavigation('uploaded-claims')">
                  <ion-label>UPLOADED CLAIMS</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-toolbar>

            <div class="tab-container" v-show="selectedTab === 'all-claims'">
              <claims-grid
                ref="claimsGrid"
                :sf-claims="sfClaims"
                @approve="approve"
                @decline="decline"
                @row-expand-collapse-change="onRowExpandCollapseChange"
                @on-toggle-row="onToggleRow"
              >
                <template v-if="!hasFiltersApplied(filters)" v-slot:noDataMessage>
                  <div class="claims-list-empty" v-if="hasFullParticipantAccess">
                    <h5>You haven't submitted any claims.</h5>
                    <p class="empty-message">
                      All submitted and processed claims will appear here. Use the ‘Submit claim’ button or email the
                      invoice to
                      <a class="emailLink" :href="`mailto:${$strings.emails.accounts}`">{{
                        $strings.emails.accounts
                      }}</a>
                      to submit your claim to be processed.
                    </p>
                  </div>
                  <div class="claims-list-empty" v-if="hasReadOnlyParticipantAccess">
                    <h5>There are no claims to show here yet.</h5>
                    <p class="empty-message">
                      All submitted and processed claims will appear here.
                    </p>
                  </div>
                </template>
              </claims-grid>
              <claims-pagination
                v-if="!hasNoClaims"
                :pagination="pagination"
                @change-page="changePage"
              ></claims-pagination>
            </div>
            <div class="tab-container" v-show="selectedTab === 'reimbursements'">
              <reimbursements ref="reimbursementsGrid" :filters="filters" @on-toggle-row="onToggleRow" />
            </div>
            <div class="tab-container" v-show="selectedTab === 'uploaded-claims'">
              <uploaded-claims-grid ref="uploadedClaimsGrid" />
            </div>
            <MobileAddClaimButton />
          </div>
        </div>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import SiteMap from "@/components/menu/SiteMap.vue";
import MobileAddClaimButton from "@/components/MobileAddClaimButton.vue";
import MpmHelpLink from "@/components/MpmHelpLink.vue";
import ClaimsFilterList from "@/components/salesforce/ClaimsFilterList.vue";
import ClaimsFilters from "@/components/salesforce/ClaimsFilters.vue";
import ClaimsGrid from "@/components/salesforce/ClaimsGrid.vue";
import ClaimsPagination from "@/components/salesforce/ClaimsPagination.vue";
import RecordsPagination from "@/components/salesforce/RecordsPagination.vue";
import Reimbursements from "@/components/salesforce/Reimbursements.vue";
import UploadedClaimsGrid from "@/components/salesforce/UploadedClaimsGrid.vue";
import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import { DateGroupedClaims } from "@/types/claims";
import { IonContent, IonIcon, IonLabel, IonRefresher, IonRefresherContent, IonSegment } from "@ionic/vue";
import { debounce } from "lodash";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

interface Content extends HTMLElement {
  scrollToTop(n: number): void;
}
interface Data {
  activeFilter: string;
  currentPage: number;
  collapseAllLinkDisabled: boolean;
  planRetrieved: number;
  activePlan: object;
  selectedTab: string;
  isCollapseAllActive: false;
}
interface Methods {
  loadClaims: () => void;
  setFilter: (filter: string) => void;
  approveClaim: ({ claimId, approve }: { claimId: number; approve: boolean }) => Promise<any>;
  approve: (claimId: number) => void;
  decline: (claimId: number) => void;
  changePage: (pageNum: number) => void;
  loadClaimsAtPage: (page: number) => void;
  onCollapseAllClick: () => void;
  onRowExpandCollapseChange: (rows: string[]) => void;
  onToggleRow: (rows: string[]) => void;
}
interface Computed {
  claims: DateGroupedClaims;
  groupedClaims: DateGroupedClaims;
  claimsForApprovalCount: number;
  reminderText: string;
  filterClaims: (supportTypeCode: string) => DateGroupedClaims;
  tableData: object;
  hasNoClaims: boolean;
  participant: any;
}

export default defineComponent({
  name: "SFClaims",
  components: {
    RecordsPagination,
    UploadedClaimsGrid,
    Reimbursements,
    ClaimsFilters,
    ClaimsFilterList,
    ClaimsGrid,
    ClaimsPagination,
    MobileAddClaimButton,
    MpmHelpLink,
    TotalAvailableBudget,
    SiteMap,
    IonContent,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonSegment,
    IonLabel
  },
  data(): Data {
    return {
      activeFilter: "",
      currentPage: 1,
      collapseAllLinkDisabled: true,
      planRetrieved: null,
      activePlan: this.$store.state.currentPlan,
      selectedTab: "all-claims",
      isCollapseAllActive: false
    };
  },
  methods: {
    ...mapActions("sf/claims", {
      loadClaims: "loadClaims",
      approveClaim: "approveClaim"
    }),
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    ...mapActions("systemPersist", {
      removeOutDatedClaimStatus: "removeOutDatedClaimStatus"
    }),
    refreshTabs: debounce(async function(page: number) {
      try {
        this.updateContentsLoading(true);
        this.selectedTab = "all-claims";
        this.planRetrieved = this.$store.state.currentPlan.id;
        await this.$refs.reimbursementsGrid.loadClaimsAtPage(page);
        await this.$refs.uploadedClaimsGrid.loadUploadedClaims(page);
        await this.loadClaims({ page });
        this.notifyClaimsForApproval();
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100),
    scrollToTop() {
      const form = document.getElementById("claims-container") as Content;
      form.scrollToTop(0);
    },
    setFilter(filter: string) {
      this.activeFilter = filter;
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.refreshTabs(1);
    },
    async approve(claimId: string): Promise<any> {
      try {
        this.updateContentsLoading(true);
        this.removeOutDatedClaimStatus();
        await this.approveClaim({ claimId, approve: true });
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    async decline(claimId: string): Promise<any> {
      try {
        this.updateContentsLoading(true);
        this.removeOutDatedClaimStatus();
        await this.approveClaim({ claimId, approve: false });
      } catch (e) {
        console.log(e);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    async loadClaimsAtPage(page: number) {
      //Record the User + Plan selected so it can be invalidated later.
      this.scrollToTop();
      this.updateContentsLoading(true);
      try {
        await this.loadClaims({ page });
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
      this.currentPage = page;
    },
    changePage(pageNum: number) {
      this.loadClaimsAtPage(pageNum);
    },
    reimbursementChangePage(pageNum: number) {
      this.$refs.reimbursementsGrid.loadClaimsAtPage(pageNum);
    },
    onCollapseAllClick() {
      if (!this.collapseAllLinkDisabled) this.$refs.claimsGrid.collapseAll();
    },
    onRowExpandCollapseChange(rows) {
      this.collapseAllLinkDisabled = rows.length <= 0;
    },
    onPageChange(pageNumber: number) {
      this.updateContentsLoading(true);
      try {
        this.loadProviderClaims({
          providerId: this.providerId,
          pageNumber: pageNumber
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
        this.currentPage = pageNumber;
      }
    },
    onFilterChange(filters: unknown) {
      console.log("updating filters from claims.vue");
      console.log(filters);
      this.$store.dispatch("sf/claims/applyFiltersAndSorts", {
        filters,
        sorts: this.sorts
      });
      this.$store.dispatch("sf/reimbursements/applyFiltersAndSorts", {
        filters,
        sorts: this.sorts
      });
    },
    onSortChange(sorts: unknown) {
      this.$store.dispatch("sf/claims/applyFiltersAndSorts", {
        filters: this.filters,
        sorts
      });
      this.$store.dispatch("sf/reimbursements/applyFiltersAndSorts", {
        filters: this.filters,
        sorts: sorts
      });
    },
    handleSelected($e) {
      if ($e.detail.value === "") {
        this.selectedTab = "all-claims";
      } else {
        this.selectedTab = $e.detail.value;
      }
    },
    handleWcagNavigation(tab: string) {
      this.selectedTab = tab;
    },
    resetExpandedRows() {
      this.$refs.claimsGrid?.resetExpandedRows();
      this.$refs.reimbursementsGrid?.resetExpandedRows();
      this.isCollapseAllActive = false;
    },
    onToggleRow() {
      const reimbursementClaimsExpandedRows = this.$refs.reimbursementsGrid?.$refs.claimsGrid?.expandedRows ?? [];
      const allClaimsRowsExpandedRows = this.$refs.claimsGrid?.expandedRows ?? [];
      const reimbursementRows = Object.values(reimbursementClaimsExpandedRows).map(String);
      const allClaimsRows = Object.values(allClaimsRowsExpandedRows).map(String);

      this.isCollapseAllActive = reimbursementRows?.includes("true") || allClaimsRows?.includes("true");
    },
    notifyClaimsForApproval() {
      if (this.$store.getters.hasFullParticipantAccess && this.claimsForApprovalCount > 0) {
        this.$notification.createToast(this.reminderText, "awaiting");
      }
    },
    hasFiltersApplied(filters) {
      return filters?.dates || filters?.supportTypes?.length != 0 || filters?.paidToTypes?.length != 0;
    }
  },
  computed: {
    ...mapGetters({
      hasFullParticipantAccess: "hasFullParticipantAccess",
      hasReadOnlyParticipantAccess: "hasReadOnlyParticipantAccess"
    }),
    ...mapGetters("sf/claims", {
      groupedClaims: "groupedClaims",
      filterClaims: "filteredClaims",
      claimsForApprovalCount: "claimsForApprovalCount",
      pagination: "pagination",
      filters: "getFilters",
      sorts: "getSorts",
      hasNoClaims: "hasNoClaims"
    }),
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading",
      currentTab: "getCurrentTab"
    }),
    ...mapGetters("currentPlan", {
      currentPlan: "getCurrentPlan"
    }),
    reminderText(): string {
      switch (this.claimsForApprovalCount) {
        case 0:
          return "";
        case 1:
          return "There is 1 claim that requires approval!";
        default:
          return `There are ${this.claimsForApprovalCount} claims that require approval!`;
      }
    },
    sfClaims() {
      if (this.activeFilter) {
        return this.filterClaims(this.activeFilter);
      }
      return this.groupedClaims;
    },
    tableData() {
      return {
        columns: ["provider", "amount"],
        data: this.sfClaims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    hasNoClaims() {
      return !Object.keys(this.sfClaims).length;
    },
    participant() {
      return this.$store.getters["participant"];
    }
  },
  unmounted() {
    // Clear the filter and sorting
    this.$store.dispatch("sf/claims/clearFiltersAndSorts");
  }
});
</script>

<style lang="scss">
.claims-container {
  padding: 30px 17px;
  .claims-grid {
    margin-top: -2px;
    padding-top: 0;
    .collapse-all {
      display: none;
    }
  }
}
.mpm-date-picker .picker:nth-child(2) {
  .duet-date__dialog {
    left: -47px;
  }
}
.collapse-all {
  text-align: right;
}
.collapse-all-btn {
  text-align: right;
  cursor: pointer;
  background: transparent;
  padding: 1rem 0 1rem 0;
  line-height: 1rem;
  min-height: 0;
  outline: none;
  border: none;
  color: $brand--collapse-all-color;
  text-decoration: underline;
  &:hover {
    color: $brand--collapse-all-hover-color;
    background: transparent;
  }
  &:focus-visible {
    .collapse-all-text {
      outline: 3px solid $brand--focus-outline;
    }
  }
  &[aria-disabled],
  &[disabled] {
    color: $brand--claims-label-color;
    background: transparent;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background: transparent;
      color: $brand--claims-label-color;
    }
    .collapse-all-text {
      &:focus-visible {
        outline: none;
      }
    }
  }
}
.claims {
  //margin-top: calc(96px + var(--ion-safe-area-top, 0));
  padding: 15px;
  padding-top: 8px;
  margin-bottom: 120px;
  .currency,
  .claims-filters {
    @include grid-media($grid--breakpoint--md) {
      // margin-top: 22px;
      .head {
        margin-bottom: 18px;
      }
    }
  }

  .pace-message {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;

    .myndislogo {
      vertical-align: sub;
      margin-left: 5px;
    }
  }

  .expanded-pane {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .claims-list-empty {
    padding: 0.5rem;
    h5 {
      line-height: normal;
    }
    p.empty-message {
      font-style: normal;
      line-height: 24px;
      margin-top: 24px;
    }
  }
  @each $id, $colour in $plan_support_colours {
    .cv-data-table-row--expandable.plan-support-category-#{$id} {
      border-left: 6px solid $colour;
    }
  }
  .mpm-data-table tbody.cv-data-table-row {
    border-bottom: none !important;
  }
  .data-table th {
    background-color: $brand--primary-table-color;
    @media screen and (max-width: $width--breakpoint--md) {
      font-size: 0.71em;
    }
  }
  .mpm-data-table tbody {
    border-bottom: $brand--claims-table-tbody-border-bottom !important;
  }
  .mpm-data-table td {
    border-bottom: 0 !important;
  }
  .mpm-data-table tbody.bottom-bordered-group:hover {
    .expanded > * .in-row-table tbody tr {
      border-bottom: $brand--claims-table-tbody-border-bottom;
    }
  }
  .toolbar-segment {
    margin-bottom: 0;
  }
  ion-segment {
    justify-content: flex-start;
    --background: none;
    border-radius: 0;
    margin: 0;
    ion-segment-button {
      color: $brand--font-color-primary;
      font-weight: $brand--claims-table-tab-font-weight;
      --indicator-box-shadow: none;
      --color-checked: #fff;
      margin-bottom: 0;
      border-radius: 6px 6px 0 0;
      border-left: 1px solid $brand--tab-border-color;
      border-top: 1px solid $brand--tab-border-color;
      border-right: 1px solid $brand--tab-border-color;
      &[aria-selected="false"] {
        color: $brand--tab-selected-false-color;
        &:hover {
          background-color: $brand--tab-selected-false-hover;
          --color-hover: none;
        }
      }
      ion-label {
        font-size: 0.88em;
        font-weight: 600;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.125rem;
        letter-spacing: 0.16px;
      }
      &::part(native):focus,
      &::part(native):active {
        border: 3px solid $brand--focus-outline;
      }
    }
    ion-segment-button {
      @media screen and (max-width: $width--breakpoint--md) {
        font-size: 0.7em;
      }
    }
    ion-segment-button:nth-child(1) {
      max-width: 10rem;
      margin-right: 3px;
    }
    ion-segment-button:nth-child(2) {
      max-width: 14rem;
      margin-right: 3px;
    }
    ion-segment-button:nth-child(3) {
      max-width: 15rem;
    }
    .segment-button-checked {
      background: $brand--primary-table-color;
      color: #fff;
    }
  }
}
</style>
