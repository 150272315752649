<template>
  <div class="claims-grid">
    <div class="collapse-all">
      <button
        class="collapse-all-button"
        lind="ghost"
        type="button"
        @click.prevent="resetExpandedRows"
        aria-label="Collapse All"
        :disabled="emptyRows()"
      >
        Collapse All
      </button>
    </div>
    <mpm-data-table
      v-desktop-breakpoint-only
      isDesktopTable
      ref="table"
      :columns="desktopTableData.columns"
      :has-expand-all="desktopTableData.hasExpandAll"
      :row-expanded="desktopTableData.rowExpanded"
      :data="sfClaims"
      :showIndicatorFunc="needAction"
      @row-expand-collapse-change="onRowExpandCollapseChange"
      class="desktop-claims-table"
    >
      <template v-slot:emptyTableMessage>
        <slot name="noDataMessage"></slot>
      </template>
      <template v-slot:valueCells="{ rowData, groupKey }">
        <tr>
          <td
            :data-previous-value="checkRow(groupKey) ? 'collapsed' : 'expanded'"
            role="gridcell"
            aria-label="Expand/collapse detail row"
            class="bx--table-expand cell-expand"
            @click="toggleRow(groupKey, rowData)"
          >
            <div v-if="$store.getters.hasFullParticipantAccess && needAction(rowData)" class="corner"></div>
            <button
              class="expand-collapse-button"
              aria-label="Expand/collapse current row"
              :aria-expanded="checkRow(groupKey)"
            >
              <ion-icon
                aria-hidden="true"
                :src="$brand_svg_src + `/arrow-right.svg`"
                slot="icon-only"
                class="bx--table-expand__svg"
              ></ion-icon>
            </button>
          </td>
          <td
            :headers="`column-header-provider`"
            :aria-labelledby="`date-header-${groupKey}`"
            class="provider-width-cap"
            tabindex="-1"
          >
            {{ rowData.provider.account_name }}
          </td>
          <td
            :headers="`column-header-amount`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
            :class="$root.isMobileView ? 'cell-amount' : ''"
          >
            {{ getAmount(rowData) }}
          </td>
          <td
            :headers="`column-header-status`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
            v-html="getInvoiceStatus(rowData)"
          />
          <td :headers="`column-header--invoice no`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            <div>{{ rowData.reference_number }}</div>
            <a
              class="view-invoice"
              v-if="
                !rowData.protect_client_data &&
                  showViewInvoiceLink(rowData) &&
                  !(rowData.lineItems?.length < 2 && checkRow(groupKey))
              "
              target="_blank"
              tabindex="0"
              :href="rowData.document_link"
              aria-label="View invoice link"
              @keydown.space.prevent
              @click.prevent="openInNewTab(rowData)"
              @keyup.enter.prevent="openInNewTab(rowData)"
            >
              View Invoice
            </a>
          </td>
          <td :headers="`column-header--service dates`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            <span class="service-date-label">{{ $filters.friendlyDate(rowData.service_date) }}</span>
            <template v-if="rowData.service_date !== rowData.service_date_to">
              -
              <span class="service-date-label">{{ $filters.friendlyDate(rowData.service_date_to) }}</span>
            </template>
          </td>
          <td :headers="`column-header--paid date`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            <div>
              {{ $filters.friendlyDate(rowData.date_paid) }}
            </div>
            <claim-actions
              :needs-action="needAction(rowData)"
              :status="rowData.status"
              @approve="approve(rowData.sfid)"
              @decline="decline(rowData.sfid)"
            />
          </td>
        </tr>
      </template>
      <template v-slot:expandedContent="{ rowData, groupKey }">
        <tr
          v-if="rowData?.lineItems?.length > 1"
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td colspan="7" class="in-row-table-parent-cell">
            <table class="in-row-table">
              <thead>
                <tr>
                  <th />
                  <th
                    class="line-items-header"
                    v-for="(lineItemColumn, lineIndex) in lineItemColumns"
                    :key="`line-heading:${lineIndex}`"
                  >
                    <span class="">{{ lineItemColumn }}</span>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lineItem, LineItemIndex) in rowData.lineItems" :key="`line-item:${LineItemIndex}`">
                  <td />
                  <td>
                    {{ lineItem.support_item.name }} -
                    {{ lineItem.support_item.code }}
                  </td>
                  <td>
                    <claim-info-cell-content label="">
                      <div :class="['support-area', getSupportAreaClass(rowData, currentPlan.pace_plan)]">
                        {{
                          currentPlan.pace_plan
                            ? lineItem.support_item?.pace_support_category?.pace_support_category_name
                            : lineItem.support_item?.support_category.support_category_name
                        }}
                      </div>
                    </claim-info-cell-content>
                  </td>
                  <td>{{ $filters.currency(lineItem.total) }}</td>
                  <td>
                    {{ $filters.friendlyDate(lineItem.service_date) }} -
                    {{ $filters.friendlyDate(lineItem.service_date_to) }}
                  </td>
                  <td>
                    <div v-if="!isInvoiceInCurrentPlan(lineItem.service_date)" class="bx--row">
                      <ion-icon src="svg/chat-alert.svg" class="chat-alert-icon"></ion-icon>
                      <div class="chat-alert-message">
                        This budget comes from another plan
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="hasInvoiceFromOtherPlan(rowData.lineItems)" style="height: 1.5rem; border-bottom: none">
                  <td></td>
                  <td colspan="5" class="line-items-note">
                    Note: This invoice is for services provided across multiple plans. These services will be funded
                    from budgets for their relevant plan.
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr
          v-else
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td></td>
          <td colspan="1" class="cell-padding-right">
            <claim-info-cell-content label="Support Category">
              <div :class="['support-area', getSupportAreaClass(rowData, currentPlan.pace_plan)]">
                {{
                  currentPlan.pace_plan
                    ? rowData?.lineItems?.[0].support_item?.pace_support_category?.pace_support_category_name
                    : rowData?.lineItems?.[0].support_item?.support_category.support_category_name
                }}
              </div>
            </claim-info-cell-content>
          </td>
          <td colspan="3" class="cell-padding-right">
            <claim-info-cell-content label="Support Item">
              {{ rowData?.lineItems?.[0].support_item.name }} - {{ rowData?.lineItems?.[0].support_item.code }}
            </claim-info-cell-content>
          </td>

          <td colspan="2">
            <div class="invoice-action-row">
              <a
                v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData)"
                target="_blank"
                tabindex="0"
                :href="rowData.document_link"
                aria-label="View invoice link"
                @click.prevent="openInNewTab(rowData)"
                @keydown.enter.prevent="openInNewTab(rowData)"
              >
                View Invoice
              </a>
            </div>
          </td>
        </tr>
      </template>
    </mpm-data-table>
    <mpm-data-table
      v-mobile-breakpoint-only
      ref="table"
      :columns="mobileTableData.columns"
      :has-expand-all="mobileTableData.hasExpandAll"
      :row-expanded="mobileTableData.rowExpanded"
      :data="sfClaims"
      :showIndicatorFunc="needAction"
      @row-expand-collapse-change="onRowExpandCollapseChange"
      class="mobile-claims-table"
    >
      <template v-slot:emptyTableMessage>
        <slot name="noDataMessage"></slot>
      </template>
      <template v-slot:valueCells="{ rowData, groupKey }">
        <tr class="grid-row">
          <td
            rowspan="2"
            :data-previous-value="checkRow(groupKey) ? 'collapsed' : 'expanded'"
            role="gridcell"
            aria-label="Expand/collapse detail row"
            class="bx--table-expand cell-expand"
            @click="toggleRow(groupKey, rowData)"
          >
            <div v-if="$store.getters.hasFullParticipantAccess && needAction(rowData)" class="corner"></div>
            <button
              class="expand-collapse-button"
              aria-label="Expand/collapse current row"
              :aria-expanded="checkRow(groupKey)"
            >
              <ion-icon
                aria-hidden="true"
                :src="$brand_svg_src + `/arrow-right.svg`"
                slot="icon-only"
                class="bx--table-expand__svg"
              ></ion-icon>
            </button>
          </td>
          <td
            :headers="`column-header-provider`"
            :aria-labelledby="`date-header-${groupKey}`"
            class="provider-width-cap"
            tabindex="-1"
          >
            {{ rowData.provider.account_name }}
          </td>
          <td
            :headers="`column-header-amount`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
            :class="['text-align-right', $root.isMobileView ? 'cell-amount' : '']"
            style="padding-right: 16px"
          >
            {{ getAmount(rowData) }}
          </td>
        </tr>
      </template>
      <template v-slot:actionsRow="{ rowData }">
        <tr class="header-row">
          <td>
            <claim-info-cell-content label="Status">
              <div class="status" v-if="rowData.status">
                <span class="value" v-html="getInvoiceStatus(rowData)"></span>
              </div>
            </claim-info-cell-content>
          </td>
          <td class="invoice-number-cell text-align-right">
            <claim-info-cell-content label="Invoice Number" :value="rowData.reference_number" />
          </td>
        </tr>
        <tr class="preview-row">
          <td aria-hidden="true"></td>
          <td>
            <claim-info-cell-content label="Service Dates">
              <span class="service-date-label">{{ $filters.friendlyDate(rowData.service_date) }}</span>
              <template v-if="rowData.service_date !== rowData.service_date_to">
                -
                <span class="service-date-label">{{ $filters.friendlyDate(rowData.service_date_to) }}</span>
              </template>
            </claim-info-cell-content>
          </td>
          <td class="text-align-right">
            <claim-info-cell-content v-if="rowData.date_paid" label="Paid Date">
              {{ $filters.friendlyDate(rowData.date_paid) }}
            </claim-info-cell-content>
          </td>
        </tr>
      </template>
      <template v-slot:expandedContent="{ rowData, groupKey }">
        <template v-if="rowData?.lineItems?.length === 1">
          <tr
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td colspan="2">
              <claim-info-cell-content label="Support Category">
                <div :class="['support-area', getSupportAreaClass(rowData, currentPlan.pace_plan)]">
                  {{
                    currentPlan.pace_plan
                      ? rowData?.lineItems?.[0].support_item?.pace_support_category?.pace_support_category_name
                      : rowData?.lineItems?.[0].support_item?.support_category.support_category_name
                  }}
                </div>
              </claim-info-cell-content>
            </td>
          </tr>
          <tr
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td colspan="2">
              <claim-info-cell-content label="Support Item">
                {{ rowData.lineItems[0].support_item.name }} - {{ rowData.lineItems[0].support_item.code }}
              </claim-info-cell-content>
            </td>
          </tr>
          <tr
            v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData)"
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td colspan="2">
              <a
                :class="viewInvoiceClicked ? 'a-tag-disabled' : ''"
                target="_blank"
                tabindex="0"
                :href="rowData.document_link"
                aria-label="View invoice link"
                @click.prevent="openInNewTab(rowData)"
                @keydown.enter.prevent="openInNewTab(rowData)"
                >View Invoice</a
              >
            </td>
          </tr>
        </template>
        <tr
          v-if="needAction(rowData) || rowData?.lineItems?.length > 1"
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td aria-hidden="true"></td>
          <td colspan="2">
            <div class="invoice-action-row">
              <a
                :class="viewInvoiceClicked ? 'a-tag-disabled' : ''"
                v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData) && rowData?.lineItems?.length > 1"
                target="_blank"
                tabindex="0"
                :href="rowData.document_link"
                aria-label="View invoice link"
                @click.prevent="openInNewTab(rowData)"
                @keydown.enter.prevent="openInNewTab(rowData)"
                >View Invoice</a
              >
              <claim-actions
                :needs-action="needAction(rowData)"
                :status="rowData.status"
                @approve="approve(rowData.sfid)"
                @decline="decline(rowData.sfid)"
              />
            </div>
          </td>
        </tr>
        <tr
          v-if="rowData?.lineItems?.length > 1"
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td colspan="3" class="in-row-table-parent-cell">
            <div
              class="in-row-line-item"
              v-for="(lineItem, LineItemIndex) in rowData.lineItems"
              :key="`line-item:${LineItemIndex}`"
            >
              <div class="line-item-row">
                <div class="line-item">
                  <claim-info-cell-content label="Support Item">
                    {{ lineItem.support_item.name }} {{ lineItem.support_item.code }}
                  </claim-info-cell-content>
                </div>
                <div class="line-item">
                  <claim-info-cell-content label="Amount">
                    {{ $filters.currency(lineItem.total) }}
                  </claim-info-cell-content>
                </div>
              </div>
              <div class="line-item-row">
                <div class="line-item">
                  <claim-info-cell-content label="Support Category">
                    <claim-info-cell-content label="">
                      <div :class="['support-area', getSupportAreaClass(rowData, currentPlan.pace_plan)]">
                        {{
                          currentPlan.pace_plan
                            ? lineItem.support_item?.pace_support_category?.pace_support_category_name
                            : lineItem.support_item?.support_category.support_category_name
                        }}
                      </div>
                    </claim-info-cell-content>
                  </claim-info-cell-content>
                </div>
                <div class="line-item">
                  <claim-info-cell-content label="Service Date">
                    {{ $filters.friendlyDate(lineItem.service_date) }} -
                    {{ $filters.friendlyDate(lineItem.service_date_to) }}
                  </claim-info-cell-content>
                </div>
              </div>
              <div v-if="!isInvoiceInCurrentPlan(lineItem.service_date)" class="mobile-chat-alert">
                <ion-icon src="svg/chat-alert.svg" class="mobile-chat-alert-icon"></ion-icon>
                <div class="mobile-chat-alert-message">
                  This budget comes from another plan
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr
          v-if="hasInvoiceFromOtherPlan(rowData.lineItems)"
          style="background: transparent !important; height: 1.5rem; border-bottom: none"
        >
          <td colspan="3" class="mobile-line-items-note" style="background: transparent !important;">
            Note: This invoice is for services provided across multiple plans. These services will be funded from
            budgets for their relevant plan.
          </td>
        </tr>
      </template>
    </mpm-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import MpmDataTable from "@/components/MpmDataTable.vue";
import ClaimActions from "@/components/ClaimActions.vue";
import ClaimInfoCellContent from "@/components/ClaimInfoCellContent.vue";
import { TrackingEvent } from "@/types/tracking-events";
import { IonButton, IonIcon } from "@ionic/vue";
import { supportCategories, supportCategoriesCodeIdMapping, supportTypes } from "@/helpers/support-category";
import moment from "moment";
import { createNamespacedHelpers, mapGetters } from "vuex";
import { sfGetInvoiceClaimLink } from "@/api/salesforce/claims";
const { mapActions } = createNamespacedHelpers("sf/claims");
const AWAITING_CLIENT_APPROVAL = "Awaiting Client Approval";
const PAID_TO_TYPE_FEE = "fee";
const PAID_TO_TYPE_REIMBURSEMENTS = "reimbursement";

export default defineComponent({
  name: "claims-grid",
  components: {
    ClaimActions,
    ClaimInfoCellContent,
    IonButton,
    IonIcon,
    MpmDataTable
  },
  data() {
    return {
      activePlan: this.$store.state.currentPlan,
      expandedRows: reactive({}),
      lineItemColumns: ["Line Item", "Support Category", "Amount", "Service dates"],
      planSupportCategoriesCodeIdMapping: supportCategoriesCodeIdMapping,
      viewInvoiceClicked: false,
      supportTypes: supportTypes
    };
  },
  computed: {
    ...mapGetters({
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    supportCategoryPropertyLabel() {
      return this.currentPlan.pace_plan ? "pace_support_category" : "support_category";
    },
    mobileTableData() {
      const columns = ["provider", "amount"];
      return {
        columns,
        data: this.sfClaims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    desktopTableData() {
      const columns = ["provider", "amount", "status", "invoice no", "service dates", "paid date"];
      return {
        columns,
        data: this.sfClaims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    participant() {
      return this.$store.getters["participant"];
    }
  },
  props: {
    sfClaims: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(["loadInvoiceLineItems"]),
    emptyRows() {
      if (Object.keys(this.expandedRows).length == 0) return true;
      let isEmpty = true;

      Object.keys(this.expandedRows).forEach(key => {
        if (this.expandedRows[key] === true) {
          isEmpty = false;
        }
      });
      return isEmpty;
    },
    resetExpandedRows() {
      this.expandedRows = reactive({});
    },
    checkRow(key) {
      return this.expandedRows[key];
    },
    async toggleRow(key, rowData) {
      if (!rowData?.document_link) {
        sfGetInvoiceClaimLink(rowData.sfid, false).then(async response => {
          const { url } = response;
          if (url) {
            setTimeout(() => {
              rowData.document_link = url;
            });
          }
        });
      }
      if (!rowData?.lineItems) {
        const lineItemsResponse = await this.loadInvoiceLineItems(rowData.sfid);
        if (lineItemsResponse.length >= 1) {
          rowData.lineItems = lineItemsResponse;
        }
      }
      if (this.expandedRows[key] === undefined) this.expandedRows[key] = false;
      this.expandedRows[key] = !this.expandedRows[key];
      this.$emit("on-toggle-row");
    },
    getInvoiceStatus(data) {
      if (data.status === "Awaiting Client Approval") {
        if (data.client_approval_status === "Approved") {
          return "Processing";
        } else if (data.client_approval_status === "Rejected") {
          return "Cancelled";
        } else {
          return data.status;
        }
      } else {
        return data.status;
      }
    },
    approve(claimId: string) {
      this.$emit("approve", claimId);
    },
    decline(claimId: string) {
      this.$emit("decline", claimId);
    },
    viewInvoice() {
      this.$amplitude.track(TrackingEvent.ClaimsViewInvoice, {});
    },
    needAction(claim): boolean {
      return this.getInvoiceStatus(claim) === AWAITING_CLIENT_APPROVAL;
    },
    collapseAll() {
      this.$refs.table.collapseAll();
    },
    onRowExpandCollapseChange(rows) {
      this.$emit("row-expand-collapse-change", rows);
    },
    showViewInvoiceLink(claim): boolean {
      let isMpm = false;
      if (claim.paid_to_type && claim.paid_to_type.toLowerCase() === PAID_TO_TYPE_FEE) {
        isMpm = true;
      }
      return !isMpm;
    },
    isLifeChoicesClaim(supportCategoryId: number): boolean {
      return supportCategoryId === supportCategories.IMPROVED_LIFE_CHOICES;
    },
    isInvoiceInCurrentPlan(date) {
      const planStartDate = moment(this.activePlan.start_date, "YYYY-MM-DD");
      const planEndDate = moment(this.activePlan.end_date, "YYYY-MM-DD");
      const serviceDate = moment(date, "YYYY-MM-DD");
      return serviceDate.isBetween(planStartDate, planEndDate, null, "[]");
    },
    hasInvoiceFromOtherPlan(lineItems = []) {
      const length = lineItems.length;
      if (length < 1) {
        return false;
      }
      let index = 0;
      while (index < length) {
        if (!this.isInvoiceInCurrentPlan(lineItems[index].service_date)) {
          return true;
        }
        index += 1;
      }
      return false;
    },
    isClaimReimbursement(rowData) {
      return rowData.paid_to_type === PAID_TO_TYPE_REIMBURSEMENTS;
    },
    openInNewTab(rowData) {
      if (rowData.document_link && rowData.document_link.length > 1) {
        //for migrated claims
        console.log("Open document link");
        console.log(rowData.document_link);
        window.open(rowData.document_link, "_blank")?.focus();
      } else {
        this.openUploadedFileLink(rowData.sfid, rowData);
      }
    },
    openUploadedFileLink(invoiceSfId, rowData) {
      this.viewInvoiceClicked = true;
      sfGetInvoiceClaimLink(invoiceSfId, false)
        .then(response => {
          const { url } = response;
          if (url) {
            setTimeout(() => {
              rowData.document_link = url;
              console.log("Open document link");
              console.log(url);
              window.open(url, "_blank")?.focus();
            });
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.viewInvoiceClicked = false;
        });
    },
    getSupportAreaClass(rowData, isPacePlan) {
      if (rowData.lineItems && rowData.lineItems.length >= 1) {
        const firstRow = rowData.lineItems[0];
        if (isPacePlan) {
          const code = firstRow.support_item.pace_support_category?.support_type_code;
          const supportType = supportTypes.find(supportType => supportType.support_type_code == code) ?? {
            cssClass: ""
          };
          return `support-area-` + supportType.cssClass;
        } else {
          const code = firstRow.support_item?.support_category?.code;
          return `plan-support-category-` + this.planSupportCategoriesCodeIdMapping[code];
        }
      }
    },
    getAmount(row) {
      if (row.status === "Credit Paid" || row.status === "Processing") {
        return this.$filters.currency(row.total);
      } else {
        return this.$filters.currency(row.amount_due);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.text-align-right {
  text-align: right;
}

.claims-grid {
  .mpm-data-table .expand-collapse-button {
    padding: 0;
    &:focus-visible {
      outline: none;
      ion-icon {
        outline: 3px solid $brand--focus-outline !important;
      }
    }
  }
  .icon-inner {
    background: red !important;
  }
  ion-icon {
    color: #000;
  }
  padding-top: 2px;
  td {
    vertical-align: middle;
  }
  .a-tag-disabled {
    pointer-events: none;
    cursor: default;
  }
  .provider-width-cap {
    padding-top: 21px; // to visually baseline with amount which has a larger font size
  }
  .preview-row {
    padding: 10px 0;
  }
  .view-invoice {
    color: $brand--view-invoice-color;
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }
  .bx--table-expand__svg {
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }
  .invoice-action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: $brand--view-invoice-color;
      &:focus-visible {
        outline: 3px solid $brand--focus-outline !important;
      }
    }
  }
  .service-date-label {
    display: inline-block;
    font-weight: $brand--body-font-weight;
  }

  a {
    display: inline-block;
    color: $brand--view-invoice-color;
    text-decoration: underline;
  }
  .cell-padding-right {
    padding-right: 16px;
  }
  .cell-padding-top {
    padding-top: 1rem;
  }
  .cell-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  .in-row-table-parent-cell {
    padding: 0;
  }
  .in-row-table {
    width: 100%;
    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    th {
      background: $brand--in-row-table-header;
      color: $brand--font-color-primary;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    td {
      vertical-align: middle;
      background: $brand--table-td-color;
      padding-left: 1.5rem;
    }
  }
  .line-items-header {
    padding-left: 28px;
  }
  .in-row-line-item {
    background: rgba(255, 255, 255, 0.05);
    font-size: 0.75rem;
    padding: 1rem 2rem 1rem 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .line-item-row {
    margin-bottom: 1rem;
    display: flex;
  }
  .line-item-row .line-item:last-child {
    max-width: 25%;
  }
  .line-item {
    max-width: 75%;
    flex: 0 0 75%;
    padding-right: 10px;
  }
  .cell-body {
    padding: 10px;
  }
  .chat-alert-icon {
    height: 22px;
  }
  .chat-alert-message {
    margin-left: 0.5rem;
    font-size: 0.7rem;
    width: 105px;
    color: rgba(255, 255, 255, 0.8);
  }
  .mobile-chat-alert {
    padding: 0 1rem 0 1rem;
    align-items: center;
    display: flex;
  }
  .mobile-chat-alert-icon {
    height: 17.5px;
  }
  .mobile-chat-alert-message {
    margin-left: 0.5rem;
    font-size: 0.6875rem;
    color: $brand--font-color-primary;
    opacity: 0.8;
  }
  .line-items-note {
    color: #dedbdb;
    font-size: 0.625rem;
    height: 1.5rem;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .mobile-line-items-note {
    color: #dedbdb;
    font-size: 0.625rem;
    height: 1.5rem;
    padding: 15px 40px 15px 40px;
  }

  .desktop-claims-table {
    .data-table tbody tr:hover td {
      background: $brand--accordion-hover !important;
      border: none !important;
      color: $brand--font-color-primary !important;
    }
  }
  .mobile-claims-table {
    .data-table tbody {
      tr:hover {
        background: none !important;
        background-color: $brand--table-td-color !important;
      }
    }
    tr {
      margin-bottom: 0;
    }
    td {
      vertical-align: top;
    }
  }
}
</style>
