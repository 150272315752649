<template>
  <div
    id="sort-wrap"
    :class="`claims-sort-display ${active ? 'active' : ''}`"
    @keydown="focusTrap"
    @keydown.esc="onEscape"
  >
    <modal-header v-mobile-breakpoint-only :modal-name="modalName"></modal-header>
    <div ref="container" class="container">
      <h1 class="header-label">SORT BY</h1>

      <h2 class="section-label">Provider Name</h2>

      <fieldset>
        <div class="checkbox-item">
          <label>
            <input
              type="checkbox"
              :form-item="false"
              :checked="form.data.sortByProviderName === 'A-Z'"
              value="A-Z"
              ref="rowChecked"
              label="A - Z"
              @change="onSortByProvideNameSelect('A-Z')"
              class="focusable"
            />
            A - Z
          </label>
        </div>
        <hr />
        <div class="checkbox-item">
          <label>
            <input
              type="checkbox"
              :form-item="false"
              :checked="form.data.sortByProviderName === 'Z-A'"
              value="Z-A"
              ref="rowChecked"
              label="Z - A"
              @change="onSortByProvideNameSelect('Z-A')"
              class="focusable"
            />
            Z - A
          </label>
        </div>
        <hr />
      </fieldset>

      <div class="actions">
        <ion-button
          class="focusable cancel"
          fill="outline"
          kind="tertiary"
          :disabled="isClearAllButtonDisabled"
          @click="onCancelButtonClick"
          @keydown.enter="onCancelButtonClick"
          @keydown.space="onCancelButtonClick"
          :tabindex="isClearAllButtonDisabled ? -1 : 0"
        >
          Clear all
        </ion-button>
        <ion-button
          ref="lastRefSort"
          class="focusable confirm button bx--btn--primary"
          kind="primary"
          @click="onApplyButtonClick"
          @keydown.enter="onApplyButtonClick"
          @keydown.space="onApplyButtonClick"
          tabindex="0"
        >
          Apply
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { supportTypes, paidToTypes } from "@/helpers/support-category";
import ModalHeader from "@/components/ModalHeader.vue";
import MpmTextInput from "./MpmTextInput.vue";
import { closeModal } from "@/helpers/system";
import { isMobileView } from "@/helpers/screen";

export default {
  name: "ClaimsSortDisplay",
  components: { ModalHeader, MpmTextInput },
  props: {
    sortByProviderName: {
      type: String,
      default: "A-Z"
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dismiss() {
      if (isMobileView(window.innerWidth)) {
        closeModal();
      }
      this.$emit("dismiss");
    },
    onCancelButtonClick() {
      this.$emit("update", {
        sortByProviderName: null
      });
      this.dismiss();
    },
    onSortByProvideNameSelect(option) {
      this.form.data.sortByProviderName = this.form.data.sortByProviderName === option ? null : option;
    },
    onApplyButtonClick() {
      this.$emit("update", {
        sortByProviderName: this.form.data.sortByProviderName
      });
      this.dismiss();
    },
    onEscape() {
      this.dismiss();
    },
    focusTrap(e) {
      const focusableList = document.querySelectorAll(
        "#sort-wrap .focusable, #sort-wrap ion-button.confirm, #sort-wrap ion-button.cancel"
      );
      if (e.key === "Tab" && e.shiftKey === false && e.target === focusableList[focusableList.length - 1]) {
        e.preventDefault();
        (focusableList[0] as HTMLElement)?.focus();
      }
      if (e.key === "Tab" && e.shiftKey === true && e.target === focusableList[0]) {
        e.preventDefault();
        this.$refs.lastRefSort.focus();
      }
    }
  },
  computed: {
    isClearAllButtonDisabled() {
      return this.form.data.sortByProviderName === null;
    }
  },
  mounted() {
    const compEl: Record<string, any> = this.$el;
    compEl.clickOutsideEventListener = (event: Event) => {
      const target = event.target;
      const el = this.$refs.container as Element;
      if (!el) {
        return;
      }
      if (this.active && !(el !== target && el.contains(target as Node)) && !isMobileView(window.innerWidth)) {
        this.dismiss();
      }
    };
    this.$root?.$el?.addEventListener("click", compEl.clickOutsideEventListener);
  },
  unmounted() {
    const compEl: Record<string, any> = this.$el;
    if (typeof compEl.clickOutsideEventListener === "function") {
      this.$root.$el.removeEventListener("click", compEl.clickOutsideEventListener);
      compEl.clickOutsideEventListener = null;
      delete compEl.clickOutsideEventListener;
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.form.data = {
          sortByProviderName: this.sortByProviderName
        };
      }
    }
  },
  data() {
    return {
      modalName: "Claims Sort",
      form: {
        data: {
          sortByProviderName: this.sortByProviderName
        },
        options: {
          supportTypes,
          paidToTypes,
          maxDate: dayjs().format("YYYY-MM-DD")
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.claims-sort-display {
  display: none;
  position: relative;
  label {
    padding: 3px;
    vertical-align: middle;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: 28px;
    height: 20px;
    vertical-align: bottom;
    mix-blend-mode: luminosity;
    &:checked {
      mix-blend-mode: normal;
      accent-color: $brand--checkbox-checked-bg;
    }
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
      mix-blend-mode: normal;
    }
  }
  .container {
    position: absolute;
    padding: 1rem;
    @media screen and (max-width: $width--breakpoint--md) {
      height: 100vh;
      width: 100%;
      right: auto;
    }
    z-index: 20;
    width: 25rem;
    right: 1.5rem;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    background: $brand--filter-bg;
    box-shadow: 0.5rem 0.5rem 1rem rgba(#000000, 0.25);
    .header-label {
      color: $brand--font-color-primary;
      font-size: 1rem;
    }
    .section-label {
      color: $brand--font-color-primary;
      font-size: 0.85rem;
      padding: 0.5rem 0 0.5rem 0;
      font-weight: $brand--body-font-weight;
    }
    .filter-button {
      display: inline-block;
      background: transparent;
      border: solid 1px $brand--filter-buttons-border;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      font-size: 1rem;
      line-height: 1rem;
      height: auto;
      margin: 0 0.5rem 0.5rem 0 !important;
      color: $brand--font-color-primary;
    }

    .checkbox-item {
      color: $brand--font-color-primary;
      margin: 1rem 0 1rem 0;
    }
    .actions {
      padding: 1rem 1rem 2rem 1rem;
      text-align: center;
      ion-button {
        display: inline-block;
        text-align: center;
        min-width: 8rem;
        margin: 0 1.5rem 0 0 !important;
        text-transform: none;
        letter-spacing: 0;
      }
      .cancel {
        @media only screen and (max-width: 370px) {
          margin-bottom: 1.5rem !important;
        }
        --border-width: 1px;
        box-shadow: $brand--button-filter-box-shadow;
        border-color: $brand--button-main;
        color: $brand--button-main;
        &:hover:not(:disabled) {
          border-color: $brand--button-secondary-hover;
          color: $brand--button-secondary-hover;
        }
        &:focus {
          box-shadow: none;
          outline: 3px solid $brand--cancel-button-outline !important;
          outline-offset: 2px;
        }
        &[disabled] {
          opacity: 0.5;
        }
      }
      .confirm {
        box-shadow: $brand--button-filter-box-shadow;
        color: $brand--font-color-secondary;
        background-color: $brand--primary-color;
        &:focus {
          box-shadow: none;
          outline: 3px solid $brand--confim-button-outline;
          outline-offset: 2px;
        }
      }
    }
  }
  &.active {
    display: block;
  }
}
@media screen and (max-width: $width--breakpoint--md) {
  .claims-sort-display {
    .overlay {
      position: fixed;
      background: rgba(#000000, 0.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .container {
      width: 100%;
      left: 0;
      right: 0;
      z-index: 2;
      border-radius: 0;
    }
  }
}
.container fieldset {
  border: none;
  padding: 0;
}
</style>
