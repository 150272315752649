<template>
  <div class="table-container">
    <table v-desktop-breakpoint-only class="mpm-data-table">
      <thead>
        <tr class="parent-row">
          <th
            :id="`column-header-${column}`"
            class="column-header"
            :class="column"
            v-for="(column, cellIndex) in desktopColumns"
            :key="`heading:${cellIndex}`"
          >
            <span class="bx--table-header-label">{{ column }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-show="uploadedClaims.length === 0">
        <tr>
          <td colspan="4">
            <div class="claims-list-empty">
              <h5>There are no uploaded claims to show.</h5>
              <p class="empty-message">
                Claims that are submitted to {{ $strings.brand_name }} in the portal/app will be displayed here until
                they are processed. Go to your "All Claims" tab to view received and processed claims
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-for="(rowData, index) in uploadedClaims" :key="index">
        <tr class="parent-row">
          <td
            :headers="`column-header-provider-reimbursement`"
            :aria-labelledby="`date-header-${index}`"
            class="provider-width-cap"
            tabindex="-1"
          >
            {{ rowData.type === "Reimbursements" ? "Myself/Reimbursement" : "Provider" }}
          </td>
          <td
            :headers="`column-header-date-uploaded`"
            :aria-labelledby="`date-header-${index}`"
            tabindex="-1"
            v-html="$filters.friendlyDate(rowData.date)"
          />
          <td
            :headers="`column-header-status`"
            :aria-labelledby="`date-header-${index}`"
            tabindex="-1"
            v-html="rowData.status"
          />
          <td :headers="`column-header-uploaded-invoice`" :aria-labelledby="`date-header-${index}`" tabindex="-1">
            <div class="bx--row">
              <div class="bx--col">
                <ion-icon
                  :src="$brand_svg_src + '/open-new-tab.svg'"
                  class="open-invoice-icon"
                  @click="
                    getExtraFile(rowData, index);
                    openInNewTab(rowData, index);
                  "
                />
                <a
                  class="view-invoice-link"
                  v-if="rowData.invoice_name"
                  tabindex="0"
                  :href="rowData.invoice_link"
                  aria-label="View invoice link"
                  @keydown.space.prevent
                  @click.prevent="
                    getExtraFile(rowData, index);
                    openInNewTab(rowData, index);
                  "
                  >View Invoice</a
                >
              </div>
              <div class="bx--col" v-if="rowData.extra_file_link">
                <ion-icon
                  src="svg/open-new-tab.svg"
                  class="open-invoice-icon"
                  @click="openExtraFileInNewTab(rowData)"
                />
                <a
                  target="_blank"
                  tabindex="0"
                  :href="rowData.extra_file_link"
                  aria-label="View invoice link"
                  @click.prevent="openExtraFileInNewTab(rowData)"
                  >+ 1 more
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-mobile-breakpoint-only class="mpm-data-table">
      <thead>
        <tr class="parent-row">
          <th
            :id="`column-header-${column}`"
            class="column-header"
            :class="column"
            v-for="(column, cellIndex) in mobileColumns"
            :key="`heading:${cellIndex}`"
          >
            <span class="bx--table-header-label">{{ column }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-show="uploadedClaims.length === 0">
        <tr>
          <td colspan="4">
            <div class="claims-list-empty">
              <h5>There are no uploaded claims to show.</h5>
              <p class="empty-message">
                Claims that are submitted to {{ $strings.brand_name }} in the portal/app will be displayed here until
                they are processed. Go to your "All Claims" tab to view received and processed claims
              </p>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-for="(rowData, index) in uploadedClaims" :key="index">
        <tr>
          <td
            :headers="`column-header-provider-reimbursement`"
            :aria-labelledby="`date-header-${index}`"
            class="provider-width-cap"
            tabindex="-1"
          >
            {{ rowData.type === "Reimbursements" ? "Myself/Reimbursement" : "Provider" }}
          </td>
          <td
            :headers="`column-header-date-uploaded`"
            :aria-labelledby="`date-header-${index}`"
            tabindex="-1"
            v-html="$filters.friendlyDate(rowData.date)"
          />
        </tr>
        <tr>
          <td
            :headers="`column-header-status`"
            :aria-labelledby="`date-header-${index}`"
            tabindex="-1"
            class="second-row"
          >
            <div class="in-cell-title">Status</div>
            <div>{{ rowData.status }}</div>
          </td>
          <td :headers="`column-header-uploaded-invoice`" :aria-labelledby="`date-header-${index}`" tabindex="-1">
            <div class="in-cell-title">Invoice</div>
            <div class="bx--row">
              <div>
                <ion-icon
                  src="svg/open-new-tab.svg"
                  class="open-invoice-icon"
                  @click="
                    getExtraFile(rowData, index);
                    openInNewTab(rowData, index);
                  "
                />
                <a
                  tabindex="0"
                  :href="rowData.invoice_link"
                  aria-label="View invoice link"
                  @click.prevent="
                    getExtraFile(rowData, index);
                    openInNewTab(rowData, index);
                  "
                  >View Invoice</a
                >
              </div>
              <div v-if="rowData.extra_file_link">
                <ion-icon
                  src="svg/open-new-tab.svg"
                  class="open-invoice-icon"
                  @click="openExtraFileInNewTab(rowData)"
                />
                <a
                  tabindex="0"
                  :href="rowData.extra_file_link"
                  aria-label="View invoice link"
                  @click.prevent="openExtraFileInNewTab(rowData)"
                  >+ 1 more</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <claims-pagination v-if="!hasNoClaims" @change-page="changePage" :pagination="pagination"> </claims-pagination>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { createNamespacedHelpers } from "vuex";
import ClaimsPagination from "@/components/salesforce/ClaimsPagination.vue";
import FileViewer from "@/components/FileViewer.vue";
import { sfGetInvoiceClaimLink } from "@/api/salesforce/claims";
import { debounce } from "lodash";

const { mapGetters, mapActions } = createNamespacedHelpers("sf/uploadedClaims");
const CLAIM_TYPE_REIMBURSEMENTS = "Reimbursements";
const layout = createNamespacedHelpers("layout");

export default defineComponent({
  name: "uploaded-claims-grid",
  components: {
    IonButton,
    IonIcon,
    ClaimsPagination,
    FileViewer
  },
  data() {
    return {
      desktopColumns: ["PROVIDER/REIMBURSEMENT", "DATE UPLOADED", "STATUS", "UPLOADED INVOICE"],
      mobileColumns: ["PROVIDER/REIMBURSEMENT", "DATE UPLOADED"]
    };
  },
  computed: {
    ...mapGetters({
      uploadedClaims: "uploadedClaims",
      pagination: "pagination"
    }),
    hasNoClaims() {
      return !Object.keys(this.uploadedClaims).length;
    }
  },
  methods: {
    ...mapActions(["loadUploadedClaims"]),
    ...layout.mapActions(["updateContentsLoading"]),
    isClaimReimbursement(rowData) {
      return rowData.type === CLAIM_TYPE_REIMBURSEMENTS;
    },
    async openInNewTab(rowData, index) {
      if (this.isClaimReimbursement(rowData)) {
        window.open(rowData.invoice_link, "_blank")?.focus();
      } else {
        await this.openUploadedFileLink(rowData.sfid, index);
      }
    },
    openExtraFileInNewTab(rowData) {
      window.open(rowData?.extra_file_link, "_blank")?.focus();
    },
    openUploadedFileLink(invoiceSfid, index) {
      sfGetInvoiceClaimLink(invoiceSfid, true)
        .then(response => {
          const { url } = response;
          if (url) {
            setTimeout(() => {
              this.uploadedClaims[index].invoice_link = url;
              console.log("Open document link");
              console.log(url);
              window.open(url, "_blank")?.focus();
            });
          }
        })
        .catch(error => {
          const { data } = error;
          console.log(data);
        });
    },
    async getExtraFile(rowData, index) {
      if (rowData.type === CLAIM_TYPE_REIMBURSEMENTS) {
        await sfGetInvoiceClaimLink(rowData.sfid, true)
          .then(response => {
            const { url } = response;
            if (url) {
              this.uploadedClaims[index].extra_file_link = url;
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    changePage: debounce(async function(page: number) {
      this.updateContentsLoading(true);
      await this.loadUploadedClaims(page);
      this.updateContentsLoading(false);
    }, 150)
  }
});
</script>

<style lang="scss" scoped>
// min-width is set to 500px, needs to be cleared;
.table-container {
  min-width: unset;
  padding-top: 0;
  padding-bottom: 3px;
  overflow: hidden;
  @media screen and (max-width: $width--breakpoint--md) {
    .claims-pagination {
      margin-right: 0;
    }
    .mpm-data-table > * .column-header {
      font-size: 0.71em;
    }
  }
}

.download-icon {
  font-size: 1rem;
}

.mpm-data-table {
  .bx--table-header-label {
    font-weight: $brand--claims-table-header-font-weight;
    padding: 0;
  }
  td {
    font-weight: $brand--claims-table-row-font-weight;
    border-bottom: 0 !important;
    padding: 1rem;
    cursor: default;
    background-color: $brand--table-td-color !important;
    color: $brand--font-color-primary !important;
  }
  tbody {
    cursor: default;
  }
  th {
    vertical-align: middle;
  }
  tbody {
    border-bottom: $brand--claims-table-tbody-border-bottom;
  }

  tbody tr {
    height: 82px;
  }
  td,
  th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  a {
    color: $brand--view-invoice-color;
  }
}
.bx--table-header-label {
  text-transform: uppercase;
}
.mpm-data-table {
  tr:first-of-type td {
    border-top: none !important;
  }
  .parent-row {
    .column-header {
      color: #fff;
    }
  }
}

.second-row {
  vertical-align: top;
}
.page-description {
  font-size: 11px;
}
.cell-content {
  display: flex;
  flex-direction: column;
  color: #a6a8ac;
  font-size: 11px;
}
.view-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > div {
    flex: 30px 1;
  }
}
.record-controls {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .page-meta {
    margin-right: 1rem;
  }
  button:not(:last-child) {
    margin: 0 1.5rem 0 0;
    &.duet-date__toggle {
      margin: 0;
    }
  }
  .button {
    position: relative;
    @media screen and (max-width: $width--breakpoint--md) {
      position: static;
    }
    &:last-child {
      button {
        margin: 0;
      }
    }
  }
  .page-meta {
    font-size: 12px;
    padding-left: 15px;
  }
}

.view-invoice-link {
  &:focus-visible {
    outline: 3px solid $brand--focus-outline !important;
  }
}
.open-invoice-icon {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
}
.in-cell-title {
  font-size: 0.85rem;
  color: $brand--claims-label-color;
  margin-bottom: 1rem;
}
.claims-list-empty {
  padding: 0.5rem;
  h5 {
    line-height: normal;
  }
  p.empty-message {
    font-style: normal;
    line-height: 24px;
    margin-top: 24px;
  }
}
</style>
