<template>
  <ion-footer style="display: grid">
    <footer>
      <ul class="mpm-footer-list">
        <li
          v-for="(siteMapItem, idx) in siteMapItems"
          :key="idx"
          tabindex="0"
          @keyup.space="gotoTab(siteMapItem.replace(/\s+/g, '-').toLowerCase())"
          @keyup.enter="gotoTab(siteMapItem.replace(/\s+/g, '-').toLowerCase())"
        >
          <a class="site-map-item" @click="gotoTab(siteMapItem.replace(/\s+/g, '-').toLowerCase())">{{ siteMapItem }}</a>
        </li>
      </ul>
    </footer>
    <div class="mpm-legal-section">
      <span @click="displayVersionCount++">&copy;</span> {{ $strings.brand_name }} {{ currentYear }}
      <span v-show="displayVersionCount >= 7">(Ver {{ appVersion }})</span>
    </div>
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonFooter, IonGrid, IonRow, IonCol } from "@ionic/vue";
import moment from "moment";

export default defineComponent({
  name: "Site Map",
  components: {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol
  },
  data() {
    return {
      siteMapItems: ["Plan", "Claims", "Providers", "Reports", "Settings"],
      displayVersionCount: 0,
      appVersion: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    currentYear() {
      return moment().year();
    }
  },
  methods: {
    gotoTab(tab) {
      this.$router.push({ path: "/" + tab });
    }
  },
  watch: {
    "$store.getters.participant.permissionLevel": {
      deep: true,
      immediate: true,
      async handler(v) {
        if (this.$store.getters.hasReadOnlyParticipantAccess) {
          this.siteMapItems = ["Plan", "Claims", "Providers", "Reports", "Settings"];
        }

        if (this.$store.getters.hasFullParticipantAccess) {
          this.siteMapItems.splice(4, 0, "Generate Reports");
          this.siteMapItems.push("Submit Claim");
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
ion-footer {
  font-size: 0.875rem;
  font-weight: 600;
  background: $brand--footer-bg;
  padding: 28px 80px;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
}

a {
  color: $brand--footer-font-color;
  text-decoration: underline;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }
}

a:hover {
  color: $brand--footer-font-hover;
}

ul {
  list-style: none;
  padding-left: 0;
}

.mpm-footer-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.mpm-footer-list li {
  margin: 0 80px 10px 0;
  white-space: nowrap;

  &:focus-visible {
    margin-top: -6px;
    border: 3px solid $brand--focus-outline;
    outline: none;
    padding: 3px;
  }
}

.mpm-legal-section {
  margin-top: 28px;
  color: #757c89;
}
</style>
