<template>
  <ion-content tabindex="0" class="page-content plan-section plan-tab" aria-label="Plan page content" ref="planContent">
    <section aria-label="plan page">
      <div style="overflow:auto" tabindex="-1">
        <get-feedback
          v-if="$store.state.userSettings.shouldShowCsat"
          ref="getfeedback"
          @loaded="setFeedBackParameters"
        />
        <div class="content-spacer">
          <total-available-budget v-if="$root.isMobileView && !$store.getters.currentPlan.pace_plan" />
          <div
            class="header-spacer-mobile"
            :class="[{ collapsed: isSupportSelected }, { hide: $store.getters.supportItems.expandSupport }]"
          >
            <ion-refresher
              slot="fixed"
              @ionRefresh="doRefresh($event)"
              :disabled="$store.getters.supportItems.expandSupport"
            >
              <ion-refresher-content
                pulling-icon="svg/loading.svg"
                refreshing-spinner="circles"
                refreshing-text="Refreshing..."
              >
              </ion-refresher-content>
            </ion-refresher>
            <div class="main-heading" :class="{ split: $root.zoomLevel >= 2 }" v-if="!contentsLoading">
              <h1 class="main-title">Plan</h1>
              <div class="plan-dropdown-wrap" v-desktop-breakpoint-only>
                <plan-dropdown
                  :plans="plans"
                  @change="updatePlan"
                  :default-plan="selected.plan"
                  aria-label="Click to select plan"
                />
              </div>
              <div class="select-plan-global" v-mobile-breakpoint-only>
                <ion-select
                  placeholder="Choose a plan"
                  :interface-options="getInterfaceOptions('Plans', 'Choose an option')"
                  interface="alert"
                  :value="selectedPlanId"
                  :selected-text="
                    $filters.friendlyDate($store.getters.currentPlan?.start_date) +
                      ' to ' +
                      $filters.friendlyDate($store.getters.currentPlan?.end_date)
                  "
                  v-model="selected.planId"
                  :disabled="false"
                  @ionChange="updatePlan(selected.planId)"
                >
                  <ion-icon :src="`${$brand_svg_src}/chevron-down-outline.svg`"></ion-icon>
                  <ion-select-option v-for="(plan, idx) in plans" :key="idx" :value="plan.sfid">
                    {{ $filters.friendlyDate(plan.start_date) + " to " + $filters.friendlyDate(plan.end_date) }}
                  </ion-select-option>
                </ion-select>
              </div>
            </div>
            <section class="plan-support_collection" :class="['plan-support-item-' + planSupportCategoryId]">
              <PlanSupportCategoryGroups
                :plan-support-categories="planSupportCategories"
                v-if="planSupportCategories?.length"
                @fetchItems="onFetchItems()"
                @fetchItemsCompleted="onFetchItemsCompleted()"
              />
            </section>
          </div>
          <div
            role="dialog"
            aria-labelledby="planDialogTitle"
            aria-describedby="planDialogDescription"
            class="plan-support-item"
            :hidden="!isSupportSelected"
            :class="[
              { active: isSupportSelected },
              { collapsed: isSupportSelected },
              { expand: isExpanded },
              'plan-support-item-' + planSupportCategoryId
            ]"
          >
            <ion-refresher slot="fixed" @ionRefresh="reloadSupportItems($event)" :disabled="!isSupportSelected">
              <ion-refresher-content
                pulling-icon="svg/loading.svg"
                refreshing-spinner="circles"
                refreshing-text="Refreshing..."
              >
              </ion-refresher-content>
            </ion-refresher>
            <div class="plan-support-wrap" v-if="!contentsLoading">
              <div class="sub-heading" :class="{ split: $root.zoomLevel >= 2 }">
                <h1 class="main-title">Plan</h1>
                <div class="sub-plandates" aria-hidden="true">
                  <span class="plan-date">{{ friendlyDate(selected.plan.start_date) }}</span> -
                  <span class="plan-date">{{ friendlyDate(selected.plan.end_date) }}</span>
                </div>
              </div>
              <div :class="['plan-support-area-wrap', { 'expand-fixed': $store.state.supportItems.expandSupport }]">
                <PageHeader />
                <PlanSupportArea
                  @expandSupportArea="hidePlan"
                  @reload-needed="$store.dispatch('supportItems/getSupportItems')"
                />
              </div>
            </div>
          </div>
          <MobileAddClaimButton />
        </div>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import PlanSupportCategoryGroups from "@/components/plans/PlanSupportCategoryGroups.vue";
import PlanSupportArea from "@/components/plans/PlanSupportArea.vue";
import PageHeader from "@/components/plans/support-items/PageHeader.vue";
import MpmHelpLink from "@/components/MpmHelpLink.vue";
import { find } from "lodash";
import { defineComponent, reactive } from "vue";
import { Plan } from "@/types/salesforce";
import MobileAddClaimButton from "@/components/MobileAddClaimButton.vue";
import { TrackingEvent } from "@/types/tracking-events";
import PlanHeader from "@/components/plans/PlanHeader.vue";
import GetFeedback from "@/components/GetFeedBack.vue";
import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import friendlyDate from "@/filters/friendlyDate";
import PlanDropdown from "@/components/plans/PlanDropdown.vue";
import { mapGetters, mapActions } from "vuex";

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonRefresher,
  IonRefresherContent
} from "@ionic/vue";
import { getSfParticipantPlans } from "@/api/salesforce/plans";
import returnCurrentPlan from "@/helpers/plans/returnCurrentPlan";

export default defineComponent({
  name: "SFPlan",
  data() {
    return {
      isExpanded: false,
      selected: reactive({
        planId: this.selectedPlanId,
        plan: this.$store.state.currentPlan
      })
    };
  },
  components: {
    PlanSupportCategoryGroups,
    PlanSupportArea,
    PageHeader,
    MobileAddClaimButton,
    PlanHeader,
    GetFeedback,
    MpmHelpLink,
    TotalAvailableBudget,
    SiteMap,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonRefresher,
    IonRefresherContent,
    PlanDropdown
  },
  beforeRouteEnter(to, from, next) {
    next((self: any) => {
      // callback runs when navigation finish
      // prevents someone landing at plan page with plan open
      // not ideal, except when user is coming from AddSupportItem page
      if (from.name !== "AddSupportItem") {
        self.$store.dispatch("supportItems/collapseSupportArea", false);
        self.$store.dispatch("supportItems/expandSupportArea", false);
      }
    });
  },
  computed: {
    ...mapGetters({
      planSupportCategories: "currentPlan/getPlanSupportCategories",
      contentsLoading: "layout/getContentsLoading",
      plans: "plans/getPlans"
    }),
    isSupportSelected() {
      return !!this.$store.state.supportItems.collapseSupport;
    },
    planSupportCategoryId() {
      return this.$store.state.supportPlanCategory.support_category?.id;
    },
    /**
     * This computed property solves
     * the reactivity issue of the plan ID
     * not being updated when a new participant
     * is selected.
     */
    selectedPlanId() {
      return this.selected?.plan?.sfid;
    }
  },
  methods: {
    ...mapActions({
      updateContentsLoading: "layout/updateContentsLoading",
      updateFullScreenLoading: "layout/updateFullScreenLoading"
    }),
    friendlyDate,
    /**
     * Called on fetching the support items in the plan drilldown view
     * Triggered by clicking on a PlanSupportCategory tile in grandchild component
     */
    onFetchItems() {
      this.updateContentsLoading(true);
    },
    /**
     * Called immediately after fetching the support items in the plan drilldown view
     */
    onFetchItemsCompleted() {
      this.$refs.planContent.$el.scrollToTop(0);
      this.updateContentsLoading(false);
    },
    getInterfaceOptions(alertHeader: string, alertSubHeader: string) {
      return {
        header: alertHeader,
        subHeader: alertSubHeader,
        cssClass: "ios-select-alert",
        mode: "ios"
      };
    },
    async doRefresh(refresher) {
      refresher.target.complete();
      try {
        this.updateFullScreenLoading(true);
        const plans = (await getSfParticipantPlans()) as Array<Plan>;
        await this.$store.dispatch("plans/setPlans", plans);
        await this.$store.dispatch("currentPlan/clearState");
        const currentPlan = find(plans, (plan: Plan) => plan.sfid === this.selectedPlanId);
        if (currentPlan) {
          await this.$store.dispatch("currentPlan/setCurrentPlan", currentPlan);
        } else {
          this.setCurrentPlanToActivePlan(plans);
        }
      } catch (e) {
        console.log(e);
        this.updateFullScreenLoading(false);
      }
    },
    setCurrentPlanToActivePlan(plans) {
      const currentPlan = returnCurrentPlan(plans);
      this.$store.dispatch("currentPlan/setCurrentPlan", currentPlan);
    },
    reloadSupportItems(refresher) {
      this.updateContentsLoading(true);
      refresher.target.complete();

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      this.$store
        .dispatch("supportItems/getSupportItems")
        .catch(error => {
          console.log("error loading items", error);
          vm.$notification.createToast("Unable to load support items", "error");
        })
        .finally(() => {
          vm.updateContentsLoading(false);
        });
    },
    setFeedBackParameters() {
      const params = {
        ContactID: this.$store.getters.user.sfid,
        ClientID: this.$store.getters.participant.sfid
      };
      this.$refs.getfeedback.setParameters(params);
    },
    async updatePlan(planId: string) {
      if (!planId) return;
      this.selected.plan = find(this.plans, (plan: Plan) => plan.sfid === planId);

      await this.$store.dispatch("currentPlan/setCurrentPlan", this.selected.plan);
      this.$amplitude.track(TrackingEvent.SelectPlan, {
        pacePlan: this.selected.plan.pace_plan
      });
      this.$amplitude.track(TrackingEvent.Navigation, {
        page: this.selected.plan.pace_plan ? "PacePlanTab" : "PlanTab"
      });
    },
    closeSupportArea() {
      this.$store.commit("supportPlanCategory/clearSelectedSupportPlanCategory");
      this.$store.dispatch("supportItems/collapseSupportArea", false);
    },
    hidePlan(value) {
      this.isExpanded = value;
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/style/components/plan.scss";

.bx--loading .bx--loading__stroke {
  stroke: $brand--theme--active-primary;
  stroke-linecap: round;
}
.main-breadcrumbs {
  margin: 15px 0 4px 0;
  padding: 0 20px;
  display: none;

  .bx-link {
    color: #ffffff;
  }
  @include grid-media($grid--breakpoint--md) {
    display: inline-block;
  }
}
.main-heading {
  .select-plan-global {
    flex-grow: 1;
    ion-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border: $brand--plan-dropdown-border;
      border-radius: $brand--plan-dropdown-border-radius;
      &::part(text) {
        overflow: visible;
        font-size: 18px;
        font-weight: 600;
        color: $brand--plan-dropdown-color;
        letter-spacing: 0;
        flex: unset;
        white-space: break-spaces;
        line-height: normal;
      }
      &::part(icon) {
        opacity: $brand--plan-dropdown-icon-opacity-mobile;
        margin-left: 0.3rem;
        color: transparent;
        background: url("../../assets/icons/#{$brand}-chevron-down-outline.svg") no-repeat center;
        overflow: visible;
        background-size: 25px 25px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
.expanded-support-area-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -60px;
}
.main-breadcrumbs.expanded-support-area {
  margin-left: 10px;
}
.main-plandates {
  padding: 8px 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  @include grid-media($grid--breakpoint--md) {
    display: none;
  }
}
.sub-heading {
  max-width: 1018px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 1rem;
  align-items: baseline;
  justify-content: flex-start;
  gap: 1rem;
}
.sub-plandates {
  flex-grow: 1;
  padding: 8px 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}
.main-shortdesc {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: center;
  margin-bottom: 0;

  .icon {
    height: 32px;
    width: 32px;
    display: block;
    margin: 26px 0 0 20px;
    padding: 2px;
  }

  .icon:hover {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 42rem) {
  .bx--tile {
    box-shadow: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    padding-right: 35px;
    width: calc(50% - 40px) !important;
    max-width: 525px;
    .managed-logo {
      right: 34px;
    }
  }
}

.plan-dropdown-wrap {
  max-width: 302px;
  width: 100%;
}
</style>
