<template>
  <div class="provider-progress-summary">
    <div class="header" @click="toggle">
      <circle-progress-bar v-if="circleProgressbar" :percentage="percentage" :colour="colour" />
      <h5 class="title">{{ providerName }}</h5>
      <button
        :aria-label="(isOpen ? 'collapse' : 'expand') + ' provider'"
        tabindex="0"
        :class="{ active: isOpen }"
        class="chevron-up"
      >
        <ion-icon style="display: inline" :src="`${$brand_svg_src}/icon-chevron-up.svg`" />
      </button>
    </div>

    <slot v-if="isOpen"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CircleProgressBar from "@/components/CircleProgressBar.vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "ProviderProgressSummary",
  props: {
    colour: { type: String },
    providerName: { type: String },
    percentage: { type: Number, default: 0 },
    isActive: { type: Boolean, default: true },
    circleProgressbar: { type: Boolean, default: false }
  },
  components: { CircleProgressBar, IonIcon },
  data() {
    return {
      isOpen: true
    };
  },
  methods: {
    toggle() {
      return (this.isOpen = !this.isOpen);
    }
  },
  mounted() {
    this.isOpen = this.isActive;
  }
});
</script>

<style lang="scss">
.provider-progress-summary {
  margin-top: 32px;
  .header {
    display: flex;
    margin-bottom: 12.3px;
  }

  .title {
    margin-left: 12px;
    width: 100%;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  }

  .chevron-up {
    margin-top: 2px;
    width: 22px;
    height: 22px;
    float: right;
    transform: rotate(-180deg);
    transition-duration: 0.2s;
    background: none;
    border: none;
    padding: 0;
    svg {
      height: 22px;
      width: 22px;
    }
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }

  .chevron-up.active {
    transform: unset;
  }
}
</style>
