<template>
  <div class="claims-filter-list">
    <div class="active-filters">
      <button
        :class="['filter-button', filterItem.cssClass]"
        v-for="(filterItem, idx) in filterItems"
        :key="idx"
        @click="onClickFilter(filterItem)"
        type="button"
        :aria-label="filterItem.name + ` filter applied `"
      >
        {{ filterItem.name }}
        <button class="close-button" :aria-label="`Remove ` + filterItem.name + ` filter `">
          <ion-icon slot="icon-only" :src="`mpm/svg/close.svg`" size="small" />
        </button>
      </button>
      <button class="filter-button clear-all-button" v-if="filterItems.length > 0" @click="onClearAllFilter">
        Clear All
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "ClaimsFilterList",
  components: {
    IonIcon
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {
          dates: null,
          supportTypes: [],
          paidToTypes: []
        };
      }
    }
  },
  computed: {
    filterItems() {
      return [
        ...this.filters.supportTypes,
        ...this.filters.paidToTypes,
        ...(this.filters.dates
          ? [
              {
                type: "date",
                name: `${dayjs(this.filters.dates.startDate, "DD-MM-YYYY").format("DD MMM YYYY")} ${
                  this.filters.dates.endDate
                    ? ` -  ${dayjs(this.filters.dates.endDate, "DD-MM-YYYY").format("DD MMM YYYY")}`
                    : ""
                }`,
                cssClass: "date"
              }
            ]
          : [])
      ];
    }
  },
  methods: {
    onClickFilter(filterItem) {
      const dates = filterItem?.type === "date" ? null : this.filters.dates;
      this.$emit("filter-change", {
        dates,
        supportTypes: this.filters.supportTypes.filter(f => {
          return f !== filterItem;
        }),
        paidToTypes: this.filters.paidToTypes.filter(f => {
          return f !== filterItem;
        })
      });
    },
    onClearAllFilter() {
      this.$emit("filter-change", {
        dates: null,
        supportTypes: [],
        paidToTypes: []
      });
    }
  },
  data() {
    return {};
  }
});
</script>
<style lang="scss" scoped>
.claims-filter-list {
  .active-filters {
    display: flex;
    flex-wrap: wrap;
    .filter-button {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      border-radius: $brand--filter-list-button-border-radius;
      font-size: 1rem;
      line-height: 1rem;
      margin: 0 0.5rem 0.5rem 0 !important;
      text-align: left;
      &.core {
        color: $brand--filter-list-color;
        border: none;
        background: $brand--filter-core;
      }
      &.capacity {
        color: $brand--filter-list-color;
        border: none;
        background: $brand--filter-capacity;
      }
      &.capital {
        color: $brand--filter-list-color;
        border: none;
        background: $brand--filter-capital-2;
      }
      &.date {
        color: $brand--filter-list-color;
        border: none;
        background: $brand--filter-list-date-bg;
      }
      &.provider,
      &.myself {
        color: $brand--filter-list-color;
        border: none;
        background: $brand--filter-myself-and-provider;
      }
      &.clear-all-button {
        background: $brand--filter-list-clear-all-bg;
        color: $brand--filter-list-clear-all-color;
        border: solid 1px $brand--filter-list-clear-all-border;
        &:focus-visible,
        &:active {
          outline: 3px solid $brand--focus-outline-button-secondary;
          outline-offset: 2px;
        }
      }
      &:focus-visible,
      &:active {
        outline: 3px solid $brand--focus-outline;
        outline-offset: 2px;
      }
      .close-button {
        background: none;
        height: 18px;
        width: 18px;
        &:focus-visible {
          outline: 3px solid $brand--focus-outline;
        }
      }
    }
  }
}
</style>
