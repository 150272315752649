<template>
  <ion-page>
    <mpm-loading :is-loading="fullScreenLoading"></mpm-loading>
    <ion-content>
      <section id="passwordless-login" aria-label="passwordless login page">
        <div class="passwordless-login">
          <!--top-section -->
          <div class="top-section">
            <svg class="shape" aria-hidden="true">
              <ellipse class="tablet" cx="0" cy="50%" rx="100%" ry="100%" />
              <ellipse class="mobile" cx="50%" cy="0" rx="75%" ry="100%" />
            </svg>
            <div class="logo">
              <ion-icon :src="$brand_svg_src + '/title.svg'" class="mpm-title mobile" aria-hidden="true" />
            </div>
          </div>

          <!-- main-section -->
          <div v-if="!passwordlessLoginStartedState" class="main-section">
            <form @submit.prevent="onSubmit" novalidate="true" class="total-height">
              <ion-grid class="flex-evenly-vertical">
                <ion-row class="header-row text-center">
                  <ion-col>
                    <h1 class="main-title">Login</h1>
                  </ion-col>
                </ion-row>
                <ion-row class="body-row">
                  <ion-col>
                    <!-- Notification -->
                    <div
                      v-if="form.error !== ''"
                      kind="error"
                      :sub-title="form.error"
                      @close="onNotificationDismiss"
                    ></div>

                    <div class="pay-to-radio-group">
                      <ion-segment :value="selectedTabIndex" v-model="selectedTabIndex">
                        <ion-segment-button
                          @keyup.enter="selectedTabIndex = 'sms'"
                          @keyup.space="selectedTabIndex = 'sms'"
                          mode="ios"
                          value="sms"
                        >
                          <ion-label>{{ smsSwitchLabel }}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button
                          @keyup.enter="selectedTabIndex = 'email'"
                          @keyup.space="selectedTabIndex = 'email'"
                          mode="ios"
                          value="email"
                        >
                          <ion-label>{{ emailSwitchLabel }}</ion-label>
                        </ion-segment-button>
                      </ion-segment>
                    </div>

                    <section class="tab-section">
                      <div v-if="selectedTabIndex === 'sms'">
                        <p class="paragraph text-center">
                          We use your email address to send a <strong>code</strong> to the
                          <strong>mobile number</strong> on your {{ $strings.brand_name_short }} account so you can log
                          in or you can
                          <span
                            class="router-link"
                            tabindex="0"
                            :class="isSubmitting ? 'disabled' : ''"
                            @keyup.enter="onClickLoginWithEmail(isSubmitting, 'email')"
                            @click="onClickLoginWithEmail(isSubmitting, 'email')"
                          >
                            Log in with Email
                          </span>
                          instead...
                        </p>
                      </div>
                      <div v-else>
                        <p class="paragraph text-center" v-mobile-only>
                          We use your email address to send a <strong>code</strong> to your
                          <strong>email address</strong> on your {{ $strings.brand_name_short }} account so you can log
                          in.
                        </p>

                        <p v-if="brand === 'mpm'" class="paragraph text-center" v-desktop-only>
                          We use your email address to send a <strong>code</strong> to your
                          <strong>email address</strong> on your {{ $strings.brand_name_short }} account so you can log
                          in.
                        </p>
                        <p v-else class="paragraph text-center" v-desktop-only>
                          We use your email address to send a <strong>code</strong> to your
                          <strong>email address</strong> on your {{ $strings.brand_name_short }} account so you can log
                          in.
                        </p>
                      </div>
                      <label>
                        Email Address (required)
                        <ion-input
                          enterkeyhint="send"
                          class="email-input-field"
                          tabindex="0"
                          type="email"
                          v-model="form.data.email"
                          placeholder="you@domain.com"
                          :disabled="isSubmitting"
                          autocomplete="email"
                          name="email"
                          ref="email"
                          aria-describedby="email-error-message"
                          @keypress.space.prevent
                          @ionInput="form.data.email = removeSpaceInStr(form.data.email)"
                          @ionFocus="form.invalid.email = null"
                        />
                        <div id="email-error-message" class="form-error">
                          {{ form.invalid.email }}
                        </div>
                      </label>
                      <br />
                    </section>
                  </ion-col>
                </ion-row>
                <ion-row class="footer-row">
                  <ion-col>
                    <button
                      @keyup.space="onSubmit"
                      @keyup.enter="onSubmit"
                      class="submit-button btn-primary"
                      kind="primary"
                      :disabled="isSubmitting"
                    >
                      Send me the code
                    </button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>

          <!-- Sms Code -->
          <div v-else-if="passwordlessLoginStartedState" class="main-section code-section">
            <form @submit.prevent="onCodeSubmit">
              <ion-grid v-if="hasNoMobileNumber">
                <ion-row>
                  <ion-col>
                    <!-- Error SMS Code state -->
                    <div
                      class="sms-code-error"
                      v-if="hasNoMobileNumber && passwordlessLoginStartedState.type === 'sms'"
                    >
                      <div class="text-center">
                        <p class="paragraph">
                          Sorry, we do not have a <strong>mobile number</strong> registered with that email address
                          <br /><br />
                          <a
                            :href="`tel:` + $strings.brand_phone_number"
                            :aria-label="$strings.brand_phone_number_aria"
                          >
                            <strong>Contact us on {{ $strings.brand_phone_number }}</strong></a
                          >
                          or alternatively you can <strong>log in</strong> with a code sent to your
                          <strong>email address</strong>
                        </p>
                        <br />
                        <ion-button class="back-to-login-button" @click="redirectToLogin">Back to Login</ion-button>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-grid v-else>
                <ion-row class="header-row">
                  <ion-col>
                    <h1 class="main-title text-center">A code is on its way</h1>
                    <p class="paragraph text-center" v-if="passwordlessLoginStartedState.type === 'sms'">
                      We have sent you a
                      <strong>unique code</strong> to:<br /><strong>{{ maskedMobileNumber || "04XX XXX XXX" }}</strong>
                    </p>
                    <p class="paragraph text-center" v-else>
                      We have sent you a
                      <strong>unique code</strong> to:<br /><strong>{{ passwordlessLoginStartedState.email }}</strong>
                    </p>
                    <p class="paragraph text-center">Enter the code below to log in.</p>
                    <label>ENTER YOUR CODE</label>
                    <ion-input
                      enterkeyhint="login"
                      inputmode="numeric"
                      placeholder="Enter your code"
                      :invalid-message="form.invalid.code"
                      v-model="form.data.code"
                      case-transform="lower"
                    />
                    <div class="error" v-if="form.invalid.code">
                      {{ form.invalid.code }}
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row class="footer-row">
                  <ion-col>
                    <ion-button
                      class="resend-button"
                      kind="primary"
                      :disabled="disableResendCodeButton"
                      type="button"
                      @click="onResendCodeClick"
                      >Resend Code</ion-button
                    >
                    <button class="submit-button btn-primary" kind="primary" :disabled="isSubmitting">Login</button>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div class="text-center">
                      <p
                        v-if="passwordlessLoginStartedState.type === 'sms'"
                        class="paragraph"
                        style="margin-bottom: 0.5rem;"
                      >
                        Is the phone number incorrect?
                      </p>
                      <p v-if="passwordlessLoginStartedState.type === 'sms'">
                        <strong
                          >Call {{ $strings.brand_name_short }} on
                          <a
                            v-if="$root.isMobileView"
                            :href="`tel:` + $strings.brand_phone_number"
                            :aria-label="$strings.brand_phone_number_aria"
                            >{{ $strings.brand_phone_number }}
                          </a>
                          <span v-else :aria-label="$strings.brand_phone_number_aria">{{
                            $strings.brand_phone_number
                          }}</span>
                        </strong>
                      </p>
                      <button
                        class="back-to-login btn--ghost"
                        kind="ghost"
                        aria-label="Back to Login"
                        @click="redirectToLogin"
                        style="margin:1rem;"
                      >
                        <ion-icon :src="$brand_svg_src + '/back-arrow-reverse-color.svg'" class="icon-left" />
                        Back to Login
                      </button>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import IdentIcon from "@/assets/icons/ident.svg";
import MpmLogoIcon from "@/assets/icons/mpm-title.svg";
import { isAuMobile, isEmail } from "@/helpers/validate";
import MpmTextInput from "@/components/MpmTextInput.vue";
import { isEmpty } from "validate.js";
import {
  IonContent,
  IonButton,
  IonPage,
  IonIcon,
  IonInput,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import { hasParticipants, handleFailedParticipantCheck } from "@/helpers/participants";
import { removeSpaceInStr } from "@/helpers/data-transform";
import { TrackingEvent } from "@/types/tracking-events";
import { isDesktop } from "@/directives/platform";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("layout");

const brand = process.env.VUE_APP_BRAND;

export default defineComponent({
  name: "PasswordlessLogin",
  components: {
    MpmTextInput,
    MpmLogoIcon,
    IdentIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonContent,
    IonButton,
    IonIcon,
    IonInput,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel
  },
  mounted() {
    this.updateFullScreenLoading(false);
    if (!isDesktop && Capacitor.isPluginAvailable("Keyboard")) {
      // Adjusts the bottom margin of the screen so that automatic (native) scrolling to view
      // a focused input does not end up behind the keyboard
      Keyboard.addListener("keyboardWillShow", info => {
        if (!document.activeElement.closest("ion-content").classList.contains("keyboard-open")) {
          document.activeElement.closest("ion-content").classList.add("keyboard-open");
        }
      });
      Keyboard.addListener("keyboardDidHide", () => {
        if (document.activeElement.closest("ion-content").classList.contains("keyboard-open")) {
          document.activeElement.closest("ion-content").classList.remove("keyboard-open");
        }
      });
    }
  },
  methods: {
    ...mapActions(["updateFullScreenLoading"]),
    removeSpaceInStr(v) {
      return removeSpaceInStr(v);
    },
    onClickLoginWithEmail(isSubmitting: boolean, selectedTabIndex: string) {
      return isSubmitting ? null : (this.selectedTabIndex = selectedTabIndex);
    },
    onNotificationDismiss() {
      this.form.error = "";
    },
    async onResendCodeClick() {
      if (this.passwordlessLoginStartedState.type === "sms") {
        await this.sendSMSCode();
      } else {
        await this.sendEmailCode();
      }
      this.codeResent = true;
    },
    async onResendLinkClick() {
      await this.$store.dispatch("passwordless/reset");
      this.form.data.email = "";
      this.form.data.mobile = "";
      this.form.data.code = "";
    },
    onCodeSubmit() {
      this.validateCode().then(async (validate: boolean) => {
        let method = "EMAIL";
        if (this.passwordlessLoginStartedState.type === "sms") {
          method = "SMS";
        }
        if (validate) {
          this.updateFullScreenLoading(true);
          try {
            await this.$store.dispatch("passwordless/codePasswordlessLogin", {
              email: this.form.data.email.toLowerCase(),
              code: this.form.data.code,
              channel: this.selectedTabIndex
            });
            this.maskedMobileNumber = "";

            this.$amplitude.track(TrackingEvent.Login, {
              email: this.$store.getters.user.email,
              method: method
            });
            await this.$store.dispatch("participant/getParticipants");
            if (hasParticipants()) {
              this.form.data.code = "";
              await this.$router.push(this.$route.query.redirect || "/");
            } else {
              handleFailedParticipantCheck(this.$router);
              this.updateFullScreenLoading(false);
            }
          } catch (error) {
            this.updateFullScreenLoading(false);
            let e = error;
            if (error?.response?.status === 403) {
              e = "This code has expired. Please click 'Resend Code' to receive a new code.";
            } else {
              e = "The code you have entered is invalid. Please click 'Resend Code' or 'Back to Login.'";
            }

            this.form.error = e;
            this.$notification.createToast(e, "error");
          }
          return;
        }
      });
    },
    onSubmit() {
      this.validateEmail().then(async (validate: boolean) => {
        if (validate) {
          if (this.selectedTabIndex == "email") {
            await this.sendEmailCode();
          } else {
            await this.sendSMSCode();
          }
          return;
        } else {
          console.log("not validated");
        }
      });
    },
    async sendEmailCode() {
      try {
        const result = await this.$store.dispatch(
          "passwordless/emailLinkPasswordlessLoginStart",
          this.loginCodePayload
        );
        if (result.success !== undefined && result.success === false) {
          this.isEmailInvalid = true;
        }
      } catch (error) {
        let e = error;
        if (error instanceof Error) e = error.message;
        this.form.error = e;
        this.$notification.createToast(e, "error");
      }
    },
    async sendSMSCode() {
      try {
        const result = await this.$store.dispatch(
          "passwordless/emailCodePasswordlessLoginStart",
          this.loginCodePayload
        );
        if (result) {
          this.maskedMobileNumber = result.masked_mobile_number?.toUpperCase() ?? "";
          if ((result.success !== undefined && result.success === false) || this.maskedMobileNumber === "") {
            this.form.error = `Please contact ${this.$strings.brand_name_short} to login with a mobile. Alternatively you can select Login with Email`;
            this.hasNoMobileNumber = true;
          }
        } else {
          this.hasNoMobileNumber = true;
        }
      } catch (error) {
        let e = error;
        if (error instanceof Error) e = error.message;
        this.form.error = e;
      }
    },
    redirectToLogin() {
      this.$store.dispatch("passwordless/reset");
      this.form.data.email = "";
      this.form.data.code = "";
      this.hasNoMobileNumber = false;
      this.isEmailInvalid = false;
      this.codeExpiredLabel = null;
      this.codeResent = false;
    },
    async validateEmail(): Promise<boolean> {
      this.form.error = ""; // Clear the error

      this.form.invalid.email = null;
      if (!isEmail(this.form.data.email)) {
        this.form.invalid.email = this.form.invalid.email = this.getErrorMessage();
      }

      return Object.values(this.form.invalid).find(v => v !== null) === undefined;
    },
    async validate(): Promise<boolean> {
      this.form.error = ""; // Clear the error

      this.form.invalid.mobile = null;
      if (this.selectedTabIndex === 0 && !isAuMobile(this.form.data.mobile)) {
        // SMS
        this.form.invalid.mobile = "Please enter a 10 digit number starting with 04";
      }

      this.form.invalid.email = null;
      if (this.selectedTabIndex === 1 && !isEmail(this.form.data.email)) {
        this.form.invalid.email = this.getErrorMessage();
      }

      return Object.values(this.form.invalid).find(v => v !== null) === undefined;
    },
    async validateCode(): Promise<boolean> {
      this.form.error = ""; // Clear the error

      this.form.invalid.code = null;
      if (isEmpty(this.form.data.code) || this.form.data.code.length !== 6) {
        this.form.invalid.code = "Please enter the unique code";
      }

      return Object.values(this.form.invalid).find(v => v !== null) === undefined;
    },
    getErrorMessage(): string {
      const userEmail = this.form.data.email;
      if (userEmail.length < 1) {
        return "The email address field cannot be blank. Please enter your email address and try again.";
      } else if (!userEmail.includes("@")) {
        return "Please enter a valid email address. A valid email address is similar to 'you@domain.com'";
      }
      return "Please enter a valid email address";
    }
  },
  computed: {
    ...mapGetters({
      fullScreenLoading: "getFullScreenLoading"
    }),
    smsSwitchLabel(): string {
      return this.$root.isMobileView ? "SMS" : "Log in with SMS";
    },
    emailSwitchLabel(): string {
      return this.$root.isMobileView ? "Email" : "Log in with Email";
    },
    loginCodePayload(): { email: string } {
      return { email: this.form.data.email.toLowerCase() };
    },
    disableResendCodeButton(): boolean {
      return this.isSubmitting || this.codeResent;
    },
    isSubmitting(): boolean {
      return this.$store.getters["passwordless/isSubmitting"];
    },
    passwordlessLoginStartedState(): { email: string; type: string } | { mobile: string; type: string } | null {
      return this.$store.getters["passwordless/getPasswordlessLoginStartedDetails"];
    }
  },
  data() {
    return {
      signUpUrl: process.env.VUE_APP_REGISTER_URL,
      selectedTabIndex: "sms",
      maskedMobileNumber: null,
      brand,
      form: {
        data: {
          mobile: "",
          email: "",
          code: ""
        },
        invalid: {
          mobile: null,
          email: null,
          code: null
        },
        error: ""
      },
      icons: {
        MpmLogoIcon,
        IdentIcon
      },
      codeResent: false,
      hasNoMobileNumber: false,
      isEmailInvalid: false,
      codeExpiredLabel: null
    };
  }
});
</script>

<style lang="scss" scoped>
ion-grid {
  margin: 0 1rem;
  box-shadow: $brand--login-card-box-shadow;
  --ion-grid-padding: 15px;
}
.keyboard-open {
  padding-bottom: 300px;
}
.error {
  color: $brand--login-error-color;
}
#email-error-message {
  color: $brand--login-error-color;
  font-weight: $brand--body-font-weight;
  margin-top: 0.25rem;
  height: 1rem;
}

.pay-to-radio-group {
  margin-bottom: 25px;
  legend {
    margin-bottom: 12px;
  }
  input {
    margin-right: 1rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 50%;
    height: 1.125rem;
    width: 1.125rem;
  }
  input:checked {
    background-color: #b4ec51 !important;
    border-color: #b4ec51 !important;
  }
  .radio-inputs {
    display: flex;
    align-items: center;
    margin-top: 0.375rem;
  }
  .radio-with-label {
    display: flex;
    align-items: center;
    margin: 1rem 1rem 1rem 0;
  }
  ion-segment {
    --background: #{$brand--login-radio-group-bg-color};
    border-radius: 7px;
    ion-segment-button {
      --color: #{$brand--login-radio-color-unchecked};
      --indicator-color: var(--ion-color-primary);
      --color-checked: #{$brand--login-radio-color-checked};
      ion-label {
        font-size: 0.875rem;
        font-weight: 800;
        padding: 0.5rem 0 0.5rem 0;
        line-height: 1.125rem;
        letter-spacing: 0.16px;
      }
      &:focus {
        border: 3px solid $brand--login-radio-color-checked-focus;
        border-radius: 0px;
        --border-radius: 0px;
        --indicator-transform: scale(102%, 1);
      }
    }
  }
}

.passwordless-login {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh; /* Not a perfect solution, but it guarantees that even in iphone 6 you can see in the input. */
  padding-bottom: var(--ion-safe-area-bottom, 0);
  color: $brand--login-text-color;

  @include tablet-only {
    flex-direction: row;
    justify-content: center;
    display: flex;
    align-items: stretch;
  }

  .cv-link {
    color: $brand--login-router-link;
  }

  strong {
    color: $brand--login-strong-text-color;
  }
  .back-to-login-button {
    text-transform: none;
    height: 48px;
    border-radius: 4px;
    letter-spacing: 0;
    background-color: $brand--login-back-to-login-button-color;
    color: $brand--login-back-to-login-button-text-color;
    &::part(native) {
      padding: 0 2rem;
    }
  }

  .sms-code-error {
    padding: 24px;
    margin: 0 1rem;
    background-color: $brand--login-card-background-color;
    box-shadow: $brand--login-card-box-shadow;
  }

  .mpm-title {
    display: inline !important;
  }

  .main-title {
    font-size: 1.5rem;
    margin-top: 0;
  }

  .text-center {
    text-align: center;
    strong {
      color: $brand--login-strong-text-color;
    }
  }

  .link {
    color: $brand--login-router-link;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }

  .bx--inline-notification {
    max-width: none;
  }

  .bx--inline-notification--error,
  .bx--inline-notification--error-square {
    background: #000000;
    color: #ffffff;
    border-top: solid 1px rgba(#ed3735, 0.5);
    border-bottom: solid 1px rgba(#ed3735, 0.5);
    border-right: solid 1px rgba(#ed3735, 0.5);
    ::v-deep .bx--inline-notification__close-button .bx--inline-notification__close-icon {
      fill: #ffffff;
    }
  }

  .paragraph {
    margin: 1rem 0 1rem 0;
    line-height: 1.5rem;
    color: $brand--login-text-color !important;
    a {
      text-decoration: none;
    }
  }
  .resend-button {
    background: none !important;
    --background: transparent;
    --border-style: solid;
    --border-width: 1px;
    --border-color: $brand--login-resend-button-color;
    --border-radius: 4px;
    --color: $brand--login-resend-button-color;
    --color-hover: #d0ff7e;
    --padding-start: 2rem;
    --padding-end: 2rem;
    --padding: 0.5rem 1rem;
    --background-activated: transparent;
    color: $brand--login-resend-button-color;
    min-width: 6rem;
    justify-content: center;
    height: 48px;
    text-transform: capitalize;
    &:disabled {
      border-color: #648642;
      color: #648642;
    }
    &::part(native):focus-visible {
      outline: 3px solid $brand--focus-outline;
      outline-offset: 2px;
    }
  }
  form {
    background: transparent;
    max-width: 50rem;
    ::placeholder {
      color: $brand--login-email-placeholder-color;
    }

    .submit-button {
      background: $brand--login-submit-button-color;
      width: auto;
      min-width: 6rem;
      color: #ffffff;
      justify-content: center;
      &:disabled {
        background: rgba(#4536c2, 0.25);
        border-color: rgba(#4536c2, 0.25);
      }
    }
    ::v-deep .bx--label--disabled {
      color: rgba(#ffffff, 0.5);
    }
  }

  .top-section {
    position: relative;
    // display: block;
    @include tablet-only {
      flex: 1 1 50%;
    }
    .shape {
      width: 100%;
      height: 100%;
      border-top: #b4ec51 calc(var(--ion-safe-area-top, 0) / 2) solid;

      @include tablet-only {
        border-top: 0;
      }
      .tablet {
        display: none;
        fill: $brand--login-ellipse-background;
        @include tablet-only {
          display: block;
        }
      }

      .mobile {
        display: block;
        fill: $brand--login-ellipse-background;
        @include tablet-only {
          display: none;
        }
      }
    }

    .logo {
      margin-top: calc(var(--ion-safe-area-top, 0) / 4);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 20rem;
      height: auto;
      max-width: 80vw;
      @include tablet-only {
        width: 22rem;
        max-width: 50vw;
      }
    }
  }

  .fill-container-height {
    flex-grow: 1;
  }

  .main-section {
    align-items: center;
    display: flex;
    justify-content: center;
    @include tablet-only {
      flex: 1 1 50%;
      position: relative;
    }

    ion-input {
      height: 45px;
      min-height: 36px;
      border: $brand--login-email-input-border;
      border-bottom: $brand--login-email-input-border-bottom-color;
      background-color: $brand--login-email-input-bg-color;
      width: 100%;
      --padding-start: 0.5rem;
      --padding-bottom: 0.6rem;
      --padding-top: 0.5rem;
      --highlight-color-focused: none;
      padding-left: 0.5rem;
      color: $brand--login-email-input-text-color;
      margin: 0.5rem 0 0.25rem 0;
      font-size: 0.875rem;
      font-weight: $brand--body-font-weight;
      &.has-focus {
        border: 3px solid $brand--focus-outline;
        --padding-top: 0.4rem;
      }
      .input-wrapper .label-text-wrapper .label-text {
        display: none;
      }
    }
    label {
      font-size: 0.8rem;
      line-height: 1rem;
      letter-spacing: 0.32px;
      color: $brand--login-email-address-required-label;
      display: inline-block;
      vertical-align: baseline;
      font-weight: 600;
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      max-width: 35rem;
      position: relative;
      transform: none;
      top: 0;
      left: 0;
      align-content: center;
    }
    .ident-icon-area {
      text-align: center;
      padding: 2rem;
      height: 190px;
    }
    .body-row {
      .tab-section {
        margin: 0;
      }
    }
    .footer-row {
      text-align: center;
      ion-col {
        display: flex;
        justify-content: center;
      }
      .resend-button,
      .submit-button {
        width: 100%;
      }
    }
    &.code-section {
      .footer-row {
        margin: 0;
        .resend-button,
        .submit-button {
          display: inline-flex;
          width: auto;
          min-width: 8rem;
          margin: 0 1rem 0 1rem;
        }
      }
    }
  }
  .flex-evenly-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
  }
  .router-link {
    color: $brand--login-router-link;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;

    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }
  .back-to-login {
    display: inline-block;
    margin-top: 2rem;
    text-decoration: underline;
    color: $brand--login-router-link;
    svg {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
      fill: black;
    }
    &:focus {
      border: 3px solid $brand--login-resend-button-focus-color;
      border-radius: 4px;
      box-shadow: none;
    }
    &:hover {
      background-color: $brand--login-resend-button-hover-bg;
    }
  }
  .icon-left {
    vertical-align: middle;
    font-size: 21px;
    padding-right: 8px;
  }
  .btn-primary {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    font-weight: 400;
    border-radius: 3px;
    padding: 1rem 0;
    max-width: 320px;
    font-size: 0.875rem;
    &:focus-visible {
      outline: 3px solid $brand--login-radio-color-checked-focus;
      outline-offset: 2px;
      --indicator-transform: scale(102, 1);
    }
  }
  .btn-primary:hover {
    background: $brand--button-main-hover;
    color: $brand--button-font-color;
  }
  .btn--ghost {
    background: transparent;
    max-width: 20rem;
    min-height: 3rem;
    padding: 1rem;
    border-radius: 3px;
    width: 186px;
  }
  .header-row,
  .body-row,
  .footer-row {
    padding: 15px 0;
  }
}
</style>
