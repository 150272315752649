<template>
  <ion-page>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-content>
      <section id="personal-information" aria-label="personal information">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content
            pulling-icon="svg/loading.svg"
            refreshing-spinner="circles"
            refreshing-text="Refreshing..."
          >
          </ion-refresher-content>
        </ion-refresher>
        <mpm-loading :is-loading="contentsLoading"></mpm-loading>
        <div class="personal-information modal-content">
          <button :aria-disabled="true" :disabled="true" v-show="false" tabindex="-1">Submit</button>
          <form>
            <div class="bx--row">
              <div class="bx--col">
                <h1 class="main-title">{{ modalName }}</h1>
                <p>
                  Personal details for the participant being viewed. Contact {{ $strings.brand_name_short }} to update
                  any of this information
                </p>
                <span id="form-description" v-show="false">
                  This read only form is the personal information we have on record for you.
                </span>
              </div>
            </div>
            <div class="bx--row">
              <div class="bx--col">
                <hr role="presentation" />
              </div>
            </div>
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">FIRST NAME</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.firstName"
                    label-placement="stacked"
                    class="primary-input"
                  />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">LAST NAME</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.lastName"
                    label-placement="stacked"
                    class="primary-input"
                  />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">DATE OF BIRTH</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="dateOfBirth"
                    label-placement="stacked"
                    class="primary-input short"
                  />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">NDIS NUMBER</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.ndis"
                    label-placement="stacked"
                    class="primary-input medium"
                  />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">ADDRESS</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.address"
                    label-placement="stacked"
                    class="primary-input"
                  />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">SUBURB</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.suburb"
                    label-placement="stacked"
                    class="primary-input"
                  />
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">STATE</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.state"
                    label-placement="stacked"
                    class="primary-input"
                  />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">POSTCODE</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    :value="participant.postcode"
                    label-placement="stacked"
                    class="primary-input short"
                  />
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { displayDate } from "@/helpers/data-transform";
import { defineComponent } from "vue";
import ModalHeader from "@/components/ModalHeader.vue";
import MpmTextInput from "@/components/MpmTextInput.vue";
import { IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  components: { ModalHeader, MpmTextInput, IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent },
  name: "personal-information",
  data() {
    return {
      modalName: "Personal Information"
    };
  },
  methods: {
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async loadPage() {
      this.updateContentsLoading(true);
      try {
        await this.$store.dispatch("user/refreshUserDetails");
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    participant() {
      return this.$store.getters.participant;
    },
    dateOfBirth() {
      return displayDate(this.participant.date_of_birth);
    }
  }
});
</script>
<style lang="scss" scoped>
.primary-input {
  background-color: $brand--text-input-bg-disabled;
  border-bottom: 1px solid $brand--text-input-border-color;
  color: $brand--text-input-color-placeholder;
  font-size: 0.875rem;
  font-weight: 400;
}
ion-col {
  --ion-grid-column-padding: 5px;
}

ion-label {
  font-weight: 600;
  font-size: 0.75rem;
  margin-bottom: 5px;
}

ion-input.primary-input {
  --padding-start: 10px;
  margin-top: 5px;
  min-height: 45px !important;
}

ion-input.has-focus {
  --background-focused: transparent;
  outline: 3px solid $brand--focus-outline;
}
</style>
