<template>
  <ion-page>
    <component :is="'SFUploadClaim'" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonPage } from "@ionic/vue";
import { mapGetters } from "vuex";
import SFUploadClaim from "@/components/pages/UploadClaim.vue";

export default defineComponent({
  components: {
    SFUploadClaim,
    IonPage
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading"
    })
  }
});
</script>
