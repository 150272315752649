<template>
  <ion-card class="plan-budget-spend-overview-tile">
    <h2 class="title">Your {{ supportType }} budget spend overview</h2>
    <ion-accordion-group :multiple="true" :value="accordionKeys">
      <ion-accordion
        class="budget-spend-overview-accordion"
        v-for="item in accordionContent"
        :key="item.id"
        :value="item.sfid"
      >
        <ion-item slot="header">
          <ion-label>
            {{ item.support_category.pace_support_category_name }}
          </ion-label>
        </ion-item>
        <div class="support-breakdown ion-padding" slot="content">
          <div class="breakdown-header">
            <span>Payee</span>
            <span>Amount spent</span>
          </div>
          <hr />
          <ion-row v-for="(payee, idx) in item.payees" class="breakdown-item" :key="`payee-${idx}`">
            <ion-col size="8" class="label">{{ payee.name }}</ion-col>
            <ion-col size="4" class="amount"
              >{{ $filters.currency(payee.spent) }} ({{
                ((payee.spent * 100) / item.budget_allocated).toFixed(1)
              }}%)</ion-col
            >
          </ion-row>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonRow, IonCard, IonCol, IonAccordion, IonAccordionGroup, IonItem, IonLabel } from "@ionic/vue";
import { mapGetters } from "vuex";
import { supportTypes } from "@/helpers/support-category";
import { converter } from "@/helpers/filters";
import { map } from "lodash";

export default defineComponent({
  name: "PacePlanBudgetSpendOverview",
  props: {},
  data() {
    return {};
  },
  components: {
    IonIcon,
    IonRow,
    IonCard,
    IonCol,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonLabel
  },
  computed: {
    ...mapGetters({
      planSupports: "pace/planSupports/getPlanSupports"
    }),
    accordionContent() {
      const arr = [...this.planSupports].filter(support => {
        return support.payees.length > 0;
      });
      if (arr.length > 0) {
        return map(arr, item => {
          return { ...item, active: true };
        });
      }
      return arr;
    },
    supportType() {
      return converter("route_name", this.$route.params.supportArea, "sf_name", supportTypes);
    },
    accordionKeys() {
      return this.planSupports?.map(item => item.sfid) ?? [];
    }
  }
});
</script>
<style lang="scss" scoped>
.plan-budget-spend-overview-tile {
  margin: 10px 0 10px 0;
  padding: 20px;
  background-color: $brand--pace-plan-tile-bg;

  .budget-spend-overview-accordion {
    border-radius: 4px;
    margin-top: 8px;
    background-color: $brand--pace-plan-accordion-bg;
    ion-item {
      --background: $brand--pace-plan-accordion-bg;
      --border-width: 0px;
      --ripple-color: transparent;
      &:focus {
        border: 3px solid $brand--focus-outline;
        outline: none;
      }
    }
  }
  .title {
    color: $brand--font-color-primary;
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .support-breakdown {
    background-color: $brand--pace-plan-accordion-bg;
    .breakdown-header {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        line-height: 22px;
        color: $brand--font-color-primary;
        font-weight: 600;
      }
    }
    hr {
      border-top: 1px solid $brand--pace-plan-divider-color !important;
      margin-top: 16px;
    }
    .breakdown-item {
      .label {
        letter-spacing: normal;
        font-size: 18px;
        line-height: 25px;
        color: $brand--font-color-primary;
      }
      .amount {
        display: flex;
        justify-content: end;
        font-size: 18px;
        line-height: 25px;
        color: $brand--font-color-primary;
        letter-spacing: normal;
      }
    }
  }
}
</style>
