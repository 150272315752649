<template>
  <div class="provider-claims-grid">
    <div class="collapse-all">
      <button
        class="collapse-all-button"
        lind="ghost"
        type="button"
        @click.prevent="resetExpandedRows"
        aria-label="Collapse All"
        :disabled="emptyRows()"
      >
        <span class="collapse-all-text">Collapse All</span>
      </button>
    </div>
    <mpm-data-table
      v-desktop-breakpoint-only
      isDesktopTable
      ref="table"
      :columns="desktopTableData.columns"
      :has-expand-all="desktopTableData.hasExpandAll"
      :row-expanded="desktopTableData.rowExpanded"
      :data="claims"
      :showIndicatorFunc="needAction"
      @row-expand-collapse-change="onRowExpandCollapseChange"
      class="desktop-data-table"
    >
      <template v-slot:valueCells="{ rowData, groupKey }">
        <tr class="table-rows">
          <td
            :data-previous-value="checkRow(groupKey) ? 'collapsed' : 'expanded'"
            role="gridcell"
            tabindex="0"
            aria-label="Expand/collapse detail row"
            class="bx--table-expand cell-expand"
            @click="toggleRow(groupKey, rowData)"
          >
            <div v-if="$store.getters.hasFullParticipantAccess && needAction(rowData)" class="corner"></div>
            <button
              class="expand-collapse-button"
              aria-label="Expand/collapse current row"
              :aria-expanded="checkRow(groupKey)"
            >
              <ion-icon
                aria-hidden="true"
                :src="$brand_svg_src + `/arrow-right.svg`"
                slot="icon-only"
                class="bx--table-expand__svg"
              ></ion-icon>
            </button>
          </td>
          <td :headers="`column-header--service dates`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            <span class="service-date-label">{{ rowData.serviceDateFrom }}</span>
            <template v-if="rowData.serviceDateFrom !== rowData.serviceDateTo">
              -
              <span class="service-date-label">{{ rowData.serviceDateTo }}</span>
            </template>
          </td>
          <td
            :headers="`column-header-amount`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
            :class="$root.isMobileView ? 'cell-amount' : ''"
          >
            {{ getAmount(rowData) }}
          </td>
          <td
            :headers="`column-header-status`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
            v-html="rowData.status"
          />
          <td :headers="`column-header--invoice no`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            <div>{{ rowData.reference }}</div>
            <a
              v-if="
                !rowData.protect_client_data &&
                  showViewInvoiceLink(rowData) &&
                  !(rowData.lineItems?.length < 2 && checkRow(groupKey))
              "
              class="view-invoice"
              target="_blank"
              tabindex="0"
              :href="rowData.document_link"
              aria-label="View invoice link"
              @click.prevent="openInNewTab(rowData)"
              @keydown.enter.prevent="openInNewTab(rowData)"
            >
              View Invoice
            </a>
          </td>
          <td :headers="`column-header--paid date`" :aria-labelledby="`date-header-${groupKey}`" tabindex="-1">
            {{ $filters.friendlyDate(rowData.paidDate) }}
            <claim-actions
              :needs-action="needAction(rowData)"
              :status="rowData.status"
              @approve="approve(rowData.sfid)"
              @decline="decline(rowData.sfid)"
            />
          </td>
        </tr>
      </template>
      <template v-slot:expandedContent="{ rowData, groupKey }">
        <tr
          v-if="rowData?.lineItems?.length > 1"
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td colspan="6" class="line-item-container">
            <table class="in-row-table">
              <thead>
                <tr>
                  <th class="line-items-header"><span class="">Line Item</span></th>
                  <th class="line-items-header"><span class="">Support Category</span></th>
                  <th class="line-items-header"><span class="">Amount</span></th>
                  <th class="line-items-header"><span class="">Service Dates</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lineItem, LineItemIndex) in rowData.lineItems" :key="`line-item:${LineItemIndex}`">
                  <td>
                    {{ lineItem.support_item?.name }} -
                    {{ lineItem.support_item?.code }}
                  </td>
                  <td class="support-area">
                    <div
                      :class="
                        currentPlan.pace_plan
                          ? `support-area-${
                              supportTypes.find(
                                supportType =>
                                  supportType.support_type_code ==
                                  lineItem.support_item.pace_support_category.support_type_code
                              )?.cssClass
                            }`
                          : `plan-support-category-${
                              planSupportCategoriesCodeIdMapping[
                                lineItem.support_item?.[supportCategoryPropertyLabel]?.code
                              ]
                            }`
                      "
                    >
                      {{ lineItem.support_item?.support_category[supportCategoryNamePropertyLabel] }}
                    </div>
                  </td>
                  <td>{{ $filters.currency(lineItem.total) }}</td>
                  <td>
                    {{ $filters.friendlyDate(lineItem.service_date) }} -
                    {{ $filters.friendlyDate(lineItem.service_date_to) }}
                  </td>
                </tr>
                <tr
                  v-if="!isInvoiceInCurrentPlan(rowData.lineItems)"
                  style="background: transparent !important; height: 1.5rem; border-bottom: none"
                >
                  <td></td>
                  <td colspan="5" class="line-items-note">
                    Note: This invoice is for services provided across multiple plans. These services will be funded
                    from budgets for their relevant plan.
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr
          v-else
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td></td>
          <td colspan="1" class="support-area">
            <claim-info-cell-content label="Support Category">
              <div
                :class="
                  currentPlan.pace_plan
                    ? `support-area-${
                        supportTypes.find(
                          supportType =>
                            supportType.support_type_code ==
                            rowData?.lineItems?.[0].support_item.support_category.support_type_code
                        )?.cssClass
                      }`
                    : `plan-support-category-${
                        planSupportCategoriesCodeIdMapping[
                          rowData?.lineItems?.[0].support_item[supportCategoryPropertyLabel]?.code
                        ]
                      }`
                "
              >
                {{ rowData?.lineItems?.[0].support_item.support_category[supportCategoryNamePropertyLabel] }}
              </div>
            </claim-info-cell-content>
          </td>

          <td
            colspan="2"
            :headers="`column-header--support item`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
          >
            <claim-info-cell-content label="Support Item">
              {{ rowData?.lineItems?.[0].support_item?.name }} -
              {{ rowData?.lineItems?.[0].support_item?.code }}
            </claim-info-cell-content>
          </td>

          <td colspan="2">
            <div class="invoice-action-row">
              <a
                v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData)"
                target="_blank"
                tabindex="0"
                :href="rowData.document_link"
                aria-label="View invoice link"
                @click.prevent="openInNewTab(rowData)"
                @keydown.enter.prevent="openInNewTab(rowData)"
              >
                View Invoice
              </a>
            </div>
          </td>
        </tr>
      </template>
    </mpm-data-table>
    <mpm-data-table
      v-mobile-breakpoint-only
      ref="table"
      :columns="mobileTableData.columns"
      :has-expand-all="mobileTableData.hasExpandAll"
      :row-expanded="mobileTableData.rowExpanded"
      :data="claims"
      :showIndicatorFunc="needAction"
      @row-expand-collapse-change="onRowExpandCollapseChange"
      class="mpm-mobile-data-table"
    >
      <template v-slot:valueCells="{ rowData, groupKey }">
        <tr>
          <td
            rowspan="2"
            :data-previous-value="checkRow(groupKey) ? 'collapsed' : 'expanded'"
            role="gridcell"
            tabindex="0"
            aria-label="Expand/collapse detail row"
            class="bx--table-expand cell-expand"
            @click="toggleRow(groupKey, rowData)"
          >
            <div v-if="$store.getters.hasFullParticipantAccess && needAction(rowData)" class="corner"></div>
            <button
              class="expand-collapse-button"
              aria-label="Expand/collapse current row"
              :aria-expanded="checkRow(groupKey)"
            >
              <ion-icon
                aria-hidden="true"
                :src="$brand_svg_src + `/arrow-right.svg`"
                slot="icon-only"
                class="bx--table-expand__svg"
              ></ion-icon>
            </button>
          </td>
          <td
            class="service-dates"
            :headers="`column-header-service dates`"
            :aria-labelledby="`date-header-${groupKey}`"
            tabindex="-1"
          >
            <span class="service-date-label">{{ rowData.serviceDateFrom }}</span>
            <template v-if="rowData.serviceDateFrom !== rowData.serviceDateTo">
              - <br />
              <span class="service-date-label">{{ rowData.serviceDateTo }}</span>
            </template>
          </td>
          <td
            :headers="`column-header-amount`"
            :aria-labelledby="`date-header-${groupKey}`"
            v-html="getAmount(rowData)"
            tabindex="-1"
            :class="['amount', 'currency', 'text-align-right', $root.isMobileView ? 'cell-amount' : '']"
          ></td>
        </tr>
      </template>

      <template v-slot:actionsRow="{ rowData }" style="padding: 8px 0">
        <tr class="header-row">
          <td>
            <claim-info-cell-content label="Status" class="padding-left-0">
              <div class="status" v-if="rowData.status">
                <span class="value" v-html="rowData.status"></span>
              </div>
            </claim-info-cell-content>
          </td>
          <td class="invoice-number-cell text-align-right">
            <claim-info-cell-content label="Invoice Number" :value="rowData.reference" />
          </td>
        </tr>
        <tr class="preview-row">
          <td />
          <td>
            <claim-info-cell-content v-if="rowData.paidDate" label="Paid Date" :value="`${rowData.paidDate}`" />
          </td>
          <td class="text-align-right">
            <a
              v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData) && rowData?.lineItems?.length > 1"
              target="_blank"
              tabindex="0"
              class="view-invoice"
              :href="rowData?.document_link"
              :data-row="rowData.providerId"
              aria-label="View invoice link"
              @click.prevent="openInNewTab(rowData)"
              @keydown.enter.prevent="openInNewTab(rowData)"
              >View Invoice</a
            >
          </td>
        </tr>
        <tr v-if="$store.getters.hasFullParticipantAccess && needAction(rowData)">
          <td />
          <td colspan="2">
            <claim-actions
              :needs-action="needAction(rowData)"
              :status="rowData.status"
              justify="right"
              @approve="approve(rowData.sfid)"
              @decline="decline(rowData.sfid)"
            />
          </td>
        </tr>
      </template>

      <template v-slot:expandedContent="{ rowData, groupKey }">
        <template v-if="rowData?.lineItems?.length === 1">
          <tr
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td colspan="2">
              <claim-info-cell-content
                v-if="rowData.lineItems[0].support_item"
                label="Support Item"
                :value="`${rowData.lineItems[0].support_item?.name} - ${rowData.lineItems[0].support_item?.code}`"
              />
              <claim-info-cell-content
                v-else
                label="Support Item"
                :value="`${rowData.lineItems[0].support_item?.name} - ${rowData.lineItems[0].support_item?.code}`"
              />
            </td>
          </tr>
          <tr
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td
              colspan="2"
              :class="
                `plan-support-category-${
                  planSupportCategoriesCodeIdMapping[rowData.lineItems[0].support_item.support_category.code]
                }`
              "
            >
              <div class="cell-content">
                <span class="label">Support Category</span>
                <div class="cell-body support-area">
                  <div
                    :class="
                      currentPlan.pace_plan
                        ? `support-area-${
                            supportTypes.find(
                              supportType =>
                                supportType.support_type_code ==
                                rowData?.lineItems?.[0].support_item.pace_support_category.support_type_code
                            )?.cssClass
                          }`
                        : `plan-support-category-${
                            planSupportCategoriesCodeIdMapping[
                              rowData?.lineItems?.[0].support_item[supportCategoryPropertyLabel]?.code
                            ]
                          }`
                    "
                  >
                    {{
                      rowData.lineItems[0].support_item?.[supportCategoryPropertyLabel][
                        supportCategoryNamePropertyLabel
                      ]
                    }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="!rowData.protect_client_data && showViewInvoiceLink(rowData)"
            :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
          >
            <td />
            <td colspan="2">
              <a
                :class="viewInvoiceClicked ? 'a-tag-disabled' : ''"
                target="_blank"
                tabindex="0"
                :href="rowData?.document_link"
                aria-label="View invoice link"
                @click.prevent="openInNewTab(rowData)"
                @keydown.enter.prevent="openInNewTab(rowData)"
                >View Invoice</a
              >
            </td>
          </tr>
        </template>
        <tr
          v-if="rowData?.lineItems?.length > 1"
          :class="[checkRow(groupKey) ? 'expanded' : 'collapsed', 'bx--expandable-row', 'bx--expandable-row--hidden']"
        >
          <td colspan="3" class="in-row-table-parent-cell">
            <div class="mobile-line-items">
              <table>
                <thead>
                  <tr>
                    <th class="line-items-header"><span class="">Line Item</span></th>
                    <th class="line-items-header"><span class="">Support Category</span></th>
                    <th class="line-items-header"><span class="">Amount</span></th>
                    <th class="line-items-header"><span class="">Service Dates</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="line-items-tr"
                    v-for="(lineItem, LineItemIndex) in rowData.lineItems"
                    :key="`line-item:${LineItemIndex}`"
                  >
                    <td>
                      <claim-info-cell-content
                        v-if="lineItem.support_item"
                        label="Support Item"
                        :value="`${lineItem.support_item?.name} - ${lineItem.support_item.code}`"
                      />
                      <claim-info-cell-content
                        v-else
                        label="Support Item"
                        :value="`${lineItem.support_item?.name} - ${lineItem.support_item.code}`"
                      />
                    </td>
                    <td>
                      <claim-info-cell-content
                        class="text-align-right"
                        label="Amount"
                        :value="`${$filters.currency(lineItem.total)}`"
                      />
                    </td>
                    <td>
                      <div class="cell-content">
                        <span class="label">Support Category</span>
                        <div class="cell-body support-area">
                          <div
                            :class="
                              currentPlan.pace_plan
                                ? `support-area-${
                                    supportTypes.find(
                                      supportType =>
                                        supportType.support_type_code ==
                                        rowData?.lineItems?.[0].support_item.pace_support_category.support_type_code
                                    )?.cssClass
                                  }`
                                : `plan-support-category-${
                                    planSupportCategoriesCodeIdMapping[
                                      rowData?.lineItems?.[0].support_item[supportCategoryPropertyLabel]?.code
                                    ]
                                  }`
                            "
                          >
                            {{
                              lineItem.support_item?.[supportCategoryPropertyLabel]?.[supportCategoryNamePropertyLabel]
                            }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <claim-info-cell-content
                        class="text-align-right"
                        label="Service Date"
                        :value="
                          `${$filters.friendlyDate(lineItem.service_date)} - ${$filters.friendlyDate(
                            lineItem.service_date_to
                          )}`
                        "
                      />
                    </td>
                  </tr>
                  <tr
                    v-if="!isInvoiceInCurrentPlan(rowData.lineItems)"
                    style="background: transparent !important; height: 1.5rem; border-bottom: none"
                  >
                    <td style="background: transparent !important;"></td>
                    <td colspan="5" class="line-items-note" style="background: transparent !important;">
                      Note: This invoice is for services provided across multiple plans. These services will be funded
                      from budgets for their relevant plan.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </template>
    </mpm-data-table>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import MpmDataTable from "@/components/MpmDataTable.vue";
import ClaimActions from "@/components/ClaimActions.vue";
import ClaimInfoCellContent from "@/components/ClaimInfoCellContent.vue";
import { TrackingEvent } from "@/types/tracking-events";
import { IonButton, IonIcon } from "@ionic/vue";
import { supportCategories, supportCategoriesCodeIdMapping, supportTypes } from "@/helpers/support-category";
import moment from "moment";
import { SFUIClaim } from "@/types/salesforce/claims";
import { sfGetInvoiceClaimLink } from "@/api/salesforce/claims";
import { createNamespacedHelpers, mapGetters } from "vuex";

const { mapActions } = createNamespacedHelpers("sf/claims");

export default defineComponent({
  name: "sf-provider-claims-grid",
  data() {
    return {
      expandedRows: reactive({}),
      lineItemColumns: ["Line Item", "Support Category", "Amount", "Service dates"],
      planSupportCategoriesCodeIdMapping: supportCategoriesCodeIdMapping,
      viewInvoiceClicked: false,
      supportTypes: supportTypes
    };
  },
  components: {
    ClaimActions,
    ClaimInfoCellContent,
    MpmDataTable,
    IonButton,
    IonIcon
  },
  computed: {
    ...mapGetters({
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    supportCategoryPropertyLabel() {
      return this.currentPlan.pace_plan ? "pace_support_category" : "support_category";
    },
    supportCategoryNamePropertyLabel() {
      return this.currentPlan.pace_plan ? "pace_support_category_name" : "support_category_name";
    },
    mobileTableData() {
      const columns = ["service dates", "amount"];
      return {
        columns,
        data: this.claims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    desktopTableData() {
      const columns = ["service dates", "amount", "status", "invoice no", "paid date"];
      return {
        columns,
        data: this.claims,
        rowExpanded: -1,
        hasExpandAll: false
      };
    },
    participant() {
      return this.$store.getters["participant"];
    }
  },
  props: {
    claims: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions(["loadInvoiceLineItems"]),
    emptyRows() {
      if (Object.keys(this.expandedRows).length == 0) return true;
      let isEmpty = true;

      Object.keys(this.expandedRows).forEach(key => {
        if (this.expandedRows[key] === true) {
          isEmpty = false;
        }
      });
      return isEmpty;
    },
    resetExpandedRows() {
      this.expandedRows = reactive({});
    },
    checkRow(key) {
      return this.expandedRows[key];
    },
    onRowExpandCollapseChange(rows) {
      this.$emit("row-expand-collapse-change", rows);
    },
    async toggleRow(key, rowData) {
      if (!rowData?.document_link) {
        sfGetInvoiceClaimLink(rowData.sfid, false).then(async response => {
          const { url } = response;
          if (url) {
            setTimeout(() => {
              rowData.document_link = url;
            });
          }
        });
      }
      if (!rowData?.lineItems) {
        const lineItemsResponse = await this.loadInvoiceLineItems(rowData.sfid);
        if (lineItemsResponse.length >= 1) {
          rowData.lineItems = lineItemsResponse;
        }
      }
      if (this.expandedRows[key] === undefined) this.expandedRows[key] = false;
      this.expandedRows[key] = !this.expandedRows[key];
      this.$forceUpdate();
    },
    approve(claimId: string) {
      this.$emit("approve", claimId);
    },
    decline(claimId: string) {
      this.$emit("decline", claimId);
    },
    viewInvoice() {
      this.$amplitude.track(TrackingEvent.ProviderClaimsViewInvoice, {});
    },
    needAction(claim: SFUIClaim): boolean {
      return claim.status === "Awaiting Client Approval";
    },
    showViewInvoiceLink(claim: SFUIClaim): boolean {
      return !claim.isMpm;
    },
    isLifeChoicesClaim(supportCategoryId: number): boolean {
      return supportCategoryId === supportCategories.IMPROVED_LIFE_CHOICES;
    },
    isInvoiceInCurrentPlan(lineItems) {
      const planStartDate = moment(this.$store.getters.currentPlan.start_date, "YYYY-MM-DD");
      const planEndDate = moment(this.$store.getters.currentPlan.end_date, "YYYY-MM-DD");
      let isBetweenPlan = true;
      lineItems.forEach(function(item) {
        const serviceDate = moment(item.service_date, "YYYY-MM-DD");
        if (!serviceDate.isBetween(planStartDate, planEndDate, null, "[]")) {
          isBetweenPlan = false;
        }
      });
      return isBetweenPlan;
    },
    async openInNewTab(rowData) {
      if (rowData.document_link && rowData.document_link.length > 1) {
        //for migrated claims
        console.log("Open document link");
        console.log(rowData.document_link);
        window.open(rowData.document_link, "_blank")?.focus();
      } else {
        await this.openUploadedFileLink(rowData.sfid, rowData);
      }
    },
    async openUploadedFileLink(invoiceSfId, rowData) {
      this.viewInvoiceClicked = true;
      await sfGetInvoiceClaimLink(invoiceSfId, false)
        .then(response => {
          const { url } = response;
          if (url) {
            setTimeout(() => {
              rowData.document_link = url;
              console.log("Open document link");
              console.log(url);
              window.open(url, "_blank")?.focus();
            });
          }
        })
        .catch(error => {
          const { data } = error;
          console.log(data);
        })
        .finally(() => {
          this.viewInvoiceClicked = false;
        });
    },
    getAmount(row) {
      if (row.status === "Credit Paid" || row.status === "Processing") {
        return row.amount;
      } else {
        return this.$filters.currency(row.amount_due);
      }
    }
  },
  mounted() {
    console.log(this.claims);
  }
});
</script>

<style lang="scss" scoped>
.text-align-right {
  text-align: right;
}

.support-area-core {
  background-color: $brand--pace-claim-core-support-bg;
  padding: 6px;
  border-radius: 7px;
  color: $brand--theme--text-03;
  width: fit-content;
}

.support-area-capacity {
  background-color: $brand--pace-claim-capacity-building-support-bg;
  padding: 6px;
  border-radius: 7px;
  color: $brand--theme--text-03;
  width: fit-content;
}

.support-area-capital {
  background-color: $brand--pace-claim-capital-support-bg;
  padding: 6px;
  border-radius: 7px;
  color: $brand--theme--text-03;
  width: fit-content;
}

.provider-claims-grid {
  // Styles for plan-support-category class can be found in providerClaimsGrid.scss

  .collapse-all-button {
    color: $brand--collapse-all-color;
    &:focus-visible {
      .collapse-all-text {
        outline: 3px solid $brand--focus-outline;
      }
    }
    &:hover {
      color: $brand--collapse-all-hover-color;
      background: transparent;
    }
    &[disabled] {
      color: $brand--claims-label-color;
      background: transparent;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        background: transparent;
        color: $brand--claims-label-color;
      }
    }
  }
  .mpm-data-table .expand-collapse-button {
    color: $brand--collapse-all-color;
    padding: 0;
    &:focus-visible {
      outline: none;
      ion-icon {
        outline: 3px solid $brand--focus-outline !important;
      }
    }
  }
  padding-top: 2px;
  td {
    vertical-align: top;
  }
  .a-tag-disabled {
    pointer-events: none;
    cursor: default;
  }
  .service-dates {
    padding-top: 21px; // to visually baseline with amount which has a larger font size
  }
  .preview-row {
    padding: 10px 0;
  }

  .view-invoice {
    color: $brand--view-invoice-color;
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }
  .invoice-action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: $brand--view-invoice-color;
      font-weight: $brand--body-font-weight;
      &:focus-visible {
        outline: 3px solid $brand--focus-outline;
      }
    }
  }

  .table-rows {
    td {
      font-weight: $brand--body-font-weight;
    }
  }

  a {
    color: $brand--theme--text-01;
    text-decoration: underline;
  }

  .cell-padding-right {
    padding-right: 16px;
  }

  .cell-amount {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  .in-row-table-parent-cell {
    padding: 0;
  }
  .line-item-container {
    padding: 0;
  }
  .in-row-table:hover {
    background: $brand--table-provider-table-bg !important;
  }
  .in-row-table {
    width: calc(100% + 32px);
    background: $brand--table-provider-table-bg;
    margin-left: -32px;
    tr {
      background: #2b3d5b;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    th {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      border-left: 1px solid rgba(86, 148, 251, 0);
      border-right: 1px solid rgba(86, 148, 251, 0);
      background: $brand--in-row-table-header;
      color: $brand--font-color-primary;
    }
    td {
      vertical-align: middle;
    }
    td:nth-child(1) {
      padding-left: 3rem;
    }
    tbody tr {
      background: $brand--table-provider-table-bg !important;
      border-bottom: $brand--table-provider-multi-line-tr-border-bottom !important;
      border-left: none !important;
      border-right: none !important;
    }
    thead tr .line-items-header {
      border-left: none !important;
      border-right: none !important;
      font-size: 12px;
    }
    thead tr .line-items-header:first-child {
      padding-left: 3rem;
    }
    tbody {
      background: $brand--table-td-color !important;
    }
  }
  .in-row-line-item {
    background: rgba(255, 255, 255, 0.05);
    font-size: 0.75rem;
    padding: 1rem 2rem 1rem 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .line-item-row {
    margin-bottom: 1rem;
  }

  .mobile-line-items {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      margin-bottom: 0.625em;
      border-bottom: $brand--table-provider-multi-line-tr-border-bottom !important;
    }

    table tr.line-items-tr {
      display: grid;
      grid-template-columns: 50% 50%;
      height: 100%;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 14px;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
  .mpm-data-table {
    tbody {
      background: $brand--table-td-color !important;
    }
    .column-header-service {
      vertical-align: middle;
    }

    tr td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      vertical-align: middle;
    }
    tbody tr td:nth-child(5) .cell-content {
      padding-bottom: 1rem;
    }
  }
  .padding-left-0 {
    padding-left: 0 !important;
  }
  .padding-left-2 {
    padding-left: 2rem;
  }

  .desktop-data-table {
    .bx--data-table tbody tr:hover td {
      background-color: $brand--accordion-hover !important;
      background: $brand--accordion-hover !important;
      border: none !important;
      color: $brand--font-color-primary !important;
    }
  }

  .mpm-mobile-data-table {
    .bx--data-table tbody tr:hover td {
      background: $brand--table-td-color !important;
      background-color: $brand--table-td-color !important;
    }
    .bx--data-table tbody {
      tr:hover {
        background: $brand--table-td-color !important;
        background-color: $brand--table-td-color !important;
      }
    }
  }
}
</style>
