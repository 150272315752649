<template>
  <ion-content
    id="formcontent"
    :scroll-events="true"
    tabindex="0"
    class="page-content submit-claim-tab"
    aria-label="Submit claim page content"
  >
    <section id="submit-claim" aria-label="submit claim page">
      <div style="overflow:auto" tabindex="-1">
        <modal-header v-mobile-only :modal-name="modalName" />
        <ion-row class="submit-claim">
          <ion-col size-xs="12" size-lg="5" size-xl="4">
            <div class="max-width-500">
              <div class="submit-claim-head">
                <div>
                  <div>
                    <h1 class="main-title">
                      {{ isReviewing ? `Review and Submit New Claim` : `New Claim` }}
                    </h1>
                  </div>
                </div>
                <div v-if="!isReviewing">
                  <p v-desktop-only class="description">
                    Upload an invoice to make a new claim. Choose a file or Drop a file to upload.
                  </p>
                  <p v-mobile-only class="description">
                    Upload an invoice to make a new claim. Take a photo of your invoice or choose a file to upload.
                  </p>
                </div>
                <div v-if="isReviewing">
                  <p class="description">
                    Please check that the information is correct before submitting.
                  </p>
                </div>
              </div>

              <form id="claimform" ref="submitClaimForm" novalidate v-show="!isReviewing">
                <!--      Select Pay To Section      -->
                <div class="row-spacing-sm">
                  <div>
                    <fieldset aria-label="Enter a payee option">
                      <legend>PAY TO</legend>
                      <div class="pay-to-radio-group" style="display: flex">
                        <div class="radio-input">
                          <input type="radio" id="provider" name="payTo" v-model="payTo" value="provider" />
                          <label for="provider" class="radio-label">Provider</label>
                        </div>
                        <div class="radio-input">
                          <input
                            type="radio"
                            class="radio-input"
                            id="myself"
                            name="payTo"
                            v-model="payTo"
                            value="reimbursement"
                          />
                          <label for="myself" class="radio-label">Myself (Reimbursement)</label>
                        </div>
                      </div>
                      <div class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
                        {{ errors.payTo }}
                      </div>
                    </fieldset>
                  </div>
                </div>

                <!--      File Thumbnails Section      -->
                <div v-if="uploadedFile || extraUploadedFile">
                  <div>
                    <!--      Uploaded Invoice thumbnail      -->
                    <fieldset>
                      <legend v-if="payTo === 'provider'">File</legend>
                      <legend v-else>Please provide a copy of your invoice and receipt</legend>
                      <template v-if="uploadedFile">
                        <ion-card v-mobile-only v-if="isImg(uploadedFile.type)" class="mobile-thumbnail">
                          <ion-card-header class="mobile-thumbnail-header">
                            <div class="mobile-thumbnail-header-container">
                              <div class="mobile-thumbnail-file-name">{{ uploadedFile.name }}</div>
                              <ion-icon
                                class="mobile-thumbnail-close"
                                src="svg/close-outline.svg"
                                @click.prevent="removeFile('uploadedFile')"
                              ></ion-icon>
                            </div>
                          </ion-card-header>

                          <ion-card-content
                            class="mobile-thumbnail-content"
                            :style="
                              'background: url(' + getFileUrl(uploadedFile) + ') no-repeat;background-size: 100% auto;'
                            "
                          >
                          </ion-card-content>
                        </ion-card>
                        <div v-mobile-only v-if="isPdf(uploadedFile.type)" class="mobile-thumbnail">
                          <div class="mobile-thumbnail-header">
                            <div class="mobile-thumbnail-header-container">
                              <div class="mobile-thumbnail-file-name">{{ uploadedFile.name }}</div>
                              <ion-icon
                                class="mobile-thumbnail-close"
                                src="svg/close-outline.svg"
                                @click.prevent="removeFile('uploadedFile')"
                              ></ion-icon>
                            </div>
                          </div>
                          <div class="mobile-thumbnail-content">
                            <vue-pdf-embed class="pdf-thumbnail" :source="getFileUrl(uploadedFile)" />
                          </div>
                        </div>
                        <div v-desktop-only v-if="isImg(uploadedFile.type)" class="file-thumbnail">
                          <ion-button
                            size="small"
                            class="close-action thumbnail-close-button"
                            @click.prevent="removeFile('uploadedFile')"
                          >
                            <ion-icon src="svg/close-outline.svg"></ion-icon>
                          </ion-button>
                          <div
                            class="file-thumbnail-content"
                            @click="selectToPreview(uploadedFile)"
                            :style="
                              'background: url(' + getFileUrl(uploadedFile) + ') no-repeat;background-size: 100% auto;'
                            "
                          ></div>
                        </div>
                        <div v-desktop-only v-if="isPdf(uploadedFile.type)" class="file-thumbnail">
                          <ion-button
                            size="small"
                            class="close-action thumbnail-close-button"
                            @click.prevent="removeFile('uploadedFile')"
                          >
                            <ion-icon src="svg/close-outline.svg"></ion-icon>
                          </ion-button>
                          <div class="file-thumbnail-content" @click="selectToPreview(uploadedFile)">
                            <vue-pdf-embed class="pdf-thumbnail" :source="getFileUrl(uploadedFile)" />
                          </div>
                        </div>
                      </template>
                      <!--      Uploaded Extra File thumbnail      -->
                      <template v-if="extraUploadedFile">
                        <ion-card v-mobile-only v-if="isImg(extraUploadedFile.type)" class="mobile-thumbnail">
                          <ion-card-header class="mobile-thumbnail-header">
                            <div class="mobile-thumbnail-header-container">
                              <div class="mobile-thumbnail-file-name">{{ extraUploadedFile.name }}</div>
                              <ion-icon
                                class="mobile-thumbnail-close"
                                src="svg/close-outline.svg"
                                @click.prevent="removeFile('extraUploadedFile')"
                              ></ion-icon>
                            </div>
                          </ion-card-header>

                          <ion-card-content
                            class="mobile-thumbnail-content"
                            :style="
                              'background: url(' +
                                getFileUrl(extraUploadedFile) +
                                ') no-repeat;background-size: 100% auto;'
                            "
                          >
                          </ion-card-content>
                        </ion-card>
                        <div v-mobile-only v-if="isPdf(extraUploadedFile.type)" class="mobile-thumbnail">
                          <div class="mobile-thumbnail-header">
                            <div class="mobile-thumbnail-header-container">
                              <div class="mobile-thumbnail-file-name">{{ extraUploadedFile.name }}</div>
                              <ion-icon
                                class="mobile-thumbnail-close"
                                src="svg/close-outline.svg"
                                @click.prevent="removeFile('extraUploadedFile')"
                              ></ion-icon>
                            </div>
                          </div>
                          <div class="mobile-thumbnail-content">
                            <vue-pdf-embed class="pdf-thumbnail" :source="getFileUrl(extraUploadedFile)" />
                          </div>
                        </div>
                        <div v-desktop-only v-if="isImg(extraUploadedFile.type)" class="file-thumbnail">
                          <ion-button
                            size="small"
                            class="close-action thumbnail-close-button"
                            @click.prevent="removeFile('extraUploadedFile')"
                          >
                            <ion-icon src="svg/close-outline.svg"></ion-icon>
                          </ion-button>
                          <div
                            class="file-thumbnail-content"
                            @click="selectToPreview(extraUploadedFile)"
                            :style="
                              'background: url(' +
                                getFileUrl(extraUploadedFile) +
                                ') no-repeat;background-size: 100% auto;'
                            "
                          ></div>
                        </div>
                        <div v-desktop-only v-if="isPdf(extraUploadedFile.type)" class="file-thumbnail">
                          <ion-button
                            size="small"
                            class="close-action thumbnail-close-button"
                            @click.prevent="removeFile('extraUploadedFile')"
                          >
                            <ion-icon src="svg/close-outline.svg"></ion-icon>
                          </ion-button>
                          <div class="file-thumbnail-content" @click="selectToPreview(extraUploadedFile)">
                            <vue-pdf-embed class="pdf-thumbnail" :source="getFileUrl(extraUploadedFile)" />
                          </div>
                        </div>
                      </template>
                    </fieldset>
                  </div>
                </div>

                <!--      Comment Section      -->
                <div v-if="uploadedFile && payTo === 'reimbursement'">
                  <div>
                    <fieldset v-cloak>
                      <legend>Comments</legend>
                      <ion-textarea
                        maxlength="110"
                        rows="5"
                        class="mpm-textarea"
                        @input="comment = $event.target.value"
                      ></ion-textarea>
                    </fieldset>
                  </div>
                </div>

                <!--      File Upload Section      -->
                <div
                  v-show="
                    !(
                      (payTo === 'provider' && uploadedFile) ||
                      (payTo === 'reimbursement' && uploadedFile && extraUploadedFile)
                    )
                  "
                >
                  <div>
                    <fieldset v-cloak>
                      <legend v-if="payTo === 'reimbursement' && !uploadedFile">
                        Please provide a copy of your invoice and receipt
                      </legend>
                      <legend v-if="payTo === 'provider'">Choose an option to upload an invoice</legend>
                      <legend v-if="payTo === 'reimbursement' && uploadedFile">Add another file</legend>
                      <section id="upload-document" aria-label="upload document section">
                        <div class="drop-zone" ref="drop_zone">
                          <ion-row class="row row-gap justify-space-around">
                            <ion-col size="6">
                              <button class="upload-button" @click.prevent="browsFiles">
                                <ion-icon class="upload-button-icon" :src="`${$brand_svg_src}/folder.svg`"></ion-icon>
                              </button>
                              <div class="upload-button-caption">
                                <p>Choose a file to upload</p>
                              </div>
                            </ion-col>
                            <ion-col size="6" v-mobile-only>
                              <button class="upload-button" @click.prevent="cameraUpload">
                                <ion-icon class="upload-button-icon" :src="`${$brand_svg_src}/camera.svg`"></ion-icon>
                              </button>
                              <div class="upload-button-caption">
                                <p>Take a photo of Invoice</p>
                              </div>
                            </ion-col>
                            <ion-col size="6" v-desktop-only>
                              <button class="upload-button" @click.prevent="browsFiles">
                                <ion-icon
                                  class="upload-button-icon"
                                  :src="`${$brand_svg_src}/cloud-upload.svg`"
                                ></ion-icon>
                              </button>
                              <div class="upload-button-caption">
                                <p>Drop a file here</p>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="col-100">
                              <div class="drop-zone__prompt">Only PNG, PDF and JPG files. 3MB max file size.</div>
                            </ion-col>
                          </ion-row>
                          <input
                            type="file"
                            name="myFile"
                            class="drop-zone__input"
                            ref="file_input"
                            accept="application/pdf, image/png, .jpeg, .jpg"
                            @change="handleFiles"
                          />
                          <input
                            type="file"
                            name="myFile"
                            class="cam__input"
                            ref="cam_file_input"
                            accept="application/pdf, image/png, .jpeg, .jpg"
                            capture="environment"
                            @change="handleFiles"
                          />
                        </div>
                      </section>
                    </fieldset>

                    <div class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
                      {{ errors.uploadedFile }}
                    </div>
                  </div>
                </div>

                <div>
                  <div class="form-actions center-align">
                    <ion-button v-mobile-only class="secondary-action" @click="close">Close</ion-button>
                    <div>
                      <ion-button v-desktop-only class="secondary-action" @click="cancel">Cancel</ion-button>
                    </div>
                    <div>
                      <ion-button
                        class="call-to-action"
                        :class="$root.isMobileView ? 'mobile-main-button' : 'main-button'"
                        @click="startReview()"
                        >Next</ion-button
                      >
                    </div>
                  </div>
                </div>
              </form>

              <!--      Review Claim Section      -->
              <div v-if="isReviewing" class="form-review">
                <div>
                  <fieldset>
                    <div
                      class="edit-claim-button"
                      @keyup.space="cancelReview"
                      @keyup.enter="cancelReview"
                      @click="cancelReview"
                      tabindex="0"
                    >
                      <ion-icon class="edit-claim-icon" :src="`${$brand_svg_src}/edit.svg`"></ion-icon>
                      Edit
                    </div>
                    <div class="review-item">
                      <legend>PAY TO</legend>
                      <div>{{ payTo === "provider" ? "Provider" : "Myself (Reimbursement)" }}</div>
                    </div>
                    <div v-if="payTo === 'reimbursement'" class="review-item">
                      <legend>COMMENTS</legend>
                      <div>{{ comment }}</div>
                    </div>
                    <div class="review-item">
                      <div class="file-item">
                        {{ uploadedFile.name }}
                      </div>
                      <div v-if="extraUploadedFile" class="file-item">
                        {{ extraUploadedFile.name }}
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="form-actions center-align">
                  <div class="desktop-cancel">
                    <ion-button class="secondary-action" @click="cancelReview">Cancel</ion-button>
                  </div>
                  <div class="desktop-next">
                    <ion-button
                      class="call-to-action"
                      :class="$root.isMobileView ? 'mobile-main-button' : 'main-button'"
                      @click="submitClaim"
                      >Submit Claim</ion-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </ion-col>

          <!--    Invoice Preview Section    -->
          <ion-col
            size-lg="7"
            size-xl="8"
            v-if="!isReviewing && selectedFile && isIonOverLgView"
            class="invoice-preview"
          >
            <h4>Invoice Preview</h4>
            <img v-if="isImg(selectedFile.type)" :src="getFileUrl(selectedFile)" alt="PREVIEW" class="image-preview" />
            <vue-pdf-embed v-if="isPdf(selectedFile.type)" :source="getFileUrl(selectedFile)" />
          </ion-col>
        </ion-row>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { usePhoto } from "@/helpers/camera";
import { closeModal } from "@/helpers/system";
import ModalHeader from "@/components/ModalHeader.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import VuePdfEmbed from "vue-pdf-embed";
import { isEmpty } from "lodash";
import { isIonOverLgView } from "@/helpers/screen";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonDatetime,
  IonIcon,
  IonButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonTextarea,
  IonInput,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonThumbnail,
  loadingController
} from "@ionic/vue";
import { isDesktop, isAndroid } from "@/directives/platform";
import { uploadClaim } from "@/api/salesforce/claims";
import { TrackingEvent } from "@/types/tracking-events";
import imageCompression from "browser-image-compression";

interface Content extends HTMLElement {
  scrollToTop(n: number): void;
}

interface Errors {
  payTo?: string;
  uploadedFile?: string;
  extraUploadedFile?: string;
}

interface FormInputField {
  validationType: string;
  errorMessage: string;
}

export default defineComponent({
  components: {
    IonRow,
    IonCol,
    IonGrid,
    SiteMap,
    ref,
    VuePdfEmbed,
    IonDatetime,
    IonIcon,
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonLabel,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    IonInput,
    IonThumbnail,
    ModalHeader
  },
  setup() {
    const { takePhoto, openFromLibrary } = usePhoto();
    return {
      takePhoto,
      openFromLibrary
    };
  },
  data() {
    return {
      modalName: "Submit New Claim",
      payTo: null,
      uploadedFile: null,
      extraUploadedFile: null,
      comment: "",
      maxUploadSize: 3000000,
      errors: {} as Errors,
      formInputFields: {
        pay_client: {
          validationType: "isNull",
          errorMessage: "Please select who we should pay the claim to"
        } as FormInputField
      },
      isLoading: false,
      selectedFile: null,
      isReviewing: false
    };
  },
  mounted() {
    const dropZoneElement = this.$refs.drop_zone;
    const inputElement = this.$refs.file_input;

    dropZoneElement.addEventListener("dragover", e => {
      e.preventDefault();
      dropZoneElement.classList.add("drop-zone--over");
    });

    ["dragleave", "dragend"].forEach(type => {
      dropZoneElement.addEventListener(type, () => {
        dropZoneElement.classList.remove("drop-zone--over");
      });
    });

    dropZoneElement.addEventListener("drop", e => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        inputElement.files = e.dataTransfer.files;
        this.handleFiles(null);
      }

      dropZoneElement.classList.remove("drop-zone--over");
    });
  },
  computed: {
    isIonOverLgView() {
      return isIonOverLgView(window.innerWidth);
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === "SubmitClaim") {
        this.payTo = null;
        this.uploadedFile = null;
        this.extraUploadedFile = null;
        this.selectedFile = null;
        this.isReviewing = false;
      }
    },
    payTo(val) {
      if (val !== null) {
        this.uploadedFile = null;
        this.extraUploadedFile = null;
        this.selectedFile = null;
        this.validatePayTo();
        this.comment = "";
      }
    }
  },
  methods: {
    close() {
      closeModal();
      // Clear form before closing
      this.clearSubmitClaimForm();
    },
    cancel() {
      this.$router.push({ path: "/claims" });
      // Clear form before closing
      this.clearSubmitClaimForm();
    },
    async cameraUpload() {
      if (!this.validatePayTo()) return;
      try {
        if (isAndroid) {
          const photo = await this.takePhoto();
          await this.handleFiles(
            null,
            new File([photo.data], photo.name, {
              type: photo.mediaType
            })
          );
        } else {
          this.$refs.cam_file_input.click();
        }
      } catch (e) {
        console.log(e);
        await this.$notification.createToast(e, "error");
      }
    },
    browsFiles() {
      if (this.validatePayTo()) {
        this.$refs.file_input.click();
      }
    },
    async handleFiles(event, file: File = null) {
      if (!this.validatePayTo()) return;
      this.errors = {} as Errors;

      // Check files in drop zone
      let uploadedFiles: Array<File> = Array.from(this.$refs.file_input.files);

      // Check camera files
      if (uploadedFiles.length < 1) uploadedFiles = Array.from(this.$refs.cam_file_input.files);

      // No files found
      if (uploadedFiles.length < 1 && !file) return;

      let uploadedFile: File = file
        ? file
        : new File([uploadedFiles[0]], uploadedFiles[0].name, { type: uploadedFiles[0].type });

      // clear file input
      this.$refs.file_input.value = null;
      this.$refs.cam_file_input.value = null;

      if (uploadedFile.size > this.maxUploadSize) {
        const options = {
          maxSizeMB: 2.85,
          maxWidthOrHeight: 2048,
          useWebWorker: true
        };
        try {
          console.log("Compressing file...");
          const fileName = uploadedFile.name;
          const fileType = uploadedFile.type;
          const compressedBlob = await imageCompression(uploadedFile, options);
          uploadedFile = new File([compressedBlob], fileName, { type: fileType });
        } catch (error) {
          console.log(error);
        }
      }
      if (!this.isImg(uploadedFile.type) && !this.isPdf(uploadedFile.type)) {
        this.errors.uploadedFile = "Please upload a PNG, PDF or JPG file";
        return;
      }
      if (!this.uploadedFile) {
        this.uploadedFile = uploadedFile;
      } else if (this.payTo === "reimbursement") {
        this.extraUploadedFile = uploadedFile;
      }
      if (!this.selectedFile) {
        this.selectedFile = this.uploadedFile;
      }
      console.log("File uploaded...");
      console.log(uploadedFile);
    },
    removeFile(file: string) {
      if (file === "uploadedFile") {
        this.uploadedFile = null;
      }
      if (file === "extraUploadedFile") {
        this.extraUploadedFile = null;
      }
      if (this.uploadedFile) {
        this.selectedFile = this.uploadedFile;
      } else if (this.extraUploadedFile) {
        this.selectedFile = this.extraUploadedFile;
      } else {
        this.selectedFile = null;
      }
    },
    getFileUrl(file: File) {
      return window.URL.createObjectURL(file);
    },
    validatePayTo() {
      if (!this.payTo) {
        this.errors.payTo = "Please select who we should pay the claim to.";
      } else {
        delete this.errors["payTo"];
      }
      return !!this.payTo;
    },
    validateFileUpload() {
      if (!this.uploadedFile) {
        this.errors.uploadedFile = "Please select an option to upload the file.";
      } else {
        delete this.errors["uploadedFile"];
      }
      return !!this.uploadedFile;
    },
    isImg(type: string) {
      return type === "image/jpg" || type === "image/jpeg" || type === "image/png";
    },
    isPdf(type: string) {
      return type === "application/pdf";
    },
    selectToPreview(file: File) {
      this.selectedFile = file;
    },
    startReview() {
      this.validateFileUpload();
      this.validatePayTo();
      if (isEmpty(this.errors)) {
        this.isReviewing = true;
      }
    },
    cancelReview() {
      this.isReviewing = false;
    },
    clearSubmitClaimForm() {
      this.payTo = null;
      this.uploadedFile = null;
      this.extraUploadedFile = null;
      this.comment = "";
      this.errors = {};
      this.selectedFile = null;
      this.isReviewing = false;
    },
    async submitClaim() {
      const resp = await this.handleClaim();
      if (resp?.sfid) {
        this.isSuccessful = true;
        this.$amplitude.track(TrackingEvent.SubmitClaim, {
          pacePlan: this.$store.state.currentPlan.pace_plan
        });

        // Clear form before closing
        this.clearSubmitClaimForm();

        if (isDesktop) {
          await this.$router.push({ path: "/claims" });
        } else {
          this.close();
        }
      }
    },

    async handleClaim() {
      const loading = await loadingController.create({
        message: "Processing claim...",
        cssClass: "loading-controller"
      });

      await loading.present();

      const formData = new FormData();
      formData.append("paid_to_type", this.payTo);
      formData.append("file", this.uploadedFile);
      if (this.extraUploadedFile) {
        formData.append("extra_file", this.extraUploadedFile);
      }
      if (this.comment) {
        formData.append("comment", this.comment);
      }
      const resp: any = await uploadClaim(formData)
        .then(result => {
          loading.dismiss();
          this.$notification.createToast(
            "Claim successfully submitted. Check the Uploaded Claims tab to view the claim while it is being processed. Newly uploaded claims may take a few moments to appear in the list",
            "success"
          );
          console.log(result);
          return result;
        })
        .catch(error => {
          loading.dismiss();
          console.log(error);
          // TODO handle errors
        });

      return resp;
    }
  }
});
</script>
<style lang="scss" scoped>
fieldset {
  border-style: none;
  @media only screen and (max-width: $ion--breakpoint--md) {
    padding: 0;
  }
}
fieldset legend {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
}
.submit-claim {
  background: $brand--theme--ui-background;
  padding: 1.5rem;
  .max-width-500 {
    max-width: 500px;
  }
}
.main-heading {
  flex-direction: column;
}
form {
  background: none;
  width: 100%;
  max-width: 100%;
  margin: 2rem 0;
  .invoice-detail-fields {
    margin-bottom: 1rem;
  }
}
label {
  width: 100%;
  display: inline-block;
  margin-bottom: 1.618rem;
  select {
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
  }
}
label span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: block;
}

.submit-claim-head {
  @include tablet-only {
    margin-left: 1rem;
  }
}
.description {
  margin-top: 1rem;
}

.pay-to-radio-group {
  label {
    margin-bottom: 0;
  }
}

.file-container {
  margin-top: 0.5rem;
  .selected-file {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr auto;
    grid-auto-rows: auto;
    align-items: center;
    min-height: 3rem;
    max-width: 20rem;
    background-color: #000000;
    word-break: break-word;
    .file-name {
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      margin-left: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.error-box {
  outline: 2px solid #fa4d56 !important;
  outline-offset: -2px !important;
}

.error-box-ion {
  border: 2px solid #fa4d56 !important;
}
.error-box-attachment {
  border: 2px solid #fa4d56 !important;
  border-radius: 4px !important;
}

.drop-zone {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: $brand--submit-claim-drop-zone-text-color;
  border: $brand--submit-claim-drop-zone-border;
  box-shadow: $brand--submit-claim-drop-zone-box-shadow;
  background-color: $brand--submit-claim-drop-zone-bg;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: $brand--submit-claim-drop-zone-over-border-style;
  box-shadow: $brand--submit-claim-drop-zone-over-box-shadow;
}

.drop-zone__input {
  display: none;
}

.cam__input {
  display: none;
}

.drop-zone__prompt {
  font-size: 0.75rem;
  color: $brand--submit-claim-drop-zone-prompt-color;
}

.upload-button {
  width: 100px;
  height: 100px;
  background-color: $brand--submit-claim-file-upload-bg;
  margin: auto;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: $brand--submit-claim-file-upload-box-shadow;
  &:focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
    box-shadow: none;
    .upload-button-icon {
      margin: 23px 23px 27px 23px;
    }
  }
}

.upload-button-icon {
  color: white;
  height: 45px;
  width: 45px;
  margin: 27px;
}

.upload-button-caption {
  margin-top: 20px;
  margin-bottom: 20px;
}

.upload-button-caption p {
  font-weight: 700;
  font-size: 1rem;
}

.invoice-preview {
  padding: 0 0 20px 20px;
  border-left: 2px solid $brand--submit-claim-invoice-preview-border-color;
}

.file-thumbnail {
  position: relative;
  margin-bottom: 20px;
  height: 300px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: $brand--submit-claim-drop-zone-border;
  border-radius: 10px;
}

.file-thumbnail-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.file-item {
  margin-bottom: 1rem;
  background-color: $brand--submit-claim-attachment-bg;
  max-width: 250px;
  padding: 0.5rem;
  font-size: 0.8rem;
  color: $brand--submit-claim-attachment-text-color;
  font-weight: $brand--body-font-weight;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdf-thumbnail {
  position: absolute;
  top: 0;
}

.thumbnail-close-button {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 10px;
}

.image-preview {
  width: 100%;
}

.mpm-textarea {
  margin-bottom: 20px;
  background: $brand--submit-claim-comment-area-bg;
  color: $brand--submit-claim-comment-area-text-color;
  border-color: $brand--submit-claim-comment-area-border-color;
  border-width: $brand--submit-claim-comment-area-border-width;
  border-style: $brand--submit-claim-comment-area-border-style;
}

.form-review {
  background: none;
  width: 100%;
  max-width: 100%;
  margin: 2rem 0;
}

.review-item {
  margin-bottom: 2rem;
  font-weight: 700;
}

.main-button {
  width: 216px;
}
.mobile-main-button {
  width: 140px;
}

input {
  cursor: pointer;
}

input[type="radio"]:after {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -2px;
  position: relative;
  background-color: $brand--radio-button-unchecked-bg;
  content: "";
  visibility: visible;
  border: $brand--radio-button-unchecked-border;
}

input[type="radio"]:checked:after {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -2px;
  position: relative;
  background-color: $brand--radio-button-checked-bg;
  content: "";
  visibility: visible;
  border: $brand--radio-button-checked-border;
}

label {
  margin-left: 1rem;
  margin-right: 1.5rem;
  width: auto;
  cursor: pointer;
  font-size: 0.875rem;
}

.mobile-thumbnail {
  height: 220px;
  margin: 0 0 30px 0;
  position: relative;
  border-radius: 10px;
}
.mobile-thumbnail-file-name {
  float: left;
  font-size: 0.8rem;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mobile-thumbnail-close {
  float: right;
  width: 1.2rem;
  height: 1.2rem;
}
.mobile-thumbnail-header {
  color: black;
  background-color: #c4c4c4;
  height: 36px;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}
.mobile-thumbnail-container {
  display: flex;
  justify-content: space-between;
}
.mobile-thumbnail-content {
  height: 184px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 0 0 10px 10px;
}
.edit-claim-button {
  float: right;
  color: $brand--submit-claim-edit-button-color;
  cursor: pointer;
  text-decoration: underline;
  &:focus-visible {
    outline: 3px solid $brand--focus-outline;
    padding: 5px;
    margin-top: -6px;
  }
}
.edit-claim-icon {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 3px;
}
.radio-input {
  margin: 0 0 0 1rem;
  input:focus-visible {
    outline: 3px solid $brand--focus-outline;
  }
}
.radio-label {
  margin: 0 1.5rem 0 0.5rem;
}
.row-gap {
  gap: 1rem 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.col-100 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}
.justify-space-around {
  justify-content: space-around;
}
.row-spacing-sm {
  margin: 1.5rem 0;
}
.form-requirement {
  display: block;
  color: #ff8389;
  font-weight: 400;
  overflow: visible;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;
  margin-top: 0.25rem;
  margin-bottom: 1.618rem;
  padding-left: 1rem;
}
</style>
