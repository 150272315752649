<template>
  <div
    id="filters-wrap"
    :class="`claims-filter-display ${active ? 'active' : ''}`"
    @keydown="focusTrap"
    @keydown.esc="onEscape"
  >
    <modal-header v-mobile-breakpoint-only :modal-name="modalName"></modal-header>
    <div ref="container" class="container">
      <h1 class="header-label">FILTER BY</h1>

      <h2 class="section-label">Service Date Range</h2>

      <div class="bx--row">
        <div class="bx--col">
          <date-picker
            ref="datePicker"
            @input="setDateRange($event)"
            @change="setDateRange($event)"
            v-model="form.data.dates"
            :max="form.options.maxDate"
            startDateLabel="From"
            endDateLabel="To"
          />

          <hr />
        </div>
      </div>

      <h2 class="section-label">Support Categories</h2>

      <div class="bx--row">
        <div class="bx--col">
          <button
            :class="
              `focusable filter-button ${
                (
                  form.data.supportTypes.find(supportType => supportType.name === filter.name) || {
                    cssClass: ''
                  }
                ).cssClass
              }`
            "
            v-for="(filter, idx) in form.options.supportTypes"
            :key="idx"
            @click="toggleSupportType(filter)"
          >
            {{ filter.name }}
          </button>

          <button class="focusable filter-button" @click="toggleAllSupportType()">
            All
          </button>

          <hr />
        </div>
      </div>

      <h2 class="section-label" v-show="showPaidToTypeFilter">Paid To</h2>

      <div class="bx--row" v-show="showPaidToTypeFilter">
        <div class="bx--col">
          <button
            :class="
              `focusable filter-button ${
                (
                  form.data.paidToTypes.find(paidTo => paidTo.name === filter.name) || {
                    cssClass: ''
                  }
                ).cssClass
              }`
            "
            v-for="(filter, idx) in form.options.paidToTypes"
            :key="idx"
            @click="togglePaidToType(filter)"
          >
            {{ filter.name }}
          </button>

          <button class="focusable filter-button" @click="toggleAllPaidToType()">
            All
          </button>

          <hr />
        </div>
      </div>

      <div class="actions">
        <ion-button
          class="cancel focusable"
          kind="tertiary"
          fill="outline"
          :disabled="isClearAllButtonDisabled"
          @click="onClearButtonClick"
          @keydown.enter="onClearButtonClick"
          @keydown.space="onClearButtonClick"
          :tabindex="isClearAllButtonDisabled ? -1 : 0"
        >
          Clear all
        </ion-button>
        <ion-button
          tabindex="0"
          ref="lastRef"
          class="confirm focusable"
          kind="primary"
          @click="onApplyButtonClick"
          @keydown.enter="onApplyButtonClick"
          @keydown.space="onApplyButtonClick"
        >
          Apply
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { supportTypes, sfPaidToTypes as paidToTypes } from "@/helpers/support-category";
import { IonDatetime, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from "@ionic/vue";
import { closeModal } from "@/helpers/system";
import { isMobileView } from "@/helpers/screen";
import { mapGetters, mapActions } from "vuex";

import ModalHeader from "@/components/ModalHeader.vue";
import DatePicker from "@/components/DatePicker.vue";

const AUS_DEFAULT_FORMAT = "DD-MM-YYYY";

export default {
  name: "ClaimsFilterDisplay",
  components: {
    DatePicker,
    IonDatetime,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    ModalHeader
  },
  props: {
    dates: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    },
    supportTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    paidToTypes: {
      type: Array,
      default() {
        return [];
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    showPaidToTypeFilter: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions({
      fetchSupportTypes: "pace/supportPlanTypes/fetchSupportTypes"
    }),
    dismiss() {
      if (isMobileView(window.innerWidth)) {
        closeModal();
      }
      this.$emit("dismiss");
    },
    setDateRange(event) {
      if (event) {
        if (
          dayjs(event.startDate, "YYYY-MM-DD", true).isValid() &&
          dayjs(event.startDate, "YYYY-MM-DD", true).isValid()
        ) {
          this.form.data.dates.startDate = dayjs(event.startDate, "YYYY-MM-DD").format(AUS_DEFAULT_FORMAT);
          this.form.data.dates.endDate = dayjs(event.endDate, "YYYY-MM-DD").format(AUS_DEFAULT_FORMAT);
        }
      } else {
        this.form.data.dates.startDate = null;
        this.form.data.dates.endDate = null;
      }
    },
    toggleSupportType(supportType) {
      const index = this.form.data.supportTypes.indexOf(supportType);
      if (index >= 0) {
        this.form.data.supportTypes.splice(index, 1);
      } else this.form.data.supportTypes.push(supportType);
    },
    togglePaidToType(paidToType) {
      const index = this.form.data.paidToTypes.indexOf(paidToType);
      if (index >= 0) {
        this.form.data.paidToTypes.splice(index, 1);
      } else this.form.data.paidToTypes.push(paidToType);
    },
    toggleAllSupportType() {
      this.form.data.supportTypes = [...this.form.options.supportTypes];
    },
    toggleAllPaidToType() {
      this.form.data.paidToTypes = [...this.form.options.paidToTypes];
    },
    onClearButtonClick() {
      this.$emit("update", {
        dates: null,
        supportTypes: [],
        paidToTypes: []
      });
      this.dismiss();
    },
    onApplyButtonClick() {
      let sendDates = null;
      if (
        dayjs(this.form.data.dates.startDate, AUS_DEFAULT_FORMAT, true).isValid() &&
        dayjs(this.form.data.dates.endDate, AUS_DEFAULT_FORMAT, true).isValid()
      ) {
        sendDates = {
          startDate: this.form.data.dates.startDate,
          endDate: this.form.data.dates.endDate
        };
      }

      this.$emit("update", {
        dates: sendDates,
        supportTypes: this.form.data.supportTypes,
        paidToTypes: this.form.data.paidToTypes
      });
      this.dismiss();
    },
    onEscape() {
      this.dismiss();
    },
    focusTrap(e) {
      const focusableList = document.querySelectorAll(
        "#filters-wrap button.filter-button, #filters-wrap ion-button.confirm, #filters-wrap ion-button.cancel, #filters-wrap input.duet-date__input"
      );
      if (e.key === "Tab" && e.shiftKey === false && e.target === focusableList[focusableList.length - 1]) {
        e.preventDefault();
        (focusableList[0] as HTMLElement)?.focus();
      }
      if (e.key === "Tab" && e.shiftKey === true && e.target === focusableList[0]) {
        e.preventDefault();
        this.$refs.lastRef.$el.focus();
      }
    }
  },
  computed: {
    ...mapGetters({
      planSupportTypes: "pace/supportPlanTypes/getSupportTypes"
    }),
    isClearAllButtonDisabled() {
      return (
        Object.values(this.form.data.dates).some(v => v === null) &&
        this.form.data.supportTypes.length === 0 &&
        this.form.data.paidToTypes.length === 0
      );
    }
  },
  mounted() {
    const compEl: Record<string, any> = this.$el;
    compEl.clickOutsideEventListener = (event: Event) => {
      const target = event.target;
      const el = this.$refs.container as Element;
      if (!el) {
        return;
      }
      if (this.active && !(el !== target && el.contains(target as Node)) && !isMobileView(window.innerWidth)) {
        this.dismiss();
      }
    };
    this.$root?.$el?.addEventListener("click", compEl.clickOutsideEventListener);
  },
  unmounted() {
    const compEl: Record<string, any> = this.$el;
    if (typeof compEl.clickOutsideEventListener === "function") {
      this.$root.$el.removeEventListener("click", compEl.clickOutsideEventListener);
      compEl.clickOutsideEventListener = null;
      delete compEl.clickOutsideEventListener;
    }
  },
  watch: {
    "form.data.dates": {
      deep: true,
      immediate: true,
      handler(dates) {
        if (dates === null || (dates?.startDate === null && dates?.endDate === null)) {
          this.$refs?.datePicker?.setDates(null, null);
        }
      }
    },
    "$store.getters.currentPlan": {
      deep: true,
      immediate: true,
      async handler(currentPlan) {
        // Check plan exists
        if (!currentPlan.id) return;
        let myPlanSupportTypes;
        if (!this.planSupportTypes?.length) {
          await this.fetchSupportTypes();
        }
        if (currentPlan.pace_plan) {
          myPlanSupportTypes = supportTypes.filter(supportType => {
            return this.planSupportTypes.find(st => st.name == supportType.sf_name);
          });
        } else {
          myPlanSupportTypes = supportTypes.filter(supportType => {
            if (currentPlan.support_budgets) {
              return currentPlan.support_budgets?.includes(supportType.sf_name);
            }
          });
        }
        this.form.options.supportTypes.splice(0, this.form.options.supportTypes.length, ...myPlanSupportTypes);
      }
    },
    active() {
      if (this.active) {
        this.form.data = {
          dates: this.dates ? Object.assign(this.dates) : { startDate: null, endDate: null },
          supportTypes: [...this.supportTypes],
          paidToTypes: [...this.paidToTypes]
        };
      }
    }
  },
  data() {
    return {
      modalName: "Claims Filters",
      form: {
        data: {
          dates: this.dates ? Object.assign(this.dates) : { startDate: null, endDate: null },
          supportTypes: [...this.supportTypes],
          paidToTypes: [...this.paidToTypes]
        },
        options: {
          supportTypes: [...supportTypes],
          paidToTypes: [...paidToTypes],
          maxDate: dayjs().format("YYYY-MM-DD")
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.claims-filter-display {
  display: none;
  position: relative;
  .container {
    position: absolute;
    @media screen and (max-width: $width--breakpoint--md) {
      height: 100vh;
      width: 100%;
      right: auto;
    }
    padding: 1rem;
    min-height: 25rem;
    background: $brand--filter-bg;
    box-shadow: 0.5rem 0.5rem 1rem rgba(#000000, 0.25);
    z-index: 20;
    width: 25rem;
    right: 1.5rem;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    .header-label {
      color: $brand--font-color-primary;
      font-size: 1rem;
    }
    .section-label {
      color: $brand--font-color-primary;
      font-size: 0.85rem;
      padding: 0.5rem 0 0.5rem 0;
      font-weight: $brand--body-font-weight;
    }
    .filter-button {
      display: inline-block;
      background: transparent;
      border: solid 1px $brand--filter-buttons-border;
      padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      font-size: 1rem;
      line-height: 1rem;
      height: auto;
      width: auto;
      margin: 0 0.5rem 0.5rem 0 !important;
      color: $brand--font-color-primary;
      &:focus-visible {
        outline: 3px solid $brand--focus-outline !important;
        outline-offset: 2px;
      }
      &.core {
        color: $brand--filter-select-font-color;
        background: $brand--filter-core;
      }
      &.capacity {
        color: $brand--filter-select-font-color;
        background: $brand--filter-capacity;
      }
      &.capital {
        color: $brand--filter-select-font-color;
        background: $brand--filter-capital;
      }
      &.all,
      &.provider,
      &.myself {
        color: $brand--filter-select-font-color;
        background: $brand--filter-paid-to-bg;
      }
    }
    .actions {
      padding: 1rem 1rem 2rem 1rem;
      text-align: center;
      ion-button {
        display: inline-block;
        text-align: center;
        min-width: 8rem;
        margin: 0 1.5rem 0 0 !important;
        text-transform: none;
        letter-spacing: 0;
      }
      .cancel {
        @media only screen and (max-width: 370px) {
          margin-bottom: 1.5rem !important;
        }
        box-shadow: $brand--button-filter-box-shadow;
        border-color: $brand--button-main;
        color: $brand--button-main;
        --border-width: 1px;
        &:hover:not(:disabled) {
          border-color: $brand--button-secondary-hover;
          color: $brand--button-secondary-hover;
        }
        &:focus {
          box-shadow: none;
          outline: 3px solid $brand--cancel-button-outline !important;
          outline-offset: 2px;
        }
        &[disabled] {
          opacity: 0.5;
        }
      }
      .confirm {
        box-shadow: $brand--button-filter-box-shadow;
        color: $brand--font-color-secondary;
        background-color: $brand--primary-color;
        &:focus {
          box-shadow: none;
          outline: 3px solid $brand--confim-button-outline !important;
          outline-offset: 2px;
        }
      }
    }
  }
  &.active {
    display: block;
  }
}
@media screen and (max-width: $width--breakpoint--md) {
  .claims-filter-display {
    .overlay {
      position: fixed;
      background: rgba(#000000, 0.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .container {
      width: 100%;
      left: 0;
      right: 0;
      z-index: 2;
      border-radius: 0;
    }
  }
}
</style>
