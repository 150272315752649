<template>
  <div>
    <div class="progressbar" role="progressbar" :style="`height: ${height}px`">
      <div
        class="bar-left"
        :style="{
          width: percentageSpent + '%'
        }"
        :class="{ negative: percentageSpent === 100 }"
      ></div>
      <div
        v-if="percentageSpent < 100"
        class="bar-middle"
        :style="{
          width: percentageReserved + '%'
        }"
      ></div>
      <div
        v-if="percentageSpent < 100"
        class="bar-right"
        :class="colorClass"
        :style="{
          width: percentageRemaining + '%'
        }"
      ></div>
    </div>
    <div v-if="showLabels" class="progressbar-labels">
      <ion-row class="ion-align-items-center">
        <div class="color-ball budget-spent-color-ball" :class="{ negative: percentageSpent === 100 }" />
        <div class="label">
          Spent: <span class="amount">{{ $filters.currency(spent) }} ({{ spentPercentage }}%)</span>
        </div>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <div
          class="color-ball budget-available-color-ball"
          :class="percentageSpent === 100 ? 'negative' : colorClass"
        />
        <div class="label">
          Available:
          <span class="amount"
            >{{ $filters.currency(remaining)
            }}<template v-if="remaining >= 0"> ({{ percentageRemaining }}%)</template></span
          >
        </div>
      </ion-row>
      <ion-row v-if="reserved" class="ion-align-items-center">
        <div class="color-ball reserved-budget-color-ball" />
        <div class="label">
          Reserved Budget: <span class="amount">{{ $filters.currency(reserved) }}</span>
        </div>
      </ion-row>
      <ion-row v-else style="height: 22px" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonRow, IonCol } from "@ionic/vue";
export default defineComponent({
  name: "BalanceProgressBar",
  props: {
    colorClass: {
      type: String
    },
    spent: {
      default: 0
    },
    reserved: {
      default: 0
    },
    remaining: {
      default: 0
    },
    allocated: {
      default: 0
    },
    height: {
      default: 17
    },
    showLabels: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IonRow,
    IonCol
  },
  computed: {
    percentageSpent() {
      let amount = this.spent / this.allocated;
      if (isNaN(amount)) {
        amount = 0;
      }
      return Math.round(Math.min(amount * 100, 100));
    },
    percentageReserved() {
      return Math.round((this.reserved / this.allocated) * 100);
    },
    percentageRemaining() {
      let amount = this.remaining / this.allocated;
      if (isNaN(amount)) {
        amount = 0;
      }
      return (amount * 100).toFixed(1);
    },
    spentPercentage() {
      if (this.allocated <= 0) {
        return 0;
      }
      return (100 - this.percentageRemaining).toFixed(1);
    }
  }
});
</script>
<style lang="scss" scoped>
.progressbar {
  margin: 0;
  display: flex;
  .bar-left {
    border-right: 1px solid $brand--pace-balance-bar-left-border-color;
    background-color: $brand--pace-balance-bar-left-background-color;
    &.negative {
      background-color: $brand--pace-balance-bar-negative-background-color;
    }
  }

  .bar-middle {
    border-right: 1px solid $brand--pace-balance-bar-left-border-color;
    background-color: $brand--pace-balance-bar-middle-background-color;
  }

  .bar-right {
    &.core {
      background-color: $brand--pace-core-budget-color;
    }
    &.capital {
      background-color: $brand--pace-capital-budget-color;
    }
    &.capacity {
      background-color: $brand--pace-capacity-building-budget-color;
    }
  }

  .bar-left,
  .bar-middle,
  .bar-right {
    height: 100%;
    clear: both;
  }
}

.progressbar-labels {
  margin-top: 16px;
  ion-row {
    margin-top: 8px;
  }
  .label {
    color: $brand--font-color-primary;
    font-size: 14px;
    line-height: 19px;
  }
  .amount {
    margin-left: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
  .color-ball {
    margin-right: 8px;
    width: 15px;
    height: 15px;
    border-radius: 999px;
  }
  .budget-spent-color-ball {
    background-color: $brand--pace-balance-bar-left-background-color;
    &.negative {
      background-color: $brand--pace-balance-bar-negative-background-color;
    }
  }
  .budget-available-color-ball {
    &.core {
      background-color: $brand--pace-core-budget-color;
    }
    &.capital {
      background-color: $brand--pace-capital-budget-color;
    }
    &.capacity {
      background-color: $brand--pace-capacity-building-budget-color;
    }
    &.negative {
      background-color: $brand--pace-balance-bar-negative-background-color;
    }
  }
  .reserved-budget-color-ball {
    background-color: $brand--pace-balance-bar-middle-background-color;
  }
}
</style>
