<template>
  <div class="form reserve-budget-form">
    <form @submit.prevent="submit" novalidate v-if="!isReviewing">
      <!--
        Header
      -->
      <div class="title-bar">
        <h1 class="main-title">Reserve Budget</h1>
        <p class="subtitle">Reserve budget to pay for services under this support area for any provider</p>
        <p class="required-fields">Fields marked with "*" are mandatory</p>
      </div>
      <!--
        Dates
      -->
      <date-picker
        :key="activePlan.id"
        start-date-label="Start Date *"
        :start-date-invalid-message="errors.startDate"
        end-date-label="End Date *"
        :end-date-invalid-message="errors.endDate"
        :initial-dates="{ start: activePlan.start_date, end: activePlan.end_date }"
        :min="activePlan.start_date"
        :max="activePlan.end_date"
        @input="setDateRange('input', $event)"
        @change="setDateRange('change', $event)"
        @start-date-blur="validateDates"
        @end-date-blur="validateDates"
      />
      <!--
        Provider
      -->
      <ion-row>
        <ion-col style="display: flex; gap: 0.5rem;" :style="{ 'align-items': errors.providerId ? 'center' : 'end' }">
          <div class="combobox-style" style="flex-grow: 1;">
            <ion-item class="conditional-inner-border ion-select-item">
              <ion-label position="stacked">Provider *</ion-label>
              <ion-select
                id="providerSearchField"
                tabindex="0"
                placeholder="Search by Provider Name or ABN"
                :interface-options="getInterfaceOptions('Providers', 'Choose a provider', 'display-none-ion-alert')"
                interface="alert"
                v-model="supportItem.providerId"
                :class="{ 'error-box-ion': errors.providerId }"
                @click="openProvidersList"
                @keydown.space="openProvidersList"
                @keydown.enter="openProvidersList"
                @ionChange="errors.providerId ? validate() : null"
              >
                <ion-select-option v-for="(provider, idx) in providerOptions" :key="idx" :value="provider.sfid">
                  {{ provider.label }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <div
              v-if="errors.providerId"
              class="form-requirement"
              aria-live="assertive"
              aria-relevant="additions removals"
            >
              <slot name="invalid-message">{{ errors.providerId }}</slot>
            </div>
          </div>
          <div>
            <mpm-tooltip class="provider-tooltip">
              <template v-slot:icon>
                <ion-icon class="help-icon" :src="`${$brand_svg_src}/icon-information.svg`" />
              </template>
              <template v-slot:content>
                <p>Search for a Provider by name (minimum 3 characters) or 11 digit ABN</p>
              </template>
            </mpm-tooltip>
          </div>
        </ion-col>
      </ion-row>
      <!--
        Support Item
      -->
      <ion-row>
        <ion-col
          style="display: flex; gap: 0.5rem;"
          :style="{ 'align-items': errors.supportItemId ? 'center' : 'end' }"
        >
          <div class="combobox-style" style="flex-grow: 1;">
            <ion-item class="conditional-inner-border ion-select-item">
              <ion-label position="stacked">Support Item *</ion-label>
              <ion-select
                tabindex="0"
                id="supportItemSearchField"
                placeholder="Select a Support Item"
                :interface-options="getInterfaceOptions('Support Items', 'Choose an item', 'display-none-ion-alert')"
                interface="alert"
                v-model="supportItem.supportItemId"
                :class="{ 'error-box-ion': errors.supportItemId }"
                @click="openSupportItemsList"
                @keydown.space="openSupportItemsList"
                @keydown.enter="openSupportItemsList"
                @ionChange="errors.supportItemId ? validate() : null"
              >
                <ion-select-option v-for="(item, idx) in supportCategoryItemOptions" :key="idx" :value="item.sfid">
                  {{ item.label }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <div
              v-if="errors.supportItemId"
              class="form-requirement"
              aria-live="assertive"
              aria-relevant="additions removals"
            >
              <slot name="invalid-message">{{ errors.supportItemId }}</slot>
            </div>
          </div>
          <div>
            <mpm-tooltip class="provider-tooltip">
              <template v-slot:icon>
                <ion-icon class="help-icon" :src="`${$brand_svg_src}/icon-information.svg`" />
              </template>
              <template v-slot:content>
                <p>
                  If the required Support Item cannot be found in this list please contact
                  {{ $strings.brand_name_short }}. It may be listed by the NDIA as a Stated Support under this plan.
                </p>
              </template>
            </mpm-tooltip>
          </div>
        </ion-col>
      </ion-row>
      <!--
        Price / Quantity
      -->
      <ion-row>
        <ion-col class="form-field">
          <ion-item class="conditional-inner-border text-input-item">
            <ion-label position="stacked">Price *</ion-label>
            <ion-input
              @ionChange="errors.price ? validate() : null"
              placeholder="Insert price"
              min="0.01"
              step="0.01"
              inputmode="decimal"
              class="text-input"
              :class="{ 'error-box-ion': errors.price }"
              :required="true"
              type="number"
              v-model="supportItem.price"
              :invalid="!!errors.price"
              :disabled="noSupportItemSelected"
              @click="select"
            >
            </ion-input>
          </ion-item>
          <div v-if="errors.price" class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            <div slot="invalid-message">{{ errors.price }}</div>
          </div>
        </ion-col>
        <ion-col class="form-field">
          <ion-item class="conditional-inner-border text-input-item">
            <ion-label position="stacked">Quantity *</ion-label>
            <ion-input
              @ionChange="errors.quantity ? validate() : null"
              placeholder="1"
              min="1"
              step="1"
              inputmode="numeric"
              class="text-input"
              :class="{ 'error-box-ion': errors.quantity }"
              :required="true"
              type="number"
              v-model="supportItem.quantity"
              :invalid="!!errors.quantity"
              :disabled="noSupportItemSelected"
              @click="select"
            >
            </ion-input>
          </ion-item>
          <div v-if="errors.quantity" class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            <div slot="invalid-message">{{ errors.quantity }}</div>
          </div>
        </ion-col>
      </ion-row>
      <!--
        Total
      -->
      <ion-row>
        <ion-col size-lg="6" class="form-field">
          <ion-item class="item-total conditional-inner-border">
            <ion-label position="stacked">Total</ion-label>
            <p>{{ $filters.currency(totalAmount) }}</p>
          </ion-item>
        </ion-col>
      </ion-row>
      <!--
        Service Agreement?
      -->
      <ion-row>
        <ion-col>
          <ion-item class="item-file conditional-inner-border">
            <ion-checkbox
              v-model="supportItem.locked"
              :modelValue="supportItem.locked"
              value="check-2"
              @ionChange="onServiceAgreementChange()"
              :disabled="noSupportItemSelected"
            />
            <ion-label
              class="bx--checkbox-label-text ion-text-wrap service-agreement-label"
              :class="{ 'disabled-styling': noSupportItemSelected }"
            >
              I have a Service Agreement for this Support Item
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <!--
        Locked Support Item?
      -->
      <ion-row v-if="supportItem.locked">
        <ion-col>
          <div class="upload-message">
            <strong>Service agreement *</strong><br />
            Only PDF, PNG and JPG files.<br />
            3MB max file size.
          </div>
          <mpm-file-input
            ref="file-input"
            accept="application/pdf,image/png,image/jpeg"
            :required="supportItem.locked"
            @change="fileChange"
          />
          <div class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            {{ errors.document }}
          </div>
        </ion-col>
      </ion-row>
      <!--
        Uploaded Files?
      -->
      <ion-row v-if="uploadLabel">
        <ion-col>
          <button class="remove-btn" type="button" @click="removeFile">
            <div class="button-content">
              <span>
                {{ uploadLabel }}
              </span>
              <ion-icon :src="`${$brand_svg_src}/close.svg`" />
            </div>
          </button>
        </ion-col>
      </ion-row>
      <!--
        Form Actions
      -->
      <ion-row>
        <ion-col>
          <div class="form-actions right-align">
            <ion-button class="secondary-action" type="button" @click="$emit('cancel')">Cancel</ion-button>
            <ion-button class="call-to-action" type="button" @click="submit">Next</ion-button>
          </div>
        </ion-col>
      </ion-row>
    </form>
    <!--

      REVIEW FORM

    -->
    <div class="review" v-if="isReviewing">
      <ion-row>
        <ion-col>
          <div class="title-bar split">
            <div>
              <h1 class="main-title">Review and Reserve Budget</h1>
              <span class="subtitle">Please check that the information is correct before submitting.</span>
            </div>
            <div>
              <div class="edit">
                <a href="#" @click.prevent="editForm">
                  <span><ion-icon :src="`${$brand_svg_src}/edit.svg`" /> Edit</span>
                </a>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-sm="2" size-md="4" size-xl="4">
          <label class="datelabel">
            <span>START DATE</span>
            <p>{{ formatServiceDate(supportItem.startDate) }}</p>
          </label>
        </ion-col>
        <ion-col size-sm="2" size-md="4" size-xl="4">
          <label class="datelabel">
            <span>END DATE</span>
            <p>{{ formatServiceDate(supportItem.endDate) }}</p>
          </label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="8">
          <label>
            <span>PROVIDER</span>
          </label>
          <p>{{ selectedProviderLabel }}</p>
        </ion-col>
        <ion-col size-lg="8">
          <label>
            <span>SUPPORT ITEM</span>
          </label>
          <p>{{ selectedItemLabel }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-sm="2" size-md="4">
          <label>
            <span>PRICE</span>
          </label>
          <p>{{ $filters.currency(supportItem.price) }}</p>
        </ion-col>
        <ion-col size-sm="2" size-md="4">
          <label>
            <span>QUANTITY</span>
          </label>
          <p>{{ supportItem.quantity }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-sm="2" size-md="4">
          <label>
            <span>TOTAL</span>
          </label>
          <p>{{ $filters.currency(totalAmount) }}</p>
        </ion-col>
        <ion-col class="bx--col-lg-4 bx--col-md-4 bx--col-sm-2" v-if="supportItem.document">
          <label>
            <span>SERVICE AGREEMENT</span>
          </label>
          <p>{{ supportItem.document ? `Yes` : `` }}</p>
        </ion-col>
      </ion-row>
      <ion-row v-if="supportItem.document">
        <ion-col>
          <span class="attachment">{{ supportItem.document.name }}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="form-actions right-align">
            <ion-button class="secondary-action" type="button" @click="editForm">Back</ion-button>
            <ion-button class="call-to-action" type="submit" @click="reserveBudget" :disabled="reserveButtonDisabled"
              >Reserve</ion-button
            >
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { createNamespacedHelpers } from "vuex";
import { sfReserveBudget } from "@/api/reserve-budget";
import { getSfPlanSupportCategoryItems } from "@/api/support-plan-categories";
import dayjs from "dayjs";
import validate from "@/validators/ReserveBudget";
import DatePicker from "@/components/DatePicker.vue";
import MpmCombobox from "@/components/MpmCombobox.vue";
import MpmTextInput from "@/components/MpmTextInput.vue";
import MpmFileInput from "@/components/MpmFileInput.vue";
import MpmTooltip from "@/components/MpmTooltip.vue";
import PopoverListSearch from "@/components/PopoverListSearch.vue";

import { getProvidersByKeyword } from "@/api/providers";

import {
  IonRow,
  IonCol,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonPopover,
  popoverController
} from "@ionic/vue";

const { mapState: currentPlanState } = createNamespacedHelpers("currentPlan");

export default defineComponent({
  name: "reserve-budget-form",
  components: {
    IonRow,
    IonCol,
    IonButton,
    IonCheckbox,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    DatePicker,
    MpmCombobox,
    MpmTextInput,
    MpmFileInput,
    MpmTooltip,
    IonPopover,
    popoverController
  },
  props: {
    planSupportCategoryId: { required: true },
    planSupportCategoryName: String,
    activePlan: Object
  },
  async mounted() {
    if (!this.planSupportCategoryId) {
      return this.$router.push({ path: "/plan" });
    }
    this.supportItemsList = await getSfPlanSupportCategoryItems(this.planSupportCategoryId);
  },
  data() {
    return {
      isNdsp: process.env.VUE_APP_BRAND === "ndsp",
      uploadLabel: "",
      total: 0,
      errors: {},
      isReviewing: false,
      supportItemsList: [],
      providerOptions: [],
      supportCategoryItemOptions: [],
      selectedItemLabel: "",
      selectedProviderLabel: "",
      reserveButtonDisabled: false,
      supportItem: {
        startDate: this.activePlan.start_date,
        endDate: this.activePlan.end_date,
        providerId: null,
        supportItemId: null,
        supportItemCode: null,
        price: 0,
        quantity: 1,
        locked: false,
        document: null
      }
    };
  },
  computed: {
    noSupportItemSelected(): boolean {
      return !this.supportItem.supportItemId;
    },
    ...currentPlanState({
      planId: "id"
    }),
    totalAmount() {
      if (!this.supportItem.price) {
        return "0";
      }
      if (!this.supportItem.quantity) {
        return "0";
      }
      return (this.supportItem.price * this.supportItem.quantity).toFixed(2);
    }
  },
  methods: {
    clearForm() {
      this.supportItem = {
        startDate: this.activePlan.start_date,
        endDate: this.activePlan.end_date,
        providerId: null,
        supportItemId: null,
        price: 0,
        quantity: 1,
        locked: false,
        document: null
      };
    },
    formatServiceDate(date: string) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    async validateDates() {
      await this.validate();
    },
    async setDateRange(type, event) {
      this.supportItem.startDate = event.startDate;
      this.supportItem.endDate = event.endDate;
      await this.validate();
    },
    async openProvidersList() {
      const popover = await popoverController.create({
        component: PopoverListSearch,
        componentProps: {
          valueKey: "sfid",
          initialItems: [],
          validKeywords(keywords) {
            const isNumber = parseInt(keywords);
            const length = keywords.length;
            let valid = true;
            let message = "";
            if (length < 3) {
              valid = false;
              message = "Please enter minimum 3 characters for provider name or enter 11 digit ABN.";
            } else {
              if (isNumber) {
                valid = keywords.length === 11;
                message = "Please enter full 11 digit ABN";
              }
            }
            return {
              valid,
              message
            };
          },
          makeMessage({ items, keywords, searching, keywordValidation }): string | boolean {
            let message;
            if (searching) {
              return false;
            }
            if (keywordValidation.valid) {
              if (keywords.length > 0 && items.length === 0) {
                const isNumber = parseInt(keywords);
                if (isNumber) {
                  message = `Provider not found please contact ${this.$strings.brand_name_short} for assistance.`;
                } else {
                  message = `Provider not found, search by ABN or contact ${this.$strings.brand_name_short} for assistance.`;
                }
              }
            } else {
              message = keywordValidation.message;
            }
            return message ?? false;
          },
          labelFormatter(item) {
            let label = item.name;
            if (item.abn) {
              label = `${label} - ${item.abn}`;
            }
            return label;
          },
          async searchFunction(keywords = "") {
            return (await getProvidersByKeyword(keywords))?.data;
          }
        },
        cssClass: "select-search-popover",
        keyboardClose: false
      });
      await popover.present();
      const { data } = await popover.onWillDismiss();
      if (data && data.selectedValue) {
        this.selectProvider(data.selectedValue);
      }
      await popover.onDidDismiss();
      // refs not working, use id instead
      const target = document.getElementById("providerSearchField");
      await this.$nextTick(() => target.focus());
    },
    selectProvider(provider) {
      this.providerOptions = [provider];
      this.selectedProviderLabel = provider.label;
      this.supportItem.providerId = provider?.sfid;
    },
    async openSupportItemsList() {
      const supportItemsList = this.supportItemsList;
      const popover = await popoverController.create({
        component: PopoverListSearch,
        componentProps: {
          valueKey: "sfid",
          initialItems: this.supportItemsList,
          validKeywords(keywords) {
            const length = keywords.length;
            let valid = true;
            let message = "";
            if (length > 0 && length < 3) {
              valid = false;
              message = "Please enter minimum 3 characters for support item name or code.";
            }
            console.log("Valid keywords?", valid, message);
            return {
              valid,
              message
            };
          },
          makeMessage({ items, keywords, searching, keywordValidation }): string | boolean {
            let message;
            if (searching) {
              return false;
            }
            if (keywordValidation.valid) {
              if (keywords.length > 0 && items.length === 0) {
                message = "No support items were found for those search terms.";
              }
            } else {
              message = keywordValidation.message;
            }
            return message ?? false;
          },
          labelFormatter(item) {
            let label = item.name;
            if (item.code) {
              label = `${label} - ${item.code}`;
            }
            return label;
          },
          searchFunction(keywords = "") {
            return supportItemsList.filter(item => {
              const name = item.name.toLowerCase();
              const code = item.code.toLowerCase();
              const keywordsLower = keywords.toLowerCase();
              return name.includes(keywordsLower) || code.includes(keywordsLower);
            });
          }
        },
        cssClass: "select-search-popover",
        keyboardClose: false
      });
      await popover.present();
      const { data } = await popover.onWillDismiss();
      this.selectSupportItem(data.selectedValue);
      await popover.onDidDismiss();
      // refs not working, use id instead
      const target = document.getElementById("supportItemSearchField");
      await this.$nextTick(() => target.focus());
    },
    selectSupportItem(supportItem) {
      this.supportCategoryItemOptions = [supportItem];
      this.selectedItemLabel = supportItem.label;
      this.supportItem.supportItemId = supportItem?.sfid;
      this.supportItem.supportItemCode = supportItem?.code;
    },
    editForm() {
      this.isReviewing = false;
    },
    reviewForm() {
      this.isReviewing = true;
    },
    async submit() {
      await this.validate();
      if (Object.keys(this.errors).length > 0) {
        this.$notification.createToast("Please make sure all fields have been completed", "error");
        return;
      }
      this.reviewForm();
    },
    getDates() {
      const start = document.getElementById("startDate") as HTMLInputElement;
      const end = document.getElementById("endDate") as HTMLInputElement;
      return {
        startDate: start.value,
        endDate: end.value
      };
    },
    async validate() {
      this.errors = await validate(
        { ...this.supportItem, ...this.getDates() },
        this.supportItem.locked,
        this.activePlan
      );
    },
    async reserveBudget() {
      this.reserveButtonDisabled = true;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const $self = this;
      await sfReserveBudget(this.supportItem, this.planSupportCategoryId)
        .then(function(response) {
          if (response.data.code === 200) {
            $self.$notification.createToast(
              `A budget of ${$self.$filters.currency($self.totalAmount)} has been successfully reserved for ${
                $self.selectedItemLabel
              }`,
              "success"
            );
            $self.$emit("success");
          }
        })
        .catch(function(error) {
          if (error.response) {
            let errorMessage = error.response.data.message;
            if (error?.response?.data?.errors?.total?.[0]) {
              errorMessage = error.response.data.errors.total[0];
            } else if (error?.response?.data?.errors?.support_item_number?.[0]) {
              errorMessage = error.response.data.errors.support_item_number[0];
            } else if (error?.response?.data?.errors?.end_date?.[0]) {
              errorMessage = error.response.data.errors.end_date[0];
            } else if (error?.response?.data?.errors?.start_date?.[0]) {
              errorMessage = error.response.data.errors.start_date[0];
            }
            $self.$notification.createToast(errorMessage, "error");
            $self.$emit("error", error);
          }
        })
        .finally(function() {
          $self.reserveButtonDisabled = false;
        });
    },
    fileChange(e: any) {
      const { files } = e.target;
      this.uploadLabel = files[0].name;
      this.supportItem.document = files[0];
    },
    removeFile() {
      this.$refs["file-input"].clear();
      this.uploadLabel = "";
      this.supportItem.document = null;
    },
    getInterfaceOptions(alertHeader: string, alertSubHeader: string, cssClass?: string) {
      return {
        header: alertHeader,
        subHeader: alertSubHeader,
        cssClass: "select-alert " + cssClass,
        mode: "md",
        htmlAttributes: { useless: true }
      };
    },
    onServiceAgreementChange() {
      if (!this.supportItem.locked) {
        this.removeFile();
      }
    },
    select(event: any) {
      if (event.target.select) {
        // tapped directly on html input
        event.target.select();
      } else if (event.target.children[0].select) {
        // tapped on the wrapper
        event.target.children[0].select();
      }
    }
  }
});
</script>
<style lang="scss">
$--remove-agreement-btn-bg: $brand--reserve-budget-attachment-bg;

.select-search-popover {
  --width: 80%;
  --max-width: 90%;
  --height: 80%;
  --background: #{$brand--global-nav-bg};
  --backdrop-opacity: 0.55;
  @include tablet-only {
    --width: 50%;
    --height: 50%;
  }
}

.popover-viewport {
  height: 100%;
}

.ion-select-item.ion-focused {
  --background-focused: transparent;
  outline: 3px solid $brand--focus-outline;
}

form {
  .form-requirement {
    display: block;
    color: $brand--form-requirement-color;
    font-weight: $brand--form-requirement-font-weight;
    overflow: visible;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.32px;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.display-none-ion-alert {
  // hide the default alert for ion-select
  display: none;
}

.form {
  .title-bar {
    margin-bottom: 2rem;
    &.split {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
  }

  .main-title,
  .subtitle,
  .required-fields {
    margin-bottom: 0.5rem;
  }

  .required-fields {
    color: $brand--reserve-budget-required-fields-text-color;
    font-size: 0.75rem;
  }
}

.provider-tooltip {
  .button {
    width: 40px;
    height: 40px;
  }

  .help-icon {
    background: $brand--help-icon-bg;
    border: 0;
    border-radius: 11px;
    height: 24px;
    width: 24px;
  }
}

.reserve-budget-form {
  padding: 2rem;
  max-width: 720px;

  ion-item {
    --background: transparent;
    &::part(native) {
      border-width: $brand--reserve-budget-item-border-width;
    }
  }

  ion-item:not(.text-input-item) {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
  }

  ion-item.text-input-item {
    &::part(native) {
      padding-left: 0;
    }
    ion-input {
      --placeholder-color: #{$brand--text-input-color-placeholder};
      --placeholder-opacity: 1;
      &[aria-disabled="true"] {
        --color: #{$brand--text-input-color-disabled};
        --background: #{$brand--text-input-bg-disabled};
        border-color: $brand--text-input-border-color-disabled;
      }
    }
    .text-input input {
      --padding-start: 10px;
      --padding-end: 10px;
      border-width: $brand--text-input-border-width;
      border-color: $brand--text-input-border-color;
      border-style: $brand--text-input-border-style;
      border-radius: $brand--text-input-border-radius;
      height: 45px;
      color: $brand--text-input-color;
      font-weight: $brand--text-input-font-weight;
      background-color: $brand--text-input-bg;
      &[disabled] {
        color: $brand--text-input-color-disabled;
        background-color: $brand--text-input-bg-disabled;
        border-color: $brand--text-input-border-color-disabled;
      }
      &:focus,
      &:active {
        outline: $brand--text-input-outline-focus;
        outline-offset: -3px;
      }

      &::placeholder {
        color: $brand--text-input-color-placeholder;
      }
    }
  }

  ion-item > ion-label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    display: block;
    margin-bottom: 12px !important;
  }

  ion-item ion-select {
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-top: 12px;
    --padding-bottom: 12px;
    border: $brand--reserve-budget-select-border;
    border-width: $brand--reserve-budget-select-border-width;
    border-radius: $brand--reserve-budget-select-border-radius;
    background-color: $brand--reserve-budget-select-bg;
    &::part(placeholder),
    &::part(text) {
      padding-left: $brand--reserve-budget-select-padding-left;
      white-space: pre-wrap;
    }
    &::part(icon) {
      padding-right: $brand--reserve-budget-select-icon-padding-right;
      opacity: $brand--plan-dropdown-icon-opacity-mobile;
      margin-left: 0.3rem;
      color: transparent;
      background: url("../../../assets/icons/#{$brand}-chevron-down-outline.svg") no-repeat center;
      overflow: visible;
      background-size: 25px 25px;
      width: 30px;
      height: 30px;
      margin-bottom: -25px;
    }
  }

  div > ion-label {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.32px;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .error-box-ion {
    border-width: $brand--reserve-budget-fields-required-border-width;
    border-color: $brand--reserve-budget-fields-required-border-color;
    border-style: $brand--reserve-budget-fields-required-border-style;
    input {
      border-width: 0 !important;
    }
  }

  .horizontal-checkboxes {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .remove-btn {
    border-radius: 3px;
    margin: 25px 0;
    width: 290px;
    padding: 11px 15px;
    color: $brand--theme--text-01;
    background-color: $--remove-agreement-btn-bg;
    &:hover {
      color: $brand--theme--text-01;
      background-color: darken($--remove-agreement-btn-bg, 5%);
    }
  }
  .button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      font-size: 1rem;
      text-align: left;
      word-break: break-all;
      flex-basis: 85%;
    }
  }
  .upload-message {
    font-size: 12px;
    margin: 1rem 0;
    strong {
      text-transform: uppercase;
      line-height: 1.5rem;
    }
  }
  .review {
    max-width: 800px;
    .edit {
      margin-right: 0;
      a {
        padding: 0.75rem 1rem 0.75rem 0.75rem;
        border-radius: 3px;
      }
      span {
        color: $brand--reserve-budget-review-edit-color;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        text-align: right;
        text-decoration-line: underline;
        ion-icon {
          height: 1.5rem;
        }
        svg {
          margin-right: 6px;
          path {
            stroke: $brand--reserve-budget-review-edit-color;
          }
        }
      }
    }
    label {
      color: $brand--reserve-budget-review-label-color;
      margin-bottom: 0.5rem;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: 16px;
    }
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      text-align: left;
      color: $brand--font-color-primary;
    }
    .bx--row [class*="bx--col-"] {
      padding-bottom: 1.5rem;
    }
    .attachment {
      background: $brand--reserve-budget-attachment-bg;
      display: inline-block;
      vertical-align: middle;
      padding: 11px 15px;
      color: $brand--reserve-budget-review-attachment-color;
    }
  }
  .service-agreement-label {
    padding-left: 10px;
    text-transform: unset !important;
    &.disabled-styling {
      opacity: $brand--reserve-budget-checkbox-label-disabled-opacity;
      color: $brand--reserve-budget-checkbox-label-disabled-color;
    }
  }
}
</style>
