<template>
  <section
    :class="[
      'plan-support-area',
      {
        'expand-full':
          $store.state.supportItems.expandSupport && $store.getters.supportItems.collapseSupport && !$root.isMobileView
      }
    ]"
  >
    <div class="row" v-if="!$store.getters.supportItems.expandSupport">
      <div class="support-header">
        <a
          aria-label="expand support area"
          tabindex="0"
          class="icon"
          @click.prevent="$store.dispatch('supportItems/expandSupportArea', true)"
        >
        </a>
        <h3 v-if="!$store.getters.supportItems.expandSupport">
          {{ categoryName }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div :class="['col-lg-6', { 'collapse-full': !$store.getters.supportItems.expandSupport }]">
        <spend-summary v-desktop-breakpoint-only class="spend-summary" :budget="budget" />
        <div class="chart">
          <div class="pie">
            <data-chart :total="budget.allocated" :supports="planSupportItems" v-if="categoryId" />
          </div>
        </div>
        <a
          v-if="!$store.getters.isSfClaimingExperience"
          class="plan-support-link"
          @click.prevent="openPlanSupportCategoryDescriptionModal(categoryId)"
        >
          <p>What can be claimed under this support area?</p>
        </a>
        <button
          v-if="!$store.getters.isSfClaimingExperience && canAddSupportItem"
          role="button"
          class="reserve-budget-button"
          tabindex="0"
          @click="openAddSupportItemModal"
        >
          Add Support Item
        </button>
        <button
          v-if="$store.getters.isSfClaimingExperience && canReserveBudget"
          role="button"
          class="reserve-budget-button"
          tabindex="0"
          @click="openReserveBudgetModal"
        >
          <ion-icon src="svg/icon-lock-black.svg" />Reserve Budget
        </button>
      </div>
    </div>
    <div :class="['col-lg-6', { 'collapse-full': !$store.getters.supportItems.expandSupport }]">
      <AllocatedBudget v-if="budget.allocated > 0" :total="budget.allocated" />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isDesktop } from "@/directives/platform";
import { openModal } from "@/helpers/system";
import AllocatedBudget from "@/components/plans/support-items/AllocatedBudget.vue";
import UnAllocatedBudget from "@/components/plans/support-items/UnAllocatedBudget.vue";
import DataChart from "@/components/plans/Donut.vue";
import ProgressBar from "@/components/plans/ProgressBar.vue";
import SpendSummary from "@/components/plans/support-items/SpendSummary.vue";
import PlanSupportCategoryDescription from "@/components/plans/support-items/PlanSupportCategoryDescription.vue";
import AddSupportItem from "@/views/AddSupportItem.vue";
import ReserveBudget from "@/views/ReserveBudget.vue";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "PlanSupportArea",
  components: {
    AllocatedBudget,
    UnAllocatedBudget,
    DataChart,
    ProgressBar,
    SpendSummary,
    PlanSupportCategoryDescription,
    IonIcon
  },
  computed: {
    categoryName() {
      return this.$store.getters.supportPlanCategory?.support_category?.support_category_name;
    },
    canAddSupportItem() {
      return (
        this.$store.getters.hasFullParticipantAccess &&
        this.$store.getters.supportPlanCategory.managed === "plan" &&
        this.categoryId !== 14
      );
    },
    canReserveBudget() {
      return this.$store.getters.hasFullParticipantAccess;
    },
    budget() {
      return {
        unallocated: this.$store.getters.supportPlanCategory.budget_unallocated,
        allocated: this.$store.getters.supportPlanCategory.budget_allocated,
        spent: this.$store.getters.supportPlanCategory.budget_spent,
        left: this.$store.getters.supportPlanCategory.budget_left
      };
    },
    categoryId() {
      return this.$store.getters.supportPlanCategory.support_category?.id;
    },
    planSupportCategoryId() {
      return this.$store.getters.supportPlanCategory.id;
    },
    planSupportCategorySfId() {
      return this.$store.getters.supportPlanCategory.sfid;
    },
    planSupportItems() {
      return this.$store.getters.supportItems.supportItems;
    }
  },
  methods: {
    async openPlanSupportCategoryDescriptionModal(categoryId) {
      openModal(PlanSupportCategoryDescription, {
        cssClass: "app-modal",
        componentProps: {
          categoryId
        }
      });
    },
    async openAddSupportItemModal() {
      const categoryId = this.categoryId;
      const planSupportCategoryId = this.planSupportCategoryId;
      openModal(AddSupportItem, {
        componentProps: {
          categoryId,
          planSupportCategoryId
        }
      });
    },
    async openReserveBudgetModal() {
      const categoryId = this.categoryId;
      const planSupportCategoryId = this.planSupportCategorySfId;
      if (isDesktop) {
        this.$router.push({ name: "ReserveBudgetTab" });
      } else {
        openModal(ReserveBudget, {
          componentProps: {
            categoryId,
            planSupportCategoryId
          }
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.reserve-budget-button {
  background-color: $brand--button-main;
  color: $brand--button-font-color;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  text-align: center;
  cursor: pointer;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  ion-icon {
    width: 16px;
  }
  &:hover {
    background-color: $brand--button-main-hover;
  }
  &:focus-visible {
    outline: 3px solid $brand--focus-outline-button;
    outline-offset: 2px;
  }
}

.pie {
  padding: 1rem;
}
</style>

<style lang="scss">
ul li {
  list-style: none;
}
.spend-summary {
  .title,
  .budget,
  .progress {
    color: $brand--font-color-primary;
  }
  box-shadow: $brand--plan-support-tiles-box-shadow;
  .progress {
    height: 10px;
    .progressbar {
      height: 10px;
      overflow: hidden;
      background-color: $brand--spend-summary-progressbar-bg;
    }
    .progressbar .bar {
      height: 10px !important;
    }
  }
}

.plan-support-link p {
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  text-decoration-line: underline;
  color: #8fb8ff;
  margin-left: 28px;
  padding-bottom: 30px;
  margin-right: 28px;
}

.plan-support-link:hover {
  cursor: pointer;
}

#doughnut-data-chart {
  width: 100% !important;
  height: auto;
}
.icon:hover {
  cursor: pointer;
}

.plan-support-area {
  display: block;
  max-width: 650px;
  width: 100%;
  margin-bottom: 100px;

  &.expand-full {
    @include large-tablet-only {
      display: flex;
    }
  }
  h3 {
    display: inline;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 38px;
    padding-right: 5rem;
  }

  .icon {
    height: 18px;
    width: 18px;
    display: block;
    float: right;
  }

  .icon svg:hover {
    transform: scale(1.05);
  }

  .unallocated-budget-description {
    max-width: $brand-support-chart-max-width;
    margin: 30px auto 0;
    padding-bottom: 5rem;
  }
}

.expand {
  @media only screen and (min-width: $brand-support-drawer-breakpoint) {
    margin: 0;
    max-width: 100%;
    .allocated-budget {
      margin-top: 29px;
      max-width: 455px;
    }
    .chart {
      max-width: $brand-support-chart-max-width;
    }
    .pie {
      /*temp pie container*/
      height: 433px;
      margin-bottom: 49.21px;
      margin-top: 38px;
      width: 100%;

      .action {
        width: 50%;
        display: inline-block;
        text-align: center;
        margin-top: 20rem;
      }
    }
  }
}

.active.collapsed:not(.expand):nth-child(2) {
  max-width: unset;
}

.active.collapsed {
  .collapse-full {
    max-width: 100%;
    flex: unset;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
  }
  .col-lg-6 {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @media only screen and (min-width: 66rem) {
    .col-lg-6 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.active.collapsed.expand {
  .plan-support-area {
    margin-left: 0;
  }

  .collapse-full {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.collapsed {
  @media only screen and (min-width: 66rem) {
    .allocated-budget,
    .unallocated-budget {
    }
  }
  @media only screen and (min-width: $brand-support-drawer-breakpoint) {
    .expand-full {
      @include large-tablet-only {
        .chart {
          padding: 0;
          .pie {
            margin-left: 0;
          }
        }
        .allocated-budget,
        .unallocated-budget {
          margin-top: 15px;
          padding: 0;
          max-width: $brand-support-chart-max-width;
          float: right;
          width: 100%;
        }
        .col-lg-6:first-child {
          max-width: 460px;
          margin-right: 40px;
          min-width: 100%;
        }
      }
    }
    margin: 0;
    max-width: 100%;
    .support-header {
      width: 100%;
      margin: 0 auto 0;
      max-width: $brand-support-chart-max-width;
      .icon {
        height: 18px;
        width: 18px;
        float: right;
      }
    }

    .allocated-budget {
      margin-top: 29px;
      padding: 0 5px 5rem 5px;
    }

    .chart {
      min-width: 100%;
      margin-bottom: 28px;
      box-shadow: $brand--plan-support-tiles-box-shadow;
      background: $brand--plan-support-pie-chart-section-bg;
      .pie {
        /*temp pie container*/

        margin: 0 auto;
        max-width: $brand-support-chart-max-width;
        .action {
          width: 50%;
          display: inline-block;
          text-align: center;
          padding: 1.5rem;
        }
        .action:hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: $brand-support-drawer-breakpoint) {
  .chart {
    margin-bottom: 32px;
    margin-top: 0;
    .pie {
      width: 100%;
      margin: 0 auto;
    }
  }
  .active.collapsed:not(.expand):nth-child(2) .plan-support-area {
    margin-left: 5px;
    .support-header {
      display: none;
    }
  }
  .active.collapsed:has(.expand-full):nth-child(2) .plan-support-area {
    .row {
      margin-left: 0;
      margin-right: 0;

      .col-lg-6 {
        padding: 0;
      }
    }
  }

  .plan-support-area {
    .allocated-budget {
      margin-top: 2rem;
      padding: 0 6px;
    }
  }
}
</style>
