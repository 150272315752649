<template>
  <div class="add-support-item-form">
    <form @submit.prevent="submit" novalidate v-if="!isReviewing">
      <div class="bx--row">
        <div class="bx--col">
          <h1 class="main-title">Add Support Item</h1>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col">
          <p class="sub-title">Fields marked with "*" are mandatory</p>
        </div>
      </div>
      <div class="bx--row pay-to-radio-group-col">
        <div class="bx--col-xlg-9">
          <fieldset
            class="managed-fieldset"
            :aria-invalid="!!errors.type"
            aria-label="How is this support item managed?"
          >
            <div class="pay-to-radio-group">
              <legend>How is this support item managed? *</legend>
              <ion-segment
                :class="{ 'error-box-ion': errors.type }"
                :value="supportItem.type"
                v-model="supportItem.type"
                @ionChange="Object.keys(errors).length ? validate() : null"
              >
                <ion-segment-button mode="ios" value="provider">
                  <ion-label>Provider</ion-label>
                </ion-segment-button>
                <ion-segment-button mode="ios" value="self">
                  <ion-label>Myself</ion-label>
                </ion-segment-button>
              </ion-segment>
            </div>
            <div v-if="errors.type" class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
              {{ errors.type }}
            </div>
          </fieldset>
        </div>
      </div>

      <div class="bx--row">
        <div class="bx--col combobox-style">
          <ion-item>
            <ion-label v-if="providerRequired" position="stacked">Provider *</ion-label>
            <ion-label v-else position="stacked">Provider</ion-label>
            <ion-select
              placeholder="Enter a Provider"
              :interface-options="getInterfaceOptions('Providers', 'Choose a provider')"
              interface="alert"
              v-model="supportItem.providerId"
              :disabled="!providerRequired"
              :class="{ 'error-box-ion': errors.providerId }"
              @ionChange="errors.providerId ? validate() : null"
            >
              <ion-select-option v-for="(provider, idx) in providerOptions" :key="idx" :value="provider.value">
                {{ provider.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            v-if="errors.providerId"
            class="form-requirement"
            aria-live="assertive"
            aria-relevant="additions removals"
          >
            <slot name="invalid-message">{{ errors.providerId }}</slot>
          </div>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col combobox-style">
          <ion-item>
            <ion-label position="stacked">Support Item *</ion-label>
            <ion-select
              @ionChange="errors.supportCategoryItemId ? validate() : null"
              @click="openPopover"
              placeholder="Select a Support Item"
              :interface-options="getInterfaceOptions('Support Items', 'Choose an item', 'display-none-ion-alert')"
              interface="alert"
              v-model="this.supportItem.supportCategoryItemId"
              :class="{ 'error-box-ion': errors.supportCategoryItemId }"
            >
              <ion-select-option v-for="(item, idx) in supportCategoryItemOptions" :key="idx" :value="item.value">
                {{ item.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            v-if="errors.supportCategoryItemId"
            class="form-requirement"
            aria-live="assertive"
            aria-relevant="additions removals"
          >
            <slot name="invalid-message">{{ errors.supportCategoryItemId }}</slot>
          </div>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col form-field">
          <ion-item>
            <ion-label position="stacked">Price *</ion-label>
            <ion-input
              @ionChange="errors.price ? validate() : null"
              placeholder="Insert price"
              min="0.01"
              step="0.01"
              inputmode="decimal"
              class="text-input"
              :class="{ 'error-box-ion': errors.price }"
              :required="true"
              type="number"
              v-model="supportItem.price"
              :invalid="!!errors.price"
            >
            </ion-input>
          </ion-item>
          <div v-if="errors.price" class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            <div slot="invalid-message">{{ errors.price }}</div>
          </div>
        </div>

        <div class="bx--col form-field">
          <ion-item>
            <ion-label position="stacked">Quantity *</ion-label>
            <ion-input
              @ionChange="errors.quantity ? validate() : null"
              placeholder="1"
              min="1"
              step="1"
              inputmode="numeric"
              class="text-input"
              :class="{ 'error-box-ion': errors.quantity }"
              :required="true"
              type="number"
              v-model="supportItem.quantity"
              :invalid="!!errors.quantity"
            >
            </ion-input>
          </ion-item>
          <div v-if="errors.quantity" class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            <div slot="invalid-message">{{ errors.quantity }}</div>
          </div>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-6 form-field">
          <ion-item>
            <ion-label position="stacked">Total</ion-label>
            <ion-input class="text-input" aria-readonly="true" readonly="true" :value="totalAmount"> </ion-input>
          </ion-item>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col">
          <ion-item>
            <ion-checkbox
              v-model="supportItem.locked"
              :modelValue="supportItem.locked"
              value="check-2"
              @ionChange="onServiceAgreementChange()"
            ></ion-checkbox>
            <ion-label class="bx--checkbox-label-text ion-text-wrap"
              >I have a service agreement for this support item</ion-label
            >
          </ion-item>
        </div>
      </div>
      <div v-if="supportItem.locked" class="bx--row">
        <div class="bx--col">
          <div class="upload-message">
            <strong> Service agreement * </strong><br />Only PDF and JPG files. <br />3MB max file size.
          </div>

          <mpm-file-input :required="supportItem.locked" ref="file-input" @change="fileChange"></mpm-file-input>
          <div class="form-requirement" aria-live="assertive" aria-relevant="additions removals">
            {{ errors.document }}
          </div>
        </div>
      </div>

      <!--    &lt;!&ndash; files rendered here &ndash;&gt;-->
      <div>
        <button class="remove-btn" type="button" v-if="uploadLabel" @click="removeFile">
          <div class="button-content">
            <span>
              {{ uploadLabel }}
            </span>
            <ion-icon :src="`${$brand_svg_src}/close.svg`" />
          </div>
        </button>
      </div>

      <div class="form-actions right-align">
        <ion-button class="secondary-action" type="button" @click="$emit('cancel')">Cancel</ion-button>
        <ion-button class="call-to-action" @click="submit">Next</ion-button>
      </div>
    </form>

    <!-- REVIEW CLAIM -->
    <div class="review" v-if="isReviewing">
      <div class="bx--row">
        <div class="bx--col-lg-8">
          <h1 class="main-title">Review and Submit New Support Item</h1>
        </div>
        <div class="bx--col-lg-8 edit-section">
          <span class="sub-title">Please check that the information is correct before submitting.</span>
          <div class="edit">
            <a href="#" @click.prevent="editClaim">
              <span><ion-icon :src="$brand_svg_src + `/edit.svg`" /> Edit</span>
            </a>
          </div>
          <br />
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-8">
          <label>
            <span>PAY TO</span>
          </label>
          <p>{{ supportItem.type === `provider` ? "Provider" : "Myself" }}</p>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-8" v-if="supportItem.type === `provider`">
          <label>
            <span>PROVIDER</span>
          </label>
          <p>{{ getProviderName() }}</p>
        </div>
        <div class="bx--col-lg-8">
          <label>
            <span>SUPPORT AREA</span>
          </label>
          <p>{{ planSupportCategoryName }}</p>
        </div>
        <div class="bx--col-lg-8">
          <label>
            <span>SUPPORT ITEM</span>
          </label>
          <p>{{ getSupportItemName() }}</p>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-4 bx--col-md-4 bx--col-sm-2">
          <label>
            <span>PRICE</span>
          </label>
          <p>{{ $filters.currency(supportItem.price) }}</p>
        </div>
        <div class="bx--col-lg-4 bx--col-md-4 bx--col-sm-2">
          <label>
            <span>QUANTITY</span>
          </label>
          <p>{{ supportItem.quantity }}</p>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-4 bx--col-md-4 bx--col-sm-2">
          <label>
            <span>TOTAL</span>
          </label>
          <p>{{ $filters.currency(totalAmount) }}</p>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col-lg-4 bx--col-md-4 bx--col-sm-2" v-if="supportItem.document">
          <label>
            <span>SERVICE AGREEMENT</span>
          </label>
          <p>{{ supportItem.document ? `Yes` : `` }}</p>
        </div>
      </div>
      <div class="bx--row">
        <div class="bx--col">
          <div class="bx--file-container" v-if="supportItem.document">
            <span class="attachment">{{ supportItem.document.name }}</span>
          </div>
        </div>
      </div>
      <div class="form-actions">
        <ion-button class="secondary-action" type="button" @click="editClaim()">Back</ion-button>
        <ion-button class="call-to-action" type="submit" @click="createSupportItem()">Add</ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpmTextInput from "@/components/MpmTextInput.vue";
import MpmFileInput from "@/components/MpmFileInput.vue";
import { createNamespacedHelpers } from "vuex";
import { TrackingEvent } from "@/types/tracking-events";
import validate from "@/validators/AddSupportItem";
import { find } from "lodash";
import MpmCombobox from "@/components/MpmCombobox.vue";
import PopoverListSelect from "@/components/PopoverListSelect.vue";
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonPopover,
  popoverController
} from "@ionic/vue";

const { mapState: currentPlanState } = createNamespacedHelpers("currentPlan");
const { mapActions } = createNamespacedHelpers("supportItem");

export default defineComponent({
  name: "add-support-item-form",
  components: {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    MpmFileInput,
    MpmTextInput,
    MpmCombobox,
    PopoverListSelect,
    IonPopover,
    popoverController
  },
  props: {
    planSupportCategoryId: { required: true },
    categoryId: { required: true },
    providers: { required: true },
    supportCategoryItems: { required: true },
    planSupportCategoryName: { required: true }
  },
  data() {
    return {
      uploadLabel: "",
      total: 0,
      errors: {},
      isReviewing: false,
      supportItemsList: [],
      supportItem: {
        providerId: null,
        supportCategoryItemId: null,
        price: 0,
        quantity: 1,
        locked: false,
        document: null,
        type: ""
      }
    };
  },
  computed: {
    providerRequired() {
      return this.supportItem.type !== "self";
    },
    providerOptions() {
      if (!this.providers?.length) {
        return [];
      }
      return this.providers.map(provider => ({
        value: provider.id,
        label: provider.company_name
      }));
    },
    supportCategoryItemOptions() {
      if (!this.supportItemsList?.length) {
        return [];
      }
      return this.supportItemsList.map(supportCategoryItem => ({
        value: supportCategoryItem.id,
        label: supportCategoryItem.name
      }));
    },
    ...currentPlanState({
      planId: "id"
    }),
    totalAmount() {
      if (!this.supportItem.price) {
        return "0";
      }
      if (!this.supportItem.quantity) {
        return "0";
      }

      return (this.supportItem.price * this.supportItem.quantity).toFixed(2);
    }
  },
  methods: {
    async openPopover() {
      if (this.supportItemsList.length === 0) {
        // Initialize initial search results list with prop
        this.supportItemsList = [...this.supportCategoryItems];
      }
      const popover = await popoverController.create({
        component: PopoverListSelect,
        componentProps: {
          initialSupportItems: this.supportItemsList,
          planSupportCategoryId: this.planSupportCategoryId,
          categoryId: this.categoryId
        },
        cssClass: "select-search-popover",
        keyboardClose: false
      });
      await popover.present();

      // Set value on Support Item select/dropdown using data passed from popover dismissal
      const { data } = await popover.onDidDismiss();
      if (data) {
        this.supportItemsList = data.itemsFilteredBySearchString;
        this.selectSupportItem(data.selectedValue);
      }
    },
    selectSupportItem(supportItemId) {
      if (supportItemId) {
        this.supportItem.price = this.findSupportCategoryItemPrice(+supportItemId);
        this.supportItem.supportCategoryItemId = supportItemId;
      }
    },
    findSupportCategoryItemPrice(supportCategoryItemId: number) {
      const { price } = find(this.supportItemsList, item => {
        return item.id === supportCategoryItemId;
      });
      if (!price) {
        return 0;
      }
      return +price;
    },
    ...mapActions(["createSupportCategoryItem", "setSupportItem"]),
    editClaim() {
      this.isReviewing = false;
      // this.$emit("on-review", false);
    },
    reviewClaim() {
      this.isReviewing = true;
      // this.$emit("on-review", true);
    },
    async submit() {
      await this.validate();
      if (Object.keys(this.errors).length > 0) {
        this.$notification.createToast("Please make sure all fields have been completed", "error");
        return;
      }
      this.reviewClaim();
    },
    async validate() {
      this.errors = await validate(this.supportItem, this.supportItem.locked, this.supportItem.type === "provider");
    },
    async createSupportItem() {
      this.setSupportItem(this.supportItem); // setSupportItem in the store
      try {
        await this.createSupportCategoryItem({
          categoryId: this.planSupportCategoryId,
          planId: this.planId
        });
        this.$notification.createToast(
          `The new ${this.getSupportItemName() || ""} support item has been added.`,
          "success"
        );
        this.$emit("success");
      } catch (error) {
        this.$notification.createToast(error.response.data.message, "error");
        this.$emit("error", error);
        // if error do not track
        return;
      }
      this.$amplitude.track(TrackingEvent.CreateSupportItem, {});
    },
    getSupportItemName() {
      const supportItemId = this.supportItem.supportCategoryItemId;
      const selectedSupportItem = find(this.supportItemsList, function(item) {
        return item.id == supportItemId;
      });
      return selectedSupportItem.name || "";
    },
    selectProvider(item) {
      this.supportItem.providerId = item.value;
    },
    getProviderName() {
      const providerId = this.supportItem.providerId;
      const provider = find(this.providers, function(item) {
        return item.id == providerId;
      });
      return provider.name || "";
    },

    fileChange(e: any) {
      const { files } = e.target;

      this.uploadLabel = files[0].name;
      this.supportItem.document = files[0];
    },
    removeFile() {
      this.$refs["file-input"].clear();
      this.uploadLabel = "";
      this.supportItem.document = null;
    },
    getInterfaceOptions(alertHeader: string, alertSubHeader: string, cssClass?: string) {
      return {
        header: alertHeader,
        subHeader: alertSubHeader,
        cssClass: "select-alert " + cssClass,
        mode: "md"
      };
    },
    clearSupportItem() {
      this.supportItem.price = 0;
      this.supportItem.quantity = 1;
      this.supportItem.providerId = null;
      this.supportItem.supportCategoryItemId = null;
      this.supportItem.type = "provider";
      this.supportItem.document = null;
      this.supportItem.locked = false;

      this.setSupportItem(this.supportItem);
    },
    onServiceAgreementChange() {
      if (!this.supportItem.locked) {
        this.removeFile();
      }
    }
  }
});
</script>
<style lang="scss">
$--remove-agreement-btn-bg: #692e85;

.select-search-popover {
  --width: 80%;
  --height: 80%;
  --background: #{$brand--global-nav-bg};
  --backdrop-opacity: 0.55;
  @include tablet-only {
    --width: 50%;
    --height: 50%;
  }
}

form {
  // .bx--row {
  //   margin: 1rem 0;
  // }

  .form-requirement {
    display: block;
    color: #ff8389;
    font-weight: $brand--body-font-weight;
    overflow: visible;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.32px;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.mpm-combobox-wrapper {
  margin-bottom: 1.618rem;
  label {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.32px;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.display-none-ion-alert {
  // hide the default alert for ion-select
  display: none;
}

.add-support-item-form {
  margin-top: 1rem;

  // .ion-segment-button .ion-label {
  //   padding-bottom: 0;
  // }

  ion-item {
    --background: transparent;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    .text-input {
      --padding-start: 10px;
    }
  }

  ion-item > ion-label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    display: block;
    margin-bottom: 12px !important;
  }

  div > ion-label {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.32px;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .horizontal-checkboxes {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  // .two-input-row {
  //   display: flex;
  //   justify-content: space-between;
  //   .form-field {
  //     flex-basis: 48%;
  //   }
  //   .bx--number {
  //     width: 100%;
  //   }
  // }
  .btn-row {
    margin: 30px 0;
    display: flex;
    justify-content: space-around;
  }
  .remove-btn {
    border-radius: 3px;
    margin: 25px 0;
    width: 290px;
    padding: 11px 15px;
    color: $brand--theme--text-01;
    background-color: $--remove-agreement-btn-bg;
    &:hover {
      color: $brand--theme--text-01;
      background-color: darken($--remove-agreement-btn-bg, 5%);
    }
  }
  .button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      font-size: 1rem;
      text-align: left;
      word-break: break-all;
      flex-basis: 85%;
    }
  }
  .upload-message {
    font-size: 12px;
    margin: 1rem 0;
    strong {
      text-transform: uppercase;
      line-height: 1.5rem;
    }
  }
  .review {
    max-width: 800px;
    .edit-section {
      padding-bottom: 0 !important;
      margin-bottom: -1rem;
    }

    .edit {
      display: flex;
      flex-direction: row-reverse;
      flex: 1;
      margin-right: 1rem;

      margin-bottom: 0;
      padding-bottom: 0 !important;
      a {
        padding: 0.8rem;
        border-radius: 3px;
      }
      a:hover {
        background-color: #4671bc;
        span {
          color: #fff;
          svg path {
            stroke: #fff;
          }
        }
      }
      span {
        color: #8fb8ff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: right;
        text-decoration-line: underline;
        svg {
          margin-right: 6px;
          path {
            stroke: #8fb8ff;
          }
        }
      }
    }
    label {
      color: #c2c3c3;
      margin-bottom: 0.5rem;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: 16px;
    }
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      text-align: left;
    }
    .bx--row div {
      padding-bottom: 1.5rem;
    }
    p {
      color: #fff;
    }
    .service-dates {
      display: flex;
      flex-direction: inherit;
      margin-bottom: -1.5rem;
    }
    .service-dates span:first-child {
      margin-right: 1.5rem;
    }
    .attachment {
      background: #692e85;
      display: inline-block;
      vertical-align: middle;
      padding: 11px 15px;
    }
    .bx--file-container {
      height: 43px;
      margin: 60px 0 62px 0;
    }
  }
}
</style>
