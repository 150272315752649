<template>
  <ion-card class="total-budget-card">
    <div class="card-header">
      <ion-card-title>Total Budget</ion-card-title>
      <mpm-tooltip>
        <template v-slot:icon>
          <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
        </template>
        <template v-slot:content>
          <p class="tooltip-content">
            This information tells you how much of your NDIS budget you have spent and how much you have left. It will
            only show new budget information for your plan managed supports. If you have self and agency managed
            supports, please go to your ‘my NDIS portal’.
          </p>
        </template>
      </mpm-tooltip>
    </div>

    <ion-card-content>
      <div class="ion-margin-bottom">
        <span class="card-label">Opening balance: </span>
        <span class="card-amount">{{ $filters.currency(currentPlan.budget_allocated) }}</span>
      </div>
      <div style="margin-bottom: 14px">
        <span class="card-label">Spent: </span>
        <span class="card-amount"
          >{{ $filters.currency(currentPlan.budget_spent) }} ({{ percentageBudgetSpent }}%)</span
        >
      </div>
      <div class="ion-margin-bottom">
        <progress-bar
          color-class="brand"
          :percentage-left="
            Number(
              (
                ((parseFloat(currentPlan.budget_allocated) - parseFloat(currentPlan.budget_spent)) * 100) /
                parseFloat(currentPlan.budget_allocated)
              ).toFixed(0)
            )
          "
          :proportional-width="100"
        />
      </div>

      <ion-grid v-if="$root.isIonUnderMdView" class="ion-no-padding ion-margin-bottom">
        <ion-row>
          <ion-col class="ion-no-padding"><span class="card-label">Available balance: </span></ion-col>
          <ion-col class="ion-no-padding">
            <span class="card-amount"
              >{{
                $filters.currency(parseFloat(currentPlan.budget_allocated) - parseFloat(currentPlan.budget_spent))
              }}
              ({{ percentageBudgetLeft }}%)
            </span>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div v-else class="ion-margin-bottom">
        <span class="card-label">Available balance: </span>
        <span class="card-amount"
          >{{ $filters.currency(parseFloat(currentPlan.budget_allocated) - parseFloat(currentPlan.budget_spent)) }} ({{
            percentageBudgetLeft
          }}%)</span
        >
      </div>
      <div class="card-header">
        <ion-card-title>Time</ion-card-title>
        <mpm-tooltip>
          <template v-slot:icon>
            <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
          </template>
          <template v-slot:content>
            <p class="tooltip-content tooltip-sm">This information shows you how much time you have left on your current plan.</p>
          </template>
        </mpm-tooltip>
      </div>
      <ion-grid class="ion-no-padding ion-margin-bottom">
        <ion-row>
          <ion-col size="12" size-lg="6" class="ion-no-padding ion-text-center"
            ><span class="card-label">{{ $filters.friendlyDate(today) }} ({{ timeLeftString }}) </span></ion-col
          >
        </ion-row>
      </ion-grid>
      <div class="ion-margin-bottom">
        <progress-bar color-class="brand" :percentage-left="Number(percentageTimeLeft)" :proportional-width="100" />
      </div>
      <ion-row>
        <ion-col size="6" class="ion-no-padding ion-text-start"
          ><span class="card-label">{{ $filters.friendlyDate(currentPlan.start_date) }}</span></ion-col
        >
        <ion-col size="6" class="ion-no-padding ion-text-end"
          ><span class="card-label">{{ $filters.friendlyDate(currentPlan.end_date) }}</span></ion-col
        >
      </ion-row>
    </ion-card-content>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import mpmTooltip from "@/components/MpmTooltip.vue";
import progressBar from "@/components/plans/pace/ProgressBar.vue";
import moment from "moment";
import {
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle
} from "@ionic/vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "PacePlanTotalBudget",
  props: {},
  components: {
    progressBar,
    mpmTooltip,
    IonIcon,
    IonRow,
    IonCol,
    IonGrid,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle
  },
  computed: {
    ...mapGetters({
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    percentageTimeLeft() {
      const startDate = moment(this.currentPlan.start_date);
      const endDate = moment(this.currentPlan.end_date);
      const today = moment();
      const duration = endDate.diff(startDate, "days", true);
      const diff = endDate.diff(today, "days", true);
      if (diff < 0) {
        return 0;
      }
      return ((diff / duration) * 100).toFixed(0);
    },
    timeLeftString() {
      const today = moment();
      const endDate = moment(this.currentPlan.end_date);

      const years = endDate.diff(today, "year");
      today.add(years, "years");

      const months = endDate.diff(today, "months");
      today.add(months, "months");

      const days = endDate.diff(today, "days");
      if (years < 0) {
        return "Expired";
      }
      if (years > 0) {
        return years + " years, " + (months > 0 ? months + " months left" : "");
      } else {
        return (months > 0 ? months + " months, " : "") + days + " days left";
      }
    },
    today() {
      return moment().format();
    },
    percentageBudgetSpent() {
      if (this.currentPlan.budget_spent === 0) {
        return 0;
      }
      return ((this.currentPlan.budget_spent / this.currentPlan.budget_allocated) * 100).toFixed(1);
    },
    percentageBudgetLeft() {
      if (this.currentPlan.budget_spent === 0) {
        return 0;
      }
      return (100 - (this.currentPlan.budget_spent / this.currentPlan.budget_allocated) * 100).toFixed(1);
    }
  }
});
</script>
<style lang="scss" scoped>
.total-budget-card {
  background-color: $brand--pace-plan-total-budget-bg;
  padding: 20px;
  margin: 0;
  .card-header {
    padding: 11px 0;
    display: flex;
    ion-card-title {
      color: $brand--pace-plan-total-budget-title-color;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
      margin-right: 10px;
    }
    .help-icon {
      border: 0;
      border-radius: 11px;
      height: 24px;
      width: 24px;
    }
    .tooltip-content {
      color: $brand--font-color-primary;
      line-height: 1.4;
      font-size: 1rem;
    }
    .tooltip-sm {
      font-size: 14px;
      padding: 0.75rem 0.5rem;
    }
  }
  ion-card-content {
    padding: 0;
    .card-label {
      font-size: 16px;
      font-weight: 600;
      color: $brand--font-color-primary;
    }
    .card-amount {
      font-size: 18px;
      font-weight: bold;
      color: $brand--font-color-primary;
    }
  }
  .progress-bar-color {
  }
}
</style>
