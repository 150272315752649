<template>
  <div
    :class="[
      'page-header',
      'plan-support-category-' + $store.getters.supportPlanCategory.support_category?.support_category_number
    ]"
  >
    <div class="wrapper">
      <a
        v-if="$store.state.supportItems.expandSupport || $store.state.supportItems.collapseSupport"
        class="icon arrow-left"
        aria-label="Back Arrow. Back to Plan screen."
        role="button"
        tabindex="0"
        @click.prevent="backToPlan"
        @keydown.space="backToPlan"
        @keydown.enter="backToPlan"
      >
        <ion-icon :src="$brand_svg_src + `/back-arrow.svg`" />
      </a>
      <span style="display: none" id="planDialogTitle">View Plan Support Area</span>
      <div class="header-wrap">
        <h1 id="planDialogDescription">
          {{ $store.state.supportPlanCategory?.support_category?.support_category_name }}
        </h1>
        <p class="sub-header" v-desktop-breakpoint-only>
          {{ currency(this.budget.spent) }} SPENT / {{ currency(this.budget.allocated) }} TOTAL
        </p>
      </div>
    </div>
    <spend-summary v-mobile-breakpoint-only :budget="budget" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import SpendSummary from "@/components/plans/support-items/SpendSummary.vue";
import { IonIcon } from "@ionic/vue";

import currency from "@/filters/currency";

export default defineComponent({
  name: "PageHeader",
  props: {
    categoryName: { type: String }
  },
  components: {
    IonIcon,
    SpendSummary
  },
  computed: {
    budget() {
      return {
        allocated: this.$store.state.supportPlanCategory.budget_allocated,
        unallocated: this.$store.state.supportPlanCategory.budget_unallocated,
        spent: this.$store.state.supportPlanCategory.budget_spent,
        left: this.$store.state.supportPlanCategory.budget_left
      };
    }
  },
  methods: {
    ...mapActions(["supportItems/collapseSupportArea"]),
    currency,
    backToPlan() {
      this.$store.dispatch("supportItems/collapseSupportArea", false);
      this.$store.dispatch("supportItems/expandSupportArea", false);
    }
  }
});
</script>
<style lang="scss" scoped></style>
<style lang="scss">
@if $brand == "mpm" {
  @each $id, $colour in $plan_support_colours {
    .page-header.plan-support-category-#{$id} {
      background: $colour;
    }
  }
} @else if $brand == "ndsp" {
  .page-header[class*="plan-support-category-"] {
    background: $brand--primary-color;
    color: $brand--font-color-secondary;
  }
}

.spend-summary {
  margin-top: 1rem;
}
.page-header {
  h1 {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    display: inline-block;
  }

  .icon {
    ion-icon {
      height: 21px;
      width: 21px;
      display: inline-block;
    }
    svg ion-icon {
      width: 22px;
      height: 22px;
    }
  }

  p.sub-header {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
  }
}

@media only screen and (min-width: $ion--breakpoint--md) {
  .page-header {
    margin: $brand--page-header-margin;
    .wrapper {
      display: flex;
      margin-bottom: 10px;
      padding: 14px 0;
      margin-left: 50px;
      .header-wrap {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .arrow-left {
      text-align: center;
      color: #fff;
      padding-right: 10px;
    }
    .arrow-left:focus-visible {
      border: 3px solid $brand--focus-outline;
      outline: none;
      box-shadow: none;
      height: 30px;
      width: 40px;
    }
  }
}

@media only screen and (max-width: $ion--breakpoint--md) {
  .page-header {
    padding: 0 1rem;
    .wrapper {
      display: flex;
      flex-wrap: unset;
      padding-top: 10px;
    }
    .arrow-left {
      text-align: center;
      padding: 0 10px;
    }
  }
}
</style>
