<!-- ModalHeader.vue -->
<!-- This component is used as a header for ionic modals in the app -->
<!-- The dismiss action button (X) emits a "close" event to the parent on-click -->
<template>
  <ion-header id="modal-header" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button @click="close()" :aria-label="`Close ${modalName} modal`">
          <ion-icon :src="`${$brand_svg_src}/close.svg`"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonButton, IonButtons, IonHeader, IonIcon, IonToolbar } from "@ionic/vue";
import { closeModal } from "@/helpers/system";

export default defineComponent({
  name: "modal-header",
  components: {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonToolbar
  },
  props: {
    modalName: { type: String }
  },
  emits: ["close"],
  methods: {
    close() {
      closeModal();
      this.$emit("close");
    }
  }
});
</script>

<style lang="scss" scoped>
#modal-header {
  border-bottom: none;
  background-color: $brand--modal-header-bg;

  ion-button::part(native):focus-visible {
    border: 3px solid $brand--focus-outline;
    --background-focused: none;
  }

  ion-button {
    margin: 11px;
  }
}
</style>
