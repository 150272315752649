<template>
  <div>
    <ion-select
      ref="planCombobox"
      interface="popover"
      mode="ios"
      placeholder="Select Plan"
      :value="$store.getters.currentPlan.sfid"
      class="plan-dropdown"
      @ionChange="actionChanged($event)"
      @ionFocus="onOpen"
      @ionCancel="onClose"
      :class="{ 'select-open': isOpen }"
      :selected-text="selectedText"
      aria-label="Select Plan"
      keyboard-close="true"
      tabindex="0"
      @keypress.prevent="onSelect"
      size="cover"
      backdrop-dismiss="true"
      trigger-action="click"
      button="true"
      :interface-options="interfaceOptions"
    >
      <ion-select-option
        v-bind:key="plan.sfid"
        v-for="plan in plans"
        :value="plan.sfid"
        :selected="plan.sfid === $store.getters.currentPlan.sfid"
        class="plan-item"
      >
        {{ $filters.friendlyDate(plan.start_date) }} to {{ $filters.friendlyDate(plan.end_date) }}
      </ion-select-option>
    </ion-select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import friendlyDate from "@/filters/friendlyDate";
import { IonSelect, IonSelectOption } from "@ionic/vue";

export default defineComponent({
  name: "PlanDropdown",
  filters: { friendlyDate },
  components: {
    IonSelect,
    IonSelectOption
  },
  props: {
    plans: {
      required: true,
      type: Array,
      default() {
        return [];
      }
    },
    defaultPlan: {
      required: true,
      type: Object
    }
  },
  setup() {
    const interfaceOptions = {
      cssClass: "plan-dropdown-popover",
      mode: "ios",
      size: "cover"
    };
    return { interfaceOptions };
  },
  updated() {
    const planShadowRoot = document.querySelector(".plan-dropdown").shadowRoot ?? null;
    if (planShadowRoot) {
      planShadowRoot.querySelector(".native-wrapper").setAttribute("style", "width: 100%;");
    }
  },
  data() {
    return {
      isExpanded: false,
      planLabel: "Select Plan combobox collapsed",
      isOpen: false
    };
  },
  computed: {
    selectedText() {
      return (
        this.$filters.friendlyDate(this.$store.getters.currentPlan.start_date) +
        " to " +
        this.$filters.friendlyDate(this.$store.getters.currentPlan.end_date)
      );
    }
  },
  methods: {
    actionChanged(event) {
      const planId = event.detail.value;
      this.$emit("change", planId);
      this.$store.dispatch("supportItems/collapseSupportArea", false);
      this.$store.dispatch("supportItems/expandSupportArea", false);
    },
    onSpaceDown(e, planId) {
      if (e.keyCode === 32) {
        e.preventDefault();
        this.actionChanged(planId);
      }
    },
    onOpen() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
    onSelect(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.$refs.planCombobox.$el.click();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.plan-dropdown {
  font-style: normal;
  font-family: $brand--theme--header-font;
  text-align: left;
  border: $brand--plan-dropdown-border;
  color: $brand--plan-dropdown-color;
  border-radius: $brand--plan-dropdown-border-radius;
  &:focus-visible {
    outline: 3px solid $brand--focus-outline;
    border: none;
  }
  &::part(icon) {
    opacity: $brand--plan-dropdown-icon-opacity-mobile;
    margin-left: 0.3rem;
    color: transparent;
    background: url("../../assets/icons/#{$brand}-chevron-down-outline.svg") no-repeat center;
    overflow: visible;
    background-size: 25px 25px;
    width: 30px;
    height: 30px;
    transform: rotate(0deg);
    transition-duration: 0.15s;
  }
}
.plan-dropdown.select-expanded {
  border: $brand--plan-dropdown-expanded-border;
  border-radius: 5px;
  background: $brand--plan-dropdown-expanded-bg;
  &::part(icon) {
    transform: rotate(180deg);
    transition-duration: 0.15s;
  }
}
.select-popover .popover-wrapper .popover-content {
  overflow: hidden !important;
  width: inherit;
  border-radius: 0 !important;
  .popover-arrow {
    display: none;
  }
}
</style>
