<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <component :is="'PacePlanView2'" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import PacePlanView2 from "@/components/pages/PacePlanView2.vue";
import { supportTypes } from "@/helpers/support-category";
import { IonPage } from "@ionic/vue";
import { debounce, map } from "lodash";
import { converter } from "@/helpers/filters";

export default defineComponent({
  name: "PacePlanView2Tab",
  components: {
    IonPage,
    PacePlanView2
  },
  beforeRouteEnter(to, from, next) {
    if (map(supportTypes, "route_name")?.includes(to.params?.supportArea as string)) {
      next();
    }
  },
  async ionViewWillEnter() {
    this.updateContentsLoading(true);
    await this.loadPage();
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading"
    })
  },
  methods: {
    ...mapActions({
      updateContentsLoading: "layout/updateContentsLoading",
      loadSelectedPlanSupportType: "pace/supportPlanTypes/fetchSupportType",
      loadPlanSupports: "pace/planSupports/fetchPlanSupports"
    }),
    loadPage: debounce(async function() {
      this.updateContentsLoading(true);
      try {
        const supportTypeCode = converter(
          "route_name",
          this.$route.params.supportArea,
          "support_type_code",
          supportTypes
        );
        await this.loadSelectedPlanSupportType(supportTypeCode);
        await this.loadPlanSupports(supportTypeCode);
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100)
  }
});
</script>
