<template>
  <section id="participant-information" aria-label="Participant information">
    <div class="menu-participant-info">
      <div class="name">{{ participant.name }}</div>
      <div class="ndis" role="heading" :aria-label="readNdisNumber()">
        <p>NDIS {{ participant.ndis }}</p>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  computed: {
    participant() {
      return this.$store.getters.participant;
    }
  },
  methods: {
    readNdisNumber() {
      if (this.participant.ndis) {
        const arr = this.participant.ndis.split("").join(". ");
        return "NDIS Number " + arr;
      }
      return "";
    }
  }
});
</script>
<style lang="scss" scoped>
.menu-participant-info {
  background: #ffffff;
  padding: 13px 14px;
  color: #000;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 14px;

  .name {
    font-weight: $brand--name-font-weight;
    font-size: 14px;
  }
  .ndis {
    font-weight: $brand--ndis--number-font-weight;
    font-size: 14px;
  }
  // Responsiveness
  @include tablet-only {
    display: inline-block;
    padding: 18px 18px;
    font-size: 1rem;
    width: 100%;
    height: auto;
    letter-spacing: 0.16px;
    .name {
      font-weight: $brand--name-font-weight;
      margin-bottom: 10px;
    }
    .ndis {
      font-weight: $brand--ndis--number-font-weight;
    }
  }
}
</style>
