<template>
  <ion-page>
    <modal-header :modal-name="modalName" @close="dismissModal()"></modal-header>
    <ion-content>
      <section id="add-support-item" aria-label="add support item page">
        <div class="add-support-item">
          <div class="help-section">
            <!--      <mpm-help-link :href="$root.helpLinks.addSupportItem">-->
            <!--        Watch Add Support Item video-->
            <!--      </mpm-help-link>-->
          </div>
          <div
            :class="['page-header', 'plan-support-category-' + $store.getters.supportPlanCategory.support_category?.id]"
          >
            <h4 id="planDialogDescription">
              {{ $store.state.supportPlanCategory?.support_category?.support_category_name }}
            </h4>
            <!--      <p class="sub-header" v-if="!$root.isMobileView">-->
            <!--        {{ $store.state.supportPlanCategory.budget_spent | currency }} SPENT /-->
            <!--        {{ $store.state.supportPlanCategory.budget_allocated | currency }} TOTAL-->
            <!--      </p>-->
            <!--        <spend-summary v-if="$root.isMobileView" :budget="budget" />-->
          </div>

          <add-support-item-form
            :plan-support-category-id="planSupportCategoryId"
            :category-id="categoryId"
            :providers="providers"
            :support-category-items="supportCategoryItems"
            :plan-support-category-name="$store.state.supportPlanCategory?.support_category?.support_category_name"
            @success="success()"
            @cancel="dismissModal()"
          ></add-support-item-form>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { createNamespacedHelpers } from "vuex";
import { sortBy } from "lodash";
import AddSupportItemForm from "@/components/plans/support-items/AddSupportItemForm.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import MpmHelpLink from "@/components/MpmHelpLink.vue";
import SpendSummary from "@/components/plans/support-items/SpendSummary.vue";
import currency from "@/filters/currency";
import { closeModal } from "@/helpers/system";
import { IonButton, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/vue";

const { mapMutations, mapActions } = createNamespacedHelpers("supportItem");

export default defineComponent({
  name: "add-support-item",
  props: {
    categoryId: Number,
    planSupportCategoryId: Number
  },
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    SpendSummary,
    AddSupportItemForm,
    ModalHeader,
    MpmHelpLink
  },
  filters: {
    currency
  },
  data() {
    return {
      modalName: "Add Support Item",
      providers: [],
      supportCategoryItems: reactive([]),
      perPage: 100,
      page: 1,
      lastPage: null,
      isReviewing: false,
      plans: [],
      activePlan: this.$store.state.currentPlan
    };
  },
  // TODO: move load pieces to this component
  // so that load can happen on navigation
  async mounted() {
    // in the support area, so get the budget from there
    this.providers = await this.getAllProviders();
    const data = await this.fetchSupportCategoryItems();
    this.updateSupportItems(data);
  },
  computed: {
    budget() {
      return {
        allocated: this.$store.state.supportPlanCategory.budget_allocated,
        unallocated: this.$store.state.supportPlanCategory.budget_unallocated,
        spent: this.$store.state.supportPlanCategory.budget_spent,
        left: this.$store.state.supportPlanCategory.budget_left
      };
    }
  },
  methods: {
    ...mapMutations(["setSupportItem", "clearSupportItem"]),
    ...mapActions(["createSupportCategoryItem", "getAllProviders", "searchSupportCategoryItems"]),
    dismissModal() {
      this.clearSupportItem();
      closeModal();
    },
    success() {
      this.dismissModal();
    },
    async fetchSupportCategoryItems(value) {
      if (this.categoryId) {
        const params: any = {
          categoryId: this.categoryId,
          perPage: this.perPage,
          page: this.page
        };
        if (value && value.length >= 3) {
          params.search = value;
        }
        return await this.searchSupportCategoryItems(params);
      }
    },
    updateSupportItems(data) {
      this.page = 1;
      if (typeof data.meta != "undefined") {
        this.lastPage = data.meta.last_page;
      }
      this.supportCategoryItems = sortBy(data.data, supportItem => supportItem.name.toLowerCase());
    }
  }
});
</script>
<style lang="scss" scoped>
//
.page-header {
  width: 100%;
  height: 92px;
  margin: 0px -64px 22px -65px;
  width: unset;
  padding: 0 55.5px;
  flex-wrap: wrap;
  display: flex;
  .arrow-left {
    text-align: center;
    display: inline-block;
    margin-top: 22.5px;
    padding-left: 4px;
    color: #fff;
    margin-left: -5px;
  }
  h4 {
    flex: 1 1 91%;
    margin-top: 22.5px;
    padding-left: 18px;
  }
  p.sub-header {
    padding-left: 10px;
    padding-bottom: 5px;
    margin-top: -13px;
  }
  .icon-expand {
    display: none;
  }
  @media only screen and (min-width: $brand-support-drawer-breakpoint) {
    .arrow-left {
      text-align: center;
      display: inline-block;
      margin-top: 22.5px;
      padding-left: 4px;
      color: #fff;
      margin-left: -30px;
    }
    h4 {
      flex: 1 1 91%;
      margin-top: 22.5px;
      padding-left: 18px;
    }
    p.sub-header {
      padding-left: 10px;
      padding-bottom: 5px;
      margin-top: -13px;
    }
    .icon-expand {
      display: none;
    }
  }
}

@media only screen and (max-width: $brand-support-drawer-breakpoint) {
  .page-header {
    width: unset;
    flex-wrap: wrap;
    display: flex;
    min-height: 75px;
    height: 1%;
    .arrow-left {
      text-align: center;
      display: inline-block;
      margin-top: 12px;
      color: #fff;
    }
    h4 {
      flex: 1 1 80%;
      margin-top: 12px;
      padding-left: 16px;
    }
    p.sub-header {
      padding-left: 35px;
      padding-bottom: 5px;
    }
    .icon-expand {
      display: none;
    }
  }
}
.add-support-item {
  background: #162032;
  color: $brand--theme--text-01;
  padding-right: 22px;
  padding-left: 22px;
  padding-top: 0;
  padding-bottom: 50px;

  .help-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cv-link {
      padding-top: 0;
    }
  }
  p.sub-title {
    padding: 11px 0 1rem 0;
    font-size: 14px;
  }
}
</style>
