<template>
  <div class="total-available-budget-side-item">
    <div :class="[isExpanded ? 'expanded' : '']"></div>

    <div
      :class="['main-display', isExpanded && !fullScreenLoading ? 'expanded' : '']"
      @click="onToggle"
      @keyup.space="onToggle"
      @keyup.enter="onToggle"
      tabindex="0"
    >
      <p class="head-line">Total Available Budget</p>
      <div class="amount-line">
        <div class="amount">
          <p v-if="!fullScreenLoading">
            {{ $filters.currency(totalAvailableBudgetAmount) }}
          </p>
          <ion-icon v-else class="loading-icon" src="svg/loading.svg"></ion-icon>
        </div>
        <button
          fill="clear"
          style="background: none;"
          aria-label="Total Available Budget"
          :aria-expanded="isExpanded && !fullScreenLoading"
          tabindex="-1"
        >
          <ion-icon src="svg/caret.svg" class="caret-icon"></ion-icon>
        </button>
      </div>
    </div>
    <div :class="['expanded-display', isExpanded && !fullScreenLoading ? 'expanded' : '']">
      <div v-if="isExpanded && !fullScreenLoading">
        <mpm-padded-donut-chart
          class="donut-chart"
          background="#ffffff"
          :sections="chartDataSections"
          :thickness="45"
          :size="135"
          :total="totalAvailableBudgetAmount"
          foreground="#727D93"
          :auto-adjust-text-size="true"
          padding-size="0.25rem"
          padding-color="#ffffff"
          has-legend
        >
          <template #legend>
            <div class="legend">
              <div
                class="legend-item"
                v-show="chartDataSection.value > 0"
                v-for="(chartDataSection, index) in chartDataSections"
                :key="index"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" version="1.1">
                  <circle :fill="chartDataSection.color" cx="0.5rem" cy="0.5rem" r="0.45rem" />
                </svg>
                <div class="info">
                  <div class="title" :aria-label="chartDataSection.label">
                    {{ chartDataSection.label }}
                  </div>
                  <div class="amount" :aria-label="chartDataSection.value">
                    {{ $filters.currency(chartDataSection.value) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </mpm-padded-donut-chart>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$fontColor: #ffffff;
$fontColorExpanded: #000000;
$mainDisplayBgColorExpanded: #dedede;
$expandedDisplaymainDisplayBgColor: #ffffff;

.total-available-budget-side-item {
  .loading-icon {
    height: 2rem;
  }

  .main-display {
    position: relative;
    z-index: 1001;
    background: $brand--available-budget-bg-color;
    color: $fontColor;
    padding: 1rem;
    cursor: pointer;
    transition: background 0.25s;
    .head-line {
      font-size: 14px;
      line-height: 1.5rem;
    }
    p {
      margin: 0;
    }
    &:focus-visible {
      border: 3px solid $brand--focus-outline;
      outline: none;
    }
    .amount-line {
      display: flex;
      .amount {
        flex: 1 0 auto;
        p {
          font-weight: 700;
          font-size: 1.15rem;
          line-height: 2rem;
        }
        .loading-icon {
          margin: 0 auto 0 auto;
        }
      }
      .caret-icon {
        padding-right: 4px;
        font-size: 11px;
        transform: scale(1.25) rotate(-90deg);
        align-self: center;
        transition: transform 0.25s;
      }
    }
    &:hover {
      background: lighten($brand--available-budget-bg-color, 5%);
    }

    &.expanded {
      background: $mainDisplayBgColorExpanded;
      color: $fontColorExpanded;
      .caret-icon {
        transform: scale(1.25) rotate(0);
        filter: invert(100%);
      }
    }
  }

  .expanded-display {
    position: relative;
    z-index: 1001;
    max-height: 0;
    background: $expandedDisplaymainDisplayBgColor;
    transition: max-height 0.5s;
    &.expanded {
      max-height: 25rem;
      padding: 2rem 1rem 3rem 1rem;
      box-shadow: $brand--available-budget-mobile-box-shadow;
    }
  }

  &::v-deep .donut-chart {
    .cdc-container {
      display: flex;
      flex-direction: row !important;
      @include tablet-only {
        flex-direction: column !important;
      }
      justify-content: space-around;
    }

    .legend {
      width: 100%;
      .legend-item {
        display: flex;
        margin-top: 15px;
        svg {
          margin-top: 0.125rem;
          margin-right: 0.5rem;
          align-self: flex-start;
        }
        .info {
          flex: 1 0 auto;
          color: $fontColorExpanded;
          .title {
            font-size: 0.85rem;
          }
          .amount {
            font-weight: 700;
          }
        }
      }
    }
  }

  @media screen and (max-width: $width--breakpoint--md) {
    & {
      .main-display {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        // flex-direction: column;
        .head-line {
          font-size: 1rem;
          margin-left: 1rem;
        }
        .amount-line {
          flex-grow: 1;
          .amount {
            margin: 0.2rem 0.5rem;
          }
        }
        &.expanded {
          .donut-chart .cdc {
            box-shadow: 0.25rem 0.25rem 1rem #000000;
          }
        }
      }

      .expanded-display {
        max-height: 0;
        height: auto;
        transition: max-height 0.25s;
        z-index: 1001;
        &.expanded {
          height: auto;
          max-height: 25rem;
          padding: 1rem;
        }
      }
    }

    .overlay {
      position: fixed;
      top: 3.6rem;
      bottom: 0;
      width: 100%;
      height: 0;
      &.expanded {
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
      }
    }

    &::v-deep .expanded-display .donut-chart {
      display: flex;

      justify-content: space-around;
      .cdc {
        margin-left: 1rem;
      }
      .legend {
        width: 50%;
        margin-left: 1rem;
        margin-right: 1rem;
        .legend-item {
          margin-left: 25%;
          display: inline-flex;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import groupPlanSupportCategories from "@/helpers/plans/groupPlanSupportCategories";
import MpmPaddedDonutChart from "@/components/MpmPaddedDonut.vue";
import { IonIcon } from "@ionic/vue";
import { mapGetters } from "vuex";

const planSupportCategoryNames = {
  coreSupports: { label: "Core Supports", color: "#4536C2" },
  capacitySupports: { label: "Capacity Building", color: "#1A39F0" },
  capitalSupports: { label: "Capital Supports", color: "#006059" }
};

export default defineComponent({
  name: "TotalAvailableBudget",
  components: {
    MpmPaddedDonutChart,
    IonIcon
  },
  methods: {
    onToggle() {
      this.isExpanded = !this.isExpanded;
    }
  },
  computed: {
    ...mapGetters({
      rawPlanSupportCategories: "currentPlan/getPlanSupportCategories",
      fullScreenLoading: "layout/getFullScreenLoading"
    }),
    planSupportCategories() {
      if (this.rawPlanSupportCategories) {
        return groupPlanSupportCategories(this.rawPlanSupportCategories);
      }
      return {};
    },
    chartDataSections() {
      return Object.keys(this.planSupportCategories).map(planSupportCategoryName => {
        const planSupportCategory = this.planSupportCategories[planSupportCategoryName];
        const sumOfPlanSupportCategory = planSupportCategory.reduce((total, plan) => {
          return total + (parseFloat(plan.budget_remaining) || 0);
        }, 0);

        return {
          label: planSupportCategoryNames[planSupportCategoryName].label,
          value: sumOfPlanSupportCategory,
          color: planSupportCategoryNames[planSupportCategoryName].color
        };
      });
    },
    totalAvailableBudgetAmount() {
      return Object.keys(this.planSupportCategories).reduce((sum, planSupportCategoryName) => {
        const planSupportCategory = this.planSupportCategories[planSupportCategoryName];
        return (
          sum +
          planSupportCategory.reduce((total, plan) => {
            return total + (parseFloat(plan.budget_remaining) || 0);
          }, 0)
        );
      }, 0);
      /* return 100000; */
    },
    currentPlan() {
      return this.$store.getters["currentPlan"];
    }
  },
  data() {
    return {
      isExpanded: false
    };
  }
});
</script>
