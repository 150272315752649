<template>
  <div class="mpm-combobox-wrapper" :class="{ disabled: disabled, invalid: isInvalid }">
    <label :for="uuid">{{ label }}</label>
    <div class="mpm-combobox">
      <input
        ref="inputBox"
        type="text"
        role="combobox"
        :aria-label="ariaLabel || label"
        aria-autocomplete="both"
        aria-expanded="false"
        :aria-controls="uuid"
        aria-haspopup="listbox"
        @keydown.enter.prevent
        @input="handleInput"
        :value="selected"
        autocomplete="off"
        :disabled="disabled"
        :aria-disabled="disabled"
        :aria-invalid="isInvalid"
        v-bind="$attrs"
        :placeholder="placeholder"
      />
      <button v-if="!loading" ref="listBoxButton" type="button" aria-label="open state list" tabindex="-1">
        <span>▼</span>
      </button>
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 104"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
        v-else
      >
        <path
          fill="#B4EC51"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <ul class="mpm-combobox-listbox" :id="uuid" ref="listbox" role="listbox" aria-label="States"></ul>

    <!-- Hidden options list -->
    <ul :id="`listbox-options-${uuid}`" ref="listboxOptions" class="hidden">
      <li v-for="(option, idx) in options" :data-value="idx" :key="idx">
        {{ option.label }}
      </li>
    </ul>
    <div class="bx--form-requirement" aria-live="assertive" aria-relevant="additions removals">
      <slot v-if="isInvalid" name="invalid-message">{{ invalidMessage }}</slot>
    </div>
  </div>
</template>
<style scoped>
.hidden {
  display: none;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import Combobox from "@/components/combobox";

interface Data {
  combobox: any;
  uuid: string;
}
interface Methods {
  setupCombobox: () => void;
  destroyCombobox: () => void;
  handleInput: (args: any) => void;
}
interface Computed {
  selected: string;
  isInvalid: boolean;
}
interface Props {
  options: any[];
  label: string;
  placeholder: string;
  ariaLabel: string;
  disabled: boolean;
  value: any;
  invalidMessage: string;
  loading: boolean;
}
export default defineComponent({
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    ariaLabel: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    value: {
      required: true
    },
    invalidMessage: {
      default: "",
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(): Data {
    return {
      uuid: null,
      combobox: null
    };
  },
  beforeMount() {
    this.uuid = (this as any)._uid;
  },
  computed: {
    isInvalid(): boolean {
      return !this.disabled && !!this.invalidMessage;
    },
    selected() {
      if (this.value === undefined || this.value === null || !this.options || !this.options.length) {
        return "";
      }
      const val = this.options.find((option: any) => {
        return option.value === this.value;
      });
      return val?.label || "";
    }
  },
  methods: {
    handleInput(e) {
      const { value } = e.target;
      // if user manually clears field, emit null
      // this clears value, validation can be applied again
      if (value === "") {
        this.$emit("input", null);
      }
    },
    setupCombobox() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const optionsEl = Array.from((self.$refs.listboxOptions as any).children);
      self.combobox = new Combobox(
        self.$refs.inputBox,
        self.$refs.listBoxButton,
        self.$refs.listbox,
        self.value,
        optionsEl,
        function() {
          // onShowItems
          console.log("show");
        },
        function() {
          // onHideItems
        },
        function(e: any) {
          // onSelectItem
          const idx = +e.dataset.value;
          // console.log(+e.dataset.value)
          // console.log(e.textContent.trim())
          self.$emit("input", self.options[idx].value);
          self.invalid = false;
        },
        function() {
          // onError
        }
      );
    },
    destroyCombobox() {
      this.combobox.deregisterEvents();
      this.combobox = null;
    }
  },
  watch: {
    options: function(newOptions) {
      if (this.combobox) {
        this.destroyCombobox();
      }
      if (newOptions && newOptions.length) {
        this.$nextTick(() => {
          this.setupCombobox();
        });
      }
    }
  },
  mounted() {
    this.setupCombobox();
  },
  beforeDestroy() {
    this.destroyCombobox();
  }
});
</script>
<style lang="scss">
.mpm-combobox-wrapper {
  width: 100%;
  position: relative;

  &.disabled {
    opacity: 0.6;
    input,
    button {
      cursor: not-allowed;
    }
    button span {
      opacity: 0;
    }
  }
  &.disabled {
    opacity: 0.6;
    input,
    button {
      cursor: not-allowed;
    }
  }

  .mpm-combobox {
    width: 100%;
    display: flex;
    input {
      background: $brand--text-input-bg;
      color: $brand--theme--text-01;
      flex: 1 0 auto;
      border: none;
      line-height: 1.4;
      font-size: 1em;
      padding: 0.65rem 1rem;
    }
    button {
      background: $brand--text-input-bg;
      color: $brand--theme--text-01;
      border: none;
    }
    input,
    button {
      border-bottom: 1px solid $brand--theme--interactive-01;
    }
    svg {
      width: 40px;
      position: absolute;
      right: 0;
    }
  }
  .mpm-combobox-listbox {
    background: $brand--text-input-bg;
    color: $brand--theme--text-01;
    border: none;
    top: 45px;
    position: absolute;
    z-index: 1;
    width: inherit;
    li {
      line-height: 1.4;
      padding: 1rem;
      position: relative;
      &:hover {
        cursor: pointer;
        background: $brand--theme--ui-background-hover;
      }
    }
  }
  input,
  button {
    border-bottom: 1px solid $brand--theme--interactive-01;
  }
  &.invalid:not(.disabled) {
    .mpm-combobox {
      outline: 2px solid #fa4d56;
      outline-offset: -2px;
      input,
      button {
        border-bottom: 1px solid #fa4d56;
      }
    }
  }
}
</style>
