<template>
  <div class="mpm-tooltip">
    <button
      :id="id"
      ref="button"
      class="button"
      @click.prevent="onButtonClick"
      @focus="onButtonFocus"
      @mouseenter="onButtonMouseEnter"
    >
      <slot name="icon"></slot>
    </button>
    <div
      ref="tooltip"
      :class="['content', visible ? 'show' : '']"
      :aria-describedby="id"
      :aria-hidden="visible ? 'false' : 'true'"
    >
      <slot name="content"></slot>
      <div data-popper-arrow class="arrow"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { createPopper } from "@popperjs/core";
import uuid from "uuid";

export default defineComponent({
  name: "MpmTooltip",

  computed: {
    id() {
      return `tooltip-${uuid()}`;
    }
  },

  methods: {
    onButtonClick() {
      this.visible = true;
    },
    onButtonMouseEnter() {
      this.visible = true;
    },
    onButtonFocus() {
      this.visible = true;
    },
    onClickOutsideEvent(event) {
      const target = event.target;
      const el = this.$el;
      if (this.visible && !(el !== target && el.contains(target as Node))) {
        this.visible = false;
      }
    },
    onKeyDown(event) {
      // Esc
      if (this.visible && event.keyCode === 27) {
        this.visible = false;
        this.$refs.button.blur();
      }
    }
  },

  mounted() {
    this.popper = createPopper(this.$refs.button, this.$refs.tooltip, {
      strategy: "absolute",
      modifiers: [
        {
          name: "arrow",
          options: {
            padding: 16
          }
        },
        {
          name: "offset",
          options: {
            offset: [0, 16]
          }
        },
        {
          name: "preventOverflow",
          options: {
            padding: 16
          }
        }
      ]
    });

    this.$root.$el.addEventListener("click", this.onClickOutsideEvent);

    window.addEventListener("keydown", this.onKeyDown);
  },

  unmounted() {
    this.$root.$el.removeEventListener("click", this.onClickOutsideEvent);
    window.removeEventListener("keydown", this.onKeyDown);
  },

  data() {
    return {
      hoverTimer: null,
      visible: false,
      popper: null
    };
  },

  watch: {
    visible() {
      this.popper.update();
    }
  }
});
</script>

<style lang="scss" scoped>
$arrow-size: 1rem;
$arrow-size-half: ($arrow-size * 0.5);

.mpm-tooltip {
  .button {
    background: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.8;

    svg {
      background: white;
      border-radius: 100%;
    }

    &:hover {
      opacity: 1;
      & + .content {
        display: block;
      }
    }

    &:focus + .content {
      display: block;
    }
    &:focus-visible {
      outline: 3px solid $brand--focus-outline;
    }
  }

  .content {
    position: absolute;
    border-radius: 0.25rem;
    left: 1rem;
    transform: translateX(-50%);
    padding: 0 1rem;
    background: $brand--tooltip-bg;
    max-width: 20rem;
    z-index: 600;
    font-weight: $brand--tooltip-font-weight;
    display: none;
    box-shadow: 0 0.9px 0.9px rgba(0, 0, 0, 0.042), 0 2.1px 2.1px rgba(0, 0, 0, 0.061), 0 4px 4px rgba(0, 0, 0, 0.075),
      0 7.1px 7.1px rgba(0, 0, 0, 0.089), 0 13.4px 13.4px rgba(0, 0, 0, 0.108), 0 32px 32px rgba(0, 0, 0, 0.15);

    &.show {
      display: block;
    }
  }

  .content > .arrow,
  .content > .arrow::before {
    position: absolute;
    width: $arrow-size;
    height: $arrow-size;
    background: inherit;
  }

  .content > .arrow {
    visibility: hidden;
  }

  .content > .arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
    left: 0;
  }

  .content[data-popper-placement^="top"] > .arrow {
    bottom: -$arrow-size-half;
  }

  .content[data-popper-placement^="bottom"] > .arrow {
    top: -$arrow-size-half;
  }

  .content[data-popper-placement^="left"] > .arrow {
    right: -$arrow-size-half;
  }

  .content[data-popper-placement^="right"] > .arrow {
    left: -$arrow-size-half;
  }
}
</style>
