<template>
  <div class="breadcrumbs">
    <div>
      <template v-for="(breadcrumbItem, index) in breadcrumbItems">
        <span class="current-link" v-if="index === breadcrumbItems.length - 1">{{ breadcrumbItem.title }}</span>
        <span
          v-else
          tabindex="0"
          @click="gotoLink(breadcrumbItem.link)"
          @keyup.space="gotoLink(breadcrumbItem.link)"
          @keyup.enter="gotoLink(breadcrumbItem.link)"
          class="breadcrumb focus-item"
          :id="'breadcrumb-' + breadcrumbItem.link.split('/')[0]"
        >
          {{ breadcrumbItem.title }}
        </span>
        <span v-if="index !== breadcrumbItems.length - 1" class="separator"> / </span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Breadcrumbs",
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    gotoLink(link) {
      this.$router.push({ path: "/" + link });
    }
  }
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
  margin-bottom: 32px;
  .current-link {
    color: $brand--font-color-primary;
    cursor: default;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
  .breadcrumb {
    color: $brand--breadcrumbs-link-color;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
  .breadcrumb:hover {
    text-decoration: underline;
  }
  .separator {
    color: $brand--font-color-primary;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
}
</style>
