<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <ion-content tabindex="0" class="page-content reports-tab" aria-label="Reports page content">
      <section id="reports" aria-label="reports page">
        <div style="overflow:auto" tabindex="-1">
          <div class="content-spacer">
            <total-available-budget v-if="$root.isMobileView && !$store.getters.currentPlan.pace_plan" />
            <div class="reports header-spacer-mobile">
              <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
                <ion-refresher-content
                  pulling-icon="svg/loading.svg"
                  refreshing-spinner="circles"
                  refreshing-text="Refreshing..."
                >
                </ion-refresher-content>
              </ion-refresher>
              <div class="main-heading" :class="{ split: $root.zoomLevel >= 2 }">
                <h1 class="main-title">Reports</h1>
                <ion-button v-if="hasFullParticipantAccess" class="generate-report-button" @click="openGenerateReport">
                  Generate Report
                </ion-button>
              </div>
              <div class="page-preamble">
                <div class="main-desc">A list of all reports that have been generated for any plan.</div>
              </div>
              <div v-if="!$root.isMobileView" class="view-controls">
                <div class="record-controls">
                  <span class="page-meta">
                    {{ pagination.first_record_number }}
                    -
                    {{ pagination.last_record_number }}
                    of
                    {{ pagination.total }}
                    items
                  </span>
                  <button
                    :aria-label="`Go to page ${pagination.current_page - 1} of records`"
                    class="filter-button arrow-button"
                    @click="onPageChange(pagination.current_page - 1)"
                    :disabled="pagination.is_first_page"
                  >
                    <ion-icon :src="$brand_svg_src + `/arrow-left-white.svg`" />
                  </button>
                  <button
                    :aria-label="`Go to page ${pagination.current_page + 1} of records`"
                    class="filter-button arrow-button"
                    @click="onPageChange(pagination.current_page + 1)"
                    :disabled="pagination.is_last_page"
                  >
                    <ion-icon :src="$brand_svg_src + `/arrow-right-white.svg`" />
                  </button>
                </div>
              </div>
              <div class="data-table-container">
                <table v-desktop-breakpoint-only class="data-table" :data="reports">
                  <thead>
                    <tr class="row">
                      <th
                        :id="`column-header-${column}`"
                        class="column-header"
                        :class="column"
                        v-for="(column, cellIndex) in desktopColumns"
                        :key="`heading:${cellIndex}`"
                      >
                        <span class="table-header-label">{{ column }}</span>
                      </th>
                      <th>
                        <!---->
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="cv-data-table-row cv-data-table-row--expandable"
                    v-for="(report, groupKey) in reports"
                    :key="groupKey"
                    v-show="!isEmpty()"
                  >
                    <tr class="cv-data-table-row-inner row">
                      <td>
                        <span class="nowrap">{{ reportStartDate(report) }} </span> -
                        <span class="nowrap"> {{ reportEndDate(report) }}</span>
                        <img
                          class="myndislogo"
                          v-if="planIsPace(report)"
                          :src="`${$brand_svg_src}/myndislogo.png`"
                          alt="my NDIS logo"
                        />
                      </td>
                      <td>
                        {{ report.date }}
                      </td>
                      <td>
                        <div
                          :class="[
                            'support-area-cell',
                            (planIsPace(report) ? 'pace-plan-support-area-' : 'plan-support-area-') +
                              report.plan_supports_id
                          ]"
                        >
                          {{ findCategory(report) }}
                        </div>
                      </td>
                      <td>
                        <div class="reference-cell">
                          <a
                            :aria-label="genDownloadReportAriaLabel(report)"
                            target="_blank"
                            @click="openReport"
                            :href="report.url"
                            ><ion-icon :src="$brand_svg_src + '/download.svg'" class="download-icon"></ion-icon
                          ></a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="isEmpty()">
                    <tr>
                      <td colspan="4">
                        <h5>There are no reports to show here yet.</h5>
                        <p v-if="hasFullParticipantAccess" class="empty-message">
                          All generated reports including monthly reports will appear here. Use the ‘Generate Report’
                          button to generate a report for any plan.
                        </p>
                        <p v-else class="empty-message">
                          All generated reports including monthly reports will appear here.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table v-mobile-breakpoint-only class="data-table" :data="reports">
                  <thead>
                    <tr class="row">
                      <th data-previous-value="expanded" class="table-expand" width="48px">
                        <!---->
                      </th>
                      <th
                        :id="`column-header-${column}`"
                        class="column-header"
                        :class="column"
                        v-for="(column, cellIndex) in mobileColumns"
                        :key="`heading:${cellIndex}`"
                      >
                        <span class="table-header-label">{{ column }}</span>
                      </th>
                      <th>
                        <!---->
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="cv-data-table-row cv-data-table-row--expandable"
                    v-for="(report, groupKey) in reports"
                    :key="groupKey"
                    v-show="!isEmpty()"
                  >
                    <tr class="cv-data-table-row-inner row">
                      <td
                        rowspan="2"
                        :data-previous-value="checkRow(groupKey) ? 'collapsed' : 'expanded'"
                        role="gridcell"
                        tabindex="0"
                        aria-label="Expand/collapse detail row"
                        class="table-expand"
                        @click="toggleRow(groupKey)"
                        width="48px"
                      >
                        <div class="corner"></div>
                        <button
                          class="expand-collapse-button"
                          aria-label="Expand/collapse current row"
                          :aria-expanded="checkRow(groupKey)"
                        >
                          <ion-icon
                            aria-hidden="true"
                            :src="$brand_svg_src + `/arrow-right.svg`"
                            slot="icon-only"
                            class="table-expand__svg"
                          ></ion-icon>
                        </button>
                      </td>
                      <td>
                        <span class="nowrap">{{ reportStartDate(report) }} </span> -
                        <span class="nowrap"> {{ reportEndDate(report) }}</span>
                        <img v-if="planIsPace(report)" :src="`${$brand_svg_src}/myndislogo.png`" alt="my NDIS logo" />
                      </td>
                      <td>
                        {{ report.date }}
                      </td>
                      <td>
                        <div class="reference-cell">
                          <a
                            :aria-label="genDownloadReportAriaLabel(report)"
                            target="_blank"
                            @click="openReport"
                            :href="report.url"
                            ><ion-icon :src="$brand_svg_src + '/download.svg'" class="download-icon"></ion-icon
                          ></a>
                        </div>
                      </td>
                    </tr>
                    <tr class="expandable-row" :class="checkRow(groupKey) ? 'expanded' : 'collapsed'">
                      <td>
                        <div class="expanded-content">
                          <span class="cell-content">Plan</span>
                          <span>{{ reportPlanDates(report) }}</span>
                        </div>
                      </td>
                      <td colspan="2">
                        <div class="expanded-content">
                          <span class="cell-content">Support Area</span>
                          <div
                            :class="[
                              'support-area-cell',
                              (planIsPace(report) ? 'pace-plan-support-area-' : 'plan-support-area-') +
                                report.plan_supports_id
                            ]"
                          >
                            {{ findCategory(report) }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="isEmpty()">
                    <tr>
                      <td colspan="4">
                        <h5>There are no reports to show here yet.</h5>
                        <p v-if="hasFullParticipantAccess" class="empty-message">
                          All generated reports including monthly reports will appear here. Use the ‘Generate Report’
                          button to generate a report for any plan.
                        </p>
                        <p v-else class="empty-message">
                          All generated reports including monthly reports will appear here.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <claims-pagination v-show="!isEmpty()" :pagination="pagination" @change-page="onPageChange" />
            </div>
          </div>
          <site-map v-desktop-only></site-map>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { TrackingEvent } from "@/types/tracking-events";
import { reportPlanLabel } from "@/helpers/data-transform";
import { paceSupportCategories, supportCategoryMappings } from "@/helpers/support-category";
import { openModal } from "@/helpers/system";
import { find } from "lodash";
import dayjs from "dayjs";
import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import GenerateReports from "@/views/Reports/GenerateReports.vue";
import ClaimsPagination from "@/components/ClaimsPagination.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import { isDesktop } from "@/directives/platform";
import { IonContent, IonIcon, IonPage, IonButton, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "ReportsTab",
  components: {
    TotalAvailableBudget,
    ClaimsPagination,
    SiteMap,
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonRefresher,
    IonRefresherContent
  },
  async ionViewWillEnter() {
    this.loadPage();
  },
  ionViewDidEnter() {
    if (this.$route.query.queued) {
      this.$notification.createToast(
        "Thank you! Your budget report has been requested. Some reports may take longer to be prepared. Please refresh or check back soon to view your report.",
        "success"
      );
      this.$router.replace({ query: null });
    }
  },
  data() {
    return {
      desktopColumns: ["REPORT DATE", "GENERATED ON", "SUPPORT AREA"],
      mobileColumns: ["REPORT DATE", "GENERATED ON"],
      currentPage: 1,
      expandedRows: reactive({})
    };
  },
  computed: {
    ...mapGetters("reports", {
      reports: "uiReports",
      pagination: "pagination"
    }),
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    ...mapGetters({
      hasFullParticipantAccess: "hasFullParticipantAccess"
    })
  },
  methods: {
    ...mapActions("reports", { loadReports: "loadReports" }),
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    openGenerateReport() {
      if (isDesktop) {
        this.$router.push({ path: "/generate-reports" });
      } else {
        openModal(GenerateReports);
      }
    },
    async onPageChange(pageNum: number) {
      await this.loadReports(pageNum);
      this.resetExpandedRows();
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async loadPage() {
      this.resetExpandedRows();
      this.updateContentsLoading(true);
      try {
        await this.loadReports(1);
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    },
    openReport() {
      this.$amplitude.track(TrackingEvent.OpenReport, {});
    },
    reportPlanDates(report) {
      const plan = this.findPlan(report);
      return reportPlanLabel(plan);
    },
    planIsPace(report) {
      const plan = this.findPlanByName(report);
      return plan ? plan.pace_plan : false;
    },
    findCategory(report) {
      let category;
      if (this.planIsPace(report)) {
        category = find(paceSupportCategories, item => item.id === report.plan_supports_id);
      } else {
        category = find(supportCategoryMappings, item => item.id === report.plan_supports_id);
      }
      if (category) {
        return category.name;
      }
      return "All Supports";
    },
    findPlan(report) {
      return this.$store.getters.plans
        .filter(plan => {
          return plan.id == report.plan_id;
        })
        .pop();
    },
    findPlanByName(report) {
      return this.$store.getters.plans
        .filter(plan => {
          return plan.name == report.plan_name;
        })
        .pop();
    },
    reportStartDate(report) {
      let startDate = report.date_from;
      if (startDate == "Invalid Date") {
        const plan = this.findPlan(report);
        if (plan) {
          startDate = dayjs(plan.start_date, "YYYY-MM-DD").format("DD MMM YYYY");
        } else {
          startDate = "";
        }
      }
      return startDate;
    },
    reportEndDate(report) {
      let endDate = report.date_to;
      if (endDate == "Invalid Date") {
        const plan = this.findPlan(report);
        if (plan) {
          endDate = dayjs(plan.end_date, "YYYY-MM-DD").format("DD MMM YYYY");
        } else {
          endDate = "";
        }
      }
      return endDate;
    },
    genDownloadReportAriaLabel(report) {
      // eslint-disable-next-line
      return `Download report for report dates ${this.reportStartDate(report)} to ${this.reportEndDate(report)} generated on ${report.date}`;
    },
    checkRow(key) {
      return this.expandedRows[key];
    },
    toggleRow(key) {
      if (this.expandedRows[key] === undefined) this.expandedRows[key] = false;
      this.expandedRows[key] = !this.expandedRows[key];
      this.$forceUpdate();
    },
    resetExpandedRows() {
      this.expandedRows = reactive({});
    },
    isEmpty() {
      return this.reports && this.reports.length <= 0;
    }
  }
});
</script>

<style lang="scss">
// All .plan-support-area-#{$id} styling is done in reportsGrid.scss
.reports {
  padding: 0 15px 15px 15px;
  margin-bottom: 100px;

  .main-heading {
    padding: 0;
  }

  .claims-pagination {
    margin-left: auto;
    margin-right: auto;
  }

  .filter-button:focus-visible,
  .filter-button:active {
    outline: 3px solid $brand--focus-outline !important;
    outline-offset: 2px;
  }

  .expanded {
    display: table-row !important;
  }
  .table-expand[data-previous-value="collapsed"] .table-expand__svg {
    transform: rotate(180deg);
    transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  }
  .collapsed {
    display: none !important;
  }

  .generate-report-button {
    height: 35px;
    max-width: 20rem;
    --border-style: solid;
    --border-width: 1px;
    --border-color: #{$brand--generate-report-button-color};
    --border-radius: 4px;
    --color: #{$brand--generate-report-button-font-color};
    --background: #{$brand--generate-report-button-bg-color};
    --color-hover: #{$brand--generate-report-button-hover-color};
    --padding-start: 2rem;
    --padding-end: 2rem;
    --background-activated-opacity: 0.3;
    font-size: 0.875rem;
    font-weight: $brand--generate-report-button-font-weight;
    line-height: 1.125rem;
    letter-spacing: 0.16px;

    &::part(native):focus-visible {
      outline: 3px solid $brand--focus-outline;
      outline-offset: 2px;
    }
  }
  .nowrap {
    white-space: nowrap;
  }
  .myndislogo {
    vertical-align: sub;
    margin-left: 5px;
  }
  .table-expand__svg {
    font-size: 16px;
  }

  .expand-collapse-button {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    transform: rotate(90deg);
  }

  .head {
    display: flex;
    justify-content: space-between;
  }
  .page-preamble {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 10px;
  }
  .page-description {
    padding: 20px 0;
    font-size: 16px;
  }
  .reference-cell {
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    a:focus-visible {
      outline: 3px solid $brand--focus-outline-button;
    }
  }
  .support-area-cell {
    width: fit-content;
    max-width: 250px;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    margin: 10px 0 10px 0;
    padding: 5px 10px 5px 10px;
  }
  // min-width is set to 500px, needs to be cleared;
  .data-table-container {
    min-width: unset;
  }

  .download-icon {
    font-size: 1rem;
  }

  .data-table {
    .table-header-label {
      padding: 0;
    }
    td {
      border-bottom: 0 !important;
      vertical-align: top;
      padding: 1rem;
      background-color: $brand--table-td-color;
      color: $brand--font-color-primary;
    }
    tbody {
      &:focus,
      &:active,
      &:hover {
        td {
          background: $brand--accordion-hover;
          color: $brand--font-color-primary;
        }
        background: $brand--theme--ui-background-hover !important;
      }
    }
    th {
      vertical-align: middle;
      background-color: $brand--primary-table-color;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #fff;
    }
    tbody {
      border-bottom: $brand--reports-table-tbody-border-bottom;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.29;
      letter-spacing: 0.16px;
      width: 100%;
    }

    tbody tr {
      height: 82px;
      &.hover {
        background-color: $brand--accordion-hover !important;
      }
    }
    td,
    th {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    tr.expandable-row {
      td {
        border: none !important;
      }
    }
    .bx--child-row-inner-container td:nth-child(2) {
      vertical-align: top;
    }
    tbody tr.expandable-row--hidden {
      background: unset !important;
    }
  }
  .table-header-label {
    text-transform: uppercase;
    font-size: 14px;
  }
  .data-table tbody {
    tr.row:first-of-type td {
      border-top: none !important;
    }
  }
  .data-table-container {
    overflow-x: scroll;
  }
  .data-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    th {
      text-align: left;
    }
  }
  .row {
    line-height: 1.29;
    letter-spacing: 0.16px;
    width: 100%;
    height: 3rem;
    border: none;
  }
  .page-description {
    font-size: 11px;
  }

  .cell-content {
    display: flex;
    flex-direction: column;
    color: #a6a8ac;
    font-size: 11px;
  }
  .view-controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > div {
      flex: 30px 1;
    }
  }
  .filter-button {
    background: $brand--button-filter;
    border: none;
    border-radius: 0.25rem;
    padding: 0.75rem;
    color: $brand--theme--text-01;
    display: flex;
    height: 2.75rem;
    justify-content: center;
    .label {
      display: block;
      margin-left: 1rem;
      font-size: 1rem;
      height: 1rem;
    }
    .caret-icon {
      display: block;
      padding: 0 0 0 1rem;
      height: 1rem;
      svg {
        display: inline-block;
        transform: rotate(-90deg) scale(1.5);
        transition: transform 0.25s;
      }
    }
    &.active {
      background: #263d5d;
      z-index: 1000;
      position: relative;
      .caret-icon {
        svg {
          transform: rotate(180deg) scale(1.5);
        }
      }
    }
    &.arrow-button {
      display: flex;
      justify-content: center;
      width: 2.75rem;
      ion-icon {
        display: inline-block;
        height: 1.25rem;
      }
      &:hover:not(:disabled) {
        background-color: $brand--filter-toggle-button-hover;
        border-color: $brand--filter-toggle-button-hover;
      }
    }

    &:focus,
    &:active {
      outline: 2px solid #fff;
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .record-controls {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .page-meta {
      margin-right: 1rem;
    }
    button:not(:last-child) {
      margin: 0 1.5rem 0 0;
      &.duet-date__toggle {
        margin: 0;
      }
    }
    .button {
      position: relative;
      @media screen and (max-width: $width--breakpoint--md) {
        position: static;
      }
      &:last-child {
        button {
          margin: 0rem;
        }
      }
    }
    .page-meta {
      font-size: 12px;
      padding-left: 15px;
    }
  }
}
</style>
