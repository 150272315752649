<template>
  <section>
    <header>
      <h2>{{ title }}</h2>
      <mpm-tooltip>
        <template v-slot:icon>
          <ion-icon class="help-icon" :src="`${$brand_svg_src}/icon-information2.svg`" />
        </template>
        <template v-slot:content>
          <p>{{ tooltipContent }}</p>
        </template>
      </mpm-tooltip>
    </header>
    <div class="plan-support-categories">
      <ion-grid>
        <ion-row class="ion-align-items-start">
          <ion-col
            size-xl="4"
            size-md="6"
            size-xs="12"
            v-for="planSupportCategory in planSupportCategories"
            :key="planSupportCategory.id"
          >
            <PlanSupportCategory
              :plan-support-category="planSupportCategory"
              :class="{ selected: isSelected(planSupportCategory) }"
              :role="isPlanManaged(planSupportCategory) ? 'link' : 'listitem'"
              @click.native="isPlanManaged(planSupportCategory) ? handleSelect(planSupportCategory) : null"
              @keydown.native.enter.prevent="
                isPlanManaged(planSupportCategory) ? handleSelect(planSupportCategory) : null
              "
            />
          </ion-col>
          <ion-col v-if="planSupportCategories.length === 0" size-xl="4" size-lg="6" size-xs="12">
            <EmptyPlanSupportCategory />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import PlanSupportCategory from "@/components/plans/PlanSupportCategory.vue";
import EmptyPlanSupportCategory from "@/components/plans/EmptyPlanSupportCategory.vue";
import MpmTooltip from "@/components/MpmTooltip.vue";
import { IonIcon, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { mapActions } from "vuex";
import { TrackingEvent } from "@/types/tracking-events";

const CB_CHOICE_CONTROL_ID = 14;

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    tooltipContent: {
      type: String
    },
    planSupportCategories: {
      required: true,
      type: Array
    }
  },
  components: {
    PlanSupportCategory,
    EmptyPlanSupportCategory,
    MpmTooltip,
    IonIcon,
    IonGrid,
    IonCol,
    IonRow
  },
  methods: {
    ...mapActions(["supportItems/getSupportItems", "supportPlanCategory/setSelectedSupportPlanCategory"]),
    handleSelect(planSupportCategory: any) {
      this.$store.dispatch("supportItems/collapseSupportArea", true);
      this.$store.dispatch("supportItems/expandSupportArea", true);
      this.$store.dispatch("supportPlanCategory/clearSelectedSupportPlanCategory");
      this.$store.commit("supportPlanCategory/setSelectedSupportPlanCategory", planSupportCategory);
      this.$store.dispatch("supportItems/getSupportItems");
      this.$amplitude.track(TrackingEvent.SelectPlanSupportCategory);
    },
    isPlanManaged(planSupportCategory: any): boolean {
      return (
        planSupportCategory.managed === "plan" ||
        planSupportCategory.support_category?.support_category_number === CB_CHOICE_CONTROL_ID
      );
    },
    isSelected(planSupportCategory: any) {
      return planSupportCategory.id === this.$store.getters.supportPlanCategory.id;
    }
  }
});
</script>
<style lang="scss" scoped>
section {
  header {
    display: flex;
    background-color: $brand--plan-support-category-group-header-bg;
    height: 44px;
    align-items: center;
    padding: $brand--plan-support-category-group-header-padding;
    margin-left: 20px;
    margin-bottom: 16px;
    margin-right: 1rem;
    gap: 10px;
    .help-icon {
      background: white;
      border: 0;
      border-radius: 11px;
      height: 24px;
      width: 24px;
    }
    h2 {
      font-size: 18px;
      color: $brand--plan-support-category-group-header-color;
    }
  }
  .plan-support-categories {
    max-width: 1380px;
    ion-grid {
      padding: 0 10px;
    }
    ion-col {
      padding: 0;
    }
  }
}
</style>
