<template>
  <div class="list-search-popover">
    <ion-searchbar ref="searchInput" @ionInput="searchItems" />
    <div v-if="message" class="list-search-message">
      <p>{{ message }}</p>
    </div>
    <div v-else-if="searching" class="list-search-message">
      <mpm-loading is-loading />
    </div>
    <ion-list v-else>
      <ion-item v-for="(item, idx) in items" :key="idx" :value="item[valueKey]" button @click="selectItem(item)">
        {{ labelFormatter(item) }}
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { IonContent, IonList, IonItem, IonLabel, IonSearchbar, alertController } from "@ionic/vue";
import { sortBy } from "lodash";
import { closePopover } from "@/helpers/system";

export default defineComponent({
  name: "popover-list-search",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonSearchbar,
    alertController
  },
  emits: ["itemSelected"],
  props: {
    valueKey: String,
    initialItems: Array,
    makeMessage: Function,
    validKeywords: Function,
    labelFormatter: Function,
    searchFunction: Function
  },
  setup() {
    const supportItemElement = ref();
    const searchInput = ref();
    onMounted(async () => {
      const searchInputElement: HTMLIonSearchbarElement = searchInput.value.$el;
      setTimeout(async () => {
        await searchInputElement.setFocus();
        const alert = await alertController.getTop();
        if (alert && alert.hasAttribute("useless")) {
          await alert.remove();
          return;
        }
      }, 150);
    });
    return { supportItemElement, searchInput };
  },
  methods: {
    makeInfoMessage() {
      this.message = this.makeMessage({
        items: this.items,
        keywords: this.keywords,
        keywordValidation: this.keywordValidation,
        searching: this.searching
      });
    },
    searchItems(event) {
      clearTimeout(this.searchRequestId);
      this.keywords = event.target.value;
      this.keywordValidation = this.validKeywords(this.keywords);
      if (this.keywordValidation.valid) {
        this.searching = true;
        this.searchRequestId = setTimeout(async () => {
          if (this.keywords && this.keywords.length >= 3) {
            this.updateItems(await this.searchFunction(this.keywords));
          } else {
            this.updateItems(await this.searchFunction());
          }
        }, 500);
      } else {
        this.searching = false;
      }
      this.makeInfoMessage();
    },
    updateItems(data) {
      setTimeout(() => {
        this.items = sortBy(data, item => item.name.toLowerCase());
        this.searching = false;
        this.makeInfoMessage();
      }, 1000);
    },
    async selectItem(item) {
      this.selectedItem = { ...item, label: this.labelFormatter(item) };
      await closePopover(this.selectedItem, []);
    }
  },
  mounted() {
    this.keywordValidation = this.validKeywords(this.keywords);
    this.makeInfoMessage();
  },
  data() {
    return {
      items: this.initialItems,
      keywords: "",
      keywordValidation: {},
      searching: false,
      message: false,
      selectedItem: null,
      searchRequestId: null
    };
  }
});
</script>
<style lang="scss">
.list-search-popover {
  padding: 20px 0;
  height: 100%;

  ion-item {
    --background: #{$brand--global-nav-bg};
    --inner-padding-top: 15px;
    --inner-padding-bottom: 15px;
    --inner-padding-start: 25px;
    --inner-padding-end: 25px;
    --padding-start: 0;
    --background-activated-opacity: 0.5;
    --background-hover: #{$brand--plan-dropdown-options-hover};
    --background-hover-opacity: #{$brand--plan-dropdown-options-hover-opacity};
    font-size: 1rem;
  }

  ion-searchbar {
    --color: #{$brand--reserve-budget-popover-search-color};
    --icon-color: #{$brand--reserve-budget-popover-search-icon-color};
    --placeholder-color: #{$brand--text-input-color-placeholder};
    --box-shadow: #{$brand--reserve-budget-popover-search-box-shadow};
    padding: 0 25px;
    .searchbar-input {
      border: $brand--reserve-budget-popover-search-border;
      font-size: 1rem;
    }
    .searchbar-clear-button:focus {
      outline: 3px solid $brand--focus-outline;
      outline-offset: 2px;
      opacity: 1;
    }
  }

  ion-list {
    margin: $brand--reserve-budget-popover-list-margin;
    background-color: transparent;
  }
}

.list-search-message {
  padding: 2rem;
  text-align: center;
  height: 100%;
}
</style>
