<template>
  <ion-page>
    <modal-header :modal-name="fileName" />
    <ion-content :scroll-events="true">
      <div class="file-viewer">
        <img v-if="isImg(fileUrl)" :src="fileUrl" alt="PREVIEW" />
        <vue-pdf-embed v-else-if="isPdf(fileUrl)" :source="fileUrl" />
        <h4 v-else>Nothing to display</h4>
      </div>
    </ion-content>
    <ion-footer class="form-actions file-viewer-footer">
      <ion-button class="secondary-action" @click="close">Close</ion-button>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { closeModal } from "@/helpers/system";
import ModalHeader from "@/components/ModalHeader.vue";
import VuePdfEmbed from "vue-pdf-embed";
import { IonContent, IonFooter, IonIcon, IonPage } from "@ionic/vue";

interface Content extends HTMLElement {
  scrollToTop(n: number): void;
}

export default defineComponent({
  components: {
    VuePdfEmbed,
    IonPage,
    IonContent,
    IonFooter,
    IonIcon,
    ModalHeader
  },
  props: {
    fileUrl: {
      required: true,
      type: String
    },
    fileName: {
      type: String,
      default: "File Viewer"
    }
  },
  data() {
    return {
      modalName: "File Viewer"
    };
  },
  methods: {
    close() {
      closeModal();
    },
    isImg(url: string) {
      return url.includes(".jpg") || url.includes(".jpeg") || url.includes(".png");
    },
    isPdf(url: string) {
      return url.includes(".pdf");
    }
  }
});
</script>
<style lang="scss" scoped>
.file-viewer {
  max-width: 900px;
  margin: auto;
  height: 100%;
  background: black;
}
.file-viewer-footer {
  margin: 0 !important;
  padding: 2vh 5vw 4vh 0;
}
</style>
