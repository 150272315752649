<template>
  <ion-app class="dark">
    <mpm-skip-link v-if="shouldShowSkipLinks" v-desktop-only :target="toKebab(this.$route.name)" type="class">
      {{ skipToMainLabel }}
    </mpm-skip-link>
    <mpm-skip-link v-if="shouldShowSkipLinks" v-desktop-only target="submit-claim" type="id">
      Skip to Submit Claim
    </mpm-skip-link>
    <ion-router-outlet />
    <loading :is-loading="fullScreenLoading || startupLoading"></loading>
    <version-dialog />
  </ion-app>
</template>

<script lang="ts">
import { getSfParticipantPlans, getSfParticipantPlanSupportCategories } from "@/api/salesforce/plans";
import Loading from "@/components/Loading.vue";
import VersionDialog from "@/components/VersionDialog.vue";
import MpmSkipLink from "@/components/MpmSkipLink.vue";
import { isDesktop, isMobile, isMobileWeb } from "@/directives/platform";
import { createToast } from "@/helpers/notification";
import { handleFailedParticipantCheck, hasParticipants } from "@/helpers/participants";
import returnCurrentPlan from "@/helpers/plans/returnCurrentPlan";
import { getZoomLevel, isMobileView, isIonUnderMdView } from "@/helpers/screen";
import { extractHashValues } from "@/helpers/system";
import { isUserLoggedIn } from "@/helpers/user";
import { toKebab } from "@/helpers/formatter";
import helpLinks from "@/helpLinks.json";
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import LayoutBase from "@/layouts/LayoutBase.vue";
import { Plan } from "@/types/salesforce";
import { TrackingEvent } from "@/types/tracking-events";
import { App } from "@capacitor/app";
import { getPlatforms, IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { isActivationRoute, isAuthRoute, isErrorRoute, navRoutes } from "./router";

export default defineComponent({
  name: "MyPlanManager",
  components: {
    LayoutBase,
    ErrorLayout,
    Loading,
    MpmSkipLink,
    IonApp,
    IonRouterOutlet,
    VersionDialog
  },
  methods: {
    ...mapActions("layout", {
      updateStartupLoading: "updateStartupLoading",
      updateFullScreenLoading: "updateFullScreenLoading",
      updateContentsLoading: "updateContentsLoading"
    }),
    ...mapActions({
      loadPlanSupportTypes: "pace/supportPlanTypes/fetchSupportTypes"
    }),
    async loginWithToken(token: string) {
      console.log(`Logging in with token: ${token}`);
      await this.$store
        .dispatch("user/loginWithToken", {
          passwordlessToken: token
        })
        .then(async () => {
          this.$amplitude.track(TrackingEvent.Login, {
            email: this.$store.getters.user.email,
            method: "Passwordless"
          });
          await this.$store.dispatch("participant/getParticipants").then(async () => {
            if (hasParticipants()) {
              await this.$router.push({ path: "/" });
            } else {
              handleFailedParticipantCheck(this.$router);
            }
          });
        })
        .catch(async error => {
          console.error("Log in error", error);
          await createToast(error.message, "error");
        });
    },
    async planChangeHandler(v) {
      if (!v) return;
      try {
        this.updateFullScreenLoading(true);
        // Determine the current plan's support budgets.
        const planSupportBudgets = new Set();
        // Reset state.
        await this.$store.dispatch("currentPlan/setSupportBudgets", []);

        const planSupportCategories = (await getSfParticipantPlanSupportCategories()) as Array<any>;
        planSupportCategories.forEach(c => {
          const category = c.support_category.support_type_name;
          planSupportBudgets.add(category);
        });

        await this.$store.dispatch("currentPlan/setPlanSupportCategories", planSupportCategories);
        await this.$store.dispatch("currentPlan/setSupportBudgets", Array.from(planSupportBudgets));
      } catch (e) {
        console.log(e);
      } finally {
        this.updateFullScreenLoading(false);
      }
    },
    async participantChangeHandler(v, oldValue = null) {
      if (!v) return;
      this.updateFullScreenLoading(true);
      this.getCsatPreferences();
      try {
        let plans: Plan[] = [];

        plans = (await getSfParticipantPlans()) as Array<Plan>;
        await this.checkPlans(plans);

        if (!oldValue) {
          const currentPlan = this.$store.getters.currentPlan;
          const isPlanExist = plans.find(plan => plan.id == currentPlan?.id);
          if (!isPlanExist) {
            this.setCurrentPlanToActivePlan(plans);
          } else {
            this.updateFullScreenLoading(false);
          }
        } else {
          this.setCurrentPlanToActivePlan(plans);
        }

        await this.$store.dispatch("plans/setPlans", plans);
        await this.loadPlanSupportTypes();
      } catch (error) {
        console.error(error);
        this.updateFullScreenLoading(false);
      } finally {
        // this.updateFullScreenLoading(false);
      }
    },
    setCurrentPlanToActivePlan(plans) {
      const currentPlan = returnCurrentPlan(plans);
      if (!currentPlan) {
        this.$store.dispatch("currentPlan/clearState");
        throw "No current plan available for the client.";
      }
      this.$store.dispatch("currentPlan/setCurrentPlan", currentPlan);
    },
    getCsatPreferences() {
      if (this.$brand === "mpm") {
        this.$store.dispatch("userSettings/getClientCsatSettings");
      }
    },
    async checkPlans(plans) {
      if (plans.length < 1) {
        await this.$store.dispatch("currentPlan/clearState");
        await this.$store.dispatch("plans/setPlans", []);
        throw "No plans available for the client.";
      }
    },
    toKebab(str) {
      return toKebab(str);
    }
  },
  async mounted() {
    try {
      this.updateStartupLoading(true);

      // Set display properties
      this.isMobileView = isMobileView(window.innerWidth);
      this.zoomLevel = getZoomLevel();
      window.addEventListener("resize", () => {
        this.isMobileView = isMobileView(window.innerWidth);
        this.zoomLevel = getZoomLevel();
      });

      // Setup amplitude for users
      this.$amplitude.initClient(this.$store.state.user.email);
      this.$amplitude.watchStore(this.$store);
      this.$amplitude.trackNavigations(this.$router);

      // Set loadings to false
      this.updateFullScreenLoading(false);
      this.updateContentsLoading(false);

      await this.$store.dispatch("layout/getClientPortalStatus");

      // Check for maintenance
      if (this.isUnderMaintenance) {
        await this.$router.push({ path: "/maintenance" });
        return;
      }

      // Make sure that when opening the App it will land on the Plans page without any drilldown
      await this.$store.dispatch("supportPlanCategory/clearSelectedSupportPlanCategory");
      await this.$store.dispatch("supportItems/collapseSupportArea", false);
      await this.$store.dispatch("supportItems/expandSupportArea", false);

      if (isUserLoggedIn()) await this.participantChangeHandler(this.$store.getters.participant.id);

      console.log("Handling passwordless login for:", getPlatforms());

      // Are they logging in with a URL token?
      if (isDesktop || isMobileWeb) {
        const hash = window.location.hash;
        const hashValues = extractHashValues(hash);
        // Check if hash string contains error?
        if (hashValues.error && hashValues.error_description) {
          if (hashValues.error === "unauthorized") {
            await createToast(
              "This code may have expired or has already been used. Please request a new SMS or Email code to log in again.",
              "error"
            );
          } else {
            await createToast(decodeURI(hashValues.error_description), "error");
          }
        } else if (hashValues.id_token && !isUserLoggedIn()) {
          await this.loginWithToken(hashValues.id_token);
        }
      } else if (isMobile) {
        App.addListener("appUrlOpen", data => {
          // Handle universal links here
          const slug = data.url.split("myplanmanager.com.au/").pop();
          const tokenFromHash = slug.split("token=").pop();
          // Login with the token
          this.loginWithToken(tokenFromHash);
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.updateStartupLoading(false);
    }
  },
  computed: {
    ...mapState("layout", { currentTab: "currentTab" }),
    ...mapGetters("layout", {
      startupLoading: "getStartupLoading",
      fullScreenLoading: "getFullScreenLoading",
      isUnderMaintenance: "getMaintenanceStatus"
    }),
    skipToMainLabel(): string {
      let mainLabel = "Skip to Content";
      const routeLabel = this.$route?.matched[0]?.meta?.label;
      if (routeLabel) {
        mainLabel = `Skip to ${this.$options.filters.capitalizeWord(routeLabel)} Content`;
      }
      return mainLabel;
    },
    isAuthRoute(): boolean {
      return isAuthRoute(this.$route.name);
    },
    isErrorRoute(): boolean {
      return isErrorRoute(this.$route.name);
    },
    isActivationRoute(): boolean {
      return isActivationRoute(this.$route.name);
    },
    routeComponent() {
      if (this.isAuthRoute || this.isActivationRoute) {
        return { layout: "div", bind: {} };
      }
      if (this.isErrorRoute) {
        return {
          layout: "error-layout",
          bind: {}
        };
      }
      return {
        layout: "layout-base",
        bind: {
          routes: this.routes
        }
      };
    },
    isIonUnderMdView() {
      return isIonUnderMdView(window.innerWidth);
    },
    shouldShowSkipLinks() {
      return !this.skipLinkExcludeRouteNameList.includes(this.$route.name);
    }
  },
  watch: {
    "$store.getters.currentPlan.id": {
      deep: true,
      immediate: true,
      async handler(v) {
        await this.planChangeHandler(v);
      }
    },
    "$store.getters.participant.id": {
      async handler(v, oldValue) {
        await this.participantChangeHandler(v, oldValue);
      }
    },
    isUnderMaintenance(v) {
      if (v) {
        this.$router.push({ path: "/maintenance" });
      } else {
        // logout use and refresh the page
        this.$nextTick(() => {
          this.$store.dispatch("user/logout");
          this.$router.push({ path: "/login" }).then(() => {
            location.reload();
          });
        });
      }
    }
  },
  data() {
    return {
      // globally available as this.$root.isMobileView
      isMobileView: true,
      helpLinks, // If I remove this, I will error and see the loading spinner again.
      routes: navRoutes,
      zoomLevel: getZoomLevel(),
      skipLinkExcludeRouteNameList: ["PasswordlessLogin", "NoClients", "Activate", "ActivateSuccess", "Maintenance"]
    };
  }
});
</script>
<style lang="scss">
template {
  display: block !important;
}

/* Forcing Dark Mode and overriding some odd :host css behaviour Content*/
/* These should move to app.scss but it takes 2 minutes to re-compile a changes.  */
ion-content {
  pointer-events: auto;
  --background: #{$brand--theme--ui-background};
  --color: #{$brand--font-color-primary};
}

donutchart {
  --overflow: hidden;
}
.page-content:focus-visible,
.focus-item:focus-visible {
  border: 3px solid $brand--focus-outline;
  outline: none;
}
.content-spacer {
  margin-bottom: calc(96px + var(--ion-safe-area-top, 0));
  @media only screen and (min-width: 42rem) {
    margin-top: 56px;
  }
  @media only screen and (max-width: 42rem) {
    .content-spacer {
      margin-top: 48px;
    }
  }
}
hr {
  border-top: $brand--horizontal-rule;
}
.modal-content {
  background: $brand--theme--ui-background;
  color: $brand--font-color-primary;
  padding: 1rem;
  hr {
    border: 0;
    height: 2px;
    border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
    margin: 0.5rem 0;
  }
}
.alert-tappable {
  contain: none !important;
  height: unset !important;
}
.bx--data-table-container {
  overflow-x: scroll;
}
@media only screen and (max-width: 42rem) {
  .header-spacer-mobile {
    margin-top: 48px;
  }
}
</style>
