<template>
  <div></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { debounce } from "lodash";
import { isDesktop } from "@/directives/platform";
declare global {
  interface Window {
    gf: any;
  }
}
export default defineComponent({
  mounted() {
    if (isDesktop && this.$brand === "mpm") {
      this.mountGetFeedbackScript();
    }
  },
  methods: {
    setParameters(params) {
      window.gf("setParameters", params);
    },
    mountGetFeedbackScript: debounce(async function() {
      //Load getfeedback script once
      const isGetFeedBackScriptLoaded = document.getElementById("getFeedBackId");
      if (!isGetFeedBackScriptLoaded) {
        const getFeedbackScript = document.createElement("script");
        getFeedbackScript.setAttribute("src", "/scripts/getFeedback.js");
        getFeedbackScript.setAttribute("id", "getFeedBackId");
        getFeedbackScript.onload = () => {
          this.$emit("loaded");
        };
        document.body.appendChild(getFeedbackScript);
      }
    }, 125)
  }
});
</script>
