<template>
  <div class="claims-pagination">
    <paginate
      :value="currentPage"
      :page-count="totalPage"
      :click-handler="onPageClick"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'page'"
      :page-class="'page-item'"
    >
    </paginate>
  </div>
</template>

<style lang="scss" scoped>
.claims-pagination {
  text-align: center;
  margin-top: 1rem;
  margin-right: 2.5rem;
  @media screen and (max-width: $width--breakpoint--md) {
    margin-right: 0px;
  }

  ::v-deep {
    .page {
      display: flex;
      white-space: nowrap;
      justify-content: center;
      li {
        border: solid 1px transparent;
        padding: 0.25rem;
        margin: 0 0.25rem 0 0.25rem;
        display: flex;
        align-items: center;
        a {
          display: block;
          color: $brand--font-color-primary;
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.75rem;
          outline: none;
          border-radius: 0.125rem;
        }
        &:focus-visible {
          outline: 3px solid $brand--focus-outline;
        }
        &.active {
          a {
            color: $brand--pagination-font-color-active;
            background: $brand--pagination-color;
          }
        }
        &:hover {
          border: dotted 1px #cccccc;
        }

        &:first-child {
          overflow: hidden;
          padding: 0;
          margin: 0;
          &:focus-visible {
            outline: 3px solid $brand--focus-outline;
          }
          a:before {
            display: inline;
            content: " ";
            background: $brand--pagination-left-arrow-active center center no-repeat;
            padding-left: 1rem;
            margin-right: 1rem;
          }
          &.disabled {
            a:before {
              background: $brand--pagination-left-arrow-disabled center center no-repeat;
            }
          }
        }
        &:last-child {
          overflow: hidden;
          padding: 0;
          margin: 0;
          &:focus-visible {
            outline: 3px solid $brand--focus-outline;
          }
          a:before {
            display: inline;
            content: " ";
            background: $brand--pagination-right-arrow-active center center no-repeat;
            padding-left: 1rem;
            margin-right: 1rem;
          }
          &.disabled {
            a:before {
              background: $brand--pagination-right-arrow-disabled center center no-repeat;
            }
          }
        }

        &.disabled {
          border: solid 1px transparent;
          a {
            color: #999999;
            cursor: not-allowed;
          }
        }
      }
      @media screen and (max-width: $width--breakpoint--md) {
        justify-content: space-between;
        li {
          margin: 0;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import Paginate from "@/components/Pagination.vue";

export default defineComponent({
  name: "ClaimsPagination",
  components: {
    Paginate
  },
  props: {
    pagination: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    onPageClick(page) {
      this.$emit("change-page", page);
    }
  },
  computed: {
    currentPage(): number {
      return this.pagination ? this.pagination.current_page : 1;
    },
    totalPage(): number {
      return this.pagination ? Math.ceil(this.pagination.total / this.pagination.per_page) : 0;
    }
  },
  data() {
    return {};
  }
});
</script>
