<template>
  <ion-content
    class="page-content plan-section pace-plan-view2-tab"
    tabindex="0"
    aria-label="Plan page content"
    ref="planContent"
  >
    <section id="pace-plan-view-2" aria-label="pace plan view 2 page">
      <div style="overflow:auto" tabindex="-1">
        <div class="content-spacer">
          <breadcrumbs :breadcrumb-items="breadcrumbItems"></breadcrumbs>

          <div v-if="!$root.isIonUnderMdView" class="main-title-row">
            <h1 class="main-title">Plan</h1>
            <span class="plan-span">
              {{ $filters.friendlyDate(currentPlan.start_date) }} - {{ $filters.friendlyDate(currentPlan.end_date) }}
            </span>
          </div>
          <ion-row v-else class="mobile-main-title-row">
            <ion-col size="6" class="mobile-main-title">
              Plan
            </ion-col>
            <ion-col size="6" class="mobile-plan-span ion-text-end">
              {{ $filters.friendlyDate(currentPlan.start_date) }} - {{ $filters.friendlyDate(currentPlan.end_date) }}
            </ion-col>
          </ion-row>
          <p class="pace-message">
            This shows your plan details from your new look NDIS plan.<img
              class="myndislogo"
              :src="`${$brand_svg_src}/myndislogo.png`"
              alt="my NDIS logo"
            />
          </p>

          <pace-page-header
            go-back-path="/plan"
            tooltip-content="This information tells you how much of your support area budget you have spent and how much you have left.
              It will only show new budget information for your plan managed supports. If you have self and agency
              managed supports, please go to your ‘my NDIS portal’."
            :show-body="$root.isIonUnderMdView"
          >
            <template v-slot:title>{{ headerName + " budget" }}</template>
            <template v-slot:body-content>
              <ion-grid style="margin: 0 -10px">
                <ion-row>
                  <ion-col size-md="6">
                    <pace-plan-balance-card :balance-data="selectedPlanSupportType" />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </pace-page-header>
          <ion-grid v-if="!$root.isIonUnderMdView" class="balance-cards-section">
            <ion-row>
              <ion-col size-md="6">
                <pace-plan-balance-card :balance-data="selectedPlanSupportType" class="balance-card" />
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-card class="sub-label">
            <h1>Your support categories</h1>
          </ion-card>
          <ion-grid class="support-categories">
            <ion-row>
              <ion-col
                size-lg="4"
                size-md="6"
                size-xs="12"
                v-for="(planSupport, idx) in planSupports"
                :key="'plan-support-tile-' + idx"
              >
                <pace-plan-support-tile
                  class="pace-plan-support-tile"
                  :support-type-color-class="supportTypeColorClass"
                  :plan-support="planSupport"
                />
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-card class="sub-label">
            <h1>Your spending overview</h1>
          </ion-card>
          <ion-grid class="spend-overview">
            <ion-row>
              <ion-col size="12" size-lg="6">
                <ion-card class="donut-card">
                  <SupportCategoryDonut
                    title="Funds spent by support category"
                    :secondary-title="`Opening ` + headerName + ` budget balance`"
                    :supports="planSupports"
                  />
                </ion-card>
              </ion-col>
              <ion-col size="12" size-lg="6">
                <pace-plan-budget-spend-overview class="pace-plan-budget-spend-overview-card" />
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonRow,
  IonCol,
  IonGrid,
  IonLabel,
  IonItem
} from "@ionic/vue";
import { defineComponent } from "vue";
import pacePageHeader from "@/components/pace-plan/PacePageHeader.vue";
import Breadcrumbs from "@/components/menu/Breadcrumbs.vue";
import { find } from "lodash";
import { supportTypes } from "@/helpers/support-category";
import SupportCategoryDonut from "@/components/plans/SupportCategoryDonut.vue";
import PacePlanBalanceCard from "@/components/pace-plan/PacePlanBalanceCard.vue";
import PacePlanSupportTile from "@/components/pace-plan/PacePlanSupportTile.vue";
import { mapGetters } from "vuex";
import { converter } from "@/helpers/filters";
import PacePlanBudgetSpendOverview from "@/components/pace-plan/PacePlanBudgetSpendOverview.vue";
import SiteMap from "@/components/menu/SiteMap.vue";

export default defineComponent({
  name: "PacePlanView2",
  components: {
    PacePlanBudgetSpendOverview,
    PacePlanSupportTile,
    PacePlanBalanceCard,
    SupportCategoryDonut,
    pacePageHeader,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonRow,
    IonCol,
    IonGrid,
    IonLabel,
    IonItem,
    Breadcrumbs,
    SiteMap
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      supports: "currentPlan/getPlanSupportCategories",
      planSupports: "pace/planSupports/getPlanSupports",
      selectedPlanSupportType: "pace/supportPlanTypes/getSelectedSupportType",
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    headerName() {
      return find(supportTypes, supportType => supportType.route_name === this.$route.params.supportArea)?.sf_name;
    },
    breadcrumbItems() {
      const supportType = find(supportTypes, supportType => {
        if (supportType.route_name === this.$route.params.supportArea) {
          return true;
        }
      });
      const budgetType = supportType?.sf_name + " Budget";

      return [
        { title: "Plan", link: "plan" },
        { title: budgetType, link: `plan/${this.$route}` }
      ];
    },
    supportTypeColorClass() {
      return converter("route_name", this.$route.params.supportArea, "cssClass", supportTypes);
    }
  }
});
</script>

<style lang="scss" scoped>
.content-spacer {
  padding: 50px 16px 0 16px;
  margin-top: 0;
}
.main-title-row {
  display: flex;
  align-items: center;
  ion-col {
    padding: 0;
  }
  .main-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 43px;
  }
  .plan-span {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-left: 55px;
  }
}
.mobile-main-title-row {
  display: flex;
  align-items: center;
  ion-col {
    padding: 0;
  }
  .mobile-main-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
  }

  .mobile-plan-span {
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }
}
.plan-tile {
  background-color: $brand--pace-plan-tile-bg;
}
.pace-message {
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;

  .myndislogo {
    vertical-align: sub;
    margin-left: 5px;
  }
}

.sub-label {
  background-color: $brand--pace-plan-sub-label-bg;
  margin: 40px 0 24px;
  padding: 20px;
  h1 {
    color: white;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }
}

ion-grid {
  padding: 0;
  ion-col {
    padding: 0;
  }
}

.balance-cards-section {
  margin-top: 40px;
  .balance-card {
    margin: 0;
  }
}

.support-categories {
  margin: -10px;
}

.spend-overview {
  .donut-card {
    @media screen and (min-width: $width--breakpoint--lg) {
      margin-right: 10px;
    }
    margin-inline: 0;
  }
  .pace-plan-budget-spend-overview-card {
    @media screen and (min-width: $width--breakpoint--lg) {
      margin-left: 10px;
    }
  }
}

.pace-plan-support-tile {
  min-height: 269px;
}
</style>
