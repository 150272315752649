<template>
  <ion-page>
    <modal-header v-mobile-only :modal-name="modalName"></modal-header>
    <ion-content tabindex="0" class="page-content generate-reports-tab" aria-label="Generate reports page content">
      <section id="generate-reports" aria-label="generate reports page">
        <div style="overflow:auto" tabindex="-1">
          <div class="generate-reports">
            <form class="generate-report-form">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <h1 class="main-title">{{ modalName }}</h1>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <p class="main-desc">
                      Put together a report for any dates within a plan.
                    </p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div class="combobox-style">
                      <ion-label>PLAN</ion-label>
                      <ion-select
                        placeholder="Choose an option"
                        :interface-options="interfaceOptions"
                        interface="popover"
                        :value="generateReportParams.planId"
                        :selected-text="selectedPlan?.planLabel"
                        v-model="generateReportParams.planId"
                        :disabled="false"
                        class="select-plan"
                        ref="planDropdown"
                        @ionBlur="onPopoverBlur('plan')"
                      >
                        <ion-select-option v-for="(plan, idx) in plans" :key="idx" :value="plan.sfid">
                          {{ plan.planLabel }}
                        </ion-select-option>
                      </ion-select>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <legend class="date-range-legend">REPORT DATE RANGE</legend>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <DatePicker
                      :disabled="false"
                      :min="startDateLimit"
                      :max="endDateLimit"
                      :startDateInvalidMessage="errors['dateRange.startDate']"
                      :endDateInvalidMessage="errors['dateRange.endDate']"
                      @input="setDateRange($event)"
                      @change="setDateRange($event)"
                      ref="datePicker"
                      class="date-picker"
                    ></DatePicker>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div class="combobox-style">
                      <ion-label>SUPPORT AREA</ion-label>
                      <ion-select
                        class="select-support-area"
                        placeholder="All Supports"
                        :interface-options="interfaceOptions"
                        interface="popover"
                        :value="generateReportParams.supportCategoryId"
                        v-model="generateReportParams.supportCategoryId"
                        :disabled="!generateReportParams.planId"
                        ref="supportDropdown"
                        @ionBlur="onPopoverBlur('support')"
                      >
                        <ion-select-option :value="0">All Supports</ion-select-option>
                        <ion-select-option
                          v-for="(area, idx) in supportAreas"
                          :key="idx"
                          :value="area.support_category.support_category_number"
                        >
                          {{
                            selectedPlan.pace_plan
                              ? area?.support_category.pace_support_category_name
                              : area?.support_category?.support_category_name
                          }}
                        </ion-select-option>
                      </ion-select>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <div class="form-actions">
                      <ion-button
                        v-mobile-only
                        class="secondary-action"
                        @click="
                          clearFilters();
                          closeModal;
                        "
                      >
                        Close
                      </ion-button>
                      <ion-button
                        v-desktop-only
                        class="secondary-action"
                        @click="
                          clearFilters();
                          $router.push({ path: '/reports' });
                        "
                      >
                        Cancel
                      </ion-button>
                      <ion-button class="call-to-action" :disabled="!generateReportParams.planId" @click="submit()">
                        Generate
                      </ion-button>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </form>
          </div>
          <site-map v-desktop-only></site-map>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { getSfPlanSupportCategories } from "@/api/support-plan-categories";
import { Plan } from "@/types/plans";
import { TrackingEvent } from "@/types/tracking-events";
import { closeModal } from "@/helpers/system";
import dayjs from "dayjs";
import { defineComponent, reactive } from "vue";
import { createNamespacedHelpers } from "vuex";
import validate from "@/validators/GenerateReport";
import { Browser } from "@capacitor/browser";
import ModalHeader from "@/components/ModalHeader.vue";
import DatePicker from "@/components/DatePicker.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import { isMobile, isMobileWeb } from "@/directives/platform";
import {
  loadingController,
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonButtons,
  IonContent,
  IonLabel,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
  isPlatform
} from "@ionic/vue";

const { mapActions, mapGetters } = createNamespacedHelpers("reports");

interface GenerateReportParams {
  planId: string;
  supportCategoryId: number;
  dateRange?: any;
}

interface Errors {
  planId?: string;
  supportCategoryId?: string;
  startDate?: string;
  endDate?: string;
}

export default defineComponent({
  components: {
    loadingController,
    IonRow,
    IonCol,
    IonGrid,
    IonButton,
    IonButtons,
    IonContent,
    IonLabel,
    IonHeader,
    IonIcon,
    IonInput,
    IonPage,
    IonSpinner,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonToolbar,
    ModalHeader,
    DatePicker,
    SiteMap
  },
  setup() {
    const interfaceOptions = {
      cssClass: "dropdown-popover",
      mode: "ios",
      size: "cover",
      id: "ion-select-ref"
    };
    return { closeModal, interfaceOptions };
  },
  ionViewWillEnter() {
    this.clearFilters();
  },
  ionViewDidEnter() {
    setTimeout(() => {
      const planDropDown = document.querySelector(".select-plan").shadowRoot;
      if (planDropDown) {
        planDropDown.querySelector(".select-wrapper").setAttribute("style", "display : block; padding-top: 10px;");
      }
      const supportDropdown = document.querySelector(".select-support-area").shadowRoot;
      if (supportDropdown) {
        supportDropdown.querySelector(".select-wrapper").setAttribute("style", "display : block; padding-top: 10px;");
      }
    }, 600);
  },
  data() {
    return {
      modalName: "Generate Report",
      loading: false,
      supportAreaLoading: false,
      reportUrl: "",
      selectedPlan: {},
      latestEndDate: dayjs(new Date()).format("YYYY-MM-DD"), // today
      generateReportParams: {
        planId: null,
        supportCategoryId: 0
      } as GenerateReportParams,
      supportAreas: [],
      errors: reactive({}) as Errors
    };
  },
  async mounted() {
    await this.loadPlanOptions();
  },
  watch: {
    "generateReportParams.planId"(planId) {
      if (planId === null || typeof planId === "undefined" || planId === "" || !this.$store.getters.participant.sfid)
        return;
      this.selectedPlan = this.findSfPlan(planId);
      this.getSupportAreas(this.selectedPlan.sfid);
    },
    "$store.getters.participant.id": {
      async handler(v) {
        this.clearFilters();
        if (this.$store.getters.participant.id) {
          await this.loadPlanOptions();
        }
      }
    }
  },
  computed: {
    ...mapGetters({ plans: "uiPlans" }),
    generateDisabled(): boolean {
      return this.loading || !this.generateReportParams.planId;
    },
    startDateLimit() {
      if (typeof this.selectedPlan?.start_date !== "undefined") {
        return dayjs(this.selectedPlan?.start_date).format("YYYY-MM-DD");
      }
      return this.selectedPlan?.start_date;
    },
    endDateLimit(): string {
      if (dayjs(this.selectedPlan?.end_date).isAfter(this.latestEndDate)) {
        return dayjs(this.latestEndDate).format("YYYY-MM-DD");
      }
      return dayjs(this.selectedPlan?.end_date).format("YYYY-MM-DD") || dayjs(this.latestEndDate).format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapActions(["generateUserReport", "loadPlanOptions"]),
    findPlan(planId: number): Plan {
      for (const plan of this.plans) {
        if (plan.sfid === planId) {
          console.log("found plan", plan);
          return plan;
        }
      }
      return null;
    },
    findSfPlan(planSfid: string): Plan {
      for (const plan of this.plans) {
        if (plan.sfid === planSfid) {
          return plan;
        }
      }
      return null;
    },
    async getSupportAreas(planId) {
      this.supportAreaLoading = true;
      try {
        this.supportAreas = await getSfPlanSupportCategories(planId);
      } catch (error) {
        console.error(error);
      } finally {
        this.supportAreaLoading = false;
      }
    },
    setDateRange(event) {
      this.generateReportParams.dateRange = event;
      if (Object.keys(this.errors).length > 0) {
        this.validate();
      }
    },
    async submit() {
      this.loading = true;
      const loadingReport = await loadingController.create({
        message: "Generating Report...",
        cssClass: "loading-controller"
      });
      await loadingReport.present();

      this.validate();
      if (Object.keys(this.errors).length > 0) {
        loadingReport.dismiss();
        return;
      }
      const reportParamsToSend = { ...this.generateReportParams }; // Copy to avoid data formatting breaking stuff after 1 report generated.
      if (this.generateReportParams.dateRange?.endDate && this.generateReportParams.dateRange?.startDate) {
        reportParamsToSend.dateRange = {
          startDate: dayjs(this.generateReportParams.dateRange.startDate).format("DD-MM-YYYY"),
          endDate: dayjs(this.generateReportParams.dateRange.endDate).format("DD-MM-YYYY")
        };
      }
      reportParamsToSend.isSfClaimingExperience = this.$store.getters.isSfClaimingExperience;
      try {
        const report = await this.generateUserReport(reportParamsToSend);
        this.$amplitude.track(TrackingEvent.GenerateReport, {
          pacePlan: this.selectedPlan.pace_plan
        });
        if (report.report_queued) {
          await this.$router.push({ name: "ReportsTab", query: { queued: "true" } }).then(() => {
            if (isMobile || isMobileWeb) {
              location.reload();
            }
          });
        } else {
          this.reportUrl = report.url;
          loadingReport.dismiss();
          this.$amplitude.track(TrackingEvent.OpenReport, {});
          if (isPlatform("ios")) {
            await Browser.open({ url: this.reportUrl });
          } else {
            window.open(this.reportUrl);
          }
          this.clearFilters();
        }
      } catch (error) {
        loadingReport.dismiss();
        // TODO add notification here
      } finally {
        loadingReport.dismiss();
        this.loading = false;
      }
    },
    validate() {
      this.errors = validate(this.generateReportParams, this.startDateLimit, this.endDateLimit);
    },
    clearFilters() {
      this.selectedPlan = {};
      this.generateReportParams = {
        planId: null,
        supportCategoryId: 0,
        dateRange: null
      } as GenerateReportParams;
      this.$refs.datePicker.setDates("", "");
    },
    onPopoverBlur(type) {
      const popover = document.querySelector("#app #ion-select-ref .popover-viewport");
      if (popover) {
        const list = popover.querySelector("ion-select-popover ion-list ion-radio-group").children;
        if (list && list.length > 0) {
          Object.keys(list).forEach(key => {
            list[key].addEventListener("keydown", e => {
              e.preventDefault();
              if (e.key === "Enter") {
                if (type === "plan") {
                  this.setSelectedPlan(key);
                }
                if (type === "support") {
                  this.setSelectedPlanSupport(key);
                }
              }
            });
          });
        }
      }
    },
    setSelectedPlan(key) {
      const plan = this.plans[key];
      this.generateReportParams.planId = plan.sfid;
      this.$refs.planDropdown.$el.close();
    },
    setSelectedPlanSupport(key) {
      const support = this.supportAreas[key - 1] ?? null;
      this.generateReportParams.supportCategoryId = support ? support.support_category.support_category_number : 0;
      this.$refs.supportDropdown.$el.close();
    }
  }
});
</script>
<style scoped lang="scss">
.generate-reports {
  padding: 1.5rem;
  h1 {
    font-size: 36px;
  }
  @media screen and (max-width: $width--breakpoint--md) {
    h1 {
      font-size: 20px;
    }
    padding: 0.5rem;
    ion-select::part(text) {
      width: 100%;
    }
    ion-select::part(placeholder) {
      width: 100%;
    }
    .date-picker {
      max-width: 100%;
    }
  }
}

form.generate-report-form {
  background: none;
  width: 100%;
  max-width: 100%;
}

.combobox-style {
  @media screen and (min-width: $width--breakpoint--md) {
    max-width: 250px;
  }
  ion-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 12px;
  }

  ion-select {
    -webkit-appearance: none;
    flex-direction: row;
    align-items: center;
    appearance: none;
    background: $brand--text-input-bg;
    border-width: $brand--text-input-border-width;
    border-style: $brand--text-input-border-style;
    border-color: $brand--text-input-border-color;
    border-radius: $brand--text-input-border-radius;
    font-size: 0.875rem;
    line-height: normal;
    min-height: 50px;
    &[placeholder-shown] {
      color: #84878c;
    }

    &::part(text) {
      white-space: normal !important;
    }

    &::part(placeholder) {
      color: $brand--generate-report-select-placeholder-color;
      opacity: $brand--generate-report-select-placeholder-opacity;
    }

    &::part(icon) {
      opacity: $brand--plan-dropdown-icon-opacity-mobile;
      margin-left: 0.3rem;
      color: transparent;
      background: url("/#{$brand}/svg/chevron-down-outline.svg") no-repeat center;
      overflow: visible;
      background-size: $brand-reports-plan-dropdown-icon-size;
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }

    ion-select-option {
      white-space: normal;
    }

    ion-spinner {
      flex-basis: 20%;
      order: 2;
      align-self: center;
      --color: $brand--primary-color;
    }

    &.select-disabled {
      @if $brand == "ndsp" {
        color: #b7b9ba;
        border-color: #b7b9ba;
        opacity: 1;
        &::part(icon) {
          background: url("../../assets/icons/#{$brand}-chevron-down-outline-disabled.svg") no-repeat center;
          background-size: 25px 25px;
        }
      }
    }
  }
}

legend.date-range-legend {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 1rem;
}

.dates-row {
  margin-bottom: 1.618rem;
  display: flex;

  .date-column {
    display: inline;
    width: calc(50% - 2px);
  }

  .picker-date-start {
    margin-right: 4px;
  }

  ion-label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    display: block;
    margin-bottom: 12px;
  }

  .date-box {
    position: relative;
  }

  .date-box > ion-icon {
    position: absolute;
    margin-top: 10px;
    margin-right: 6px;
    font-size: 1.5rem;
    top: 0px;
    right: 0px;
    z-index: 2;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.error-box-ion {
  border: 2px solid #fa4d56 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
}
.mpm-date-picker .picker {
  width: 100%;
  max-width: 240px;
}

ion-label {
  padding-left: 0;
}
ion-select::part(text) {
  font-size: 14px;
  font-weight: normal;
  padding-left: 0;
  min-width: 170px;
}
ion-select::part(placeholder) {
  width: 100%;
  display: block;
  min-width: 170px;
}
.date-picker {
  width: 100%;
  max-width: 510px;
}
.form-actions {
  max-width: 510px;
}
@media screen and (min-width: $ion--breakpoint--md) {
  .row {
    margin: 1rem 0;
  }
}
.call-to-action {
  &::part(native):focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
  }
}
.secondary-action {
  &::part(native):focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
  }
}
</style>
