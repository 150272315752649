<template>
  <div>
    <template v-if="coreBudget && parseInt(coreBudget?.allocated)">
      <PlanBudgetTile
        route-name="core-support"
        color-class="core"
        :plan-budget="coreBudget"
        tooltip-content="Funding to support all your assistance with day-to-day tasks and where all your carer, support worker, cleaning and other regular expenses will be paid from"
      />
    </template>
    <template v-if="capitalBudget && parseInt(capitalBudget?.allocated)">
      <PlanBudgetTile
        route-name="capital-support"
        color-class="capital"
        :plan-budget="capitalBudget"
        tooltip-content="Funds to pay for products to reduce the impact of your disability and usually cover specific items which were discussed in your planning meeting and have been approved in your plan by the NDIA."
      />
    </template>
    <template v-if="capacityBudget && parseInt(capacityBudget?.allocated)">
      <PlanBudgetTile
        route-name="capacity-building-support"
        color-class="capacity"
        :plan-budget="capacityBudget"
        tooltip-content="Funding specifically allocated to assist you to build your skills and achieve your goals"
      />
    </template>
    <template v-if="recurringBudget && parseInt(recurringBudget?.allocated)" style="display: none;">
      <PlanBudgetTile
        color-class="brand"
        :plan-budget="recurringBudget"
        tooltip-content="These are supports that are paid by the NDIS on a regular basis into your bank account. You do not need to claim for these. Please go to your ‘my NDIS portal’ to see more information."
      />
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import PlanBudgetTile from "@/components/plans/pace/PlanBudgetTile.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      planSupportTypes: "pace/supportPlanTypes/getSupportTypes"
    }),
    coreBudget() {
      return this.planSupportTypes.find(budget => budget.name == "Core");
    },
    capitalBudget() {
      return this.planSupportTypes.find(budget => budget.name == "Capital");
    },
    capacityBudget() {
      return this.planSupportTypes.find(budget => budget.name == "Capacity Building");
    },
    recurringBudget() {
      return this.planSupportTypes.find(budget => budget.name == "Recurring");
    }
  },
  components: { PlanBudgetTile }
});
</script>
