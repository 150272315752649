<template>
  <div class="unallocated-budget-description">
    <h4>How can you allocate the budget?</h4>

    <p>
      Daily personal activities, like activities related to personal hygiene; such as bathing and showering,
    </p>
    <p>Assistance and supervision with dressing</p>
    <p>
      A carer to assist and supervise the regularity and daily structure of personal daily tasks
    </p>
    <p>
      Mobility to get in and out of bed Assistance with household task; washing, changing the beds, cleaning Assistance
      in shared living with family members or friends
    </p>
    <p>
      Assistance with Respite Care – Short Term Accommodation and Assistance or Supported Independent Living
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BudgetUnAllocated"
});
</script>
<style lang="scss">
.unallocated-budget {
  @media only screen and (max-width: 42rem) {
    padding: 0 5px 70px 5px;
  }
  h4 {
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
  }
  p {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: 42rem) {
    margin-top: 29px;
    padding-bottom: 5rem;
  }
}
</style>
