<template>
  <ion-row class="mpm-date-picker" :aria-label="ariaLabel">
    <ion-col size="6" class="picker" :class="{ invalid: !!startDateInvalidMessage }">
      <label for="startDate">{{ startDateLabel }}</label>
      <duet-date-picker
        class="start"
        identifier="startDate"
        :min.prop="min"
        :max.prop="max"
        :disabled.prop="disabled"
        :localization.prop="localisation_uk_start"
        :dateAdapter.prop="dateAdapter"
      />
      <div class="bx--form-requirement" aria-live="assertive" aria-relevant="additions removals">
        <slot name="invalid-message">{{ startDateInvalidMessage }}</slot>
      </div>
    </ion-col>
    <ion-col size="6" class="picker" :class="{ invalid: !!endDateInvalidMessage }">
      <label for="endDate">{{ endDateLabel }}</label>
      <duet-date-picker
        class="end"
        identifier="endDate"
        :min.prop="min"
        :max.prop="max"
        :disabled.prop="disabled"
        :localization.prop="localisation_uk_end"
        :dateAdapter.prop="dateAdapter"
      />
      <div class="bx--form-requirement" aria-live="assertive" aria-relevant="additions removals">
        <slot name="invalid-message">{{ endDateInvalidMessage }}</slot>
      </div>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
const localisation_uk_start = {
  buttonLabel: "Choose start date from date picker",
  placeholder: "dd/mm/yyyy",
  selectedDateMessage: "The selected start date is",
  prevMonthLabel: "Previous month",
  nextMonthLabel: "Next month",
  monthSelectLabel: "Month",
  yearSelectLabel: "Year",
  closeLabel: "Close start date picker window",
  calendarHeading: "Choose a start date",
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};
const localisation_uk_end = {
  buttonLabel: "Choose end date from date picker",
  placeholder: "dd/mm/yyyy",
  selectedDateMessage: "The selected end date is",
  prevMonthLabel: "Previous month",
  nextMonthLabel: "Next month",
  monthSelectLabel: "Month",
  yearSelectLabel: "Year",
  closeLabel: "Close end date picker window",
  calendarHeading: "Choose an end date",
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

import { defineComponent } from "vue";
import dayjs from "dayjs";
import { isMobileView } from "@/helpers/screen";
import { IonRow, IonCol, IonGrid } from "@ionic/vue";
const DATE_FORMAT = /^(\d{2})\/(\d{2})\/(\d{4})$/;
export default defineComponent({
  name: "DatePicker",
  components: {
    IonRow,
    IonCol,
    IonGrid
  },
  props: {
    ariaLabel: {
      type: String,
      default: "Service Dates"
    },
    startDateLabel: {
      type: String,
      default: "Start Date"
    },
    endDateLabel: {
      type: String,
      default: "End Date"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    startDateInvalidMessage: {
      type: String
    },
    endDateInvalidMessage: {
      type: String
    },
    max: {
      type: String,
      default: ""
    },
    min: {
      type: String,
      default: ""
    },
    initialDates: {
      type: Object,
      default: () => ({ start: null, end: null })
    }
  },
  emits: ["change", "input"],
  data() {
    return {
      localisation_uk_start: localisation_uk_start,
      localisation_uk_end: localisation_uk_end,
      dateAdapter: {
        parse(value = "", createDate) {
          const matches = value.match(DATE_FORMAT);
          if (matches) {
            return createDate(matches[3], matches[2], matches[1]);
          }
        },
        format(date) {
          return dayjs(date).format("DD/MM/YYYY");
        }
      }
    };
  },
  watch: {
    value: {
      deep: true,
      handler(v) {
        if (v === null) {
          this.startDatePicker.value = "";
          this.endDatePicker.value = "";
        } else if (v && v.startDate && v.endDate) {
          this.startDatePicker.value = dayjs(v.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
          this.endDatePicker.value = dayjs(v.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
        }
      }
    }
  },
  computed: {
    startDatePicker() {
      return this.$el.querySelector(".start");
    },
    endDatePicker() {
      return this.$el.querySelector(".end");
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    const startPicker = this.$el.querySelector(".start");
    const endPicker = this.$el.querySelector(".end");

    let startPickerDialog;
    let startPickerDialogWrapper;

    let endPickerDialog;
    let endPickerDialogWrapper;

    setTimeout(() => {
      startPickerDialog = startPicker.querySelector(".duet-date__dialog");
      if (startPickerDialog && startPickerDialog.parentElement) {
        startPickerDialogWrapper = startPickerDialog?.parentElement;
      }
      endPickerDialog = endPicker.querySelector(".duet-date__dialog");
      if (endPickerDialog && endPickerDialog.parentElement) {
        endPickerDialogWrapper = endPickerDialog?.parentElement;
      }
    }, 500);

    let startDate, endDate;

    // Handle date selection
    function handleDateSelect() {
      const formattedStartDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : null;
      const formattedEndDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : null;
      if (!formattedStartDate && !formattedEndDate) {
        vm.$emit("change", null);
        vm.$emit("input", null);
      } else {
        vm.$emit("change", { startDate: formattedStartDate, endDate: formattedEndDate });
        vm.$emit("input", { startDate: formattedStartDate, endDate: formattedEndDate });
      }
    }

    function handleDatePickerOpen(datePicker) {
      if (isMobileView(window.innerWidth)) {
        document.body.appendChild(datePicker);
      }
      document.body.classList.add("datepicker-open");
    }

    function handleDatePickerClose(datePickerParent, datePicker) {
      if (isMobileView(window.innerWidth)) {
        datePickerParent.appendChild(datePicker);
      }
      document.body.classList.remove("datepicker-open");
    }

    // When the start date picker is changed, set end date's min to equal date

    startPicker.addEventListener("duetOpen", e => {
      handleDatePickerOpen(startPickerDialog);
    });

    startPicker.addEventListener("duetClose", e => {
      handleDatePickerClose(startPickerDialogWrapper, startPickerDialog);
    });

    endPicker.addEventListener("duetOpen", e => {
      handleDatePickerOpen(endPickerDialog);
    });

    endPicker.addEventListener("duetClose", e => {
      handleDatePickerClose(endPickerDialogWrapper, endPickerDialog);
    });

    startPicker.addEventListener("duetChange", function(e) {
      startDate = e.detail.valueAsDate;
      endPicker.min = e.detail.value;
      // Automatically show the end date picker if the user is already
      // using the start date picker to fill in the form
      if (!endDate && document.body.classList.contains("datepicker-open")) {
        endPicker.show();
      }
      handleDateSelect();
    });

    // When the end date picker is changed, set start date's max to equal date
    endPicker.addEventListener("duetChange", function(e) {
      endDate = e.detail.valueAsDate;
      startPicker.max = e.detail.value;
      handleDateSelect();
    });

    startPicker.addEventListener("duetBlur", function(e) {
      vm.$emit("startDateBlur", e);
    });

    startPicker.addEventListener("duetClose", function(e) {
      vm.$emit("startDateDialogClose", e);
    });

    startPicker.addEventListener("duetFocus", function(e) {
      vm.$emit("startDateFocus", e);
    });

    endPicker.addEventListener("duetBlur", function(e) {
      vm.$emit("endDateBlur", e);
    });

    endPicker.addEventListener("duetClose", function(e) {
      vm.$emit("endDateDialogClose", e);
    });

    endPicker.addEventListener("duetFocus", function(e) {
      vm.$emit("endDateFocus", e);
    });

    if (this.initialDates?.start || this.initialDates?.end) {
      startDate = this.initialDates?.start;
      endDate = this.initialDates?.end;
      this.setDates(this.initialDates?.start, this.initialDates?.end);
    }
  },
  methods: {
    setDates(start = null, end = null) {
      this.startDatePicker.value = start || "";
      this.endDatePicker.value = end || "";
    }
  }
});
</script>
<style lang="scss">
.mpm-date-picker {
  .picker {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
  .picker > label {
    color: $brand--font-color-primary;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 12px;
    display: block;
  }
  .picker.invalid {
    .duet-date__input-wrapper {
      outline: $brand--duet-date-invalid-outline;
      outline-offset: -2px;
      input {
        border-bottom: $brand--duet-date-invalid-border-bottom;
      }
    }
  }
}
:root {
  --duet-color-primary: #{$brand--duet-color-primary};
  --duet-color-text: #{$brand--duet-color-text};
  --duet-color-text-active: #{$brand--duet-color-text-active};
  --duet-color-placeholder: #{$brand--duet-color-placeholder};
  --duet-color-button: #{$brand--duet-button-bg};
  --duet-color-surface: #{$brand--duet-color-surface};
  --duet-color-overlay: #{$brand--duet-color-overlay};

  --duet-font: #{$brand--theme--body-font};
  --duet-font-normal: 400;
  --duet-font-bold: 600;

  --duet-radius: 0px;
  --duet-z-index: 600;
}

.duet-date *,
.duet-date *::before,
.duet-date *::after {
  box-sizing: border-box;
  margin: 0;
  width: auto;
}

.duet-date {
  box-sizing: border-box;
  color: var(--duet-color-text);
  display: block;
  font-family: var(--duet-font);
  margin: 0;
  position: relative;
  text-align: left;
  width: 100%;
}

.duet-date__input {
  -webkit-appearance: none;
  appearance: none;
  background: $brand--duet-date-input-bg;
  border-width: $brand--duet-date-input-border-width;
  border-style: $brand--duet-date-input-border-style;
  border-color: $brand--duet-date-input-border-color;
  border-radius: var(--duet-radius);
  color: var(--duet-color-text);
  float: none;
  font-family: var(--duet-font);
  font-size: 0.875rem;
  line-height: normal;
  padding: 14px 60px 14px 14px;
  width: 100%;
  &[disabled] {
    opacity: 0.4;
  }
}
.duet-date__input:focus {
  outline: $brand--duet-date-outline-focus !important;
  box-shadow: none;
  border: none;
}
.duet-date__input::-webkit-input-placeholder {
  color: var(--duet-color-placeholder);
  opacity: 1;
}
.duet-date__input:-moz-placeholder {
  color: var(--duet-color-placeholder);
  opacity: 1;
}
.duet-date__input:-ms-input-placeholder {
  color: var(--duet-color-placeholder);
}

.duet-date__input-wrapper {
  position: relative;
  width: 100%;
}

.duet-date__toggle {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  align-items: center;
  appearance: none;
  background: $brand--duet-date-toggle-bg;
  border: 0;
  border-radius: 0;
  border-bottom-right-radius: var(--duet-radius);
  border-top-right-radius: var(--duet-radius);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
  color: $brand--duet-date-icon-color;
  cursor: pointer;
  display: flex;
  height: calc(100% - 4px);
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  user-select: none;
  width: 48px;
  z-index: 2;
  &[disabled] {
    background-color: #0b121d;
  }
}
.duet-date__toggle:focus {
  outline: $brand--duet-date-outline-focus !important;
  box-shadow: none;
}

.duet-date__toggle-icon {
  color: $brand--date-picker-icon-color;
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
}

.duet-date__dialog {
  display: flex;
  left: 0;
  min-width: 320px;
  opacity: 0;
  position: absolute;
  top: 100%;
  transform: scale(0.96) translateZ(0) translateY(-20px);
  transform-origin: top right;
  transition: transform 300ms ease, opacity 300ms ease, visibility 300ms ease;
  visibility: hidden;
  width: 100%;
  will-change: transform, opacity, visibility;
  z-index: var(--duet-z-index);
}
@media (max-width: 35.9375em) {
  .duet-date__dialog {
    background: var(--duet-color-overlay);
    bottom: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transform-origin: bottom center;
  }
}
.duet-date__dialog.is-left {
  left: auto;
  right: 0;
  width: auto;
}
.duet-date__dialog.is-active {
  opacity: 1;
  transform: scale(1.0001) translateZ(0) translateY(0);
  visibility: visible;
}

.duet-date__dialog-content {
  background: var(--duet-color-surface);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--duet-radius);
  box-shadow: $brand--duet-date-box-shadow;
  margin-left: auto;
  margin-top: 8px;
  max-width: 310px;
  min-width: 290px;
  padding: 16px 16px 20px;
  position: relative;
  transform: none;
  width: 100%;
  z-index: var(--duet-z-index);
}
@media (max-width: 35.9375em) {
  .duet-date__dialog-content {
    border: 0;
    border-radius: 0;
    border-top-left-radius: var(--duet-radius);
    border-top-right-radius: var(--duet-radius);
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: none;
    min-height: 26em;
    opacity: 0;
    padding: 0 8% 20px;
    position: absolute;
    transform: translateZ(0) translateY(100%);
    transition: transform 400ms ease, opacity 400ms ease, visibility 400ms ease;
    visibility: hidden;
    will-change: transform, opacity, visibility;
  }
  .is-active .duet-date__dialog-content {
    opacity: 1;
    transform: translateZ(0) translateY(0);
    visibility: visible;
  }
}

.duet-date__table {
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--duet-color-text);
  font-size: 1rem;
  font-weight: var(--duet-font-normal);
  line-height: 1.25;
  text-align: center;
  width: 100%;
}

.duet-date__table-header {
  font-size: 0.75rem;
  font-weight: var(--duet-font-bold);
  letter-spacing: 1px;
  line-height: 1.25;
  padding-bottom: 8px;
  text-decoration: none;
  text-transform: uppercase;
}

.duet-date__cell {
  text-align: center;
}

.duet-date__day {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 4px;
  color: var(--duet-color-text);
  cursor: pointer;
  display: inline-block;
  font-family: var(--duet-font);
  font-size: 0.875rem;
  font-variant-numeric: tabular-nums;
  font-weight: var(--duet-font-normal);
  height: 36px;
  line-height: 1.25;
  padding: 0 0 1px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  z-index: 1;
}
.duet-date__day.is-today {
  box-shadow: 0 0 0 1px var(--duet-color-primary);
  position: relative;
  z-index: 200;
}
.duet-date__day:hover::before,
.duet-date__day.is-today::before {
  background: var(--duet-color-primary);
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.06;
  position: absolute;
  right: 0;
  top: 0;
}
[aria-selected="true"] .duet-date__day,
.duet-date__day:focus {
  background: var(--duet-color-primary);
  box-shadow: none;
  color: var(--duet-color-text-active);
  outline: 0;
}
.duet-date__day:active {
  background: var(--duet-color-primary);
  box-shadow: 0 0 5px var(--duet-color-primary);
  color: var(--duet-color-text-active);
  z-index: 200;
}
.duet-date__day:focus {
  box-shadow: 0 0 5px var(--duet-color-primary);
  z-index: 200;
}
.duet-date__day.is-disabled {
  background: transparent;
  box-shadow: none;
  color: var(--duet-color-text);
  cursor: default;
  opacity: 0.5;
}
.duet-date__day.is-disabled::before {
  display: none;
}
.duet-date__day.is-outside {
  background: $brand--duet-date-disabled-day-bg;
  box-shadow: none;
  color: $brand--duet-date-disabled-day-color;
  cursor: default;
  opacity: $brand--duet-date-disabled-day-opacity;
  pointer-events: none;
}
.duet-date__day.is-outside::before {
  display: none;
}

.duet-date__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.duet-date__nav {
  white-space: nowrap;
}

.duet-date__prev,
.duet-date__next {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: var(--duet-color-button);
  border: 0;
  border-radius: 50%;
  color: $brand--duet-date-button-color;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  margin-left: 8px;
  padding: 0;
  transition: background-color 300ms ease;
  width: 32px;
}
@media (max-width: 35.9375em) {
  .duet-date__prev,
  .duet-date__next {
    height: 40px;
    width: 40px;
  }
}
.duet-date__prev:focus,
.duet-date__next:focus {
  box-shadow: 0 0 0 2px $brand--duet-date-focus-color;
  outline: 0;
}
.duet-date__prev:active:focus,
.duet-date__next:active:focus {
  box-shadow: none;
}
.duet-date__prev:disabled,
.duet-date__next:disabled {
  cursor: default;
  background: $brand--duet-button-bg-disabled;
  color: $brand--duet-date-button-color-disabled;
  opacity: $brand--duet-date-button-opacity-disabled;
}
.duet-date__prev svg,
.duet-date__next svg {
  margin: 0 auto;
}

.duet-date__select {
  display: inline-flex;
  margin-top: 4px;
  position: relative;
}
.duet-date__select span {
  margin-right: 4px;
}
.duet-date__select select {
  cursor: pointer;
  font-size: 1rem;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.duet-date__select select:focus + .duet-date__select-label {
  box-shadow: 0 0 0 2px $brand--duet-date-focus-color;
}

.duet-date__select-label {
  align-items: center;
  border-radius: var(--duet-radius);
  color: var(--duet-color-text);
  display: flex;
  font-size: 1.25rem;
  font-weight: var(--duet-font-bold);
  line-height: 1.25;
  padding: 0 4px 0 8px;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 1;
}
.duet-date__select-label svg {
  width: 16px;
  height: 16px;
}

.duet-date__mobile {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -10%;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120%;
}
@media (min-width: 36em) {
  .duet-date__mobile {
    border: 0;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: absolute;
    right: -8px;
    top: -8px;
    width: auto;
  }
}

.duet-date__mobile-heading {
  display: inline-block;
  font-weight: var(--duet-font-bold);
  max-width: 84%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 36em) {
  .duet-date__mobile-heading {
    display: none;
  }
}

.duet-date__close {
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  background: var(--duet-color-button);
  border: 0;
  border-radius: 50%;
  color: $brand--duet-date-button-color;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0;
  width: 24px;
}
@media (min-width: 36em) {
  .duet-date__close {
    opacity: 0;
  }
}
.duet-date__close:focus {
  box-shadow: 0 0 0 2px $brand--duet-date-focus-color;
  outline: none;
}
@media (min-width: 36em) {
  .duet-date__close:focus {
    opacity: 1;
  }
}
.duet-date__close svg {
  margin: 0 auto;
}

.duet-date__vhidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}
</style>
