<template>
  <button
    v-if="$store.getters.hasFullParticipantAccess"
    class="floating-claim-link"
    aria-label="Submit New Claim"
    @click="showClaim"
  >
    <ion-icon :src="$brand_svg_src + '/money-circle.svg'" id="money-circle"></ion-icon>
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SubmitClaim from "@/views/SubmitClaim.vue";
import { openModal } from "@/helpers/system";
import { IonIcon } from "@ionic/vue";
import { isDesktop } from "@/directives/platform";

export default defineComponent({
  name: "MobileAddClaimButton",
  components: { IonIcon },
  methods: {
    showClaim: function() {
      if (isDesktop) {
        this.$router.push({ path: "/submit-claim" });
      } else {
        openModal(SubmitClaim);
      }
    }
  }
});
</script>
<style lang="scss">
.floating-claim-link {
  z-index: 30;
  background: $brand--button-main;
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  border-radius: 99px;
  width: 52px;
  height: 52px;
  margin-bottom: var(--ion-safe-area-bottom, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px #000000a8;
  @include grid-media($grid--breakpoint--md) {
    display: none;
  }
  #money-circle {
    font-size: 2rem;
  }
}
</style>
