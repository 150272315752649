<template>
  <div class="select-support-item-popover">
    <ion-searchbar ref="supportItemSearchInput" @ionInput="searchSupportItems" />
    <ion-list ref="supportItemElement">
      <ion-item
        v-for="(item, idx) in supportItemsList"
        :key="idx"
        :value="item.value"
        button
        @click="selectedItem = item"
      >
        {{ item.label }}
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { IonContent, IonList, IonItem, IonLabel, IonSearchbar } from "@ionic/vue";
import { createNamespacedHelpers } from "vuex";
import { find, sortBy } from "lodash";
import { closePopover } from "@/helpers/system";

const { mapMutations, mapState, mapActions } = createNamespacedHelpers("supportItem");

export default defineComponent({
  name: "popover-list-select",
  components: { IonContent, IonList, IonItem, IonLabel, IonSearchbar },
  emits: ["itemSelected"],
  props: {
    initialSupportItems: Array,
    categoryId: Number
  },
  setup() {
    const supportItemElement = ref();
    const supportItemSearchInput = ref();

    onMounted(() => {
      const supportItemSearchInputElement: HTMLIonSearchbarElement = supportItemSearchInput.value.$el;
      setTimeout(() => {
        supportItemSearchInputElement.setFocus();
      }, 150);
    });

    return { supportItemElement, supportItemSearchInput };
  },
  data() {
    return {
      selectedItem: null,
      supportCategoryItems: this.initialSupportItems,
      searchRequestId: null,
      perPage: 100,
      page: 1,
      lastPage: null
    };
  },
  computed: {
    supportItemsList() {
      if (!this.supportCategoryItems?.length) {
        return [];
      }
      return this.supportCategoryItems.map(supportCategoryItem => ({
        label: `${supportCategoryItem.name} - ${supportCategoryItem.code}`,
        value: supportCategoryItem.id
      }));
    }
  },
  methods: {
    ...mapState(["supportItem"]),
    ...mapMutations(["clearSupportItem"]),
    ...mapActions(["searchSupportCategoryItems", "setSupportItem"]),
    searchSupportItems(event) {
      const keywords = event.target.value;
      clearTimeout(this.searchRequestId);
      this.searchRequestId = setTimeout(async () => {
        if (keywords && keywords.length >= 3) {
          this.updateSupportItems(await this.fetchSupportCategoryItems(keywords));
        } else {
          this.supportItem.supportCategoryItemId = null;
          this.supportItem.price = 0;
          this.lastPage = null;
          this.page = 1;
          this.updateSupportItems(await this.fetchSupportCategoryItems());
        }
      }, 500);
    },
    async fetchSupportCategoryItems(value = null) {
      const categoryId = this.$store.getters.supportPlanCategory.support_category?.id;
      if (categoryId) {
        const params: any = {
          categoryId: categoryId,
          perPage: this.perPage,
          page: this.page
        };
        if (value && value.length >= 3) {
          params.search = value;
        }
        return await this.searchSupportCategoryItems(params);
      }
    },
    updateSupportItems(data) {
      this.page = 1;
      if (typeof data.meta != "undefined") {
        this.lastPage = data.meta.last_page;
      }
      this.supportCategoryItems = sortBy(data.data, supportItem => supportItem.name.toLowerCase());
    },
    findSupportCategoryItemPrice(supportCategoryItemId: number) {
      const { price } = find(this.supportCategoryItems, item => {
        return item.id === supportCategoryItemId;
      });
      if (!price) {
        return 0;
      }
      return +price;
    }
  },
  watch: {
    selectedItem() {
      if (this.selectedItem) {
        closePopover(this.selectedItem.value, this.supportCategoryItems);
      }
    }
  }
});
</script>
<style lang="scss">
.select-support-item-popover {
  padding: 20px 0;

  ion-item {
    --background: #{$brand--global-nav-bg};
    --inner-padding-top: 15px;
    --inner-padding-bottom: 15px;
    --inner-padding-start: 25px;
    --inner-padding-end: 25px;
    --padding-start: 0;
    --background-activated-opacity: 0.5;
    font-size: 1rem;
  }

  ion-searchbar {
    padding: 0 25px;
    .searchbar-input {
      font-size: 1rem;
    }

    // --background: #000;
    // --border-radius: 0;
    // .searchbar-input {
    //   border-bottom: 1px $brand--theme--tab-link-border-bottom-active solid;
    // }
  }
}
</style>
