<template>
  <ion-page>
    <ion-content>
      <section id="no-clients" aria-label="no clients page">
        <div class="no-clients">
          <h2>No clients found!</h2>
          <p>We could not find any active clients for this account.</p>
          <p>
            For assistance please contact us on
            <a :href="`tel:${$strings.brand_phone_number}`" :aria-label="$strings.brand_phone_number_aria">
              <span aria-hidden="true">{{ $strings.brand_phone_number }}</span>
            </a>
            or by email <a :href="`mailto:${$strings.emails.enquiries}`">{{ $strings.emails.enquiries }}</a>
          </p>
          <button class="cv-button bx--btn bx--btn--primary" @click="logout()">
            Go back to login
          </button>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { TrackingEvent } from "@/types/tracking-events";
import { IonPage, IonContent } from "@ionic/vue";

export default defineComponent({
  components: { IonPage, IonContent },
  methods: {
    logout() {
      this.$nextTick(() => {
        this.$store.dispatch("user/logout");
        this.$router.push({ path: "/login" });
      });
      this.$amplitude.track(TrackingEvent.Logout, {});
    }
  }
});
</script>

<style lang="scss" scoped>
.no-clients {
  margin: auto;
  padding: 20px;
  max-width: 450px;
  h2 {
    font-size: 20px;
  }
  h2,
  p {
    margin: 20px 0;
  }
}
</style>
