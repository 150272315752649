<template>
  <ion-page>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-content>
      <mpm-loading :is-loading="contentsLoading"></mpm-loading>
      <div class="user-options-form modal-content">
        <form @submit.prevent>
          <ion-grid>
            <ion-row>
              <h1 class="main-title">User Options</h1>
            </ion-row>
            <ion-row>
              <p class="description">User related password and feedback options</p>
            </ion-row>
            <hr role="presentation" />
            <ion-row>
              <ion-col>
                <ion-label>
                  <p class="toggle-desc">
                    Ask me about my experience with the {{ $strings.brand_name_short }} Client Portal
                  </p>
                </ion-label>
              </ion-col>
              <ion-col size="auto">
                <ion-toggle
                  label-placement="start"
                  v-model="csatEnabled"
                  aria-label-id="csat-label"
                  @ionChange="updateCsatSubscription"
                  @keydown.enter="triggerChange"
                />
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { closeModal } from "@/helpers/system";
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar, IonToggle } from "@ionic/vue";
import ModalHeader from "@/components/ModalHeader.vue";
import SettingsToggleRow from "@/components/SettingsToggleRow.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "UserOptionsForm",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonButton,
    IonToggle,
    ModalHeader,
    SettingsToggleRow
  },
  data() {
    return {
      modalName: "User Options Form",
      errors: {},
      password: {},
      csatEnabled: null
    };
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    disableSubmitButton() {
      return this.contentsLoading;
    }
  },
  methods: {
    close() {
      closeModal();
    },
    updateCsatSubscription(e) {
      const subscribeToCsat = e.detail.checked;
      this.$store.dispatch("userSettings/setUserCsatPreference", subscribeToCsat).then(
        () => {
          this.$notification.createToast("Your preference was successfully updated.", "success");
        },
        () => {
          this.$notification.createToast("Your preference could not be updated, please try again later.", "error");
        }
      );
    },
    triggerChange(e) {
      e.target.click();
    },
    handleError(error) {
      return error && error[0] ? error[0] : "";
    }
  },
  mounted() {
    this.csatEnabled = !this.$store.state.userSettings.hasOptedOutofCsat;
  }
});
</script>

<style lang="scss" scoped>
.user-options-form {
  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p.description {
    padding: 1rem 0;
    font-size: 16px;
  }
  p.toggle-desc {
    margin: 0;
    font-size: 18px;
    color: $brand--font-color-primary;
    font-family: $brand--theme--header-font;
  }
  ion-button,
  ion-toggle {
    padding: 3px;
  }
  ion-toggle {
    margin: 1rem 0;
  }
  ion-button::part(native):focus-visible {
    outline: 3px solid $brand--focus-outline;
    outline-offset: 2px;
    --background-focused: none;
  }
  ion-toggle:focus,
  ion-toggle:focus-visible {
    outline: 3px solid $brand--focus-outline;
    padding: 3px;
  }
}
</style>
