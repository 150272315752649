<template>
  <ion-card class="plan-support-category-0">
    <p class="title">Start by adding a support area</p>
    <p class="sub-title">
      Add a support area to your plan to track your NDIS spending.
    </p>
    <progress-bar :percentage-left="0" />
    <p class="description">
      0% SPENT -
      {{ currency(0) }} LEFT
    </p>
    <p class="total">{{ currency(0) }} TOTAL</p>
    <footer>
      <a>Need Help?</a>
    </footer>
  </ion-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import currency from "@/filters/currency";
import ProgressBar from "@/components/plans/ProgressBar.vue";
import AddIcon from "@/assets/icons/add.svg";
import { IonCard } from "@ionic/vue";

export default defineComponent({
  components: {
    AddIcon,
    ProgressBar,
    IonCard
  },
  methods: {
    currency
  }
});
</script>
<style lang="scss">
.plan-support-category-0 {
  padding: 0 15px;
  footer {
    margin-bottom: 15px;
  }
}
.icon-button {
  padding-left: 28px;
  svg {
    position: absolute;
    left: 6px;
  }
}
</style>
