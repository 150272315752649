<template>
  <div class="support-item" :class="{ 'has-progress': isLocked }">
    <div class="subheading">
      <div class="title-wrapper">
        <p class="title">{{ title }}</p>
        <p v-if="$store.getters.isSfClaimingExperience" class="title">{{ code }}</p>
        <div v-if="isLocked">
          <p v-if="dates" class="title">{{ dates }}</p>
        </div>
      </div>
      <div v-if="isLocked">
        <i class="icon-lock" v-if="isLocked">
          <ion-icon src="svg/icon-lock.svg" style="display: inline" />
        </i>
        <div class="subheading-label">Available Budget</div>
        <div class="subheading-price">
          {{ $filters.currency(remainingBudget) }}
        </div>
      </div>
      <div class="" v-if="!isLocked">
        <i class="icon-lock" v-if="isLocked">
          <ion-icon src="svg/icon-lock.svg" style="display: inline" />
        </i>
        <div class="subheading-label">Spent Budget</div>
        <div class="subheading-price">{{ $filters.currency(budgetSpent) }}</div>
      </div>
    </div>
    <div class="progress" v-if="isLocked">
      <progress-bar :percentage-left="percentageLeft" :proportional-width="100" />
      <span> {{ $filters.currency(budgetSpent) }} SPENT</span>
      <span> {{ $filters.currency(remainingBudget) }} OF {{ $filters.currency(budgetAllocated) }} LEFT</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ProgressBar from "@/components/plans/ProgressBar.vue";
import currency from "@/filters/currency";
import { IonIcon } from "@ionic/vue";

export default defineComponent({
  name: "PlanSupportItem",
  components: { IonIcon, ProgressBar },
  props: {
    title: { type: String },
    budgetAllocated: { type: Number, String, default: 0 },
    budgetSpent: { type: Number, String, default: 0 },
    isLocked: { type: Boolean, default: false },
    code: { type: String, default: null },
    dates: { type: String, default: null }
  },
  filters: {
    currency
  },
  computed: {
    isOverspent() {
      return this.remainingBudget < 0;
    },
    percentageLeft() {
      const percentage = (this.budgetSpent / this.budgetAllocated) * 100;

      if (percentage > 100) {
        return 100;
      }

      return percentage.toFixed(0);
    },
    remainingBudget() {
      return this.budgetAllocated - this.budgetSpent;
    }
  }
});
</script>

<style lang="scss">
.support-item {
  background: $brand--plan-support-item-no-progress;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 8px 10px;
  &.has-progress {
    background: $brand--plan-support-item-has-progress;
  }
  &.has-progress .subheading-label {
    margin-top: 20px;
  }
  .subheading {
    margin: 0 5px;
    display: flex;
    justify-content: space-between;
    .subheading-label {
      font-weight: 600;
      font-size: 15px;
      color: $brand--plan-support-item-subheading-label;
      white-space: nowrap;
      margin-bottom: 8px;
    }
    .subheading-price {
      font-size: 18px;
      color: $brand--font-color-primary;
      font-weight: 600;
      text-align: right;
    }
    .title-wrapper {
      flex-grow: 1;
    }
    .title {
      margin-left: 0;
      font-style: normal;
      font-weight: $brand--body-font-weight;
      font-size: 15px;
      line-height: 1.3em;
      display: inline-block;
      align-items: center;
      padding: 5px;
      text-transform: capitalize;
      word-break: break-word;
      margin-top: 0;
    }

    .icon-lock {
      float: right;
      width: 12px;
    }
  }
  .progressbar-container {
    position: relative;
    .bar {
      height: 8.81px;
      position: absolute;
    }
  }

  .progress {
    border-top: 1px solid rgba(119, 165, 255, 0.12);
    padding-top: 12px;
    margin: 7px 5px 2px 5px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }

    span:last-child {
      float: right;
    }
  }

  .progressbar {
    margin-bottom: 2px;
    height: 8.81px;

    .bar {
      height: 8.81px;
    }
  }
}

@if $brand == "mpm" {
  @each $id, $colour in $plan_support_colours {
    .plan-support-item.plan-support-item-#{$id} {
      .provider-progress-summary {
        background-color: transparent;
        margin-top: 32px;
        .progressbar {
          background: $colour;
        }
        .bar {
          background-color: lighten($colour, 20%);
        }
      }
    }
  }
} @else if $brand == "ndsp" {
  .plan-support-item[class*="plan-support-item-"] {
    .provider-progress-summary {
      background-color: #ffffff;
      margin-top: 32px;
      padding: 16px;
      border-radius: 4px;
      box-shadow: $brand--plan-support-tiles-box-shadow;
      .support-item {
        background-color: #eeeeee;
      }
      .progressbar {
        background: $brand--spend-summary-progressbar-bg;
      }
      .bar {
        background-color: $brand--plan-support-category-progressbar-spent-color;
      }
    }
  }
}
</style>
