<template>
  <ion-page>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-content>
      <div
        class="plan-support-description"
        close-aria-label="Close support category dialog"
        :visible="visible"
        :autoHideOff="false"
      >
        <!-- <template v-slot:title><b></b></template> -->
        <!-- <template v-slot:content> -->
        <template>
          <div class="description" v-if="isActive(planSupportCategories.ASSISTANCE_WITH_DAILY_LIFE)">
            <h3>Assistance with Daily Life</h3>
            <p>
              <span>Name on NDIS portal – “Daily Activities”.</span>
              This budget will cover all your expenses related to assisting you to undertake daily tasks. This could
              include:
            </p>
            <br />
            <ul>
              <li>Meal preparation, personal care, cleaning, or gardening.</li>
              <li>Assistance with laundry –Linen service.</li>
              <li>Access to alternative living arrangements.</li>
              <li>
                Short and Medium Term Accommodation and Assistance (including the provision of respite care).
              </li>
              <li>Supported independent living options.</li>
              <li>
                Assistance with daily life tasks provided in Residential Aged Care facility.
              </li>
              <li>
                Nursing care to assist with your disability related health needs.
              </li>
            </ul>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.TRANSPORT)">
            <h3>Transport</h3>
            <p>
              Funding to cover transport expenses related to the impact of your disability, such as:
            </p>
            <br />
            <ul>
              <li>Taxi expenses if you are unable to access public transport.</li>
              <li>
                Specialised transport services to attend school, educational facility, employment, or the community.
              </li>
            </ul>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.CONSUMABLES)">
            <h3>Consumables</h3>
            <p>
              Funding to pay for everyday items and low risk/low cost products, like:
            </p>
            <br />
            <ul>
              <li>
                Products related to your disability like nutritional supplements or continence products.
              </li>
              <li>Interpreting and translating support.</li>
              <li>Auslan or Signed English Training.</li>
              <li>
                Low-cost assistive technology for personal care and safety such as modified cutlery and handrails to
                improve your independence and/or mobility. You may also use these funds to do minor repairs to aids and
                equipment (assistive technology) for example wheelchair tyre puncture repair.
              </li>
              <li>Low cost AT for prosthetics and orthotics.</li>
            </ul>
          </div>

          <div
            class="description"
            v-if="isActive(planSupportCategories.ASSISTANCE_WITH_SOCIAL_ECONOMIC_AND_COMMUNITY_PARTICIPATION)"
          >
            <h3>Assistance with Social, Economic and Community Participation</h3>
            <p>
              <span>Name on NDIS portal – “Social, community and civic participation”.</span>
              Covers costs to access and participate in the community, such as:
            </p>
            <br />
            <ul>
              <li>
                Assistance with engaging in a community, social or recreational activity.
              </li>
              <li>
                Support can be provided in a range of environments such as in a community setting or centre.
              </li>
              <li>
                One-to-one support to assist you with attending a social outing, holiday, camp, class,or specific
                interest/hobby i.e.,art, music, drama class.
              </li>
              <li>
                Participate in sport and exercise including joining a sporting club.
              </li>
              <li>Assistance to attend a community event such as the movies.</li>
              <li>
                Activity based transport including cost per km, parking, road tolls and public transport fares.
              </li>
              <li>Support to maintain your current employment.</li>
            </ul>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.ASSISTIVE_TECHNOLOGY)">
            <h3>Assistive Technology</h3>
            <p>
              Specialised technology or equipment, like a smart device, wheelchair, or app.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.HOME_MODIFICATIONS)">
            <h3>Home Modifications</h3>
            <p>
              Funding for modifications to be made to your home to make it easier for you to live your everyday life
              where your disability creates barriers. This may include modifications to your kitchen or bathroom
              equipment.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.SUPPORT_COORDINATION)">
            <h3>Support Coordination</h3>
            <p>
              A support coordinator can be claimed from this budget. Support coordinators help you put your plan in
              action and organise your supports. They can assist you to build connections with the community and broader
              systems of support; develop and design support options to help you work towards your goals; and work with
              you to prepare for review and report on what you have achieved.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_LIVING_ARRANGEMENTS)">
            <h3>Improved Living Arrangements</h3>
            <p>
              <span>Name on NDIS portal – “CB Home Living”.</span>
              This funding is for help for you to find suitable accommodation to live more independently and could cover
              someone to assist you with inspecting properties or negotiating your contract. Supports may including
              assistance with applying for a rental property, meeting tenancy obligations,and ensuring the home is
              appropriate for your needs.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.INCREASED_SOCIAL_COMMUNITY_PARTICIPATION)">
            <h3>Increased Social and Community Participation</h3>
            <p>
              <span>Name on NDIS portal – “CB Social Community and Civic Participation”.</span>
              This is to pay for support to help you build your skills in accessing the community. This may include
              funding a mentor or therapist to assist with learning skills for independence in the community. This
              category of funding can be used to pay for tuition fees, art classes, sports coaching and similar
              activities that build skills and independence. You can use this category to pay for camps, classes and
              vacation activities that have capacity-building components. Activity based transport is also included.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.FINDING_AND_KEEPING_A_JOB)">
            <h3>Finding and Keeping a Job</h3>
            <p>
              <span>Name on NDIS portal – “CB Employment”.</span>
              Funding to support you to find a job and have assistance in your work. This budget can pay for a support
              worker to assist you at your job or helping you to find a job that’s right for you, including:
            </p>
            <br />
            <ul>
              <li>
                On the job training to assist you manage the demands of the job.
              </li>
              <li>
                Other options may include life and work coaches, private recruitment specialists, career counsellors and
                employment mentors.
              </li>
            </ul>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_RELATIONSHIPS)">
            <h3>Improved Relationships</h3>
            <p>
              <span>Name on NDIS portal – “CB Relationships”.</span>
              Supports to help build your skills to connect and build relationships in your community. This could
              include funding to pay for someone to help you build your social skills or behavioural therapy services.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_HEALTH_AND_WELLBEING)">
            <h3>Improved Health and Wellbeing</h3>
            <p>
              <span>Name on NDIS portal – “CB Health and Wellbeing”.</span>
              Services that improve your health and wellbeing, like a personal trainer, exercise physiologist or
              dietitian to improve your skills in maintaining your own health and wellbeing.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_LEARNING)">
            <h3>Improved Learning</h3>
            <p>
              <span>Name on NDIS portal – “CB Lifelong Learning”.</span> Access to services that will help you to
              transition from school to further education. This budget could fund a support worker to help you to go to
              university or TAFE.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_LIFE_CHOICES)">
            <h3>Improved Life Choices</h3>
            <p>
              <span>Name on NDIS portal – “CB Choice and Control”.</span>
              This budget pays for your plan manager.
            </p>
          </div>

          <div class="description" v-if="isActive(planSupportCategories.IMPROVED_DAILY_LIVING)">
            <h3>Improved Daily Living</h3>
            <p>
              <span>Name on NDIS portal – “CB Daily Activity”.</span> Funding to cover your therapies and other programs
              to build your capacity to reduce the impact of your disability. This could be a physiotherapist, speech
              pathology, OT or any other therapy that assists you to become more independent with your daily living
              tasks.
            </p>
          </div>
        </template>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { supportCategories } from "@/helpers/support-category";
import ModalHeader from "@/components/ModalHeader.vue";
import { IonContent, IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PlanSupportCategoryDescription",
  components: {
    ModalHeader,
    IonContent,
    IonPage
  },
  props: {
    categoryId: {
      type: Number
    }
  },
  data() {
    return {
      modalName: "Support Category Description",
      planSupportCategories: supportCategories,
      visible: false
    };
  },
  methods: {
    show(categoryId) {
      // this.categoryId = categoryId; // TODO james
      this.visible = true;
    },
    isActive(categoryId) {
      return categoryId === parseInt(this.categoryId);
    }
  }
});
</script>

<style lang="scss">
.plan-support-description {
  background: #162032;
  color: $brand--theme--text-01;
  margin-top: 30px;
  .description {
    padding: 0 25px;
    margin-bottom: 50px;

    h3 {
      font-size: 28px !important;
      line-height: 30px !important;
      padding-bottom: 25px;
    }

    p {
      margin-top: 28px;
      font-size: 18px;
      line-height: 24.52px;
      color: #c6c6c6;

      span {
        color: #fff;
        font-weight: 700;
      }
    }

    ul {
      margin-left: 20px;
      li {
        list-style: disc;
        font-size: 18px;
        line-height: 24.52px;
        color: #c6c6c6;
      }
    }

    @media only screen and (max-width: $brand-support-drawer-breakpoint) {
      h3 {
        font-size: 18px !important;
        line-height: 27px !important;
      }
    }
  }
}
</style>
