<template>
  <div class="mpm-loading-overlay" v-if="isLoading">
    <span v-if="brand === 'mpm'">
      <MpmLoader />
    </span>
    <span v-if="brand === 'ndsp'">
      <NdspLoader />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpmLoader from "./loader/MpmLoader.vue";
import NdspLoader from "./loader/NdspLoader.vue";
const brand = process.env.VUE_APP_BRAND;

export default defineComponent({
  components: { MpmLoader, NdspLoader },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      brand
    };
  }
});
</script>
<style lang="scss" scoped>
.mpm-loading-overlay {
  background: $brand--loading-screen-background;
  width: 100%;
  z-index: 999;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  :deep(.heading) {
    font-family: $brand--theme--header-font;
  }
  :deep(.description) {
    font-size: 10px;
  }
  :deep(svg) {
    width: 200px;
    fill: $brand--loading-screen-spinner-color;
    text {
      fill: $brand--loading-screen-text-color;
    }
  }
}
</style>
