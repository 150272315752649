<template>
  <div class="provider-header">
    <a
      tabindex="0"
      role="button"
      @click="goBack"
      @keydown.space="goBack"
      @keydown.enter="goBack"
      aria-label="Back arrow. Back to providers list."
    >
      <ion-icon class="arrow-left" :src="$brand_svg_src + '/back-arrow-reverse-color.svg'" />
    </a>
    <div>
      <h4>Provider Claims</h4>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
export default defineComponent({
  name: "provider-header",
  components: { IonIcon },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
});
</script>

<style lang="scss">
.provider-header {
  .arrow-left {
    cursor: pointer;
    font-size: 24px;
    margin-right: 10px;
  }
  h4 {
    font-size: 18px;
  }
  .white {
    color: white;
  }
  // background-color: #0037ac;
  // margin: 0 -#{$layout-side-padding-mobile} 22px -#{$layout-side-padding-mobile};

  padding: 0.5rem 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include grid-media($grid--breakpoint--md) {
    // margin: 0 -#{$layout-side-padding-desktop} 22px -#{$layout-side-padding-desktop};
    .back-link {
      flex: 0 0 $layout-side-padding-desktop;
    }
  }
  a:focus-visible {
    border: 3px solid $brand--focus-outline;
    outline: none;
    box-shadow: none;
    height: 32px;
    width: 32px;
  }
}
</style>
