<template>
  <ion-content ref="planContent" tabindex="0" class="page-content plan-section plan-tab" aria-label="Plan page content">
    <section aria-label="plan page">
      <div style="overflow:auto" tabindex="-1">
        <get-feedback
          v-if="$store.state.userSettings.shouldShowCsat"
          ref="getfeedback"
          @loaded="setFeedBackParameters"
        />
        <div class="content-spacer">
          <div
            class="heading-wrapper"
            :class="[{ collapsed: isSupportSelected }, { hide: $store.getters.supportItems.expandSupport }]"
          >
            <ion-refresher
              slot="fixed"
              @ionRefresh="doRefresh($event)"
              :disabled="$store.getters.supportItems.expandSupport"
            >
              <ion-refresher-content
                pulling-icon="svg/loading.svg"
                refreshing-spinner="circles"
                refreshing-text="Refreshing..."
              >
              </ion-refresher-content>
            </ion-refresher>
            <div class="main-heading" :class="{ split: $root.zoomLevel >= 2 }" v-if="!contentsLoading">
              <h1 class="main-title">Plan</h1>
              <div class="plan-dropdown-wrap" v-desktop-breakpoint-only>
                <plan-dropdown
                  :plans="plans"
                  @change="updatePlan"
                  :default-plan="selected.plan"
                  aria-label="Click to select plan"
                />
              </div>
              <div class="select-plan-global" v-mobile-breakpoint-only>
                <ion-select
                  placeholder="Choose a plan"
                  :interface-options="getInterfaceOptions('Plans', 'Choose an option')"
                  interface="alert"
                  :value="selectedPlanId"
                  :selected-text="
                    $filters.friendlyDate(selected.plan?.start_date) +
                      ' to ' +
                      $filters.friendlyDate(selected.plan?.end_date)
                  "
                  v-model="selected.planId"
                  :disabled="false"
                  @ionChange="updatePlan(selected.planId)"
                >
                  <ion-icon :src="`${$brand_svg_src}/chevron-down-outline.svg`"></ion-icon>
                  <ion-select-option v-for="(plan, idx) in plans" :key="idx" :value="plan.sfid">
                    {{ $filters.friendlyDate(plan.start_date) + " to " + $filters.friendlyDate(plan.end_date) }}
                  </ion-select-option>
                </ion-select>
              </div>
            </div>
            <p class="pace-message">
              This shows your plan details from your new look NDIS plan.<img
                class="myndislogo"
                :src="`${$brand_svg_src}/myndislogo.png`"
                alt="my NDIS logo"
              />
            </p>
          </div>
          <pace-plan-total-budget />
          <ion-card class="main-label">
            <ion-card-header>
              <ion-card-title>Your support types</ion-card-title>
            </ion-card-header>
          </ion-card>
          <plan-budgets />

          <MobileAddClaimButton />
        </div>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import PlanBudgets from "@/components/plans/pace/PlanBudgets.vue";
import { find } from "lodash";
import { defineComponent, reactive } from "vue";
import { Plan } from "@/types/salesforce";
import MobileAddClaimButton from "@/components/MobileAddClaimButton.vue";
import { TrackingEvent } from "@/types/tracking-events";
import GetFeedback from "@/components/GetFeedBack.vue";
import SiteMap from "@/components/menu/SiteMap.vue";
import friendlyDate from "@/filters/friendlyDate";
import PlanDropdown from "@/components/plans/PlanDropdown.vue";
import { mapGetters, mapActions } from "vuex";
import PacePlanTotalBudget from "@/components/pace-plan/PacePlanTotalBudget.vue";
import {
  IonPage,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonRefresher,
  IonRefresherContent
} from "@ionic/vue";
import { getSfParticipantPlans } from "@/api/salesforce/plans";

export default defineComponent({
  name: "SFPlan",
  components: {
    MobileAddClaimButton,
    GetFeedback,
    SiteMap,
    IonPage,
    IonTitle,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonRefresher,
    IonRefresherContent,
    PlanDropdown,
    PlanBudgets,
    PacePlanTotalBudget
  },
  data() {
    return {
      isExpanded: false,
      selected: reactive({
        planId: this.selectedPlanId,
        plan: this.$store.state.currentPlan
      })
    };
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading",
      plans: "plans/getPlans"
    }),
    isSupportSelected() {
      return !!this.$store.state.supportItems.collapseSupport;
    },
    selectedPlanId() {
      return this.selected?.plan?.sfid;
    }
  },
  methods: {
    ...mapActions({
      updateContentsLoading: "layout/updateContentsLoading",
      updateFullScreenLoading: "layout/updateFullScreenLoading"
    }),
    friendlyDate,
    /**
     * Called on fetching the support items in the plan drilldown view
     * Triggered by clicking on a PlanSupportCategory tile in grandchild component
     */
    onFetchItems() {
      this.updateContentsLoading(true);
    },
    /**
     * Called immediately after fetching the support items in the plan drilldown view
     */
    onFetchItemsCompleted() {
      this.$refs.planContent.$el.scrollToTop(0);
      this.updateContentsLoading(false);
    },
    getInterfaceOptions(alertHeader: string, alertSubHeader: string) {
      return {
        header: alertHeader,
        subHeader: alertSubHeader,
        cssClass: "ios-select-alert",
        mode: "ios"
      };
    },
    async doRefresh(refresher) {
      refresher.target.complete();
      try {
        this.updateFullScreenLoading(true);
        const plans = (await getSfParticipantPlans()) as Array<Plan>;
        await this.$store.dispatch("plans/setPlans", plans);
        await this.$store.dispatch("currentPlan/clearState");
        const currentPlan = find(plans, (plan: Plan) => plan.sfid === this.selectedPlanId);
        if (currentPlan) {
          await this.$store.dispatch("currentPlan/setCurrentPlan", currentPlan);
        } else {
          this.setCurrentPlanToActivePlan(plans);
        }
      } catch (e) {
        console.log(e);
        this.updateFullScreenLoading(false);
      }
    },
    setFeedBackParameters() {
      const params = {
        ContactID: this.$store.getters.user.sfid,
        ClientID: this.$store.getters.participant.sfid
      };
      this.$refs.getfeedback.setParameters(params);
    },
    async updatePlan(planId: string) {
      if (!planId) return;
      this.selected.plan = find(this.plans, (plan: Plan) => plan.sfid === planId);

      await this.$store.dispatch("currentPlan/setCurrentPlan", this.selected.plan);
      this.$amplitude.track(TrackingEvent.SelectPlan, {
        pacePlan: this.selected.plan.pace_plan
      });
      this.$amplitude.track(TrackingEvent.Navigation, {
        page: this.selected.plan.pace_plan ? "PacePlanTab" : "PlanTab"
      });
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/style/components/plan.scss";

.bx--loading .bx--loading__stroke {
  stroke: $brand--theme--active-primary;
  stroke-linecap: round;
}
.main-breadcrumbs {
  margin: 15px 0 4px 0;
  padding: 0 20px;
  display: none;

  .bx-link {
    color: #ffffff;
  }
  @include grid-media($grid--breakpoint--md) {
    display: inline-block;
  }
}
.content-spacer {
  padding: 50px 14px 0 14px;
  max-width: 836px;
  margin-top: 0;
}
.heading-wrapper {
  margin-bottom: 40px;

  .pace-message {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;

    .myndislogo {
      vertical-align: sub;
      margin-left: 5px;
    }
  }
}
.main-heading {
  padding: 0;
  margin: 0 0 32px 0;
  .select-plan-global {
    flex-grow: 1;
    ion-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border: $brand--plan-dropdown-border;
      border-radius: $brand--plan-dropdown-border-radius;
      &::part(text) {
        overflow: visible;
        font-size: 18px;
        font-weight: 600;
        color: $brand--plan-dropdown-color;
        letter-spacing: 0;
        flex: unset;
        white-space: break-spaces;
        line-height: normal;
      }
      &::part(icon) {
        opacity: $brand--plan-dropdown-icon-opacity-mobile;
        margin-left: 0.3rem;
        color: transparent;
        background: url("../../assets/icons/#{$brand}-chevron-down-outline.svg") no-repeat center;
        overflow: visible;
        background-size: 25px 25px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
.main-breadcrumbs.expanded-support-area {
  margin-left: 10px;
}

.main-label {
  background-color: $brand--pace-plan-main-label-bg;
  margin: 40px 0 24px 0;
  ion-card-header {
    padding: 20px;
    ion-card-title {
      color: white;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
    }
  }
}

.plan-tile {
  background-color: $brand--pace-plan-tile-bg;
  margin: 0 0 16px 0;
}

@media only screen and (max-width: 42rem) {
  .bx--tile {
    box-shadow: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    padding-right: 35px;
    width: calc(50% - 40px) !important;
    max-width: 525px;
    .managed-logo {
      right: 34px;
    }
  }
}
.plan-dropdown-wrap {
  max-width: 302px;
  width: 100%;
}
</style>
