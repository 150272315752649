<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <component ref="dynamicClaims" :is="'SFClaims'" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SFClaims from "@/components/pages/Claims.vue";
import { IonPage } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "ClaimsTab",
  components: {
    SFClaims,
    IonPage
  },
  ionViewWillEnter() {
    this.updateContentsLoading(true);
    this.$refs.dynamicClaims.refreshTabs(1);
  },
  ionViewWillLeave() {
    this.$store.dispatch("sf/claims/clearFiltersAndSorts");
    this.$store.dispatch("sf/reimbursements/clearFiltersAndSorts");
    try {
      this.$refs.dynamicClaims.resetExpandedRows();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    })
  },
  computed: {
    ...mapGetters({
      contentsLoading: "layout/getContentsLoading"
    })
  }
});
</script>
