<template>
  <ion-page>
    <mpm-loading :is-loading="contentsLoading"></mpm-loading>
    <ion-content class="page-content providers-tab" tabindex="0" aria-label="Providers page content">
      <section id="providers" aria-label="providers page">
        <div style="overflow:auto" tabindex="-1">
          <div class="content-spacer">
            <total-available-budget v-if="$root.isMobileView && !$store.getters.currentPlan.pace_plan" />
            <div class="providers header-spacer-mobile">
              <div style="padding: 8px 15px 15px 15px">
                <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
                  <ion-refresher-content
                    pulling-icon="svg/loading.svg"
                    refreshing-spinner="circles"
                    refreshing-text="Refreshing..."
                  >
                  </ion-refresher-content>
                </ion-refresher>
                <h1 class="main-title">Providers</h1>
                <p class="main-desc">
                  A list of all providers with claims for Plan
                  <span>
                    {{ $filters.friendlyDate(activePlan.start_date) }} to
                    {{ $filters.friendlyDate(activePlan.end_date) }}
                  </span>
                </p>
                <p v-if="currentPlan.pace_plan" class="pace-message">
                  This shows your provider claims details from your new look NDIS plan.<img
                    class="myndislogo"
                    :src="`${$brand_svg_src}/myndislogo.png`"
                    alt="my NDIS logo"
                  />
                </p>
                <section>
                  <div class="table-container">
                    <table
                      class="data-table"
                      :columns="tableData.columns"
                      :has-expand-all="tableData.hasExpandAll"
                      :row-expanded="tableData.rowExpanded"
                      :data="tableData.data"
                    >
                      <thead>
                        <tr>
                          <th aria-sort="none">
                            <span class="table-header-label">PROVIDER</span>
                          </th>
                          <th aria-sort="none">
                            <span class="table-header-label">CLAIMS</span>
                          </th>
                          <th aria-sort="none">
                            <span class="table-header-label"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!isEmpty()">
                        <tr v-for="(row, rowIndex) in tableData.data" :key="rowIndex" @click="handleTableClick(row)">
                          <td :data-id="row.provider_sfid">{{ row.provider_name }}</td>
                          <td :data-id="row.provider_sfid">{{ row.invoice_count }}</td>

                          <td :data-id="row.provider_sfid" class="arrow">
                            <ion-button :data-id="row.provider_sfid" fill="clear" aria-label="View claims for provider">
                              <ion-icon :src="$brand_svg_src + '/arrow.svg'" />
                            </ion-button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="3">
                            <h5>There are no providers to show here yet.</h5>
                            <p class="empty-message" v-if="hasFullParticipantAccess">
                              All submitted and processed claims will appear here. Use the ‘Submit claim’ button or
                              email the invoice to
                              <a class="emailLink" :href="`mailto:${$strings.emails.accounts}`">{{
                                $strings.emails.accounts
                              }}</a>
                              to submit your claim to be processed.
                            </p>
                            <p v-else class="empty-message">
                              All providers with submitted and processed claims with will appear here
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <site-map v-desktop-only></site-map>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";

import TotalAvailableBudget from "@/components/TotalAvailableBudget.vue";
import SiteMap from "@/components/menu/SiteMap.vue";

import {
  IonButton,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonIcon
} from "@ionic/vue";

export default defineComponent({
  name: "ProvidersTab",
  components: {
    TotalAvailableBudget,
    SiteMap,
    IonButton,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonIcon
  },
  data() {
    return {
      activePlan: this.$store.state.currentPlan
    };
  },
  ionViewWillEnter() {
    this.updateContentsLoading(true);
    this.loadPage();
  },
  methods: {
    ...mapActions("providers", {
      loadSfProviders: "loadSfProviders"
    }),
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    isEmpty() {
      return this.providers && this.providers.length <= 0;
    },
    handleTableClick(row) {
      if (row?.provider_sfid) {
        this.$store.commit("providers/setProvider", { sfid: row.provider_sfid });
        this.$router.push({
          name: "ProviderClaimsTab",
          params: {
            providerId: row.provider_sfid
          }
        });
      }
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    loadPage: debounce(async function() {
      this.updateContentsLoading(true);
      try {
        await this.loadSfProviders();
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }, 100)
  },
  computed: {
    ...mapGetters("providers", { providers: "providersInvoice" }),
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    }),
    ...mapGetters({
      hasFullParticipantAccess: "hasFullParticipantAccess"
    }),
    ...mapGetters("currentPlan", {
      currentPlan: "getCurrentPlan"
    }),
    tableData() {
      return {
        columns: ["PROVIDER", ""],
        data: this.providers.filter(p => p.provider_sfid),
        rowExpanded: -1,
        hasExpandAll: false
      };
    }
  }
});
</script>
<style lang="scss" scoped>
.providers {
  margin-bottom: 100px;
  .arrow {
    ion-button::part(native) {
      &:focus-visible {
        outline: 3px solid $brand--focus-outline !important;
        background-color: none;
      }
    }

    ion-icon {
      height: 10px;
    }
  }

  .pace-message {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;

    .myndislogo {
      vertical-align: sub;
      margin-left: 5px;
    }
  }

  .table-container {
    min-width: unset;
  }
  .data-table thead {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.16px;
    background-color: rgba(86, 148, 251, 0.66);
  }
  .data-table th {
    background-color: $brand--primary-table-color !important;
  }
  .data-table tr {
    border-bottom: $brand--claims-table-tbody-border-bottom;
    background-color: $brand--primary-table-color;
    th {
      padding: 1rem;
      text-align: left;
    }
  }
  .data-table td {
    height: 3rem;
    width: 100%;
    color: $brand--font-color-primary;
    border: none;
  }
  .data-table tbody tr td {
    padding: 1rem;
    background-color: $brand--table-td-color;
  }
  .emailLink {
    color: #73a4ff;
    font-weight: bold;
    text-decoration: none;
  }
}
.data-table tbody {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  width: 100%;
}
.data-table tbody tr {
  cursor: pointer;
}

.data-table tbody tr:hover td {
  background: none !important;
  background: $brand--accordion-hover !important;
  border: none !important;
  color: $brand--font-color-primary !important;
}

.data-table tbody tr:hover th {
  background: $brand--accordion-hover !important;
  border-bottom: 1px solid $brand--accordion-hover;
  border-top: 1px solid $brand--accordion-hover;
}

.table-header-label {
  color: $brand--theme--text-01;
}
</style>
