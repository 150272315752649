<template>
  <section>
    <ion-card class="pace-plan-budget">
      <ion-card-content>
        <div class="left">
          <header>
            <div class="budget-heading">
              <h1 class="title">{{ planBudget.name }} budget</h1>
              <mpm-tooltip>
                <template v-slot:icon>
                  <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
                </template>
                <template v-slot:content>
                  <p>{{ tooltipContent }}</p>
                </template>
              </mpm-tooltip>
            </div>
            <div v-if="!routeName" class="self-managed">
              <ion-icon :src="`${$brand_svg_src}/self.svg`" class="self-logo"></ion-icon>
              <span v-if="$brand === 'ndsp'">Self managed</span>
            </div>
          </header>
          <h2 class="sub-title">Available amount</h2>
          <p class="figure">{{ $filters.currency(planBudget.remaining) }} ({{ percentageBudgetLeft }}%)</p>
          <a
            v-if="routeName"
            aria-label="View Breakdown"
            tabindex="0"
            class="breakdown focus-item"
            @keyup.enter.prevent="$router.push({ path: `/plan/${routeName}` })"
            @keyup.space.prevent="$router.push({ path: `/plan/${routeName}` })"
            @click.prevent="$router.push({ path: `/plan/${routeName}` })"
          >
            View {{ planBudget.name }} breakdown
          </a>
          <template v-else>
            <h2 class="sub-title recurring">Support category</h2>
            <p class="figure recurring">Recurring transport</p>
          </template>
        </div>
        <div class="right">
          <header>
            <h1 class="title">{{ planBudget.name }} funds</h1>
          </header>
          <p>
            Spent: <span class="figure">{{ $filters.currency(planBudget.spent) }} ({{ percentageBudgetSpent }}%)</span>
          </p>
          <progress-bar :color-class="colorClass" :percentage-left="percentageBudgetLeft" />
          <p>
            Opening balance: <span class="figure">{{ $filters.currency(planBudget.allocated) }}</span>
          </p>

          <header class="time">
            <h1 class="title">Time</h1>
            <mpm-tooltip>
              <template v-slot:icon>
                <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
              </template>
              <template v-slot:content>
                <p>This information shows you how much time you have left on your current plan.</p>
              </template>
            </mpm-tooltip>
          </header>
          <p class="today">{{ $filters.friendlyDate(today) }} ({{ timeLeftString }})</p>
          <progress-bar :color-class="colorClass" :percentage-left="Number(percentageTimeLeft)" />
          <div class="dates">
            <p>{{ $filters.friendlyDate(currentPlan.start_date) }}</p>
            <p>{{ $filters.friendlyDate(currentPlan.end_date) }}</p>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MpmTooltip from "@/components/MpmTooltip.vue";
import ProgressBar from "@/components/plans/pace/ProgressBar.vue";
import { IonIcon, IonCard, IonCardContent } from "@ionic/vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default defineComponent({
  props: {
    routeName: {
      type: String
    },
    colorClass: {
      type: String,
      required: true
    },
    tooltipContent: {
      type: String
    },
    planBudget: {
      required: true,
      type: Object
    }
  },
  components: {
    MpmTooltip,
    IonIcon,
    ProgressBar,
    IonCard,
    IonCardContent
  },
  computed: {
    ...mapGetters({
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    today() {
      return moment().format();
    },
    percentageBudgetSpent() {
      // pre-empt divide by zero error
      if (this.planBudget.spent === 0) {
        return 0;
      }
      return ((this.planBudget.spent / this.planBudget.allocated) * 100).toFixed(1);
    },
    percentageBudgetLeft() {
      if (this.planBudget.spent === 0) {
        return 100;
      }
      return (100 - (this.planBudget.spent / this.planBudget.allocated) * 100).toFixed(1);
    },
    timeLeftString() {
      const today = moment();
      const endDate = moment(this.currentPlan.end_date);

      const years = endDate.diff(today, "year");
      today.add(years, "years");

      const months = endDate.diff(today, "months");
      today.add(months, "months");

      const days = endDate.diff(today, "days");
      if (years < 0) {
        return "Expired";
      }
      if (years > 0) {
        return years + " years, " + (months > 0 ? months + " months left" : "");
      } else {
        return (months > 0 ? months + " months, " : "") + days + " days left";
      }
    },
    percentageTimeLeft() {
      const today = moment();
      const start = moment(this.currentPlan.start_date);
      const end = moment(this.currentPlan.end_date);
      const elapsed_days = today.diff(start, "days");
      const total_days = end.diff(start, "days");

      return Math.round(Math.max(100 - (elapsed_days / total_days) * 100, 0));
    }
  }
});
</script>
<style lang="scss" scoped>
section {
  width: 100%;
  display: inline-block;
  .dates {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: 600;
    }
  }
  .today {
    width: 80%;
    text-align: center;
    font-weight: 600;
  }
  .mpm-tooltip {
    margin: 5px;
    .content p {
      padding: 1rem 0;
      line-height: 1.5;
      font-size: 14px;
      font-family: $brand--theme--body-font;
      letter-spacing: 0;
    }
  }
  .left {
    width: 40%;
    border-right: 1px solid $brand--pace-budget-divider-color;

    .breakdown {
      display: inline-block;
      color: $brand--pace-budget-link-color;
      text-decoration-line: underline;
      font-size: 1rem;
      margin-top: 24px;
      cursor: pointer;
      letter-spacing: normal;
    }
  }
  .right {
    width: 60%;
    margin-left: 20px;
  }
  header {
    width: 100%;
    display: flex;
    background-color: $brand--pace-plan-support-category-group-header-bg;
    align-items: center;
    gap: 5px;
    .budget-heading {
      display: flex;
      gap: 10px;
    }
    .self-managed {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .self-logo {
        height: 1.5rem;
      }
      span {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.16px;
        line-height: 10px;
        color: $brand--primary-color;
        margin-left: 8px;
      }
    }
    .help-icon {
      background: white;
      border: 0;
      border-radius: 11px;
      height: 24px;
      width: 24px;
    }
    h5 {
      font-size: 18px;
      color: #fff;
    }

    &.time {
      margin-top: 16px;
    }
  }

  .pace-plan-budget {
    background-color: $brand--pace-budget-tile-bg;
    color: $brand--font-color-primary;
    box-shadow: 0px 4px 4px $brand--theme--box-shadow;
    border-radius: 4px;
    margin: 0 0 16px 0;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.085em;
    padding: 20px;

    ion-card-content {
      display: flex;
    }
    .title {
      font-size: 1.1rem;
      letter-spacing: 0;
    }
    .sub-title {
      font-size: 1rem;
      font-weight: 400 !important;
      margin-bottom: 8px;
      &.recurring {
        margin: 16px 0;
      }
    }
    .figure {
      font-size: 1.1rem;
      font-weight: 700;
      &.recurring {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: $ion--breakpoint--md) {
    header {
      align-items: center;
      flex-direction: row;
    }
    ion-card-content {
      flex-direction: column;
    }

    .left {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $brand--pace-budget-divider-color;

      .breakdown {
        margin: 16px 0;
      }
    }

    .right {
      margin: 0;
      width: 100%;

      header {
        margin-top: 13px;
      }
    }
  }

  @media (max-width: $ion--breakpoint--sm) {
    .today {
      width: 100%;
      text-align: end;
    }
  }
  @media (min-width: $ion--breakpoint--md) and (max-width: 1012px) {
    .budget-heading .title {
      max-width: 80px;
    }
  }
}
</style>
