<template>
  <div>
    <h3>Spent by</h3>

    <div tabindex="0" aria-label="Summary of spent Support items">
      <ul>
        <li>
          <span role="contentinfo" class="offscreen" v-for="item in dataSections">
            <span v-html="item.label" /> - {{ item.percentage }}% spent
          </span>
        </li>
      </ul>
    </div>

    <mpm-padded-donut-chart
      :background="$strings.donutBaseColors.background"
      :foreground="$strings.donutBaseColors.foreground"
      padding-size="0.1rem"
      :padding-background="$strings.donutBaseColors.paddingBackground"
      :sections="dataSections"
      :thickness="40"
      :size="180"
      :total="100"
      :highlight-scale="1"
      donut-label="Spending donut chart"
      has-legend
      no-shadow
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { sumBy } from "lodash";
interface SupportItem {
  budget_allocated: number;
  budget_spent: number;
}

const MYSELF_LABEL_LOWERCASE = "myself";
const AVAILABLE_BUDGET_LABEL = "Available Budget";

import MpmPaddedDonutChart from "@/components/MpmPaddedDonut.vue";
export default defineComponent({
  props: {
    total: {
      type: Number
    },
    providers: {
      type: Array
    },
    supports: {
      type: Array
    }
  },
  components: {
    MpmPaddedDonutChart
  },
  computed: {
    dataSections() {
      const TOTAL_BUDGET = this.total;

      let budgetUsed = 0;
      let colorCounter = 0;

      let sections = this.supports.map((support, i: number) => {
        const spentOnThisItem = sumBy(support.supportItems, (item: SupportItem) => Number(item.budget_spent));
        const spentOnThisItemFormatted = this.$filters.currency(spentOnThisItem);
        const percentage = Number(((spentOnThisItem / TOTAL_BUDGET) * 100).toFixed(2));
        const label = `${
          support.providerName
        } - <strong>${spentOnThisItemFormatted}</strong>  ${this.calculatePercentage(spentOnThisItem, TOTAL_BUDGET)}`;
        const arialabel = `${AVAILABLE_BUDGET_LABEL} - ${this.$filters.currency(
          TOTAL_BUDGET - budgetUsed
        )} ${this.calculatePercentage(spentOnThisItem, TOTAL_BUDGET)}`;

        if (colorCounter >= this.$strings.providerColors.length) {
          colorCounter = 0; //Repeat color if provider is more than default colors
        }
        const providerName = support.providerName ?? "";
        const color =
          providerName.toLowerCase() === MYSELF_LABEL_LOWERCASE
            ? this.$strings.donutBaseColors.myselfColor
            : this.$strings.providerColors[colorCounter];
        colorCounter++;

        budgetUsed += spentOnThisItem;

        return {
          percentage,
          color,
          label,
          arialabel,
          value: spentOnThisItem
        };
      });

      // if (sumBy(sections, "value") > 100) {
      //   return [];
      // }
      sections = sections.filter(section => {
        return section.value > 0;
      });

      sections.push({
        percentage: 0,
        color: this.$strings.donutBaseColors.availableBudgetColor,
        arialabel: `${AVAILABLE_BUDGET_LABEL} - ${this.$filters.currency(
          TOTAL_BUDGET - budgetUsed
        )} ${this.calculatePercentage(TOTAL_BUDGET - budgetUsed, TOTAL_BUDGET)}`,
        label: `${AVAILABLE_BUDGET_LABEL} - <strong>${this.$filters.currency(
          TOTAL_BUDGET - budgetUsed
        )} ${this.calculatePercentage(TOTAL_BUDGET - budgetUsed, TOTAL_BUDGET)}</strong>`,
        value: TOTAL_BUDGET - budgetUsed
      });

      return sections;
    }
  },
  methods: {
    calculatePercentage(budget, total) {
      const percentage = (budget / total) * 100;
      if (isNaN(percentage) || budget < 0) {
        return "";
      }
      return " (" + percentage.toFixed(1) + "%)";
    }
  }
});
</script>
<style lang="scss">
.cdc-container .cdc-legend {
  justify-content: flex-start;
}
.cdc-legend > span {
  width: 90%;
  line-height: 18px;
  font-size: 15px;
  .cdc-legend-item-color {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 99px;
    margin-top: 4px;
  }
}
.cdc-legend .cdc-legend-item {
  align-items: normal;
  margin-left: 0;
}
span.offscreen {
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
