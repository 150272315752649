<template>
  <!-- label is clickable and the file input being there should make this accessible  -->
  <label role="button" tabindex="-1" class="mpm-file-upload">
    Add files
    <component ref="file-form" :is="inputWrapper">
      <input v-bind="$attrs" type="file" @change="fileChange" />
    </component>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "mpm-file-input",
  data() {
    return {
      inputWrapper: "span"
    };
  },
  methods: {
    fileChange(e: any) {
      this.$emit("change", e);
    },
    clear() {
      // this is the most browser compatible way of clearing a file input I could find
      // temporarily wrap the input in a form and reset it
      this.inputWrapper = "form";
      this.$nextTick(() => {
        this.$refs["file-form"]?.reset();
        this.inputWrapper = "span";
      });
    }
  }
});
</script>

<style lang="scss">
.mpm-file-upload {
  background-color: $brand--reserve-budget-file-upload-bg;
  color: $brand--reserve-budget-file-upload-color;
  padding: 0.5rem 1rem;
  font-size: 14px;
  min-height: 2rem;
  border-radius: 4px;
  // make the file input hidden but also focusable
  // make it same shape and position as label so both are clickable but only input is focusable
  input {
    z-index: -2;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &:hover {
    @if $brand == "mpm" {
      background-color: darken($brand--reserve-budget-file-upload-bg, 10%);
    } @else if $brand == "ndsp" {
      background-color: lighten($brand--reserve-budget-file-upload-bg, 10%);
    }
  }
}
</style>
