<template>
  <ion-content class="page-content plan-section pace-plan-view3-tab" tabindex="0" aria-label="Plan page content">
    <section id="pace-plan-view-3" aria-label="pace plan view 3 page">
      <div style="overflow:auto" tabindex="-1">
        <div class="content-spacer">
          <breadcrumbs :breadcrumb-items="breadcrumbItems"></breadcrumbs>

          <div v-if="!$root.isIonUnderMdView" class="main-title-row">
            <h1 class="main-title">Plan</h1>
            <span class="plan-span">
              {{ $filters.friendlyDate(currentPlan.start_date) }} - {{ $filters.friendlyDate(currentPlan.end_date) }}
            </span>
          </div>
          <ion-row v-else class="mobile-main-title-row">
            <ion-col size="6" class="mobile-main-title">
              Plan
            </ion-col>
            <ion-col size="6" class="mobile-plan-span ion-text-end">
              {{ $filters.friendlyDate(currentPlan.start_date) }} - {{ $filters.friendlyDate(currentPlan.end_date) }}
            </ion-col>
          </ion-row>
          <p class="pace-message">
            This shows your plan details from your new look NDIS plan.<img
              class="myndislogo"
              :src="`${$brand_svg_src}/myndislogo.png`"
              alt="my NDIS logo"
            />
          </p>
          <pace-page-header :go-back-path="'/plan/' + $route.params.supportArea" :show-body="$root.isIonUnderMdView">
            <template v-slot:title>{{ headerName }}</template>
            <template v-if="$root.isIonUnderMdView" v-slot:body-content>
              <ion-grid class="balance-cards-section">
                <ion-row>
                  <ion-col size="12" size-md="6">
                    <pace-plan-balance-card
                      :balance-data="{
                        spent: selectedPlanSupport.budget_spent,
                        allocated: selectedPlanSupport.budget_allocated,
                        remaining: selectedPlanSupport.budget_remaining
                      }"
                    />
                  </ion-col>
                  <ion-col size="12" size-md="6">
                    <pace-plan-balance-card
                      v-if="reservedBudget.budget_allocated"
                      reserved-budget
                      :balance-data="{
                        spent: reservedBudget.budget_spent,
                        allocated: reservedBudget.budget_allocated,
                        remaining: reservedBudget.budget_left
                      }"
                    />
                  </ion-col>
                </ion-row>
              </ion-grid>
            </template>
          </pace-page-header>
          <ion-grid v-if="!$root.isIonUnderMdView" class="balance-cards-section">
            <ion-row class="balance-cards-row">
              <ion-col size="12" size-md="6">
                <pace-plan-balance-card
                  :balance-data="{
                    spent: selectedPlanSupport.budget_spent,
                    allocated: selectedPlanSupport.budget_allocated,
                    remaining: selectedPlanSupport.budget_remaining
                  }"
                />
              </ion-col>
              <ion-col size="12" size-md="6">
                <pace-plan-balance-card
                  v-if="reservedBudget.budget_allocated"
                  reserved-budget
                  :balance-data="{
                    spent: reservedBudget.budget_spent,
                    allocated: reservedBudget.budget_allocated,
                    remaining: reservedBudget.budget_left
                  }"
                />
              </ion-col>
            </ion-row>
          </ion-grid>
          <!--   Reserve budget button START   -->
          <div>
            <ion-button
              v-if="$store.getters.isSfClaimingExperience && canReserveBudget"
              role="button"
              size="default"
              expand="block"
              class="reserve-budget-button"
              @click="openReserveBudgetModal"
            >
              <ion-icon :src="$brand_svg_src + `/padlock-invert.svg`" />Reserve Budget</ion-button
            >
          </div>
          <!--   Reserve budget button END   -->
          <ion-card class="sub-label">
            <h1>Your spending overview</h1>
          </ion-card>
          <ion-grid class="spending-overview-section">
            <ion-row>
              <ion-col size="12" size-lg="6">
                <ion-card class="ion-no-margin provider-donut">
                  <ProviderDonut :plan-support="planSupport" :limit="5" cutout="60%" :data="dataSections" />
                </ion-card>
              </ion-col>
              <ion-col size="12" size-lg="6">
                <pace-plan-spend-overview></pace-plan-spend-overview>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <site-map v-desktop-only></site-map>
      </div>
    </section>
  </ion-content>
</template>

<script lang="ts">
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
  IonButton
} from "@ionic/vue";
import { defineComponent } from "vue";
import pacePageHeader from "@/components/pace-plan/PacePageHeader.vue";
import { find } from "lodash";
import { paceSupportCategories, supportTypes } from "@/helpers/support-category";
import PacePlanBalanceCard from "@/components/pace-plan/PacePlanBalanceCard.vue";
import { mapGetters } from "vuex";
import Breadcrumbs from "@/components/menu/Breadcrumbs.vue";
import { isDesktop } from "@/directives/platform";
import { openModal } from "@/helpers/system";
import ReserveBudget from "@/views/ReserveBudget.vue";
import ProviderDonut from "@/components/plans/ProviderDonut.vue";
import PacePlanSpendOverview from "@/components/pace-plan/PacePlanSpendOverview.vue";
import { converter } from "@/helpers/filters";
import SiteMap from "@/components/menu/SiteMap.vue";

export default defineComponent({
  name: "PacePlanView3",
  components: {
    IonContent,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonRow,
    IonCol,
    IonGrid,
    IonIcon,
    pacePageHeader,
    PacePlanBalanceCard,
    Breadcrumbs,
    ProviderDonut,
    PacePlanSpendOverview,
    SiteMap
  },
  computed: {
    ...mapGetters({
      selectedPlanSupport: "pace/planSupports/getSelectedPlanSupport",
      planSupports: "pace/planSupports/getPlanSupports",
      planSupportItems: "pace/planSupports/getPlanSupportItems",
      reservedBudget: "pace/planSupports/getReservedBudget",
      currentPlan: "currentPlan/getCurrentPlan"
    }),
    breadcrumbItems() {
      return [
        { title: "Plan", link: "plan" },
        {
          title: converter("route_name", this.$route.params.supportArea, "sf_name", supportTypes) + " Budget",
          link: `plan/${this.$route.params.supportArea}`
        },
        {
          title: converter("route_name", this.$route.params.supportCategory, "name", paceSupportCategories),
          link: `plan/${this.$route}`
        }
      ];
    },
    headerName() {
      return find(
        paceSupportCategories,
        paceSupportCategory => paceSupportCategory.route_name === this.$route.params.supportCategory
      )?.name;
    },
    canReserveBudget() {
      return this.$store.getters.hasFullParticipantAccess;
    },
    providers() {
      return this.planSupportItems?.providers ?? [];
    },
    planSupport() {
      return this.selectedPlanSupport;
    },
    reimbursements() {
      return this.planSupportItems?.reimbursements ?? [];
    },
    planSupportPreferences() {
      return this.planSupportItems?.plan_support_preferences ?? [];
    },
    dataSections() {
      const sections = [].concat(this.providers, this.reimbursements, this.planSupportPreferences);
      return sections.filter(item => item.budget_spent > 0);
    }
  },
  methods: {
    async openReserveBudgetModal() {
      const categoryId = this.categoryId;
      const planSupportCategoryId = this.planSupportCategorySfId;
      if (isDesktop) {
        await this.$router.push({
          name: "ReserveBudgetTab",
          params: { planSupportId: this.$route.params.planSupportId, categoryName: this.headerName }
        });
      } else {
        openModal(ReserveBudget, {
          componentProps: {
            categoryId,
            planSupportCategoryId
          }
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.content-spacer {
  padding: 50px 16px 0 16px;
  margin-top: 0;
  .main-title-row {
    display: flex;
    align-items: center;
    ion-col {
      padding: 0;
    }
    .main-title {
      font-size: 36px;
      font-weight: bold;
      line-height: 43px;
    }
    .plan-span {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-left: 55px;
    }
  }
  .mobile-main-title-row {
    display: flex;
    align-items: center;
    ion-col {
      padding: 0;
    }
    .mobile-main-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
    }

    .mobile-plan-span {
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
    }
  }
  ion-grid {
    padding: 0;
    ion-col {
      padding: 0;
    }
  }
  .provider-donut {
    margin-top: 24px;
  }

  @media only screen and (min-width: 992px) {
    .provider-donut {
      margin-right: 10px;
    }
  }

  .balance-cards-section {
    margin: 0 -10px;
    .balance-cards-row {
      margin-top: 6px;
    }
  }

  .sub-label {
    background-color: $brand--pace-plan-sub-label-bg;
    margin: 40px 0 0 0;
    padding: 20px;
    h1 {
      color: white;
      font-size: 18px;
      line-height: 22px;
      font-weight: bold;
    }
  }
  .reserve-budget-button {
    margin: 16px 0 0 0;
    text-transform: none;
    height: 48px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 20px;
    --padding-end: 20px;
    --border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    @media only screen and (min-width: 576px) {
      margin: 8px 0 0 0;
      width: 289px;
    }
    ion-icon {
      width: 16px;
      margin-right: 8px;
    }
    &::part(native):focus,
    &::part(native):active {
      outline: 3px solid $brand--button-sidemenu-submit-focus;
      outline-offset: 2px;
      box-shadow: none;
    }
    &:hover {
      color: $brand--font-color-secondary;
      background-color: $brand--button-main-hover;
    }
  }
}
.pace-message {
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 600;
  .myndislogo {
    vertical-align: sub;
    margin-left: 5px;
  }
}
</style>
