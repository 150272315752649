<template>
  <div
    class="progressbar"
    role="progressbar"
    :aria-valuenow="percentageLeft"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuetext="percentageLeft + '%'"
  >
    <div
      class="bar-left"
      :style="{
        width: 100 - percentageLeft + '%',
        minWidth: minimumWidth
      }"
    >
      <div class="inner-bar-left"></div>
    </div>
    <div
      class="bar-right"
      :style="{
        width: percentageLeft + '%',
        minWidth: minimumWidth
      }"
    >
      <div class="inner-bar-right" :class="colorClass"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    colorClass: {
      type: String
    },
    percentageLeft: {
      default: 0
    }
  },
  computed: {
    minimumWidth() {
      return this.percentageLeft > 0 ? "2px" : "0px";
    }
  }
});
</script>
<style lang="scss" scoped>
.progressbar {
  height: 20px;
  margin: 13px 0;
  display: flex;

  .bar-left {
    border-right: 1px solid $brand--pace-progress-bar-left-color;
  }

  .bar-left,
  .bar-right {
    height: 100%;
    clear: both;
    display: flex;
    align-items: center;

    .inner-bar-left {
      height: 1px;
      width: 100%;
      background-color: $brand--pace-progress-bar-left-color;
    }

    .inner-bar-right {
      height: 5px;
      border-radius: 2px;
      width: 100%;

      &.brand {
        background-color: $brand--primary-color;
      }
      &.core {
        background-color: $brand--pace-core-budget-color;
      }
      &.capital {
        background-color: $brand--pace-capital-budget-color;
      }
      &.capacity {
        background-color: $brand--pace-capacity-building-budget-color;
      }
    }
  }
}
</style>
