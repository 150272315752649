<template>
  <div class="activate">
    <svg
      class="mpm-logo"
      viewBox="0 0 133 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.6136 16.957C32.6136 8.12042 25.5062 0.957031 16.7386 0.957031C7.97073 0.957031 0.863281 8.12042 0.863281 16.957C0.863281 25.7936 7.97073 32.957 16.7386 32.957C25.5062 32.957 32.6136 25.7936 32.6136 16.957ZM6.54372 12.9748C6.54372 12.3425 7.03964 11.8429 7.66683 11.8429H7.91454C8.39591 11.8429 8.73146 12.0926 8.93576 12.4307L11.6486 16.885L14.3764 12.4161C14.6096 12.0339 14.9306 11.8429 15.3826 11.8429H15.6308C16.2577 11.8429 16.7536 12.3425 16.7536 12.9748V21.1482C16.7536 21.7803 16.2577 22.2801 15.6308 22.2801C15.0179 22.2801 14.522 21.7656 14.522 21.1482V16.0178L12.5531 19.0755C12.3197 19.4281 12.028 19.6487 11.6194 19.6487C11.2108 19.6487 10.9192 19.4281 10.6861 19.0755L8.74602 16.0619V21.1778C8.74602 21.795 8.25009 22.2801 7.63772 22.2801C7.02509 22.2801 6.54372 21.795 6.54372 21.1778V12.9748ZM18.7223 13.7097L22.0186 18.2228V21.1482C22.0186 21.7803 22.5145 22.2802 23.1417 22.2802C23.7689 22.2802 24.2651 21.7803 24.2651 21.1482V18.1786L27.5614 13.695C27.7072 13.4894 27.853 13.2101 27.853 12.9012C27.853 12.2838 27.4444 11.7986 26.759 11.7986C26.2925 11.7986 25.9569 12.0632 25.6798 12.4748L23.1565 16.1059L20.6766 12.5044C20.3996 12.0926 20.0788 11.8133 19.5681 11.8133C18.9409 11.8133 18.4452 12.2985 18.4452 12.9012C18.4452 13.2245 18.5619 13.4894 18.7223 13.7097Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.7031 17.5171C44.395 17.5171 45.5035 16.6055 45.5035 15.2532V15.2236C45.5035 13.7534 44.4093 12.9893 42.7614 12.9893H40.1796V17.5171H42.7031ZM39.0273 12.5041C39.0273 12.1806 39.2899 11.916 39.5963 11.916H42.8487C45.1534 11.916 46.6703 13.1509 46.6703 15.1796V15.2089C46.6703 17.4288 44.8327 18.5755 42.6592 18.5755H40.1796V21.6921C40.1796 22.0156 39.9171 22.2802 39.5963 22.2802C39.2899 22.2802 39.0273 22.0156 39.0273 21.6921V12.5041Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.3906 11.9758C48.3906 11.6669 48.6383 11.4023 48.9448 11.4023C49.2655 11.4023 49.5137 11.6669 49.5137 11.9758V21.7076C49.5137 22.0308 49.2803 22.281 48.9593 22.281C48.6383 22.281 48.3906 22.0308 48.3906 21.7076V11.9758Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.893 19.4139V18.6789C56.3389 18.5172 55.595 18.3556 54.6762 18.3556C53.2612 18.3556 52.4736 18.9728 52.4736 19.9284V19.9578C52.4736 20.9132 53.349 21.4719 54.3697 21.4719C55.7556 21.4719 56.893 20.6192 56.893 19.4139ZM51.3359 20.002V19.9724C51.3359 18.3703 52.6488 17.5176 54.5592 17.5176C55.5222 17.5176 56.2076 17.6501 56.8785 17.8411V17.5765C56.8785 16.209 56.0472 15.5033 54.6325 15.5033C53.8738 15.5033 53.2321 15.6799 52.6779 15.9298C52.6049 15.9591 52.5318 15.9738 52.4736 15.9738C52.2111 15.9738 51.9779 15.7535 51.9779 15.4889C51.9779 15.2537 52.1381 15.0771 52.2841 15.0184C53.0278 14.6949 53.7865 14.5039 54.7492 14.5039C55.8138 14.5039 56.6305 14.7832 57.1849 15.3417C57.6954 15.8562 57.9582 16.5913 57.9582 17.5616V21.7219C57.9582 22.0454 57.7245 22.2806 57.4183 22.2806C57.0973 22.2806 56.8785 22.0454 56.8785 21.7515V21.0604C56.3537 21.7515 55.4785 22.3687 54.1511 22.3687C52.7509 22.3687 51.3359 21.5602 51.3359 20.002Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.0586 15.1068C60.0586 14.7982 60.3066 14.5336 60.6127 14.5336C60.9337 14.5336 61.1815 14.7835 61.1815 15.1068V15.9301C61.6776 15.1068 62.4652 14.4453 63.7632 14.4453C65.5866 14.4453 66.6512 15.6802 66.6512 17.4886V21.7075C66.6512 22.0308 66.4033 22.281 66.0971 22.281C65.7763 22.281 65.5281 22.0308 65.5281 21.7075V17.7678C65.5281 16.3564 64.7697 15.4746 63.4425 15.4746C62.1445 15.4746 61.1815 16.43 61.1815 17.8561V21.7075C61.1815 22.0308 60.9483 22.281 60.6273 22.281C60.3066 22.281 60.0586 22.0308 60.0586 21.7075V15.1068Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.0195 12.4443C73.0195 12.1357 73.2821 11.8711 73.5885 11.8711H73.7198C73.9675 11.8711 74.1426 12.0034 74.2594 12.1797L77.9058 17.7072L81.5522 12.1797C81.6689 11.9885 81.8586 11.8711 82.0918 11.8711H82.2233C82.5292 11.8711 82.7923 12.1357 82.7923 12.4443V21.6912C82.7923 22.0147 82.5292 22.2793 82.2233 22.2793C81.9023 22.2793 81.6398 21.9998 81.6398 21.6912V14.0174L78.3726 18.8538C78.2411 19.0448 78.0953 19.148 77.8912 19.148C77.6869 19.148 77.5266 19.0448 77.3953 18.8538L74.1426 14.0318V21.7058C74.1426 22.0291 73.8949 22.2793 73.5737 22.2793C73.2675 22.2793 73.0195 22.0291 73.0195 21.7058V12.4443Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.3327 19.4139V18.6789C89.7783 18.5172 89.0347 18.3556 88.1156 18.3556C86.7009 18.3556 85.9131 18.9728 85.9131 19.9284V19.9578C85.9131 20.9132 86.7885 21.4719 87.8092 21.4719C89.1951 21.4719 90.3327 20.6192 90.3327 19.4139ZM84.7754 20.002V19.9724C84.7754 18.3703 86.0882 17.5176 87.9989 17.5176C88.9617 17.5176 89.6471 17.6501 90.3179 17.8411V17.5765C90.3179 16.209 89.4867 15.5033 88.072 15.5033C87.3135 15.5033 86.6718 15.6799 86.1174 15.9298C86.0443 15.9591 85.9718 15.9738 85.9131 15.9738C85.6505 15.9738 85.4174 15.7535 85.4174 15.4889C85.4174 15.2537 85.5778 15.0771 85.7236 15.0184C86.4675 14.6949 87.2259 14.5039 88.1884 14.5039C89.2533 14.5039 90.0705 14.7832 90.6246 15.3417C91.1351 15.8562 91.3976 16.5913 91.3976 17.5616V21.7219C91.3976 22.0454 91.1642 22.2806 90.858 22.2806C90.537 22.2806 90.3179 22.0454 90.3179 21.7515V21.0604C89.7931 21.7515 88.918 22.3687 87.5906 22.3687C86.1904 22.3687 84.7754 21.5602 84.7754 20.002Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M93.498 15.1068C93.498 14.7982 93.746 14.5336 94.0522 14.5336C94.3732 14.5336 94.6212 14.7835 94.6212 15.1068V15.9301C95.1171 15.1068 95.9047 14.4453 97.2027 14.4453C99.0263 14.4453 100.091 15.6802 100.091 17.4886V21.7075C100.091 22.0308 99.8427 22.281 99.5366 22.281C99.2158 22.281 98.9676 22.0308 98.9676 21.7075V17.7678C98.9676 16.3564 98.2094 15.4746 96.882 15.4746C95.5837 15.4746 94.6212 16.43 94.6212 17.8561V21.7075C94.6212 22.0308 94.3878 22.281 94.0668 22.281C93.746 22.281 93.498 22.0308 93.498 21.7075V15.1068Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M107.325 19.4139V18.6789C106.771 18.5172 106.027 18.3556 105.108 18.3556C103.693 18.3556 102.905 18.9728 102.905 19.9284V19.9578C102.905 20.9132 103.78 21.4719 104.801 21.4719C106.187 21.4719 107.325 20.6192 107.325 19.4139ZM101.768 20.002V19.9724C101.768 18.3703 103.08 17.5176 104.991 17.5176C105.954 17.5176 106.639 17.6501 107.31 17.8411V17.5765C107.31 16.209 106.479 15.5033 105.064 15.5033C104.306 15.5033 103.664 15.6799 103.11 15.9298C103.037 15.9591 102.964 15.9738 102.905 15.9738C102.643 15.9738 102.409 15.7535 102.409 15.4889C102.409 15.2537 102.57 15.0771 102.716 15.0184C103.46 14.6949 104.218 14.5039 105.181 14.5039C106.245 14.5039 107.062 14.7832 107.617 15.3417C108.127 15.8562 108.39 16.5913 108.39 17.5616V21.7219C108.39 22.0454 108.156 22.2806 107.85 22.2806C107.529 22.2806 107.31 22.0454 107.31 21.7515V21.0604C106.785 21.7515 105.91 22.3687 104.583 22.3687C103.182 22.3687 101.768 21.5602 101.768 20.002Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M116.732 18.0473V18.0177C116.732 16.4593 115.39 15.445 113.96 15.445C112.531 15.445 111.349 16.4446 111.349 18.0031V18.0324C111.349 19.5612 112.56 20.6196 113.96 20.6196C115.39 20.6196 116.732 19.5759 116.732 18.0473ZM110.824 23.6922C110.65 23.6039 110.533 23.4276 110.533 23.2217C110.533 22.9715 110.781 22.7221 111.043 22.7221C111.145 22.7221 111.218 22.7512 111.291 22.7952C112.079 23.3249 112.983 23.6186 113.96 23.6186C115.623 23.6186 116.703 22.6925 116.703 20.9135V20.0171C116.046 20.8991 115.127 21.6195 113.742 21.6195C111.933 21.6195 110.197 20.2521 110.197 18.0618V18.0324C110.197 15.8125 111.948 14.4453 113.742 14.4453C115.157 14.4453 116.075 15.151 116.688 15.9741V15.1068C116.688 14.7982 116.921 14.5336 117.242 14.5336C117.563 14.5336 117.811 14.7982 117.811 15.1068V20.8842C117.811 22.0601 117.461 22.9571 116.848 23.5743C116.177 24.2507 115.171 24.5889 113.975 24.5889C112.823 24.5889 111.772 24.28 110.824 23.6922Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125.63 17.9884C125.513 16.6063 124.726 15.4007 123.223 15.4007C121.91 15.4007 120.919 16.5036 120.772 17.9884H125.63ZM123.398 22.3837C121.327 22.3837 119.635 20.7815 119.635 18.4293V18.4C119.635 16.2094 121.166 14.4453 123.252 14.4453C125.484 14.4453 126.753 16.283 126.753 18.2967C126.753 18.6059 126.505 18.8264 126.228 18.8264H120.772C120.933 20.4726 122.085 21.3987 123.427 21.3987C124.361 21.3987 125.046 21.0311 125.6 20.5166C125.688 20.443 125.79 20.3846 125.921 20.3846C126.198 20.3846 126.417 20.6049 126.417 20.8695C126.417 21.0018 126.359 21.1344 126.242 21.237C125.527 21.9281 124.682 22.3837 123.398 22.3837Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128.59 15.1064C128.59 14.7978 128.838 14.5332 129.144 14.5332C129.465 14.5332 129.713 14.7832 129.713 15.1064V16.5326C130.267 15.2683 131.361 14.5039 132.309 14.5039C132.645 14.5039 132.863 14.7538 132.863 15.0771C132.863 15.3859 132.659 15.6063 132.367 15.6505C130.909 15.8268 129.713 16.9295 129.713 19.12V21.7072C129.713 22.016 129.48 22.2806 129.159 22.2806C128.838 22.2806 128.59 22.0305 128.59 21.7072V15.1064Z"
        fill="white"
      />
    </svg>
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: "ActivateLayout",
};
</script>

<style lang="scss" scoped>

.activate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 42px 0;
  padding: 0 20px;
  width: 100vw;
  height: 100vh;
  @include grid-media($grid--breakpoint--md) {
    justify-content: center;
    margin: 0;
  }
  .mpm-logo {
    height: 72px;
    width: 293px;
  }
}
</style>
