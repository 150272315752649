<template>
  <div v-if="$store.getters.hasFullParticipantAccess" class="claim-actions" :class="justifyButtons">
    <div class="actions">
      <template v-if="needsAction">
        <div class="button-content">
          <button class="decline" aria-label="Decline this claim" @click="decline">
            <span class="action-button-text">Decline</span>
          </button>
        </div>
        <div class="button-content">
          <button class="approve" aria-label="Approve this claim" @click="approve">
            <span class="action-button-text">Approve</span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "claim-actions",
  props: {
    needsAction: {
      required: true,
      type: Boolean
    },
    status: {
      type: String
    },
    justify: {
      type: String,
      default: null
    }
  },
  methods: {
    approve(evt: Event) {
      this.$emit("approve", evt);
    },
    decline(evt: Event) {
      this.$emit("decline", evt);
    }
  },
  computed: {
    justifyButtons(): string {
      if (this.justify === "left") {
        return "justify-left";
      } else if (this.justify === "right") {
        return "justify-right";
      } else {
        return "";
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.claim-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .status {
    display: flex;
    flex-direction: column;
    .label {
      color: $brand--claims-label-color;
      font-size: 11px;
    }
    .value {
      color: $brand--theme--text-01;
    }
  }
  .actions {
    margin: 0 auto;
    display: flex;
    .button-content {
      .approve {
        color: $brand--button-font-color;
        background-color: $brand--button-main;
        border-color: transparent;
        border-style: solid;
        border-width: 1px;
        &:focus-visible {
          outline: 3px solid $brand--focus-outline-button;
          outline-offset: 2px;
        }
      }
      .approve:hover {
        background-color: $brand--button-main-hover;
      }
      .decline {
        color: $brand--claims-action-decline-color;
        background-color: transparent;
        border-color: $brand--claims-action-decline-color;
        border-style: solid;
        border-width: 1px;
        &:focus-visible {
          outline: 3px solid $brand--focus-outline;
          outline-offset: 2px;
        }
      }
      .decline:hover {
        color: $brand--claims-action-decline-color-hover;
        background-color: $brand--claims-action-decline-bg-hover;
        border: $brand--claims-action-decline-border-hover;
      }
    }
    .approve,
    .decline {
      border-radius: 3px;
      margin: 10px;
      max-width: 110px;
      font-size: 14px;
      padding: 5px 7px;
      line-height: 20px;
      min-height: unset;
      .button-content {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.justify-left {
  justify-content: left !important;
  .actions {
    margin: 0 !important;
  }
}
.justify-right {
  justify-content: right !important;
  .actions {
    margin: 0 !important;
  }
}
</style>
