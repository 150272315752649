<template>
  <div class="spend-summary">
    <div class="title">Total available budget</div>
    <div class="budget">
      {{ currencyFormatter(budget.allocated - budget.spent) }}
    </div>
    <div
      :class="[
        'progress',
        'plan-support-item-' + $store.getters.supportPlanCategory.support_category?.support_category_number
      ]"
    >
      <progress-bar :percentage-left="getPercentage" :proportional-width="100" />
      <span>{{ currencyFormatter(budget.spent) }} SPENT</span>
      <span
        >{{ currencyFormatter(budget.allocated - budget.spent) }} OF
        {{ currencyFormatter(budget.allocated) }} LEFT</span
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ProgressBar from "@/components/plans/ProgressBar.vue";
import { currency } from "@/helpers/formatter";
export default defineComponent({
  props: {
    budget: {
      type: Object
    },
    unallocated: {
      type: Number
    },
    allocated: {
      type: Number
    },
    spent: {
      type: Number
    }
  },
  components: {
    ProgressBar
  },
  computed: {
    getPercentage() {
      return ((this.budget.spent / this.budget.allocated) * 100).toFixed(2);
    }
  },
  methods: {
    currencyFormatter(value) {
      return currency(value);
    }
  }
});
</script>
<style lang="scss">
.spend-summary {
  background: $brand--spend-summary-bg;
  border-radius: 4px;
  padding: 10px 1rem;
  display: inline-block;
  margin: 0 auto 1rem;
  width: 100%;
  .budget {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .title {
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 12px;
  }
  .progress {
    font-size: 12px;
    span {
      line-height: 12px;
    }
    span:first-child {
      float: left;
    }
    span:last-child {
      float: right;
    }
  }
}

@if $brand == "mpm" {
  @each $id, $colour in $plan_support_colours {
    .progress.plan-support-item-#{$id} {
      .progressbar {
        background: $colour !important;
        .bar {
          background-color: lighten($colour, 20%);
        }
      }
    }
  }
} @else if $brand == "ndsp" {
  .plan-support-item[class*="plan-support-category-"] {
    .progressbar {
      background-color: $brand--active !important;
      .bar {
        background-color: $brand--plan-support-category-progressbar-spent-color;
      }
    }
  }
}
</style>
