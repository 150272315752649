<template>
  <div class="plan-support-tile" :class="isNegative ? `negative ${supportTypeColorClass}` : ''">
    <div class="card-title">
      <h2>
        {{ planSupport.support_category.pace_support_category_name }}
      </h2>
      <mpm-tooltip v-if="isNegative">
        <template v-slot:icon>
          <ion-icon class="help-icon" :src="`${$brand_svg_src}/info.svg`" />
        </template>
        <template v-slot:content>
          <p class="tooltip-content">
            You have spent more funds than you had available for this support category. You can still claim as long as
            there is enough funds in your core budget.
          </p>
        </template>
      </mpm-tooltip>
    </div>
    <div class="card-label">
      Opening balance: <span class="card-amount">{{ $filters.currency(planSupport.budget_allocated) }}</span>
    </div>
    <div class="balance-prog-bar">
      <balance-progress-bar
        :color-class="supportTypeColorClass"
        :spent="planSupport.budget_spent"
        :allocated="planSupport.budget_allocated"
        :remaining="planSupport.budget_remaining"
        :reserved="planSupport?.budget_reserved"
        height="17"
        show-labels
      ></balance-progress-bar>
    </div>
    <ion-row class="ion-align-items-center">
      <template
        v-if="
          planSupport.managed === 'plan' ||
            planSupport.support_category.pace_support_category_name === 'Choice and Control'
        "
      >
        <ion-col>
          <a
            tabindex="0"
            aria-label="View Breakdown"
            class="breakdown focus-item"
            @keyup.enter.prevent="$router.push({ path: breakdownLink })"
            @keyup.space.prevent="$router.push({ path: breakdownLink })"
            @click.prevent="$router.push({ path: breakdownLink })"
          >
            View breakdown
          </a>
        </ion-col>
        <ion-col class="plan-managed">
          <ion-icon :src="`${$brand_svg_src}/managed.svg`" :class="`${$brand}-icon`" />
          <span v-if="$brand === 'ndsp'" class="icon-label">Plan managed</span>
        </ion-col>
      </template>
      <ion-col v-else-if="planSupport.managed === 'agency'" class="agency-managed">
        <ion-icon :src="`${$brand_svg_src}/ndis.svg`" class="`agency-icon`" />
        <span v-if="$brand === 'ndsp'" class="icon-label">Agency managed</span>
      </ion-col>
    </ion-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon, IonRow, IonCard, IonCol } from "@ionic/vue";
import balanceProgressBar from "@/components/plans/pace/BalanceProgressBar.vue";
import { converter } from "@/helpers/filters";
import { paceSupportCategories } from "@/helpers/support-category";
import mpmTooltip from "@/components/MpmTooltip.vue";

export default defineComponent({
  name: "PacePlanSupportTile",
  props: {
    planSupport: {
      type: Object,
      required: true
    },
    supportTypeColorClass: {
      type: String,
      required: true
    }
  },
  components: {
    mpmTooltip,
    balanceProgressBar,
    IonIcon,
    IonRow,
    IonCard,
    IonCol
  },
  computed: {
    isNegative() {
      return this.planSupport.budget_spent > this.planSupport.budget_allocated;
    },
    breakdownLink() {
      return `${this.$route.path}/${converter(
        "name",
        this.planSupport.support_category.pace_support_category_name,
        "route_name",
        paceSupportCategories
      )}/${this.planSupport.sfid}`;
    }
  }
});
</script>
<style lang="scss" scoped>
.plan-support-tile {
  margin: 10px;
  padding: 20px;
  border-radius: 3px;
  background-color: $brand--pace-plan-tile-bg;
  &.negative {
    &.core {
      background-color: $brand--pace-plan-tile-negative-core-support-bg;
    }
    &.capital {
      background-color: $brand--pace-plan-tile-negative-capital-support-bg;
    }
    &.capacity {
      background-color: $brand--pace-plan-tile-negative-capacity-building-support-bg;
    }
  }
  .card-title {
    color: $brand--font-color-primary;
    margin-bottom: 8px;
    display: flex;
    align-content: center;
    min-height: 50px;
    h2 {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
    }
    .help-icon {
      margin-left: 4px;
      border: 0;
      border-radius: 11px;
      height: 24px;
      width: 24px;
    }
  }
  .card-label {
    color: $brand--font-color-primary;
    font-size: 14px;
    line-height: 19px;
  }
  .card-amount {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
  .balance-prog-bar {
    margin: 16px 0;
  }
  ion-row {
    ion-col {
      padding: 0;
    }
  }
  .breakdown {
    color: $brand--pace-budget-link-color;
    text-decoration-line: underline;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    letter-spacing: normal;
  }
  .icon-label {
    margin-left: 8px;
    color: $brand--primary-color;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: normal;
  }
  .plan-managed {
    display: flex;
    justify-content: end;
    align-items: center;
    .mpm-icon {
      width: 27px;
      height: 27px;
    }
    .ndsp-icon {
      width: 11px;
      height: 13px;
    }
  }
  .agency-managed {
    display: flex;
    justify-content: end;
    align-items: center;
    .agency-icon {
      height: 11px;
    }
  }
}
</style>
