<template>
  <div class="bx--data-table-container">
    <table
      ref="dataTable"
      :has-expand-all="hasExpandAll"
      class="mpm-data-table data-table"
      :class="isDesktopTable ? 'mpm-desktop-data-table' : 'mpm-mobile-data-table'"
    >
      <thead>
        <!-- to allow a unique id, see https://github.com/carbon-design-system/carbon-components-vue/issues/1007 -->
        <tr class="parent-row">
          <th data-previous-value="expanded" class="bx--table-expand"></th>
          <th
            :id="`column-header-${column}`"
            :class="isDesktopTable ? column : column + ' column-header'"
            v-for="(column, cellIndex) in columns"
            :key="`heading:${cellIndex}`"
          >
            <span>{{ column }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="!Object.keys(data).length">
        <tr>
          <td :colspan="columns.length + 1">
            <slot name="emptyTableMessage"> </slot>
          </td>
        </tr>
      </tbody>
      <template v-for="(group, groupKey) in data">
        <tbody
          v-for="(row, rowIndex) in group"
          :key="`row:${groupKey}:${rowIndex}`"
          :value="`${rowIndex}`"
          :expanded="rowIndex === rowExpanded"
          inner-row-tab-index="-1"
          :show-row-indicator="showIndicatorFunc(row)"
          :class="[{ 'bottom-bordered-group': rowIndex < group.length - 1 }, `plan-support-category-${row.categoryId}`]"
          @row-expanded="onRowExpanded(`${groupKey}:${rowIndex}`)"
          @row-collapsed="onRowCollapsed(`${groupKey}:${rowIndex}`)"
        >
          <slot name="valueCells" v-bind:rowData="row" v-bind:groupKey="`${groupKey}:${rowIndex}`">
            <!-- default cell content -->
            <td
              v-for="(column, cellIndex) in columns"
              :key="`cell:${cellIndex}`"
              :headers="`column-header-${column}`"
              :aria-labelledby="`date-header-${groupKey}`"
              v-html="row[column]"
              tabindex="-1"
              class="currency"
            ></td>
          </slot>
          <slot name="actionsRow" v-bind:rowData="row" v-bind:groupKey="groupKey"> </slot>
          <slot name="expandedContent" v-bind:rowData="row" v-bind:groupKey="`${groupKey}:${rowIndex}`"></slot>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

/*
* This component is intended to be used to render similar to the standard
* Carbon data table but with an extra grouping that presents a label row
* The data needs to be passed in in the form
columns: [
  "col1",
  "col2",
]
data: {
  'GroupLabel': [
    {
      col1: "col1val",
      col2: "col2val",
      ...otherKeys
    },
    ...
  ],
  'GroupLabel': [
    {
      col1: "col1val",
      col2: "col2val",
    },
    ...
  ]
}
* Only columns specifically passed in will be rendered, this allows other meta data
* to be passed in and provided through the scoped slot
*/
export default defineComponent({
  name: "mpm-data-table",
  props: {
    // ...CvDataTable.props,
    isDesktopTable: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    rowExpanded: {
      type: Number
    },
    showIndicatorFunc: {
      type: Function,
      default: () => () => false
    },
    showGroupRowHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    collapseAll() {
      for (const row of this.$refs.dataTable.registeredRows) {
        row.isExpanded = false;
      }
    },
    onRowExpanded(rowIndex) {
      const items = new Set(this.expandedRows);
      items.add(rowIndex);
      this.expandedRows = Array.from(items);
    },
    onRowCollapsed(rowIndex) {
      const items = new Set(this.expandedRows);
      items.delete(rowIndex);
      this.expandedRows = Array.from(items);
    }
  },
  watch: {
    expandedRows: {
      deep: true,
      handler() {
        this.$emit("row-expand-collapse-change", this.expandedRows);
      }
    },
    data() {
      this.expandedRows = [];
    }
  },
  data() {
    return {
      activeIndex: {
        groupKey: "",
        rowIndex: -1
      },
      expandedRows: []
    };
  }
});
</script>

<style lang="scss">
.mpm-data-table {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.16px;
  // table header styles
  .parent-row {
    th {
      padding: 1rem;
      text-transform: uppercase;
      background-color: $brand--primary-table-color;
      text-align: left;
      color: #fff;
    }
    .bx--table-expand {
      width: 2rem;
      padding: 1rem;
      border-left: 3px $brand--primary-table-color solid;
      background-color: $brand--primary-table-color;
    }
  }

  .corner {
    position: absolute;
    transform: translate(-15px, -18px);
    border-style: solid;
    border-width: 18px 18px 0 0;
    border-color: #ff1e1e transparent transparent transparent;
    @media screen and (max-width: $width--breakpoint--md) {
      transform: translate(-15px, -16px);
    }
  }

  .invoice-number-cell {
    word-break: break-word;
  }

  .collapsed {
    display: none !important;
  }

  td {
    padding: 1rem;
    border-bottom: 0 !important;
    background-color: $brand--table-td-color !important;
    color: $brand--font-color-primary !important;
  }

  tr {
    &.bx--expandable-row td {
      background-color: $brand--table-td-color;
      vertical-align: top;
    }
    &.cv-data-table-row-inner td {
      vertical-align: middle;
    }
  }

  .bx--table-expand {
    vertical-align: top;
  }
  .mpm-data-table-group-row {
    height: 21px;
  }
  .mpm-data-table-group-cell {
    padding: 1px 5px;
    line-height: 15px;
    letter-spacing: 0.08em;
    background: rgba(196, 196, 196, 0.2);
  }
  .mpm-data-table-group-label {
    text-align: center;
    font-size: 11px;
    color: #c6c9cd;
    margin: 4px;
  }
  .bx--data-table td,
  tr.bx--parent-row.bx--expandable-row:hover td {
    border-bottom: none !important;
  }

  .bx--table-expand__svg {
    font-size: 16px;
  }

  .expand-collapse-button {
    padding: 1rem 0 2rem 0;
    background-color: transparent;
    height: 3rem;
    transform: rotate(90deg);
  }
  .expand-collapse-button[aria-expanded="true"] {
    transform: rotate(-90deg);
  }
  .mpm-data-table-group-cell {
    border-top: none;
  }

  tbody {
    td {
      outline: none;
    }
    &.bottom-bordered-group {
      tr:last-of-type {
        // border-bottom: 1px solid #4d5564 !important;
      }
    }
    &:focus,
    &:active,
    &:hover {
      tr,
      td {
        background-color: none !important;
        border-top: none !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
        border: none !important;
      }
    }

    tr,
    th,
    td {
      font-weight: $brand--claims-table-row-font-weight;
      border-top: none;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    tr.bx--parent-row:not(.bx--expandable-row):first-of-type:hover td {
      // border-top: none !important;
      border-bottom: none !important;
    }
  }
}

.mpm-desktop-data-table {
  .mpm-data-table tbody tr:hover td {
    background: $brand--accordion-hover !important;
    color: $brand--font-color-primary !important;
  }
  .mpm-data-table tbody {
    tr:hover {
      background: $brand--accordion-hover;
      background-color: $brand--accordion-hover;
    }
  }
}

.mpm-mobile-data-table {
  .mpm-data-table tbody tr:hover td {
    background: $brand--table-td-color !important;
    background-color: $brand--table-td-color !important;
  }
  .mpm-data-table tbody {
    tr:hover {
      background: $brand--table-td-color !important;
      background-color: $brand--table-td-color !important;
    }
  }
  // table header styles
  .parent-row {
    .column-header {
      width: 60%;
    }
    th:last-child {
      width: 40%;
      text-align: right;
    }
  }
}

.in-row-table .line-items-header {
  height: 3rem;
  text-align: left;
}
.in-row-table thead tr th,
.in-row-table tbody tr td {
  padding-left: 1.5rem;
}
</style>
