<template>
  <ion-page>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content
          pulling-icon="svg/loading.svg"
          refreshing-spinner="circles"
          refreshing-text="Refreshing..."
        >
        </ion-refresher-content>
      </ion-refresher>
      <mpm-loading :is-loading="contentsLoading"></mpm-loading>
      <div class="contact-information-form modal-content">
        <form @submit.prevent="submit">
          <ion-grid>
            <ion-row>
              <h1 class="main-title">Contact Details</h1>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-sm="6">
                <ion-label position="stacked">First Name (Required)</ion-label>
                <ion-input
                  class="primary-input"
                  placeholder="Enter first name"
                  v-model="profile.first_name"
                  required="required"
                  @focusout="validateForm"
                  aria-errormessage="contact-first-name"
                  :value="profile.first_name"
                />
                <div v-if="errors.first_name" class="form-requirement form-error" id="contact-first-name" role="alert">
                  {{ handleError(errors.first_name) }}
                </div>
              </ion-col>
              <ion-col size="12" size-sm="6">
                <ion-label position="stacked">Last Name (Required)</ion-label>
                <ion-input
                  id="last-name"
                  class="primary-input"
                  placeholder="Enter last name"
                  required="required"
                  aria-errormessage="contact-last-name"
                  @focusout="validateForm"
                  v-model="profile.last_name"
                />
                <div v-if="errors.last_name" class="form-requirement form-error" id="contact-last-name" role="alert">
                  {{ handleError(errors.last_name) }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-sm="6">
                <ion-label position="stacked">Email</ion-label>
                <ion-input
                  id="email-address"
                  class="primary-input read-only"
                  placeholder="Enter email address"
                  v-model="profile.email"
                  aria-readonly="true"
                  type="email"
                  :readonly="true"
                />
                <div v-if="errors.email" class="form-requirement form-error" id="contact-email" role="alert">
                  {{ handleError(errors.email) }}
                </div>
              </ion-col>
              <ion-col size="12" size-sm="6">
                <ion-label position="stacked">Mobile</ion-label>
                <ion-input
                  id="mobile-number"
                  class="primary-input read-only"
                  v-model="profile.mobile_phone"
                  required="required"
                  @focusout="validateForm"
                  aria-errormessage="contact-mobilephone"
                  readonly
                  aria-readonly="true"
                  value="1234"
                />
                <div
                  v-if="errors.mobile_phone"
                  class="form-requirement form-error"
                  id="contact-mobile-phone"
                  role="alert"
                >
                  {{ handleError(errors.mobile_phone) }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-sm="6">
                <ion-label position="stacked">Alternate Phone Number</ion-label>
                <ion-input
                  id="alternate-number"
                  class="primary-input"
                  placeholder="Enter number"
                  v-model="profile.phone"
                  @focusout="validateForm"
                  aria-errormessage="contact-phone"
                  :v-model="profile.phone"
                />
                <div v-if="errors.phone" class="form-requirement form-error" id="contact-phone" role="alert">
                  {{ handleError(errors.phone) }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <div class="form-actions">
                <ion-button class="secondary-action" @click.prevent="close">
                  Cancel
                </ion-button>
                <ion-button type="primary" class="call-to-action" :disabled="isDisabled">
                  Update
                </ion-button>
              </div>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { updateUserProfile } from "@/api/user";
import { closeModal } from "@/helpers/system";
import validate from "@/validators/ContactInformationValidator";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonInput
} from "@ionic/vue";
import ModalHeader from "@/components/ModalHeader.vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "ContactInformationForm",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonButton,
    ModalHeader,
    IonRefresher,
    IonRefresherContent,
    IonInput
  },
  data() {
    return {
      modalName: "Contact Details Form",
      errors: {},
      profile: {
        first_name: null,
        last_name: null,
        mobile_phone: null,
        phone: null
      },
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    })
  },
  methods: {
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    close() {
      this.clearForm();
      closeModal();
    },
    async submit() {
      if (Object.keys(this.errors).length < 1) {
        this.isDisabled = true;
        await updateUserProfile(this.profile)
          .then(response => {
            if (response.id) {
              this.loadPage();
              this.$notification.createToast("The settings information has been updated.", "success");
            }
            this.isDisabled = false;
          })
          .catch(() => {
            this.$notification.createToast("There was an error updating settings. Please try again.", "error");
            this.isDisabled = false;
          });
      }
    },
    async validateForm() {
      this.errors = {};
      const user = this.profile;
      user.phone = user.phone ? user.phone.replace(/\s+/g, "") : "";

      await validate(user).catch(errors => {
        this.errors = errors;
      });
    },
    clearForm() {
      this.errors = {};
      this.profile = Object.assign({}, this.defaultProfile);
    },
    handleError(error) {
      return error && error[0] ? error[0] : "";
    },
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async loadPage() {
      this.updateContentsLoading(true);
      try {
        await this.$store.dispatch("user/refreshUserDetails");
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.profile = Object.assign({}, this.$store.getters.user.profile);
        this.defaultProfile = Object.assign({}, this.$store.getters.user.profile);
        this.updateContentsLoading(false);
      }
    }
  },
  mounted() {
    this.profile = Object.assign({}, this.$store.getters.user.profile);
    this.defaultProfile = Object.assign({}, this.$store.getters.user.profile);
  }
});
</script>

<style lang="scss" scoped>
.contact-information-form {
  .form-actions {
    width: 100%;
    justify-content: right;
  }

  .primary-input {
    background-color: $brand--text-input-bg-disabled;
    border-bottom: 1px solid $brand--text-input-border-color;
    color: $brand--text-input-color-placeholder;
    font-size: 0.875rem;
    font-weight: 400;
  }

  ion-col {
    --ion-grid-column-padding: 5px;
  }

  ion-label {
    font-weight: 600;
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  ion-input.primary-input {
    --padding-start: 10px;
    margin-top: 5px;
    min-height: 45px !important;
    color: $brand--text-input-color;
  }
  ion-input.has-focus {
    --background-focused: transparent;
    outline: 3px solid $brand--focus-outline;
  }
  .read-only {
    color: $brand--text-input-color-placeholder !important;
  }
  .bx--form-item {
    width: 100%;
  }

  .sub-header {
    margin-top: 16px;
  }

  .label-lg label {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    letter-spacing: 0.04em;
    text-overflow: ellipsis;
    max-width: 146px;
  }

  @media only screen and (max-width: 25.688rem) {
    .sub-header-lg {
      display: none;
    }
    .sub-header-sm {
      display: block;
      margin-bottom: 35px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      align-items: center;
      letter-spacing: 0.08em;
    }
  }

  @media only screen and (min-width: 25.688rem) {
    .sub-header-lg {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: #fff;
      margin-bottom: 24px;
      margin-top: 31px;
    }
    .sub-header-sm {
      display: none;
    }
  }

  @media only screen and ($brand-support-drawer-breakpoint) {
    p.sub-header-lg {
      display: none;
    }
    .btn-update {
      min-height: 38px;
      height: 47px;
      letter-spacing: 0;
      line-height: 100%;
    }
  }
}

@media only screen and (min-width: 42rem) and(max-width: 66rem) {
  .label-lg label {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.04em;
    max-width: 100px;
  }
}

.bx--form-item {
  .form-error {
    color: #ff8389;
    max-height: 12.5rem;
    display: block;
  }
}
</style>
