<template>
  <div class="container" tabindex="0" :aria-label="percentage.toFixed(2) + '%'">
    <svg class="svg" width="100%" height="100%" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle
        :r="radius"
        cx="11.5"
        cy="11.5"
        fill="transparent"
        :stroke-dasharray="strokeDashArray"
        stroke-dashoffset="0"
        stroke="#444D5C"
      ></circle>
      <circle
        :r="radius"
        cx="11.5"
        cy="11.5"
        fill="transparent"
        :stroke-dasharray="strokeDashArray"
        :stroke-dashoffset="strokeDashoffset"
        :stroke="colour"
        class="bar"
      ></circle>
    </svg>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CircleProgressBar",
  props: {
    colour: {
      type: String,
      default: "#0061f6"
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      strokeDashArray: 0,
      strokeDashoffset: 0,
      radius: 9
    };
  },
  methods: {
    percentageLeft() {
      let percentageValue = this.percentage;

      if (percentageValue < 0) {
        percentageValue = 0;
      }

      if (percentageValue > 100) {
        percentageValue = 100;
      }

      this.strokeDashoffset = ((100 - percentageValue) / 100) * this.strokeDashArray;
    }
  },
  mounted() {
    this.strokeDashArray = Math.PI * (this.radius * 2);
    this.percentageLeft();
  }
});
</script>

<style lang="scss" scoped>
.svg {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  height: 23px;
  width: 23px;
}
.svg circle {
  transition: stroke-dashoffset linear;
  stroke-width: 4px;
}

.container {
  display: inline-block;
  width: 23px;
  height: 23px;
}

.container:after {
  position: absolute;
  display: block;
  width: 23px;
  height: 23px;
  left: 50%;
  top: 50%;
  margin-top: -80px;
  margin-left: -80px;
  border-radius: 100%;
  line-height: 160px;
  font-size: 2em;
}
</style>
