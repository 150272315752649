<template>
  <a class="help-link" target="_blank" :href="href">
    <ion-icon class="svg" src="svg/help.svg" />
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IonIcon } from "@ionic/vue";
export default defineComponent({
  props: {
    href: {
      required: true,
      type: String
    }
  },
  components: {
    IonIcon
  }
});
</script>

<style lang="scss" scoped>
.help-link {
  color: #78a9ff;
  text-decoration: none;
  outline: none;
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  display: flex;
  align-items: center;
  &:hover {
    color: white;
  }
}
.svg {
  height: 18px;
  width: 18px;
  margin: 0 10px 0 0;
}
</style>
