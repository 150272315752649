<template>
  <ion-page>
    <modal-header :modal-name="modalName"></modal-header>
    <ion-content>
      <section id="participant-contact" aria-label="participant contact">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content
            pulling-icon="svg/loading.svg"
            refreshing-spinner="circles"
            refreshing-text="Refreshing..."
          >
          </ion-refresher-content>
        </ion-refresher>
        <mpm-loading :is-loading="contentsLoading"></mpm-loading>
        <div class="participant-contacts modal-content">
          <form @submit.prevent aria-describedby="contacts-description" aria-label="Contacts">
            <h1 class="main-title">Contact Details</h1>
            <hr role="presentation" />
            <ion-grid v-for="(contact, idx) in contacts" :key="idx">
              <ion-row>
                <h5>Contact {{ idx + 1 }}</h5>
              </ion-row>
              <ion-row>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">CONTACT FIRST NAME</ion-label>
                  <ion-input class="primary-input" :aria-readonly="true" :readonly="true" :value="contact.first_name" />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">CONTACT LAST NAME</ion-label>
                  <ion-input class="primary-input" :aria-readonly="true" :readonly="true" :value="contact.last_name" />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">CONTACT EMAIL</ion-label>
                  <ion-input class="primary-input" :aria-readonly="true" :readonly="true" :value="contact.email" />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">MOBILE NUMBER</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    class="number primary-input"
                    :value="contact.mobile_phone"
                  />
                </ion-col>
                <ion-col size="12" size-sm="6">
                  <ion-label position="stacked">ALTERNATE PHONE NUMBER</ion-label>
                  <ion-input
                    :aria-readonly="true"
                    :readonly="true"
                    class="number primary-input"
                    :value="contact.phone"
                  ></ion-input>
                </ion-col>
              </ion-row>
              <br />
            </ion-grid>
            <button :aria-disabled="true" :disabled="true" v-show="false">Submit</button>
          </form>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalHeader from "@/components/ModalHeader.vue";
import MpmTextInput from "@/components/MpmTextInput.vue";
import { IonRow, IonCol, IonGrid, IonPage, IonContent, IonIcon, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  components: {
    IonRow,
    IonCol,
    IonGrid,
    MpmTextInput,
    ModalHeader,
    IonPage,
    IonContent,
    IonIcon,
    IonRefresher,
    IonRefresherContent
  },
  name: "participant-contacts",
  data() {
    return {
      contacts: this.$store.getters.participantContacts,
      modalName: "Participant Contacts"
    };
  },
  computed: {
    ...mapGetters("layout", {
      contentsLoading: "getContentsLoading"
    })
  },
  methods: {
    ...mapActions("layout", {
      updateContentsLoading: "updateContentsLoading"
    }),
    doRefresh(refresher) {
      refresher.target.complete();
      this.loadPage();
    },
    async loadPage() {
      this.updateContentsLoading(true);
      try {
        await this.$store.dispatch("user/refreshUserDetails");
        await this.$store.dispatch("participant/getParticipants");
      } catch (error) {
        console.error(error);
      } finally {
        this.updateContentsLoading(false);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.participant-contacts {
  margin: 10px 15px;
  .main-title {
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 10px;
  }
  .primary-input {
    background-color: $brand--text-input-bg-disabled;
    border-bottom: 1px solid $brand--text-input-border-color;
    color: $brand--text-input-color-placeholder;
    font-size: 0.875rem;
    font-weight: 400;
  }
  h5 {
    padding-left: 5px;
  }
  ion-col {
    --ion-grid-column-padding: 5px;
  }
  hr {
    margin: 20px 10px !important;
  }
  ion-label {
    font-weight: 600;
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  ion-input.primary-input {
    --padding-start: 10px;
    margin-top: 5px;
    min-height: 45px !important;
  }
  ion-input.has-focus {
    --background-focused: transparent;
    outline: 3px solid $brand--focus-outline;
  }
}
</style>
