<template>
  <ion-page>
    <ion-content>
      <activate-layout>
        <div class="activate-password-form">
          <div class="bx--row">
            <div class="bx--col">
              <h1 class="sub-header-lg">Setup / Reset password</h1>
              <p class="desc">
                Your new password must be more than 5 characters long
              </p>
            </div>
          </div>
          <form class="activate-login" @submit.prevent="submit">
            <ion-grid>
              <ion-row>
                <label class="label">
                  NEW PASSWORD*
                </label>
                <ion-input
                  aria-label="New Password"
                  helper-text=""
                  type="password"
                  autocomplete="false"
                  v-model="password.newPassword"
                  required="required"
                  @focusout="validateForm"
                  aria-errormessage="new-password"
                  class="input"
                />
                <div v-if="errors.newPassword" class="bx--form-requirement form-error" id="new-password" role="alert">
                  {{ handleError(errors.newPassword) }}
                </div>
              </ion-row>
              <ion-row>
                <label class="label">
                  RE-ENTER NEW PASSWORD*
                </label>
                <ion-input
                  aria-label="Re-enter new password"
                  helper-text=""
                  type="password"
                  v-model="password.passwordConfirm"
                  :password-visible="false"
                  autocomplete="false"
                  required="required"
                  @focusout="validateForm"
                  aria-errormessage="confirm-password"
                  class="input"
                />
                <div
                  v-if="errors.passwordConfirm"
                  class="bx--form-requirement form-error"
                  id="confirm-password"
                  role="alert"
                >
                  {{ handleError(errors.passwordConfirm) }}
                </div>
              </ion-row>
              <ion-row>
                <div class="button-wrap">
                  <ion-button>Set password</ion-button>
                </div>
              </ion-row>
            </ion-grid>
          </form>
        </div>
      </activate-layout>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import validate from "@/validators/ActivatePasswordValidator";
import ActivateLayout from "@/layouts/ActivateLayout.vue";
import { validateToken, activateToken } from "@/api/activate";
import { IonRow, IonCol, IonGrid, IonInput, IonPage } from "@ionic/vue";

export default defineComponent({
  name: "activate-login",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    ActivateLayout,
    IonInput,
    IonPage
  },
  data() {
    return {
      errors: {},
      validation: {},
      password: {
        newPassword: null,
        passwordConfirm: null
      },
      loading: false
    };
  },
  async mounted() {
    return await this.init();
  },
  methods: {
    async init() {
      const { u, type } = this.$route.query;
      const { token } = this.$route.params;
      const email = atob(u);
      this.loading = true;
      try {
        const validationResult = await validateToken(token, {
          email,
          u,
          token,
          type,
          user: u,
          valid: true
        });
        if (!validationResult.valid) {
          this.$notification.createToast("The link was either expired or invalid.", "error");
          this.$router.push({ name: "PasswordlessLogin" });
        }
        this.validation = validationResult;
      } catch (error) {
        console.error(error);
        this.$notification.createToast(
          `Something went wrong, please refresh and contact ${this.$strings.brand_name_short} if the error persists.`,
          "error"
        );
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      if (Object.keys(this.errors).length < 1) {
        this.loading = true;
        try {
          await activateToken({
            ...this.validation,
            password: this.password.newPassword,
            password_confirmation: this.password.passwordConfirm
          });
          this.$router.push({
            name: "ActivateSuccess"
          });
        } catch (error) {
          console.error(error);
          this.$notification.createToast(
            `Something went wrong, please refresh and contact ${this.$strings.brand_name_short} if the error persists.`,
            "error"
          );
        } finally {
          this.loading = false;
        }
      }
    },
    validateForm() {
      this.errors = {};
      const password = this.password;

      validate(password).catch(errors => {
        this.errors = errors;
      });
    },
    handleError(error) {
      return error && error[0] ? error[0] : "";
    },
    clearForm() {
      this.errors = {};
      this.password = {
        newPassword: null,
        passwordConfirm: null
      };
    }
  }
});
</script>

<style lang="scss">
.activate-password-form {
  margin-top: 2.4rem;
  h1 {
    font-size: 18px;
  }
  .desc {
    margin: 10px 0 40px 0;
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    .btn-update {
      flex: 1 1 auto;
      max-width: 223px;
      line-height: 150%;
      padding: 0;
      display: inline-block;
      text-align: center;
      position: relative;
      font-size: 16px;
    }
  }
  .activate-login {
    padding: 0;
  }
  .cv-text-input {
    width: 100%;
  }

  @media only screen and (min-width: 0rem) and (max-width: 42rem) {
    .btn-update {
      letter-spacing: 0;
      line-height: 100%;
      max-width: 109px;
    }
  }

  @media only screen and (min-width: 21rem) and (min-width: 42rem) {
    .desc {
      font-size: 14px;
    }
    .activate-password-form {
      margin-left: 1rem;
    }
  }
  .input {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: $brand--body-font-weight;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: #000000;
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #b4ec51;
    transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  }
  .label {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.32px;
    color: #ffffff;
    font-weight: $brand--body-font-weight;
    vertical-align: baseline;
    margin-bottom: 0.5rem;
    line-height: 1rem;
  }
  .form-item {
    margin-bottom: 1rem;
  }
  .btn-update {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 42rem) {
  .activate-password-form {
    margin-left: 1rem;
    margin-top: 46px;
  }
}
</style>
