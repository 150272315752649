<template>
  <ion-page>
    <ion-content>
      <activate-layout class="activate-success">
        <p>Your password for access to the {{ $strings.brand_name }} Client Portal has been set/reset successfully.</p>
        <p>
          <strong>To access the client portal</strong> go to
          <a href="/">portal.myplanmanager.com.au</a>
        </p>
        <p>
          Please note passwords can be reset in the <strong>user settings</strong> on the
          <strong>client portal</strong>.
        </p>
      </activate-layout>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActivateLayout from "@/layouts/ActivateLayout.vue";
import { IonInput, IonPage } from "@ionic/vue";

export default defineComponent({
  name: "activate-success",
  components: { ActivateLayout, IonInput, IonPage }
});
</script>

<style lang="scss" scoped>
.activate-success {
  a {
    color: #73a4ff;
  }
  p {
    max-width: 525px;
    padding: 20px;
    line-height: 1.6;
  }
}
</style>
