<template>
  <div class="cell-content">
    <span class="label">{{ label }}</span>
    <div class="cell-body">
      <div class="cell-icon-div">
        <ion-icon v-if="icon" aria-hidden="true" :src="icon" class="cell-icon"></ion-icon>
      </div>
      <div>
        <slot>{{ value }}</slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonIcon } from "@ionic/vue";
export default defineComponent({
  name: "claim-info-cell-content",
  components: {
    IonIcon
  },
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    icon: {
      type: String
    }
  }
});
</script>
<style lang="scss" scoped>
.cell-content {
  display: flex;
  flex-direction: column;
  .label {
    color: $brand--claims-label-color;
    font-size: 11px;
  }
  .cell-body {
    margin-top: 0.4rem;
  }
  .cell-icon-div {
    float: left;
  }
  .cell-icon {
    height: 17px;
  }
}
</style>
